import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { fetcher } from '../../services/api';

type CitySelectProps = {
  name: string;
  placeholder?: string;
  value: string | null;
  onSelectCity: (value: string) => void;
};

function getLoader() {
  return async (inputValue: string) => {
    const data = (await fetcher({
      url: '/cities',
      params: {
        query: inputValue || undefined,
      },
    })) as Array<string>;

    const options = data.map((city) => ({
      label: city,
      value: city,
    }));

    if (inputValue && !options.some((option) => option.value === inputValue)) {
      options.push({ label: inputValue, value: inputValue });
    }

    return options;
  };
}

export const CitySelect = React.forwardRef(function (
  props: CitySelectProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const [inputValue, setInputValue] = useState(props.value || '');

  return (
    <AsyncSelect
      name={props.name}
      placeholder={props.placeholder}
      cacheOptions
      loadOptions={getLoader()}
      isMulti={false}
      isSearchable={true}
      defaultValue={
        props.value ? { label: props.value, value: props.value } : null
      }
      onInputChange={(newValue) => {
        setInputValue(newValue);
      }}
      onChange={(selectedOption) => {
        const valueToSave = selectedOption ? selectedOption.value : inputValue;

        props.onSelectCity(valueToSave);
      }}
      //@ts-ignore
      ref={ref}
    />
  );
});
