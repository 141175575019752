import React from 'react';
import { Header } from '../../common/header.component';
import Dropdown from 'rsuite/Dropdown';
import { RbacObject } from '../../../export-types/cleaned-types';
import { Rbac } from '../../common/Rbac';
import { NavLink, Outlet } from 'react-router-dom';
import { ModalsService } from '../../../services/modals.service';
import { SupplierModal } from './supplier-modal.component';
import { LocationModal } from './location-modal.component';
import { RbacType } from '../finance/finance-admin-screen.component';

type Tab<Object extends RbacObject> = {
  path: string;
  title: string;
  rbac: RbacType<Object>;
};

const Suppliers: Tab<RbacObject.Supplier> = {
  path: 'list',
  title: 'Поставщики',
  rbac: {
    object: RbacObject.Supplier,
    action: ['read:admin'],
  },
};
const Locations: Tab<RbacObject.Location> = {
  path: 'location',
  title: 'Локации',
  rbac: {
    object: RbacObject.Location,
    action: ['read:admin'],
  },
};
const tabs = [Suppliers, Locations];

export const SuppliersLayout = () => {
  async function onSelectAdd(eventKey: number) {
    switch (eventKey) {
      case 1: {
        return ModalsService.createModal(SupplierModal);
      }
      case 2: {
        return ModalsService.createModal(LocationModal);
      }
    }
  }

  return (
    <>
      <Header>
        <div className="d-flex flex-row align-items-center">
          <h1>Поставщики</h1>
          <Rbac object={RbacObject.Transaction} action={'write:admin'}>
            <div className="ps-4">
              <Dropdown
                toggleClassName="btn btn-success"
                placement="bottomEnd"
                title="Добавить"
                trigger="click"
                onSelect={onSelectAdd}
              >
                <Dropdown.Item eventKey={1}>
                  <i className="bi bi-globe" />
                  <span className="ps-2">Поставщика</span>
                </Dropdown.Item>
                <Dropdown.Item eventKey={2}>
                  <i className="bi bi-geo-alt" />
                  <span className="ps-2">Локацию</span>
                </Dropdown.Item>
              </Dropdown>
            </div>
          </Rbac>
        </div>
      </Header>
      <div className="d-flex flex-row mb-4">
        <ul className="nav nav-pills d-inline-flex">
          {tabs.map(({ path, title, rbac }) => (
            <Rbac object={rbac.object} action={rbac.action} key={path}>
              <li className="nav-item" key={path}>
                <NavLink to={path} className="nav-link text-decoration-none">
                  {title}
                </NavLink>
              </li>
            </Rbac>
          ))}
        </ul>
      </div>
      <Outlet />
    </>
  );
};
