import { api } from './api';

export class DeliveryMethodService {
  static createDeliveryMethod(deliveryMethod: unknown) {
    return api.post('/delivery-method', deliveryMethod).then(({ data }) => {
      return data;
    });
  }

  static updateDeliveryMethod(deliveryMethod: unknown, id: string) {
    return api
      .put(`/delivery-method/${id}`, deliveryMethod)
      .then(({ data }) => {
        return data;
      });
  }
}
