import React, { PropsWithChildren } from 'react';

type InputWrapperProps = {
  className?: string;
  title?: React.ReactChild;
  required?: boolean;
  error?: string;
  theme?: 'light';
};

const defaultTheme = {
  titleClassName: 'mb-2',
};

const themes = {
  light: {
    titleClassName: 'mb-1 color-gray-600',
  },
};

export const InputWrapper: React.FC<PropsWithChildren<InputWrapperProps>> =
  function (props) {
    const theme = props.theme
      ? themes[props.theme]
      : defaultTheme || defaultTheme;

    return (
      <div className={props.className}>
        {props.title && (
          <div className={theme.titleClassName}>
            {props.title}
            {props.required && <span className="color-red"> *</span>}
          </div>
        )}
        {props.children}
        {props.error && <div className="color-red">{props.error}</div>}
      </div>
    );
  };
