import { useSWRConfig } from 'swr';

export function useGlobalMutate() {
  const { mutate } = useSWRConfig();

  return {
    mutate: () => {
      return mutate(() => true);
    },
  };
}
