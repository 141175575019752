import React, { useEffect, useState } from 'react';
import { Header } from '../../common/header.component';
import { NavLink, Outlet } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher } from '../../../services/api';
import { FormattedMessage, useIntl } from 'react-intl';
import { RbacObject } from '../../../export-types/cleaned-types';
import { Rbac } from '../../common/Rbac';
import {
  SupplierSelect,
  useSelectSupplierQs,
} from '../purchase-order/supplier-select';
import { useSearchParamsData } from '../purchase-order/useSearchParamsData';

export type CompositeDeliveryPropsStatus =
  | 'assembly'
  | 'sentToRussia'
  | 'receivedInRussia';

export type OutletContextParams = {
  debouncedInternalId: string;
  selectSupplierId: number | undefined;
};

export const CompositeDeliveriesScreen = () => {
  const intl = useIntl();
  const tabs = [
    {
      title: intl.formatMessage({
        id: 'delivery.status-label.onPicking',
        defaultMessage: 'На комплектации',
        description: 'Статус доставки',
      }),
      path: 'assembly',
      name: 'assembly' as const,
    },
    {
      title: intl.formatMessage({
        id: 'deliveryOrders.tab-label.InTransitToAWarehouseInRussia',
        defaultMessage: 'В пути на склад в РФ',
        description: 'Надпись на вкладке',
      }),
      path: 'sent-to-russia',
      name: 'sentToRussia' as const,
    },
    {
      title: intl.formatMessage({
        id: 'deliveryOrders.tab-label.inTheWarehouseInRussia',
        defaultMessage: 'На складе в РФ',
        description: 'Надпись на вкладке',
      }),
      path: 'received-in-russia',
      name: 'receivedInRussia' as const,
    },
  ];

  const [searchParamsData, setSearchParamsFunctions] = useSearchParamsData();

  const [internalId, setInternalId] = useState(
    searchParamsData['internal'] || '',
  );
  const [supplierId] = useSelectSupplierQs();

  useEffect(() => {
    if (searchParamsData['internal'] !== internalId) {
      setSearchParamsFunctions['internal'](internalId);
    }
  }, [internalId, setSearchParamsFunctions]);

  const { data, isLoading } = useSWR<{
    items: Record<CompositeDeliveryPropsStatus, number | undefined>;
  }>(
    {
      url: '/composite-delivery/total-by-status',
      params: {
        internalId: internalId ? internalId : undefined,
        supplierId: supplierId,
      },
    },
    fetcher,
  );

  const count = React.useMemo(() => {
    if (!data) {
      return {};
    }

    return {
      assembly: data.items.assembly || 0,
      sentToRussia: data.items.sentToRussia || 0,
      receivedInRussia: data.items.receivedInRussia || 0,
    };
  }, [data]);

  function renderTabCount(
    item: typeof tabs extends Array<infer T> ? T : never,
  ) {
    if (isLoading) {
      return (
        <>
          (<i className="bi bi-three-dots color-gray-400" />)
        </>
      );
    }

    if (typeof count[item.name] === 'undefined') {
      return null;
    }

    return `(${count[item.name]})`;
  }

  const context: OutletContextParams = {
    debouncedInternalId: internalId,
    selectSupplierId: supplierId,
  };

  return (
    <>
      <Header>
        <div className="d-flex flex-row">
          <h1>
            <FormattedMessage
              defaultMessage="Транзитные посылки"
              id="compositeDeliveries.title.transitParcels"
              description="Заголовок страницы"
            />
          </h1>
        </div>
      </Header>
      <div className="d-flex justify-content-between align-items-center pb-4 ">
        <div className="d-flex w-75 mr-auto">
          <input
            className="form-control w-25"
            type="text"
            placeholder="Поиск по номеру заказа/клиента"
            value={searchParamsData['internal']}
            onChange={(event) => setInternalId(event.currentTarget.value)}
          />
          <Rbac object={RbacObject.Supplier} action={'read:admin'}>
            <SupplierSelect />
          </Rbac>
        </div>
      </div>

      <ul className="nav nav-pills mb-4 d-inline-flex">
        {tabs.map((item) => (
          <li className="nav-item" key={item.title}>
            <NavLink to={item.path} className="nav-link text-decoration-none">
              {item.title} {renderTabCount(item)}
            </NavLink>
          </li>
        ))}
      </ul>
      <Outlet context={context} />
    </>
  );
};
