import React from 'react';
import { toMoney } from '../../../utils/common.utils';
import { CleanTransaction } from '../../../export-types/cleaned-types';

type TransactionItemProps = {
  transaction: CleanTransaction;
};

export const TransactionItem: React.FC<TransactionItemProps> = ({
  transaction,
}) => {
  const description = React.useMemo(() => {
    if (transaction.operationName) {
      return transaction.operationName;
    }
    if (
      transaction.journal === 'additionalServiceChargeExpense' &&
      transaction.type === 'writeOff'
    ) {
      return 'Дополниельные расходы (факт)';
    }
    if (
      transaction.journal === 'refundAdditionalServiceChargeExpense' &&
      transaction.type === 'refill'
    ) {
      return 'Возврат дополнительных расходов (факт)';
    }
    if (
      transaction.type === 'refill' &&
      !transaction.deliveryOrder &&
      !transaction.purchaseOrder
    ) {
      return 'Пополнение баланса';
    }
    if (transaction.type === 'refill' && transaction.bonus) {
      return `Начисление партнёрского бонуса ${
        transaction.user && `для ID ${transaction.user.internalId}`
      }`;
    }
    if (
      transaction.type === 'refill' &&
      (transaction.purchaseOrder || transaction.deliveryOrder)
    ) {
      return 'Возврат по заказу';
    }
    if (transaction.type === 'writeOff' && transaction.purchaseOrder) {
      return 'Оплата заказа';
    }
    if (transaction.type === 'writeOff' && transaction.deliveryOrder) {
      return 'Оплата доставки';
    }
  }, [transaction]);

  return (
    <div className="rounded-8 bg-white px-3 py-2 mb-2">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="me-2">
          {transaction.createdBy && (
            <div className="color-gray-400 fs-14 mb-1">
              {transaction.createdBy.name
                ? transaction.createdBy.name
                : `ID ${transaction.createdBy.internalId}`}
            </div>
          )}
          <div className="color-gray-800 fs-16 fw-500">{description}</div>
        </div>
        <div className="color-gray-800 fs-16 fw-500">
          {toMoney(transaction.amount, transaction.currency)}
        </div>
      </div>
      {transaction.privateComment && (
        <div className="mt-2 bg-gray rounded-8 py-2 px-3">
          {transaction.privateComment}
        </div>
      )}
    </div>
  );
};
