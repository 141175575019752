import React from 'react';
import { CleanLocalDeliveryMethod } from '../../../export-types/cleaned-types';
import { FormattedMessage } from 'react-intl';

type LocalDeliveryProps = {
  localDelivery: CleanLocalDeliveryMethod;
  addHover?: boolean;
};

export const LocalDelivery: React.FC<LocalDeliveryProps> = ({
  localDelivery,
  addHover,
}) => {
  return (
    <>
      {(addHover && (
        <div
          className="rs-table-cell-full-text p-0 bg-transparent hover-bg-light"
          style={{
            width: '5vw',
          }}
        >
          <div className="color-gray-800 fs-16 fw-500 rs-table-cell-content p-0">
            {localDelivery.name}
          </div>
          <div className="color-gray-600 fs-14 fw-400 rs-table-cell-content p-0">
            <FormattedMessage
              defaultMessage="ТК по РФ"
              id="delivery.label.shippingCompanyWithinTheRussianFederation"
              description="Информация от ТК"
            />
          </div>
        </div>
      )) || (
        <div>
          <div className="color-gray-800 fs-16 fw-500">
            {localDelivery.name}
          </div>
          <div className="color-gray-600 fs-14 fw-400">
            <FormattedMessage
              defaultMessage="ТК по РФ"
              id="delivery.label.shippingCompanyWithinTheRussianFederation"
              description="Информация от ТК"
            />
          </div>
        </div>
      )}
    </>
  );
};
