import React, { useCallback, useEffect } from 'react';
import { UploadedFileV2 } from '../../services/file-service';
import { Gallery } from './gallery/gallery';
import { RenderFile } from './render-file';

export type MediaInputValue =
  | {
      type: 'new';
      file: File | Blob;
    }
  | {
      type: 'exist';
      file: UploadedFileV2;
    };

type MediaInputProps = {
  className?: string;
  name?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  value?: MediaInputValue[];
  onChange: (event: { target: { value: MediaInputValue[] } }) => void;
  multiple?: boolean;
  enableClipboardPasteHandlerChat?: boolean;
};

export const ChatMediaInput: React.FC<MediaInputProps> = React.forwardRef<
  HTMLInputElement,
  MediaInputProps
>(({ multiple, onChange, enableClipboardPasteHandlerChat, ...props }, ref) => {
  const handleChange = useCallback(
    (value: MediaInputValue[]) => {
      onChange({ target: { value: value } });
    },
    [onChange],
  );

  function removeFile(index: number) {
    const newValue = Array.from(props.value || []);
    newValue.splice(index, 1);

    handleChange(newValue);
  }

  useEffect(() => {
    if (!enableClipboardPasteHandlerChat) {
      return;
    }
    const handlePasteFile = async (event: ClipboardEvent) => {
      if (event.clipboardData?.files) {
        const value: MediaInputValue[] = Array.from(
          event.clipboardData.files,
        ).map((file) => ({
          type: 'new' as 'new',
          file,
        }));

        handleChange(multiple ? [...(props.value || []), ...value] : value);
      }
    };

    document.addEventListener('paste', handlePasteFile);
    return () => {
      document.removeEventListener('paste', handlePasteFile);
    };
  }, [enableClipboardPasteHandlerChat, multiple, props.value, handleChange]);
  return (
    <div className={props.className}>
      <div className="d-flex flex-column align-content-center">
        {(multiple ||
          !Array.isArray(props.value) ||
          props.value.length === 0) && (
          <div className="w-100">
            <label className="cursor-pointer me-2 w-100 text-center">
              <i className="bi bi-paperclip fs-24" />
              <input
                multiple={multiple}
                type="file"
                className="d-none"
                accept="image/png,image/jpeg,video/*,application/pdf"
                name={props.name}
                onBlur={props.onBlur}
                ref={ref}
                onChange={(e) => {
                  if (!e.target.files || e.target.files.length === 0) {
                    return;
                  }

                  const value: MediaInputValue[] = Array.from(
                    e.target.files,
                  ).map((file) => ({
                    type: 'new' as 'new',
                    file,
                  }));

                  handleChange(
                    multiple ? [...(props.value || []), ...value] : value,
                  );
                }}
              />
            </label>
          </div>
        )}
        <Gallery className="d-flex flex-column gap-1">
          {props.value?.map((data, index) => (
            <RenderFile
              key={index}
              data={data}
              index={index}
              removeFile={removeFile}
            />
          ))}
        </Gallery>
      </div>
    </div>
  );
});
