import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  cardValidation,
  decimalValidation,
  required,
} from '../../../utils/validations';
import { api } from '../../../services/api';
import { InputWrapper } from '../../common/input-wrapper';
import ReactSelect from 'react-select';
import { getTopUpMethodTitle } from '../../../utils/common.utils';
import { useModalClose } from '../../../utils/useModalClose';

type Form = {
  cardNumber: string;
  cardHolderName: string;
  limit: string;
  currencies: Array<{ value: 'cny' | 'usd' }>;
};

type AddBankCardModelProps = {
  close: () => void;
  reject: () => void;
  method: 'sberbank' | 'alfabank' | 'tinkoff';
};

export const AddBankCardModel: React.FC<AddBankCardModelProps> = ({
  close,
  reject,
  method,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<Form>();

  function onSave(data: Form) {
    api
      .post(`/top-up/methods/${method}/accounts`, {
        ...data,
        currencies: data.currencies.map((option) => option.value),
      })
      .then(() => {
        close();
      });
  }

  const modalRef = useModalClose(reject);

  return (
    <div className="modal-dialog" ref={modalRef}>
      <form className="modal-content" onSubmit={handleSubmit(onSave)}>
        <div className="modal-body">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h3>Добавить банковскую карту</h3>
            <div className="btn p-0" onClick={reject}>
              <i className="bi bi-x fs-36 color-gray-600" />
            </div>
          </div>
          <div className="row mt-3">
            <InputWrapper title="Способ пополнения" className="col">
              <input
                className="form-control"
                type="string"
                value={getTopUpMethodTitle(method)}
                disabled={true}
              />
            </InputWrapper>
          </div>
          <div className="row mt-3">
            <InputWrapper
              title="Номер карты"
              className="col"
              error={errors.cardNumber?.message}
              required
            >
              <div className="input-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="____  ____  ____  ____"
                  {...register('cardNumber', {
                    ...required,
                    ...cardValidation,
                  })}
                />
              </div>
            </InputWrapper>
          </div>
          <div className="row mt-3">
            <InputWrapper
              title="Имя получателя"
              className="col"
              error={errors.cardHolderName?.message}
              required
            >
              <div className="input-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Например, Иван Иванович К."
                  {...register('cardHolderName', {
                    ...required,
                  })}
                />
              </div>
            </InputWrapper>
          </div>
          <div className="row mt-3">
            <InputWrapper
              title="Максимальный лимит"
              className="col"
              error={errors.limit?.message}
              required
            >
              <div className="input-group">
                <input
                  className="form-control"
                  type="number"
                  min={0}
                  step={0.01}
                  placeholder="Например, 300 000"
                  {...register('limit', {
                    ...required,
                    ...decimalValidation,
                  })}
                />
                <span className="input-group-text">₽</span>
              </div>
            </InputWrapper>
          </div>
          <div className="row mt-3">
            <Controller
              control={control}
              name="currencies"
              rules={{
                ...required,
              }}
              render={({ field, fieldState }) => (
                <InputWrapper
                  className="mb-3"
                  title="Разрешенные к пополнению счета"
                  required
                  error={fieldState.error?.message}
                >
                  <ReactSelect
                    placeholder="Выберите счета"
                    options={[{ value: 'cny' }, { value: 'usd' }]}
                    isSearchable={false}
                    isMulti={true}
                    getOptionLabel={(option) => option.value.toUpperCase()}
                    getOptionValue={(option) => option.value}
                    {...field}
                  />
                </InputWrapper>
              )}
            />
          </div>
        </div>
        <div className="modal-footer justify-content-end mt-4">
          <button type="submit" className="btn btn-success">
            Добавить
          </button>
        </div>
      </form>
    </div>
  );
};
