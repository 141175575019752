import { CleanProfile } from '../export-types/cleaned-types';
import React from 'react';

type CurrentUser = {
  profile: CleanProfile;
  isAdmin: boolean;
  isPurchase: boolean;
  isWarehouse: boolean;
};

export const CurrentUserContext = React.createContext<CurrentUser | null>(null);

export function useCurrentUser() {
  const currentUser = React.useContext(CurrentUserContext);

  if (!currentUser) {
    throw new Error('useCurrentUser must be used within a CurrentUserProvider');
  }

  return currentUser;
}
