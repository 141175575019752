import React from 'react';

export const Avatar: React.FC<{ width: number }> = (props) => {
  return (
    <div
      className="bi bi-person-circle color-gray-600 lh-1"
      style={{ fontSize: props.width }}
    />
  );
};
