import React, { useEffect, useRef } from 'react';
// @ts-ignore
import { useBranch } from 'baobab-react/hooks';
import {
  default as TopLoadingBar,
  LoadingBarRef,
  IProps,
} from 'react-top-loading-bar';

export const AppLoadingBar: React.FC<IProps> = (props) => {
  const loader = useRef<LoadingBarRef | null>(null);
  const { loaders } = useBranch({ loaders: 'loaders' });

  useEffect(() => {
    if (loaders) {
      loader.current?.continuousStart(loaders, 0);
    } else {
      loader.current?.complete();
    }
  }, [loaders]);

  return (
    <TopLoadingBar
      color="#12B76A"
      height={3}
      ref={loader}
      waitingTime={500}
      {...props}
    />
  );
};
