import { api } from './api';
import { MediaInputValue } from '../components/common/media-input';
import { chunk, flatten } from 'lodash';

export type UploadedFileV2 = {
  id: string;
  type: 'pdf' | 'image' | 'video' | 'unknown';
  size: number;
};

export type FileResponse = {
  file: UploadedFileV2;
};

export async function uploadFile(
  file: File | Blob,
  isPublic: boolean = false,
): Promise<FileResponse> {
  const form = new FormData();
  form.append('file', file, ('name' in file && file.name) || 'pasted-file');
  form.append('isPublic', isPublic.toString());

  return api.post<FileResponse>('/file', form).then(({ data }) => data);
}

export async function uploadMediaInputFiles(
  files: Array<MediaInputValue>,
  parallelUploads = 5,
) {
  const uploadOneFile = (file: MediaInputValue) =>
    file.type === 'new'
      ? uploadFile(file.file)
      : Promise.resolve({ file: file.file });

  const batches = chunk(files, parallelUploads);
  const results: Array<FileResponse[]> = [];
  while (batches.length) {
    const batch = batches.shift();
    if (batch) {
      const result = await Promise.all(batch.map(uploadOneFile));
      results.push(result);
    }
  }
  return flatten(results);
}
