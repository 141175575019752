import { useForm } from 'react-hook-form';
import { required } from '../../../utils/validations';
import { Input } from '../../common/input.component';
import React from 'react';
import { ModalProps as BaseModalProps } from '../../../services/modals.service';
import { CleanLocation } from '../../../export-types/cleaned-types';
import { AlertService } from '../../../services/alert.service';
import { api } from '../../../services/api';
import { useLocations } from '../../../api/locations';
import { useModalClose } from '../../../utils/useModalClose';

type FieldValues = {
  name: string;
  displayName: string;
  country?: string;
  city?: string;
};

type ModalProps = BaseModalProps & {
  location?: CleanLocation;
};

export const LocationModal = ({ close, location }: ModalProps) => {
  const { mutate } = useLocations();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FieldValues>({
    defaultValues: {
      name: location?.name,
      displayName: location?.displayName,
      country: location?.country || undefined,
      city: location?.city || undefined,
    },
  });

  const isEdit = !!location;

  function onSave(data: FieldValues) {
    const promise = isEdit
      ? api.put(`/location/${location.id}`, data)
      : api.post('/location', data);
    promise.then(() => {
      AlertService.success();
      close();
      return mutate();
    });
  }

  const modalRef = useModalClose(close);

  return (
    <div className="modal-dialog" ref={modalRef}>
      <form className="modal-content" onSubmit={handleSubmit(onSave)}>
        <div className="modal-body">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h3>{isEdit ? 'Редактировать' : 'Добавить'} локацию</h3>
            <div className="btn p-0" onClick={close}>
              <i className="bi bi-x fs-36 color-gray-600" />
            </div>
          </div>
          <Input
            className="mb-3"
            title="Название"
            {...register('name', {
              ...required,
            })}
            error={errors.name?.message}
          />
          <Input
            className="mb-3"
            title="Название в клиенте"
            {...register('displayName', {
              ...required,
            })}
            error={errors.displayName?.message}
          />
          <Input
            className="mb-3"
            title="Страна"
            {...register('country', {
              ...required,
            })}
            error={errors.country?.message}
          />
          <Input
            className="mb-3"
            title="Город"
            {...register('city')}
            error={errors.city?.message}
          />
        </div>
        <div className="modal-footer justify-content-end">
          <button type="submit" className="btn btn-success">
            {isEdit ? 'Сохранить' : 'Добавить'}
          </button>
        </div>
      </form>
    </div>
  );
};
