import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useSearchParamsPaginationEffect(
  handleSetPage: (pageNumber: number) => void,
) {
  const [searchParams] = useSearchParams();

  const searchParamInternalId = searchParams.get('internal');
  const searchParamSupplierId = searchParams.get('supplier');
  const searchParamManagerId = searchParams.get('manager');

  const prevSearchParamInternal = useRef(searchParamInternalId);
  const prevSearchParamSupplier = useRef(searchParamSupplierId);
  const prevSearchParamManager = useRef(searchParamManagerId);

  useEffect(() => {
    if (
      searchParamInternalId !== prevSearchParamInternal.current ||
      searchParamSupplierId !== prevSearchParamSupplier.current ||
      searchParamManagerId !== prevSearchParamManager.current
    ) {
      handleSetPage(1);
    }

    prevSearchParamInternal.current = searchParamInternalId;
    prevSearchParamSupplier.current = searchParamSupplierId;
    prevSearchParamManager.current = searchParamManagerId;
  }, [
    searchParamInternalId,
    searchParamSupplierId,
    searchParamManagerId,
    handleSetPage,
  ]);
}
