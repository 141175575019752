import { trpcSwr } from '../../../api/trpc';
import { isErrorResponse } from '../../../export-types/otcommerce';
import React from 'react';
import {
  CleanPurchaseOrder,
  CleanVendorInfo,
} from '../../../export-types/cleaned-types';
import { useIntl } from 'react-intl';
import { InputWrapper } from '../../common/input-wrapper';

type Props = { order: CleanPurchaseOrder };

export function OtcVendorInfoBlock(props: { order: CleanPurchaseOrder }) {
  const intl = useIntl();
  if (!(props.order.link || props.order.otcommerceVendor)) {
    return null;
  }

  return (
    <>
      {
        <InputWrapper
          theme="light"
          className="mt-3"
          title={intl.formatMessage({
            defaultMessage: 'Продавец',
            id: 'purchaseOrderRequest.label-input.seller',
            description: 'Информация о продавце',
          })}
        >
          <div className="text-break" style={{ whiteSpace: 'pre-line' }}>
            <OtcVendorInfo order={props.order} />
          </div>
        </InputWrapper>
      }
    </>
  );
}

export const OtcVendorInfo = ({ order }: Props) => {
  if (order.otcommerceVendor) {
    return <Show1688SellerNameByVendor vendor={order.otcommerceVendor} />;
  }

  return <OtcVendorInfoByLink link={order.link} />;
};

const Show1688SellerNameByVendor = ({
  vendor,
}: {
  vendor: CleanVendorInfo;
}) => {
  return (
    <div>
      {vendor.displayName || vendor.name} ({vendor.shopName}) <br />
      ID: {vendor.vendorId}
    </div>
  );
};

const OtcVendorInfoByLink = ({ link }: { link: string | null }) => {
  if (!link) {
    return 'Нет ссылки';
  }

  const id = match1688ItemId(link);
  if (null === id) {
    return 'Не 1688.com';
  }

  const { data, isLoading } =
    trpcSwr.otcommerce.BatchGetSimplifiedItemFullInfo.useSWR({
      itemId: `abb-${id}`,
      provider: 'Alibaba1688',
      blockList: ['Vendor'],
    });

  if (isLoading) {
    return null;
  }

  if (!data || isErrorResponse(data) || !data.Result.Vendor) {
    return 'Ошибка Апи: ' + data?.ErrorCode;
  }

  return (
    <div>
      {data.Result.Vendor.DisplayName || data.Result.Vendor.Name} (
      {data.Result.Vendor.ShopName}) <br />
      ID: {data.Result.Vendor.Id}
    </div>
  );
};

function match1688ItemId(link: string): string | null {
  const patterns = [
    // https://detail.1688.com/offer/688566155338.html
    // https://m.1688.com/offer/645683957365.html
    /\/\/(?:detail|m)\.1688\.com\/offer\/(\d+)\.html/,
    // http://detail.m.1688.com/page/index.htm?offerId=732583392213
    /\/\/detail\.m\.1688\.com\/page\/index.htm\?offerId=(\d+)/,
  ];
  for (const pattern of patterns) {
    const match1688ItemId = new RegExp(pattern, 'i');
    const matches = match1688ItemId.exec(link);
    if (matches && matches[1]) {
      return matches[1];
    }
  }

  return null;
}
