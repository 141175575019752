import React from 'react';
import Table from 'rsuite/Table';
import { CleanLocation } from '../../../export-types/cleaned-types';
import { ModalsService } from '../../../services/modals.service';
import { LocationModal } from './location-modal.component';
import { useLocations } from '../../../api/locations';

export const LocationsTable = () => {
  const { data: items, isLoading } = useLocations();

  async function handleEdit(data: CleanLocation) {
    await ModalsService.createModal(LocationModal, { location: data });
  }

  return (
    <Table data={items} headerHeight={57} autoHeight loading={isLoading}>
      <Table.Column width={60}>
        <Table.HeaderCell>Id</Table.HeaderCell>
        <Table.Cell dataKey={'id'} />
      </Table.Column>
      <Table.Column fullText width={200}>
        <Table.HeaderCell>Название</Table.HeaderCell>
        <Table.Cell dataKey={'name'} />
      </Table.Column>
      <Table.Column fullText width={200}>
        <Table.HeaderCell>Название в клиенте</Table.HeaderCell>
        <Table.Cell dataKey={'displayName'} />
      </Table.Column>
      <Table.Column fullText width={200}>
        <Table.HeaderCell>Страна</Table.HeaderCell>
        <Table.Cell dataKey={'country'} />
      </Table.Column>
      <Table.Column fullText width={200}>
        <Table.HeaderCell>Город</Table.HeaderCell>
        <Table.Cell dataKey={'city'} />
      </Table.Column>
      <Table.Column width={150}>
        <Table.HeaderCell children="" />
        <Table.Cell>
          {(rowData) => {
            return (
              <button
                className="btn btn-sm"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  return handleEdit(rowData as CleanLocation);
                }}
              >
                <i className="bi bi-pencil" />
                <span className="ps-2">Изменить</span>
              </button>
            );
          }}
        </Table.Cell>
      </Table.Column>
    </Table>
  );
};
