import InputMask from 'react-input-mask';
import { ChangeEventHandler, forwardRef } from 'react';
import React from 'react';

type InputProps = {
  className?: string;
  name?: string;
  title?: string;
  placeholder?: string;
  type?: string;
  mask?: string;
  value?: string | ReadonlyArray<string> | number | undefined;
  autoComplete?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>; //(...args: any) => any;
  disabled?: boolean;
  required?: boolean;
  postfix?: string;
  error?: string;
  step?: number | string;
  min?: number | string;
  maskChar?: string;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      name,
      title,
      placeholder,
      type,
      mask,
      value,
      autoComplete,
      onChange,
      disabled,
      required,
      postfix,
      error,
      step = '0.001',
      ...props
    },
    ref,
  ) => {
    return (
      <div className={className}>
        {title && (
          <div className="mb-2 text-nowrap">
            {title} {required && <span className="color-red">*</span>}
          </div>
        )}
        <div className="input-group">
          {mask && (
            <MaskedInput
              ref={ref}
              placeholder={placeholder}
              mask={mask}
              value={value}
              autoComplete={autoComplete}
              // @ts-ignore
              autoCorrect={autoComplete}
              onChange={onChange}
              type={type}
              disabled={disabled}
              step={step}
              {...props}
            />
          )}
          {!mask && (
            <input
              ref={ref}
              className="form-control"
              name={name}
              type={type}
              disabled={disabled}
              placeholder={placeholder}
              value={value}
              autoComplete={autoComplete}
              autoCorrect={autoComplete}
              onChange={onChange}
              step={step}
              {...props}
            />
          )}
          {postfix && <span className="input-group-text">{postfix}</span>}
        </div>
        {!!error && <div className="color-red">{error}</div>}
      </div>
    );
  },
);

const MaskedInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => (
  <InputMask
    // @ts-ignore
    ref={ref}
    {...props}
    className="form-control"
    formatChars={{
      '9': '[0-9]',
      a: '[A-Za-z]',
      '*': '[A-Za-z0-9А-Яа-я]',
    }}
  />
));
