import React from 'react';
import {
  formatDateValue,
  getCurrentDateValue,
  parseRawDateValue,
  RawDateValue,
} from '../../../utils/date-value';
import { Controller, useForm } from 'react-hook-form';
import isDate from 'validator/lib/isDate';
import { getTransactionsExportUrl } from '../../../utils/common.utils';
import { InputWrapper } from '../../common/input-wrapper';
import { DatePicker, CustomProvider, Button, DateRangePicker } from 'rsuite';
import { ruRU } from 'rsuite/locales';

type Period =
  | 'all_time'
  | 'week'
  | 'month'
  | 'year'
  | 'last_week'
  | 'last_month'
  | 'last_year'
  | 'range';

const periods: { title: string; id: Period }[] = [
  { title: 'За все время', id: 'all_time' },
  { title: 'Текущая неделя', id: 'week' },
  { title: 'Текущий месяц', id: 'month' },
  { title: 'Этот год', id: 'year' },
  { title: 'Прошлая неделя', id: 'last_week' },
  { title: 'Прошлый месяц', id: 'last_month' },
  { title: 'Прошлый год', id: 'last_year' },
  { title: 'Выбрать диапазон', id: 'range' },
];

type FinanceExportModalProps = {
  close: () => void;
};

type Form = {
  period: Period;
  range?: RawDateValue[];
};

function isCorrectDate(v: string) {
  return isDate(`${v}`, {
    format: 'DD.MM.YYYY',
    delimiters: ['.'],
    strictMode: true,
  });
}

export const FinanceExportModal: React.FC<FinanceExportModalProps> = ({
  close,
}) => {
  const {
    control,
    formState: { isValid },
    getValues,
    watch,
    trigger,
  } = useForm<Form>({
    mode: 'all',
    defaultValues: {
      period: 'week',
    },
  });

  const [period, range] = watch(['period', 'range']);

  React.useEffect(() => {
    trigger(['range']);
  }, [trigger, range]);

  const minDate = React.useMemo(
    () =>
      formatDateValue(
        getCurrentDateValue().subtract(1, 'year').startOf('year'),
      ),
    [],
  );
  const maxDate = React.useMemo(
    () => formatDateValue(getCurrentDateValue()),
    [],
  );

  const reportLink: string | null = React.useMemo<string | null>(() => {
    switch (period) {
      case 'all_time':
        return getTransactionsExportUrl();
      case 'range':
        if (!isValid || (range && range.length !== 2)) return null;
        const start = range?.[0]
          ? parseRawDateValue(range?.[0]).startOf('day').valueOf().toString()
          : undefined;
        const end = range?.[1]
          ? parseRawDateValue(range?.[1]).endOf('day').valueOf().toString()
          : undefined;

        return getTransactionsExportUrl(start, end);
      case 'week':
        return getTransactionsExportUrl(
          getCurrentDateValue().startOf('isoWeek').valueOf().toString(),
        );
      case 'month':
        return getTransactionsExportUrl(
          getCurrentDateValue().startOf('month').valueOf().toString(),
        );
      case 'year':
        return getTransactionsExportUrl(
          getCurrentDateValue().startOf('year').valueOf().toString(),
        );
      case 'last_week':
        return getTransactionsExportUrl(
          getCurrentDateValue()
            .subtract(1, 'week')
            .startOf('isoWeek')
            .valueOf()
            .toString(),
          getCurrentDateValue()
            .subtract(1, 'week')
            .endOf('isoWeek')
            .valueOf()
            .toString(),
        );
      case 'last_month':
        return getTransactionsExportUrl(
          getCurrentDateValue()
            .subtract(1, 'month')
            .startOf('month')
            .valueOf()
            .toString(),
          getCurrentDateValue()
            .subtract(1, 'month')
            .endOf('month')
            .valueOf()
            .toString(),
        );
      case 'last_year':
        return getTransactionsExportUrl(
          getCurrentDateValue()
            .subtract(1, 'year')
            .startOf('year')
            .valueOf()
            .toString(),
          getCurrentDateValue()
            .subtract(1, 'year')
            .endOf('year')
            .valueOf()
            .toString(),
        );
      default:
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
        ((_: never) => {})(period);
        return null;
    }
  }, [period, isValid, range]);

  return (
    <CustomProvider locale={ruRU}>
      <div className="modal-dialog">
        <form className="modal-content">
          <div className="modal-body">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <h3>Экспорт транзакций</h3>
              <div className="btn p-0" onClick={close}>
                <i className="bi bi-x fs-36 color-gray-600" />
              </div>
            </div>
            <Controller
              name="period"
              control={control}
              rules={{
                required: 'Укажите период',
              }}
              render={({ field, fieldState }) => {
                return (
                  <InputWrapper
                    required
                    title="Выберите период"
                    error={fieldState.error?.message}
                    className="mb-3"
                  >
                    <select className="form-select" {...field}>
                      {periods.map((item) => {
                        return (
                          <option
                            value={item.id}
                            key={item.id}
                            className="mb-1 cursor-pointer bg-white py-3 pl-3 pr-7 text-base-lh-125 font-medium text-gray-800 hover:bg-green-100"
                          >
                            {item.title}
                          </option>
                        );
                      })}
                    </select>
                  </InputWrapper>
                );
              }}
            />
            {period === 'range' && (
              <Controller
                name="range"
                control={control}
                rules={{
                  validate: (value) => {
                    if (!value || value.length !== 2) return true;

                    const [start, end] = value;

                    if (!isCorrectDate(start) || !isCorrectDate(end)) {
                      return 'Укажите корректные даты';
                    }

                    if (
                      parseRawDateValue(start).isAfter(parseRawDateValue(end))
                    ) {
                      return 'Дата начала не должна быть позже даты окончания';
                    }

                    return true;
                  },
                }}
                render={({ field, fieldState }) => {
                  return (
                    <InputWrapper
                      title="Выберите период"
                      error={fieldState.error?.message}
                      className="mb-2 w-full text-base-lh-100"
                    >
                      <DateRangePicker
                        className={'w-100'}
                        format={'dd.MM.yyyy'}
                        isoWeek
                        showOneCalendar
                        onChange={(range) => {
                          if (range == null) field.onChange([]);
                          else {
                            field.onChange(
                              range.map((date) => formatDateValue(date)),
                            );
                          }
                        }}
                      />
                    </InputWrapper>
                  );
                }}
              />
            )}
          </div>
          <div className="modal-footer justify-content-end">
            {reportLink ? (
              <Button
                as="a"
                href={reportLink}
                target="_blank"
                className="btn btn-success"
              >
                Скачать в Excel
              </Button>
            ) : (
              <Button as="button" disabled={true} className="btn btn-success">
                Скачать в Excel
              </Button>
            )}
          </div>
        </form>
      </div>
    </CustomProvider>
  );
};
