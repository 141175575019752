import { useDeliveryOrderStatuses } from '../../consts/db-values';
import React from 'react';

type Props = {
  status: string;
};

export const DeliveryOrderStatus: React.FC<Props> = ({ status }) => {
  const DELIVERY_ORDER_STATUSES = useDeliveryOrderStatuses();
  return (
    <div className={`delivery-order-status ${status}`}>
      {DELIVERY_ORDER_STATUSES[status as keyof typeof DELIVERY_ORDER_STATUSES]}
    </div>
  );
};
