import { CleanNote } from '../../export-types/cleaned-types';
import React, { useState } from 'react';
import moment from 'moment';
import NoteForm from './note-form';
import { useProfile } from '../../api/profile';
import { ModalsService } from '../../services/modals.service';
import { ConfirmModal } from './confirm-modal';
import { api } from '../../services/api';

type NoteInfoProps = {
  note: CleanNote;
  type: 'delivery' | 'purchase';
  id: string;
  handleUpdate: () => void;
};
export default function NoteInfo({
  id,
  type,
  note,
  handleUpdate,
}: NoteInfoProps) {
  const { data: profile } = useProfile();
  const [edit, setEdit] = useState(false);

  const handleEdit = () => {
    handleUpdate();
    setEdit(false);
  };

  const handleDelete = async () => {
    ModalsService.createModal(ConfirmModal, {
      buttonText: 'Удалить',
      title: 'Вы уверены что хотите удалить заметку?',
    }).then(async () => {
      await api.delete(
        type === 'delivery'
          ? `/delivery-order/${id}/notes/${note.id}`
          : `/purchase-order/${id}/notes/${note.id}`,
      );
      handleUpdate();
    });
  };
  if (edit) {
    return (
      <NoteForm
        type={type}
        id={id}
        note={note}
        handleSave={handleEdit}
        handleCancel={handleEdit}
      />
    );
  }
  return (
    <div className="d-flex flex-column w-100">
      <div className="d-flex flex-row gap-2">
        <div className="bg-green-100 p-3 flex-grow-1">{note.noteText}</div>
        {profile?.id === note.user.id && (
          <div className="d-flex flex-column gap-2 justify-content-between">
            <div onClick={() => setEdit(true)}>
              <i className="bi bi-pencil m-2" />
            </div>
            <div onClick={() => handleDelete()}>
              <i className="bi bi-trash m-2" />
            </div>
          </div>
        )}
      </div>

      <div className="fs-14 d-flex flex-row justify-content-between color-gray-400">
        <div>{note.user.name}</div>
        <div>{moment(note.createdAt).format('DD.MM.YYYY')}</div>
      </div>
    </div>
  );
}
