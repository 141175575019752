import React, { useRef } from 'react';
import useSWR from 'swr';
import { fetcher } from '../../../services/api';
import Table, { CellProps } from 'rsuite/Table';
import { WhisperSpeaker } from '../../../utils/whisper-speaker';
import Popover from 'rsuite/Popover';
import Dropdown from 'rsuite/Dropdown';
import Whisper from 'rsuite/Whisper';
import IconButton from 'rsuite/IconButton';
import { toMoney } from '../../../utils/common.utils';

import { AlertService } from '../../../services/alert.service';
import Toggle from 'rsuite/Toggle';
import { RouterOutput, trpcSwr } from '../../../api/trpc';
import { SupplierCell } from '../../common/table/supplier-cell';
import { useNavigate } from 'react-router-dom';
import Pagination from 'rsuite/Pagination';
import { usePaginationQs } from '../../../utils/usePagination';

export type ServiceChargeOptionMethod =
  RouterOutput['serviceChargeOption']['admin']['list']['items'][number];
export const ServiceChargeOptionScreen: React.FC = () => {
  const { take, skip, handleSetPage, handleChangeLimit, page } =
    usePaginationQs(17);
  const navigate = useNavigate();

  const { data, mutate, isLoading } =
    trpcSwr.serviceChargeOption.admin.list.useSWR({
      take,
      skip,
    });

  const { trigger: triggerUpdate } =
    trpcSwr.serviceChargeOption.admin.update.useSWRMutation({ fetcher });

  async function setDisable(id: number, disabled: boolean) {
    await triggerUpdate({
      id: id,
      data: { disabled },
    });
    AlertService.success();
    mutate();
  }
  const CommentClient = ({
    rowData,
    dataKey,
    ...props
  }: CellProps<ServiceChargeOptionMethod>) =>
    rowData && <Table.Cell {...props}>{rowData.commentClient}</Table.Cell>;
  const CommentCompany = ({
    rowData,
    dataKey,
    ...props
  }: CellProps<ServiceChargeOptionMethod>) =>
    rowData && <Table.Cell {...props}>{rowData.commentCompany}</Table.Cell>;
  const AmountClient = ({
    rowData,
    dataKey,
    ...props
  }: CellProps<ServiceChargeOptionMethod>) =>
    rowData && (
      <Table.Cell {...props}>
        {toMoney(rowData.amountClient as string, 'cny')}
      </Table.Cell>
    );

  const AmountCompany = ({
    rowData,
    ...props
  }: CellProps<ServiceChargeOptionMethod>) =>
    rowData && (
      <Table.Cell {...props}>
        {toMoney(rowData.amountCompany as string, 'cny')}
      </Table.Cell>
    );

  const DisableCell = ({
    rowData,
    ...props
  }: CellProps<ServiceChargeOptionMethod>) =>
    rowData && (
      <Table.Cell {...props}>
        <Toggle
          checked={!rowData.disabled}
          onChange={(val) => setDisable(rowData.id, !val)}
        />
      </Table.Cell>
    );

  const renderMenu =
    (item: ServiceChargeOptionMethod): WhisperSpeaker =>
    ({ onClose, left, top, className }, ref) => {
      const handleSelect = (eventKey: unknown) => {
        onClose();
        if (eventKey === 1) {
          navigate(`/purchases/service-charge-option/form`, { state: item });
        }
      };

      return (
        <Popover ref={ref} className={className} style={{ left, top }} full>
          <Dropdown.Menu onSelect={handleSelect}>
            <Dropdown.Item eventKey={1}>
              <i className="bi bi-pencil" />
              <span className="ps-2">Изменить</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Popover>
      );
    };

  const ActionCell = ({
    rowData,
    dataKey,
    ...props
  }: CellProps<ServiceChargeOptionMethod>) => {
    return (
      rowData && (
        <Table.Cell
          {...props}
          className="link-group no-padding-cell pt-2"
          align="right"
        >
          <Whisper
            placement="leftStart"
            trigger="click"
            speaker={renderMenu(rowData)}
          >
            <IconButton
              appearance="subtle"
              icon={<i className="bi bi-three-dots" />}
            />
          </Whisper>
        </Table.Cell>
      )
    );
  };
  const NamePurchaseCell = ({
    rowData,
    dataKey,
    ...props
  }: CellProps<ServiceChargeOptionMethod>) => (
    <Table.Cell {...props}>{rowData?.name ? rowData.name : null}</Table.Cell>
  );

  return (
    <div>
      <Table
        data={data?.items}
        loading={isLoading}
        headerHeight={57}
        autoHeight
      >
        <Table.Column flexGrow={1} fullText>
          <Table.HeaderCell>Поставщик</Table.HeaderCell>
          <SupplierCell />
        </Table.Column>
        <Table.Column flexGrow={1} fullText>
          <Table.HeaderCell>Название</Table.HeaderCell>
          <NamePurchaseCell />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Приоритет</Table.HeaderCell>
          <Table.Cell dataKey="priority" />
        </Table.Column>
        <Table.Column flexGrow={1} fullText>
          <Table.HeaderCell>Сколько списать с клиента</Table.HeaderCell>
          <AmountClient />
        </Table.Column>
        <Table.Column flexGrow={1} fullText>
          <Table.HeaderCell>Комментарий для клиента </Table.HeaderCell>
          <CommentClient />
        </Table.Column>
        <Table.Column flexGrow={1} fullText>
          <Table.HeaderCell>Сколько перевести поставщику</Table.HeaderCell>
          <AmountCompany />
        </Table.Column>
        <Table.Column flexGrow={1} fullText>
          <Table.HeaderCell>Комментарий для поставщика</Table.HeaderCell>
          <CommentCompany />
        </Table.Column>
        <Table.Column flexGrow={1} fullText>
          <Table.HeaderCell> </Table.HeaderCell>
          <DisableCell />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell> </Table.HeaderCell>
          <ActionCell />
        </Table.Column>
      </Table>

      {data && data.total > take && (
        <Pagination
          className="mt-2 pb-4"
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={['pager']}
          total={data.total}
          limit={take}
          activePage={page}
          onChangePage={handleSetPage}
          onChangeLimit={handleChangeLimit}
        />
      )}
    </div>
  );
};
