import React from 'react';
import { useForm } from 'react-hook-form';
import { InputWrapper } from '../../common/input-wrapper';
import { required } from '../../../utils/validations';
import { DeliveryOrderService } from '../../../services/delivery-order.service';
import { AlertService } from '../../../services/alert.service';
import { useModalClose } from '../../../utils/useModalClose';

type TrackNumberFormModalProps = {
  close: () => void;
  reject: () => void;
  deliveryOrder: {
    id: string;
  };
};

type Form = {
  trackNumber: string;
};

export const TrackNumberFormModal: React.FC<TrackNumberFormModalProps> = ({
  deliveryOrder,
  close,
  reject,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<{
    trackNumber: string;
  }>({});

  function onSubmit(data: Form) {
    DeliveryOrderService.updateDeliveryOrder(
      { ...data },
      deliveryOrder.id,
    ).then(() => {
      AlertService.success();
      close();
    });
  }

  const modalRef = useModalClose(reject);

  return (
    <div className="modal-dialog" ref={modalRef}>
      <form className="modal-content" onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h3>Указать трек-номер</h3>
            <div className="btn p-0" onClick={reject}>
              <i className="bi bi-x fs-36 color-gray-600" />
            </div>
          </div>
          <InputWrapper
            title="Трек-номер"
            className="pt-3"
            error={errors.trackNumber?.message}
            required
          >
            <input
              className="form-control"
              type="text"
              {...register('trackNumber', {
                ...required,
              })}
            />
          </InputWrapper>
        </div>
        <div className="modal-footer justify-content-end">
          <button type="submit" className="btn btn-success">
            Сохранить
          </button>
        </div>
      </form>
    </div>
  );
};
