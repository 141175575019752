import React from 'react';
import {
  formatDateValue,
  getCurrentDateValue,
  parseRawDateValue,
  RawDateValue,
} from '../../../utils/date-value';
import { Controller, useForm } from 'react-hook-form';
import { getDeliveryOrderExportUrl } from '../../../utils/common.utils';
import { InputWrapper } from '../../common/input-wrapper';
import { CustomProvider, Button, DateRangePicker } from 'rsuite';
import { ruRU } from 'rsuite/locales';
import { isCorrectDate } from './reconciliation-act-modal';
import { Select } from '../../common/select.component';
import { required } from '../../../utils/validations';
import { useSuppliers } from '../../../api/suppliers';

type Period =
  | 'all_time'
  | 'week'
  | 'month'
  | 'year'
  | 'last_week'
  | 'last_month'
  | 'last_year'
  | 'range';

export const periods: { title: string; id: Period }[] = [
  { title: 'За все время', id: 'all_time' },
  { title: 'Текущая неделя', id: 'week' },
  { title: 'Текущий месяц', id: 'month' },
  { title: 'Этот год', id: 'year' },
  { title: 'Прошлая неделя', id: 'last_week' },
  { title: 'Прошлый месяц', id: 'last_month' },
  { title: 'Прошлый год', id: 'last_year' },
  { title: 'Выбрать диапазон', id: 'range' },
];

type ReportType =
  | 'all_goods_delivered'
  | 'in_ru_warehouse'
  | 'on_way_to_ru_warehouse';

type ReportExportModalProps = {
  close: () => void;
  reportType?: ReportType;
};

type Form = {
  suppliers: number[];
  period: Period;
  range?: RawDateValue[];
};

const reportTitle: { [key: string]: string } = {
  all_goods_delivered: 'Все доставки',
  in_ru_warehouse: 'Доставки доставлены на склад РФ',
  on_way_to_ru_warehouse: 'Доставки в пути на склад РФ',
};

export const ReportExportModal: React.FC<ReportExportModalProps> = ({
  close,
  reportType = 'all_goods_delivered',
}) => {
  const { data: allSuppliers } = useSuppliers();

  const {
    control,
    formState: { isValid, errors },
    setValue,
    watch,
    trigger,
  } = useForm<Form>({
    mode: 'all',
    defaultValues: {
      period: 'week',
    },
  });

  const [suppliers, period, range] = watch(['suppliers', 'period', 'range']);

  React.useEffect(() => {
    trigger(['range']);
  }, [trigger, range]);

  const reportLink: string | null = React.useMemo<string | null>(() => {
    if (!isValid || !suppliers) return null;
    let periodQuery = {};
    let statusQuery = {};

    // Сформировать запрос для периода
    switch (period) {
      case 'range':
        if (!range) return null;
        const [customStart, customEnd] = range;
        if (!customStart && !customEnd) return null;

        periodQuery = {
          start: customStart
            ? parseRawDateValue(customStart).startOf('day').valueOf().toString()
            : undefined,
          end: customEnd
            ? parseRawDateValue(customEnd).endOf('day').valueOf().toString()
            : undefined,
        };
        break;
      case 'week':
        periodQuery = {
          start: getCurrentDateValue().startOf('isoWeek').valueOf().toString(),
        };
        break;
      case 'month':
        periodQuery = {
          start: getCurrentDateValue().startOf('month').valueOf().toString(),
        };
        break;
      case 'year':
        periodQuery = {
          start: getCurrentDateValue().startOf('year').valueOf().toString(),
        };
        break;
      case 'last_week':
        periodQuery = {
          start: getCurrentDateValue()
            .subtract(1, 'week')
            .startOf('isoWeek')
            .valueOf()
            .toString(),
          end: getCurrentDateValue()
            .subtract(1, 'week')
            .endOf('isoWeek')
            .valueOf()
            .toString(),
        };
        break;
      case 'last_month':
        periodQuery = {
          start: getCurrentDateValue()
            .subtract(1, 'month')
            .startOf('month')
            .valueOf()
            .toString(),
          end: getCurrentDateValue()
            .subtract(1, 'month')
            .endOf('month')
            .valueOf()
            .toString(),
        };
        break;
      case 'last_year':
        periodQuery = {
          start: getCurrentDateValue()
            .subtract(1, 'year')
            .startOf('year')
            .valueOf()
            .toString(),
          end: getCurrentDateValue()
            .subtract(1, 'year')
            .endOf('year')
            .valueOf()
            .toString(),
        };
      default:
        break;
    }

    // Сформировать запрос для типа отчёта
    switch (reportType) {
      case 'all_goods_delivered':
        statusQuery = {}; // Если нет статусов, значит брать все
        break;
      case 'in_ru_warehouse':
        statusQuery = {
          status: ['receivedInRussia', 'receivedInRetailRussia'],
        };
        break;
      case 'on_way_to_ru_warehouse':
        statusQuery = {
          status: ['sentToRussia'],
        };
        break;
    }

    return getDeliveryOrderExportUrl({
      title: reportTitle[reportType],
      suppliers,
      ...periodQuery,
      ...statusQuery,
    });
  }, [period, isValid, range, reportType]);

  return (
    <CustomProvider locale={ruRU}>
      <div className="modal-dialog">
        <form className="modal-content">
          <div className="modal-body pb-0">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <h3>{reportTitle[reportType]}</h3>
              <div className="btn p-0" onClick={close}>
                <i className="bi bi-x fs-36 color-gray-600" />
              </div>
            </div>
            <Controller
              name="suppliers"
              control={control}
              rules={required}
              render={({ field }) => (
                <Select
                  className="mb-3"
                  title="Поставщик"
                  placeholder="Выберите поставщика"
                  isMulti
                  required
                  options={
                    allSuppliers?.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    })) ?? []
                  }
                  onChange={(selectedOption) => field.onChange(selectedOption)}
                  value={field.value}
                  error={errors.suppliers?.message}
                />
              )}
            />
            <Controller
              name="period"
              control={control}
              rules={{
                required: 'Укажите период',
              }}
              render={({ field, fieldState }) => {
                return (
                  <InputWrapper
                    required
                    title="Выберите период"
                    error={fieldState.error?.message}
                    className="mb-3"
                  >
                    <select className="form-select" {...field}>
                      {periods.map((item) => {
                        return (
                          <option
                            value={item.id}
                            key={item.id}
                            className="mb-1 cursor-pointer bg-white py-3 pl-3 pr-7 text-base-lh-125 font-medium text-gray-800 hover:bg-green-100"
                          >
                            {item.title}
                          </option>
                        );
                      })}
                    </select>
                  </InputWrapper>
                );
              }}
            />
            {period === 'range' && (
              <Controller
                name="range"
                control={control}
                rules={{
                  validate: (value) => {
                    if (!value || value.length !== 2) return true;

                    const [start, end] = value;

                    if (!isCorrectDate(start) || !isCorrectDate(end)) {
                      return 'Укажите корректные даты';
                    }

                    if (
                      parseRawDateValue(start).isAfter(parseRawDateValue(end))
                    ) {
                      return 'Дата начала не должна быть позже даты окончания';
                    }

                    return true;
                  },
                }}
                render={({ field, fieldState }) => {
                  return (
                    <InputWrapper
                      title="Выберите период"
                      error={fieldState.error?.message}
                      className="mb-2 w-full text-base-lh-100"
                    >
                      <DateRangePicker
                        className={'w-100'}
                        format={'dd.MM.yyyy'}
                        isoWeek
                        showOneCalendar
                        onChange={(range) => {
                          if (range == null) field.onChange([]);
                          else {
                            field.onChange(
                              range.map((date) => formatDateValue(date)),
                            );
                          }
                        }}
                      />
                    </InputWrapper>
                  );
                }}
              />
            )}
          </div>
          <div className="modal-body pt-0 text-secondary">
            * Для получения всех заказов в статусе доставленных на склад,
            необходимо выбрать период за всё время!
          </div>
          <div className="modal-footer justify-content-end">
            {reportLink ? (
              <Button
                as="a"
                href={reportLink}
                target="_blank"
                className="btn btn-success"
              >
                Скачать в Excel
              </Button>
            ) : (
              <Button as="button" disabled={true} className="btn btn-success">
                Скачать в Excel
              </Button>
            )}
          </div>
        </form>
      </div>
    </CustomProvider>
  );
};
