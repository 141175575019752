import { useMemo } from 'react';
import { Item } from './BrandsTab';

type RowSpanInfo = {
  name: string;
  count: number;
  index: number;
};

export const useProcessedBrands = (brands?: { items: Item[] } | null) => {
  return useMemo(() => {
    if (!brands || !brands.items) return [];

    const rowSpans: { [key: string]: RowSpanInfo } = {};

    brands.items.forEach((item, index) => {
      const name = item.name;
      const id = item.id;

      if (!rowSpans[id]) {
        rowSpans[id] = { name, count: 1, index: index };
      } else {
        rowSpans[id].count += 1;
      }
    });

    return brands.items.map((item, index) => {
      const id = item.id;
      const spanInfo = rowSpans[id];

      const isFirst = spanInfo.index === index;
      const rowSpanId = isFirst ? spanInfo.count : 0;
      const rowSpanName = isFirst ? spanInfo.name : '';

      return {
        ...item,
        rowSpanId,
        rowSpanName,
      };
    });
  }, [brands]);
};
