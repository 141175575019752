import useSWR from 'swr';
import {
  CleanLocation,
  CleanPurchaseOrder,
  DirectionLocation,
} from '../../../export-types/cleaned-types';
import { fetcher } from '../../../services/api';
import { DeliveryOrder } from '../delivery/delivery-order-finances-info';

const formatLocation = (location: CleanLocation | DirectionLocation) => {
  const city = location.city;
  const country = location.country;

  if (city && country) {
    return `${city}, ${country}`;
  }
  if (country) {
    return country;
  }
  if (city) {
    return city;
  }

  return 'displayName' in location ? location.displayName : location.name;
};

export const useOrderPaths = ({ orderId }: { orderId: string }) => {
  const { data: order } = useSWR<CleanPurchaseOrder>(
    `/purchase-order/${orderId}`,
    fetcher,
  );

  if (!order || !order.supplier?.location) {
    return {
      pathFrom: null,
      countryFrom: null,
    };
  }

  const locationFrom = order.supplier.location;
  const pathFrom = formatLocation(locationFrom);
  const countryFrom = order.supplier.location.country;

  return {
    pathFrom,
    countryFrom,
  };
};

export const useOrderDeliveryPaths = ({ orderId }: { orderId: string }) => {
  const { data: order, mutate: mutateOrder } = useSWR<DeliveryOrder>(
    `/delivery-order/${orderId}`,
    fetcher,
  );

  if (!order || !order.supplier?.location) {
    return {
      countryFrom: null,
    };
  }

  const countryFrom = order.supplier.location.country;

  return {
    countryFrom,
  };
};
