import React from 'react';
import useSWR from 'swr';
import { api, fetcher } from '../../../services/api';

import { CleanDeliveryOrder } from '../../../export-types/cleaned-types';
import { useProfile } from '../../../api/profile';
import { FormattedMessage } from 'react-intl';
import { trpcSwr } from '../../../api/trpc';
import { IconCdek } from '../../common/icons/IconCdek';
import { AlertService } from '../../../services/alert.service';

type DeliveryOrderCdekInfoProps = {
  id: string;
};
export const DeliveryOrderCdekInfo: React.FC<DeliveryOrderCdekInfoProps> = (
  props,
) => {
  const { data: user } = useProfile();
  const { data: order, mutate } = useSWR<CleanDeliveryOrder>(
    `/delivery-order/${props.id}`,
    fetcher,
  );

  const { trigger: createReceipt } =
    trpcSwr.cdek.createReceipt.useSWRMutation();
  const { trigger: createBarcode } =
    trpcSwr.cdek.createBarcode.useSWRMutation();
  if (!order?.cdekOrder?.cdekUuid || !user) {
    return null;
  }
  return (
    <>
      <div
        className="btn btn-outline-secondary w-30 align-items-center d-flex  "
        onClick={async () => {
          if (order?.cdekOrder?.receiptUuid) {
            await api
              .get(`/cdek/receipt/${order.cdekOrder.receiptUuid}`, {
                responseType: 'blob',
              })
              .then(({ data }) => {
                const url = window.URL.createObjectURL(
                  new Blob([data], { type: 'application/pdf' }),
                );
                window.open(url, '_blank');
              })
              .catch((error) => {
                if (error?.response?.data?.code === 'not_ready') {
                  AlertService.success('Накладная в обработке');
                }
              });
          } else {
            createReceipt({
              deliveryOrderId: props.id,
            })
              .then(() => {
                AlertService.success(
                  'Накладная в обработке, пожалуйста попробуйте через некоторое время еще раз',
                );
              })
              .catch((err) => {
                AlertService.error(err);
              })
              .finally(mutate);
          }
        }}
      >
        <IconCdek className="w-20 mx-2" />
        <FormattedMessage
          defaultMessage="Печать накладной"
          id="DeliveryOrderCdekInfo.btn-label.createReceipt"
          description="Надпись на кнопке"
        />
      </div>
      <div
        className={'btn btn-outline-secondary align-items-center d-flex'}
        onClick={async () => {
          if (order?.cdekOrder?.barcodeUuid) {
            await api
              .get(`/cdek/barcode/${order.cdekOrder.barcodeUuid}`, {
                responseType: 'blob',
              })
              .then(({ data }) => {
                const url = window.URL.createObjectURL(
                  new Blob([data], { type: 'application/pdf' }),
                );
                window.open(url, '_blank');
              })
              .catch((err) => {
                if (err?.response?.data?.code === 'not_ready') {
                  AlertService.success('Штрих-код в обработке');
                }
              });
          } else {
            createBarcode({
              deliveryOrderId: props.id,
            })
              .then(() => {
                AlertService.success(
                  'Штрих-код в обработке, пожалуйста попробуйте через некоторое время еще раз',
                );
              })
              .catch((err) => {
                AlertService.error(err);
              })
              .finally(mutate);
          }
        }}
      >
        <i className="bi bi-upc-scan fs-24" />
      </div>
    </>
  );
};
