import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  decimalValidation,
  intValidation,
  useRequired,
} from '../../../utils/validations';
import { AlertService } from '../../../services/alert.service';
import { InputWrapper } from '../../common/input-wrapper';
import { api } from '../../../services/api';
import { uploadMediaInputFiles } from '../../../services/file-service';
import { MediaInput, MediaInputValue } from '../../common/media-input';
import { FormattedMessage, useIntl } from 'react-intl';
import { DatePicker } from 'rsuite';
import moment from 'moment/moment';
import { RawDateValue } from '../../../utils/date-value';

type DeliveryOrderAssemblyModalProps = {
  close: () => void;
  reject: () => void;
  deliveryOrderId: string;
  composite: boolean;
};
function isCorrectDate(value: Date) {
  const parsedDate = moment(value, 'DD.MM.YYYY HH:mm', true);
  if (!parsedDate.isValid()) {
    return false;
  }

  const currentDate = moment();
  const thirtyDaysEarlier = moment().subtract(30, 'days');

  if (
    parsedDate.isAfter(currentDate) ||
    parsedDate.isBefore(thirtyDaysEarlier)
  ) {
    return false;
  }

  return true;
}
export const DeliveryOrderAssemblyModal: React.FC<
  DeliveryOrderAssemblyModalProps
> = (props) => {
  const {
    register,
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
  } = useForm<{
    packedFiles: MediaInputValue[];
    waybillFiles: MediaInputValue[];
    count: string;
    volume: string;
    weight: string;
    moderateDeliveryPrice: string;
    moderatePackagePrice: string;
    moderateInsurancePrice: string;
    date: Date;
  }>({
    defaultValues: {
      packedFiles: [],
      waybillFiles: [],
    },
  });
  const intl = useIntl();
  const onSubmit = handleSubmit(async (form) => {
    const { packedFiles, waybillFiles, ...data } = form;

    const [packedFilesResponse, waybillFilesResponse] = await Promise.all([
      uploadMediaInputFiles(form.packedFiles),
      uploadMediaInputFiles(form.waybillFiles),
    ]);
    const isoDateString = moment(data.date, 'DD.MM.YYYY HH:mm').toISOString();

    await api.post(`/delivery-order/${props.deliveryOrderId}/send-to-russia`, {
      count: Number(data.count),
      volume: data.volume,
      weight: data.weight,
      moderateDeliveryPrice: data.moderateDeliveryPrice,
      moderatePackagePrice: data.moderatePackagePrice,
      moderateInsurancePrice: data.moderateInsurancePrice,
      packedFiles: packedFilesResponse.map((data) => data.file),
      waybillFiles: waybillFilesResponse.map((data) => data.file),
      date: isoDateString,
    });

    AlertService.success();
    props.close();
  });
  const required = useRequired();
  return (
    <div className="modal-dialog">
      <form className="modal-content" onSubmit={onSubmit}>
        <div className="modal-header border-bottom-0 p-4">
          <h3>
            {!props.composite ? (
              <FormattedMessage
                defaultMessage="Подтвердить отправку в РФ"
                id="delivery.btn-label.confirmShipmentToTheRussianFederation"
                description="Надпись на кнопке/заголовок"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Собрать посылку"
                id="deliveryOrderAssemblyModal.title.collectParce"
                description="Заголовок"
              />
            )}
          </h3>
          <button
            type="button"
            className="btn-close"
            onClick={() => props.reject()}
          />
        </div>

        <div className="modal-body pt-2 p-4">
          <Controller
            control={control}
            name="packedFiles"
            rules={{
              required: intl.formatMessage({
                id: 'deliveryOrderAssemblyModal.title.UploadPhotoPackagedProduct',
                defaultMessage: 'Загрузите фото упакованного товара',
                description: 'Пояснение к полю ввода',
              }),
            }}
            render={({ field, fieldState }) => (
              <InputWrapper
                title={intl.formatMessage({
                  defaultMessage: 'Фото упакованного товара',
                  id: 'deliveryAssemblyModal.input-label.photoOfThePackagedGoods',
                  description: 'Заголовок поля ввода',
                })}
                className="mb-3"
                error={fieldState.error?.message}
                required
              >
                <MediaInput
                  {...field}
                  multiple
                  enableClipboardPasteHandler={false}
                />
              </InputWrapper>
            )}
          />

          <Controller
            control={control}
            name="waybillFiles"
            rules={{
              required: intl.formatMessage({
                id: 'deliveryOrderAssemblyModal.title.UploadPhotoDeliveryNote',
                defaultMessage: 'Загрузите фото транспортной накладной',
                description: 'Пояснение к полю ввода',
              }),
            }}
            render={({ field, fieldState }) => (
              <InputWrapper
                title={intl.formatMessage({
                  defaultMessage: 'Транспортная накладная',
                  id: 'deliveryAssemblyModal.input-label.consignment',
                  description: 'Заголовок поля ввода',
                })}
                className="mb-3"
                error={fieldState.error?.message}
                required
              >
                <MediaInput
                  {...field}
                  multiple
                  enableClipboardPasteHandler={false}
                />
              </InputWrapper>
            )}
          />

          <h4>
            <FormattedMessage
              defaultMessage="Информация об отправке"
              id="deliveryAssemblyModal.label.dispatchInformation"
              description="Подзаголовок в модальном окне"
            />
          </h4>
          <p className="color-gray-600">
            <FormattedMessage
              defaultMessage="Заполните поля данными из накладной"
              id="deliveryAssemblyModal.label.fillInTheFieldsWithData"
              description="Подзаголовок в модальном окне"
            />
          </p>
          <div className="row mb-3">
            <Controller
              name="date"
              control={control}
              rules={{
                validate: (value) => {
                  if (!value)
                    return intl.formatMessage({
                      defaultMessage: 'Выберите дату',
                      id: 'deliveryOrderAssemblyModal.title.selectDate',
                      description: 'Пояснение к полю ввода',
                    });

                  if (!isCorrectDate(value))
                    return intl.formatMessage({
                      defaultMessage: 'Укажите корректную дату',
                      id: 'deliveryOrderAssemblyModal.title.selectCorrectDate',
                      description: 'Пояснение к полю ввода',
                    });

                  return true;
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <InputWrapper
                    title={intl.formatMessage({
                      defaultMessage: 'Укажите дату отправки',
                      id: 'deliveryAssemblyModal.input-label.SpecifyTheShippingDate',
                      description: 'Заголовок поля ввода',
                    })}
                    required
                    error={fieldState.error?.message}
                  >
                    <DatePicker
                      className={'w-100'}
                      format={'dd.MM.yyyy HH:mm'}
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Выберите дату',
                        id: 'RefillTransactiosTable.title.SelectDate',
                        description: 'Пояснение к полю ввода',
                      })}
                      isoWeek={true}
                      onChange={(v) => {
                        field.onChange(
                          moment(v).format('DD.MM.YYYY HH:mm') as RawDateValue,
                        );
                      }}
                    ></DatePicker>
                  </InputWrapper>
                );
              }}
            />
          </div>
          <div className="row mb-3">
            <InputWrapper
              title={intl.formatMessage({
                defaultMessage: 'Кол-во мест',
                id: 'deliveryAssemblyModal.input-label.amountOfPlaces',
                description: 'Заголовок поля ввода',
              })}
              className="col-4"
              error={errors.count?.message}
              required
            >
              <input
                type="text"
                className="form-control"
                {...register('count', {
                  ...required,
                  ...intValidation,
                })}
              />
            </InputWrapper>
            <InputWrapper
              title={intl.formatMessage({
                defaultMessage: 'Объём',
                id: 'input.label.volume',
                description: 'Заголовок поля ввода',
              })}
              className="col-4"
              error={errors.volume?.message}
              required
            >
              <div className="input-group">
                <input
                  className="form-control"
                  type="number"
                  min={0}
                  step={0.01}
                  {...register('volume', {
                    ...required,
                    ...decimalValidation,
                  })}
                />
                <span className="input-group-text">м³</span>
              </div>
            </InputWrapper>
            <InputWrapper
              title={intl.formatMessage({
                defaultMessage: 'Вес',
                id: 'input.label.weight',
                description: 'Заголовок поля ввода',
              })}
              className="col-4"
              error={errors.weight?.message}
              required
            >
              <div className="input-group">
                <input
                  className="form-control"
                  type="number"
                  min={0}
                  step={0.01}
                  {...register('weight', {
                    ...required,
                    ...decimalValidation,
                  })}
                />
                <span className="input-group-text">
                  <FormattedMessage
                    defaultMessage="кг"
                    id="input.label.kg"
                    description="Единица измерения"
                  />
                </span>
              </div>
            </InputWrapper>
          </div>

          <div className="row">
            <InputWrapper
              title={intl.formatMessage({
                defaultMessage: 'Цена доставки',
                id: 'deliveryAssemblyModal.input-label.shippingPrice',
                description: 'Заголовок поля ввода',
              })}
              className="col-4"
              error={errors.moderateDeliveryPrice?.message}
              required
            >
              <div className="input-group">
                <input
                  className="form-control"
                  type="number"
                  min={0}
                  step={0.01}
                  {...register('moderateDeliveryPrice', {
                    ...required,
                    ...decimalValidation,
                  })}
                />
                <span className="input-group-text">$</span>
              </div>
            </InputWrapper>
            <InputWrapper
              title={intl.formatMessage({
                defaultMessage: 'Цена упаковки',
                id: 'deliveryAssemblyModal.input-label.packagePrice',
                description: 'Заголовок поля ввода',
              })}
              className="col-4"
              error={errors.moderatePackagePrice?.message}
              required
            >
              <div className="input-group">
                <input
                  className="form-control"
                  type="number"
                  min={0}
                  step={0.01}
                  {...register('moderatePackagePrice', {
                    ...required,
                    ...decimalValidation,
                  })}
                />
                <span className="input-group-text">$</span>
              </div>
            </InputWrapper>
            <InputWrapper
              title={intl.formatMessage({
                defaultMessage: 'Цена страховки',
                id: 'deliveryAssemblyModal.input-label.insurancePrice',
                description: 'Заголовок поля ввода',
              })}
              className="col-4"
              error={errors.moderateInsurancePrice?.message}
              required
            >
              <div className="input-group">
                <input
                  className="form-control"
                  type="number"
                  min={0}
                  step={0.01}
                  {...register('moderateInsurancePrice', {
                    ...required,
                    ...decimalValidation,
                  })}
                />
                <span className="input-group-text">$</span>
              </div>
            </InputWrapper>
          </div>
        </div>
        <div className="modal-footer pt-3 p-4 justify-content-end">
          <button
            type="submit"
            className="btn btn-success"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Подтвердить отправку"
                id="deliveryAssemblyModal.btn-label.confirmShipment"
                description="Надпись на кнопке"
              />
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
