import { Header } from '../../common/header.component';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Input } from '../../common/input.component';
import { required } from '../../../utils/validations';
import { AddButton } from '../../common/add-button';
import { CommonService } from '../../../services/common.service';
import { AlertService } from '../../../services/alert.service';
import { useLocation, useNavigate } from 'react-router-dom';
import { CleanPackageMethod } from '../../../export-types/cleaned-types';
import React from 'react';
import { useSuppliers } from '../../../api/suppliers';
import { Select } from '../../common/select.component';

export const PackageMethodScreen = () => {
  const { data: suppliers } = useSuppliers();
  const navigate = useNavigate();
  const { state } = useLocation() as { state?: CleanPackageMethod };

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      ...{
        tariffs: [
          {
            volumeFrom: '',
            volumeTo: '',
            price: '',
          },
        ],
      },
      ...state,
      supplierId: state?.supplier?.id,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tariffs',
  });

  function onSave(data: unknown) {
    const promise = state
      ? CommonService.updatePackageMethod(state.id, data)
      : CommonService.createPackageMethod(data);

    promise.then(() => {
      AlertService.success();
      navigate('/deliveries/package');
    });
  }

  return (
    <>
      <Header />
      <div className="container" style={{ marginTop: -70 }}>
        <h1 className="mb-4">Добавить способ упаковки</h1>
        <form onSubmit={handleSubmit(onSave)}>
          <Controller
            name="supplierId"
            control={control}
            rules={required}
            render={({ field }) => (
              <Select
                className="mb-3"
                title="Поставщик"
                placeholder="Выберите поставщика"
                options={
                  suppliers?.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  })) ?? []
                }
                {...field}
                error={errors.supplierId?.message}
              />
            )}
          />
          <div className="mb-3">
            <Input
              title="Способ упаковки"
              {...register('name', {
                ...required,
              })}
              error={errors.name?.message}
            />
          </div>
          <div className="mb-3">
            <Input
              title="Комментарий"
              {...register('comment', {
                validate: {
                  length: (v) =>
                    v!.length < 61 || 'Максимальная длина 60 символов',
                },
              })}
              error={errors.comment?.message}
            />
            <div className="fs-14 color-gray-400">
              Отобразится под название способа, максимум 60 символов
            </div>
          </div>
          <h3>Тарифная сетка</h3>
          {fields.map((field, index) => (
            <div className="col-12 mb-3" key={field.id}>
              <div className="row align-items-start">
                <div className="col-8">
                  <div className="mb-2">Объём груза</div>
                  <div className="d-flex flex-row align-items-center">
                    <Input
                      className="flex-grow-1"
                      {...register(`tariffs.${index}.volumeFrom`, {
                        ...required,
                      })}
                      type="number"
                      min={0}
                      step={0.01}
                      error={errors.tariffs?.[index]?.volumeFrom?.message}
                      postfix="м³"
                    />
                    <div className="ps-2 pe-2">—</div>
                    <Input
                      className="flex-grow-1"
                      {...register(`tariffs.${index}.volumeTo`)}
                      type="number"
                      min={0}
                      step={0.01}
                      error={errors.tariffs?.[index]?.volumeTo?.message}
                      postfix="м³"
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="mb-2">Стоимость</div>
                  <div className="d-flex flex-row align-items-center">
                    <Input
                      className="flex-grow-1"
                      {...register(`tariffs.${index}.price`, {
                        ...required,
                      })}
                      type="number"
                      min={0}
                      step={0.01}
                      error={errors.tariffs?.[index]?.price?.message}
                      postfix="$"
                    />
                    {index > 0 ? (
                      <i
                        className="bi bi-trash p-2 cursor-pointer hover-red"
                        onClick={() => remove(index)}
                      />
                    ) : (
                      <div style={{ width: 31 }} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="mb-3">
            <AddButton
              onClick={() =>
                append({
                  price: '',
                  volumeTo: null,
                  id: '',
                  volumeFrom: '',
                  createdAt: '',
                })
              }
            >
              Добавить тариф
            </AddButton>
          </div>
          <button type="submit" className="btn btn-success w-100">
            Сохранить
          </button>
        </form>
      </div>
    </>
  );
};
