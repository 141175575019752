import React, { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Header } from '../../common/header.component';
import useSWR from 'swr';
import { fetcher } from '../../../services/api';
import { ReceiptConfirmation } from '../purchase-order/receipt-confirmation';
import { PurchaseOrderReceiptConfirmationModal } from '../purchase-order/purchase-order-receipt-confirmation-modal';
import { ModalsService } from '../../../services/modals.service';
import { ClientInfo } from '../../common/client-info';
import { ParcelStatusTable } from './parcel-status-table.component';
import { ParcelInfo } from './parcel-info';
import { Rbac } from '../../common/Rbac';
import {
  CleanConversationType,
  CleanPurchaseOrder,
  RbacObject,
} from '../../../export-types/cleaned-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { SupplierInfo } from '../../common/SupplierInfo';
import { ConversationWindow } from '../../common/conversation-window';
import { Nav } from 'rsuite';
import { useProfile } from '../../../api/profile';
import { PurchaseOrderFinancesInfo } from '../purchase-order/purchase-order-finances-info';
import { OrderTransactionsInfo } from '../purchase-order/admin-order-transactions-info';

const receivedInfoStatuses = ['receivedInStock', 'sentToRussia'];

export const AdminParcelPage: React.FC = () => {
  const intl = useIntl();
  const { data: user } = useProfile();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = useMemo<CleanConversationType>(() => {
    const chat = searchParams.get('chat') || 'supplier';
    if (!['supplier', 'client'].includes(chat)) {
      return 'supplier';
    }
    return chat as CleanConversationType;
  }, [searchParams]);
  const { data: parcel, mutate: parcelMutate } = useSWR<CleanPurchaseOrder>(
    `/parcels/${id}`,
    fetcher,
  );

  function nextStep() {
    if (!parcel) return;

    return ModalsService.createModal(PurchaseOrderReceiptConfirmationModal, {
      orderId: parcel.id,
      internalId: parcel.internalId,
    }).then(() => parcelMutate());
  }

  if (!parcel) {
    return null;
  }

  return (
    <div className="d-flex flex-column h-100">
      <Header>
        <div className="d-flex flex-row align-items-center">
          <h1 className="me-3 mb-0">
            {intl.formatMessage(
              {
                defaultMessage: 'Входящая посылка {value}',
                id: 'parcel.label.incomingParcel',
                description: 'Заголовок на странице',
              },
              {
                value: 'K' + parcel.internalId,
              },
            )}
          </h1>
        </div>
      </Header>
      <div className="d-flex flex-column flex-lg-row flex-grow-1 gap-4">
        <div className="col flex-grow-1">
          <div className="bg-gray p-3 pb-0 rounded-8 mb-3">
            <div className="fs-18 fw-600 color-gray-450 mb-3">
              Статус заказа
            </div>
            <ParcelStatusTable parcel={parcel} />
          </div>
          <div className="bg-gray p-3 rounded-8">
            <ParcelInfo id={parcel.id} />
            {(receivedInfoStatuses.includes(parcel.status) ||
              parcel.productFilesV2?.length > 0 ||
              parcel.category ||
              (parcel.weight && parcel.volume)) && (
              <>
                <hr />
                <ReceiptConfirmation id={parcel.id} />
              </>
            )}
          </div>
          <div className="bg-gray p-3 rounded-8 mt-3">
            <ClientInfo user={parcel.user!} />
          </div>
          <Rbac
            object={RbacObject.Purchase}
            action={['read:finances-client', 'read:finances-company']}
          >
            <div className="bg-gray p-3 rounded-8 mt-3">
              <PurchaseOrderFinancesInfo id={parcel.id} />
            </div>
          </Rbac>
          <Rbac object={RbacObject.Purchase} action={'read:transactions'}>
            <OrderTransactionsInfo type={'purchase-order'} id={parcel.id} />
          </Rbac>
          <Rbac object={RbacObject.Purchase} action={'read:extended-statuses'}>
            <SupplierInfo supplier={parcel.supplier?.name} />
          </Rbac>
        </div>
        <div className="col d-flex flex-column">
          <Rbac object={RbacObject.Purchase} action={'confirmReceipt'}>
            {parcel.status === 'onTheWayToStock' && (
              <div className="d-flex flex-row justify-content-center">
                <div className="btn btn-success" onClick={nextStep}>
                  <FormattedMessage
                    defaultMessage="Подтвердить получение на склад"
                    id="order.status-label.confirmReceiptToWarehouse"
                    description="Подтверждение получения"
                  />{' '}
                  | <i className="bi bi-check-lg fs-18" />
                </div>
              </div>
            )}
          </Rbac>
          {user && !user.accesses.includes('fulfillment') ? (
            <div className="flex-grow-1 d-flex mb-4 flex-column">
              <Nav
                appearance="tabs"
                activeKey={tab}
                onSelect={(key) => setSearchParams({ chat: key })}
              >
                <Nav.Item eventKey="supplier" className="bg-gray-50">
                  <FormattedMessage
                    defaultMessage="Поставщик"
                    id="TransactiosHistrory.lable.supplier"
                    description="Вкладка"
                  />
                </Nav.Item>
                {!user?.supplier && (
                  <Nav.Item eventKey="client" className="bg-teal-100">
                    <FormattedMessage
                      defaultMessage="Клиент"
                      id="clientInfo.label.customer"
                      description="Заголовок на странице"
                    />
                  </Nav.Item>
                )}
              </Nav>
              <ConversationWindow
                id={parcel.id}
                type="parcel"
                internalId={parcel.internalId}
                supplier={parcel.supplier}
                client={parcel.user}
                tab={tab}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
