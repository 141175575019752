import PdfIcon from '../../assets/pdf-file.svg';
import React, { useRef, useState, useEffect } from 'react';

type Props = React.ImgHTMLAttributes<HTMLImageElement> & {
  file: File;
};

export const LocalImg: React.FC<Props> = ({ file, ...props }) => {
  const reader = useRef(new FileReader());
  const [url, setUrl] = useState<string | null>(null);

  reader.current.onload = (e) => {
    // @ts-ignore
    setUrl(e.target.result);
  };

  useEffect(() => {
    if (file.type.startsWith('image/')) {
      reader.current.readAsDataURL(file);
    } else if (file.type === 'application/pdf') {
      setUrl(PdfIcon);
    }
  }, [file]);

  if (!url) {
    return null;
  }

  return <img src={url} {...props} />;
};
