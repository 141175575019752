import { Header } from '../../common/header.component';
import React from 'react';
import {
  ClientExpansesBasedCommissionsForm,
  Form,
} from './client-expanses-based-commissions-form';
import { api } from '../../../services/api';
import { useNavigate } from 'react-router-dom';
import { AlertService } from '../../../services/alert.service';

export const ClientExpansesBasedCommissionsCreateScreen: React.FC = () => {
  const navigate = useNavigate();

  const handleSubmit = React.useCallback(
    async (data: Form) => {
      await api.post('/client-expenses-purchase-markups/admin', data);
      AlertService.success('Новая комиссия на доставку создана');
      navigate(-1);
    },
    [navigate],
  );

  return (
    <>
      <Header />
      <div className="container" style={{ marginTop: -70 }}>
        <h1 className="mb-4">Добавить комиссию за выкуп</h1>
        <ClientExpansesBasedCommissionsForm onSubmit={handleSubmit} />
      </div>
    </>
  );
};
