import React from 'react';
import { useForm } from 'react-hook-form';
import { api } from '../../../services/api';
import { InputWrapper } from '../../common/input-wrapper';
import { useModalClose } from '../../../utils/useModalClose';

type Form = {
  paymentName: string;
};

type ChangePaymentNameModalProps = {
  close: () => void;
  reject: () => void;
  paymentName: string;
  method: string;
};

export const ChangePaymentNameModal: React.FC<ChangePaymentNameModalProps> = ({
  close,
  reject,
  paymentName,
  method,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Form>({
    defaultValues: {
      paymentName,
    },
  });

  function onSave(data: Form) {
    api.post(`/top-up/methods/${method}/paymentName`, data).then(() => {
      close();
    });
  }

  const modalRef = useModalClose(reject);

  return (
    <div className="modal-dialog" ref={modalRef}>
      <form className="modal-content" onSubmit={handleSubmit(onSave)}>
        <div className="modal-body">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h3>Изменить наименование платежа</h3>
            <div className="btn p-0" onClick={reject}>
              <i className="bi bi-x fs-36 color-gray-600" />
            </div>
          </div>
          <div className="row mt-3">
            <InputWrapper
              title="Наименование платежа"
              className="col"
              error={errors.paymentName?.message}
              required
            >
              <div className="input-group">
                <input
                  className="form-control"
                  type="text"
                  {...register('paymentName')}
                />
              </div>
            </InputWrapper>
          </div>
        </div>
        <div className="modal-footer justify-content-end mt-4">
          <button type="submit" className="btn btn-success">
            Сохранить
          </button>
        </div>
      </form>
    </div>
  );
};
