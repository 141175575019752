import useSWR from 'swr';
import { fetcher } from '../services/api';
import {
  CleanDeliveryMethod,
  CleanDeliveryOrderDeliveryMarkup,
  CleanLocalDeliveryMethod,
  CleanLocation,
  CleanPackageMethod,
  CleanPurchaseOrder,
} from '../export-types/cleaned-types';

export type OrderGroup = {
  orders: string[];
};

export type LocalDeliveryMethod = {
  /** @deprecated в id slug вместо uuid */
  id: string;
  uuid: string;
  name: string;
  slug: string;
  location: CleanLocation;
  isCdek: boolean;
};

export type Address = {
  id: string;
  name: string;
  phone: string;
  city: string;
  type: 'legal' | 'individual' | 'pickupPoint';
  companyInn?: string;
  companyName?: string;
  passport?: string;
  pickupPoint?: string;
  cdekCode?: string;
};

export type PackageGroup = {
  orders: string[];
  package: string[];
};

export type DeliveryMethod = {
  id: string;
  name: string;
  type: 'air' | 'railway' | 'auto';
  deliveryPeriodFrom: number;
  deliveryPeriodTo?: number;
  price: string;
  destination: CleanLocation;
};

export type PackageMethod = {
  id: string;
  name: string;
  comment?: string;
  price: string;
};

export function useDeliveryMarkup() {
  const { data, isLoading, mutate } = useSWR<
    CleanDeliveryOrderDeliveryMarkup[]
  >('/delivery-markup', fetcher);

  return { data, isLoading, mutate };
}

export function useLocalDeliveryMethods() {
  const { data, isLoading, mutate } = useSWR<CleanLocalDeliveryMethod[]>(
    '/local-delivery-method',
    fetcher,
  );
  return { data, isLoading, mutate };
}

export function useStorageLocation() {
  const { data, isLoading, mutate } = useSWR<string[]>(
    `/supplier/storage-locations`,
    fetcher,
  );
  return { data, isLoading, mutate };
}

export function useDeliveriesMethod(purchaseOrders: string[]) {
  const { data, isLoading, mutate } = useSWR<{
    delivery: CleanDeliveryMethod[];
    localDelivery: LocalDeliveryMethod[];
  }>(
    purchaseOrders.length
      ? {
          url: `/delivery-order/methods`,
          params: {
            purchaseOrders: purchaseOrders,
          },
        }
      : null,
    fetcher,
  );
  return { data, isLoading, mutate };
}

export function useDeliveriesMethodForClient(purchaseOrders: string[]) {
  const { data, isLoading, mutate } = useSWR<{
    delivery: DeliveryMethod[];
    localDelivery: LocalDeliveryMethod[];
    isMixedParcel: boolean;
  }>(
    purchaseOrders.length
      ? {
          url: `/delivery-order/methods`,
          params: {
            purchaseOrders: purchaseOrders,
          },
        }
      : null,
    fetcher,
  );
  return { data, isLoading, mutate };
}

export function usePackageMethods(groups: OrderGroup[]) {
  const { data, isLoading, mutate } = useSWR(
    () => {
      return groups
        .map((group) => group.orders)
        .map((purchaseOrders) => ({
          url: `/delivery-order/package-methods`,
          params: {
            purchaseOrders: purchaseOrders,
          },
        }));
    },
    (keys) =>
      Promise.all<CleanPackageMethod[]>(keys.map((key) => fetcher(key))),
  );
  return { data, isLoading, mutate };
}

export function usePackageMethodsForClient(groups: OrderGroup[]) {
  const { data, isLoading, mutate } = useSWR(
    () => {
      return groups
        .map((group) => group.orders)
        .map((purchaseOrders) => ({
          url: `/delivery-order/package-methods`,
          params: {
            purchaseOrders: purchaseOrders,
          },
        }));
    },
    (keys) => Promise.all<PackageMethod[]>(keys.map((key) => fetcher(key))),
  );
  return { data, isLoading, mutate };
}

export function useDeliveryAvailablePurchases(
  internalId: number | null,
  purchaseOrders: string[],
) {
  const { data, isLoading, mutate } = useSWR<{
    list: CleanPurchaseOrder[];
    orderWeight: string | null;
    orderVolume: string | null;
    orderDensity: string | null;
  }>(
    internalId
      ? {
          url: `/delivery-order/available-purchase-orders/${internalId}`,
          params: {
            purchaseOrders,
          },
        }
      : null,
    fetcher,
  );
  return { data, isLoading, mutate };
}

export function useDeliveryAddresses(internalId: number | null) {
  const { data, isLoading, mutate } = useSWR<Address[]>(
    internalId ? `/delivery-address/client/${internalId}` : null,
    fetcher,
  );
  return { data, isLoading, mutate };
}

export const useDeliveryCalculation = (
  purchaseOrders: string[],
  deliveryMethod: string,
  localDeliveryMethod: string,
  packagesGroup: PackageGroup[],
  internalId: number | null,
  address?: string,
) => {
  const { data, isLoading, mutate } = useSWR<{
    delivery: string | null;
    insurance: string | null;
    package: string | null;
    localDelivery: string | null;
    total: string | null;
  }>(() => {
    if (!purchaseOrders.length || !internalId || !localDeliveryMethod)
      return null;
    if (
      !packagesGroup.length ||
      packagesGroup.some((group) => !group.package.length)
    )
      return null;
    return {
      url: `/delivery-order/calculate`,
      params: {
        purchaseOrders: purchaseOrders,
        deliveryMethod: deliveryMethod ? deliveryMethod : undefined,
        localDeliveryMethod: localDeliveryMethod
          ? localDeliveryMethod
          : undefined,
        packagesGroup: packagesGroup ? packagesGroup : undefined,
        internalId: internalId,
        address: address,
      },
    };
  }, fetcher);
  return { data, isLoading, mutate };
};
