import React, { useMemo } from 'react';
import Table, { CellProps } from 'rsuite/Table';
import { AlertService } from '../../../services/alert.service';
import Popover from 'rsuite/Popover';
import Dropdown from 'rsuite/Dropdown';
import Whisper from 'rsuite/Whisper';
import IconButton from 'rsuite/IconButton';
import useSWR from 'swr';
import { api, fetcher } from '../../../services/api';
import { ToggleCell } from '../../common/table/toggle-cell';
import { useNavigate } from 'react-router-dom';
import {
  CleanLocalDeliveryMethod,
  RbacObject,
} from '../../../export-types/cleaned-types';
import { WhisperSpeaker } from '../../../utils/whisper-speaker';
import { SupplierCell } from '../../common/table/supplier-cell';
import {
  SupplierSelect,
  useSelectSupplierQs,
} from '../purchase-order/supplier-select';
import { Rbac } from '../../common/Rbac';

export const LocalDeliveryMethodsTab = () => {
  const navigate = useNavigate();
  const [selectSupplier] = useSelectSupplierQs();

  const { data, mutate, isValidating } = useSWR<
    Array<CleanLocalDeliveryMethod>
  >('/local-delivery-method', fetcher);

  async function checkDeliveryMethod(
    item: CleanLocalDeliveryMethod,
    value: boolean,
  ) {
    await api.put(`/local-delivery-method/${item.id}`, { disabled: value });
    mutate();
    AlertService.success();
  }

  async function checkSelfDelivery(
    item: CleanLocalDeliveryMethod,
    value: boolean,
  ) {
    await api.put(`/local-delivery-method/${item.id}`, { selfDelivery: value });
    mutate();
    AlertService.success();
  }

  const updatedData = useMemo(() => {
    if (data && selectSupplier) {
      return data.filter(
        (item) => item.supplier && item.supplier.id === selectSupplier,
      );
    } else {
      return data;
    }
  }, [data, selectSupplier]);

  const renderMenu =
    (item: CleanLocalDeliveryMethod): WhisperSpeaker =>
    ({ onClose, left, top, className }, ref) => {
      const handleSelect = (eventKey: unknown) => {
        onClose();
        if (eventKey === 1) {
          navigate(`/deliveries/local-delivery-method`, { state: item });
        }
      };

      return (
        <Popover ref={ref} className={className} style={{ left, top }} full>
          <Dropdown.Menu onSelect={handleSelect}>
            <Dropdown.Item eventKey={1}>
              <i className="bi bi-pencil" />
              <span className="ps-2">Изменить</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Popover>
      );
    };

  const ActionCell = ({
    rowData,
    dataKey,
    ...props
  }: CellProps<CleanLocalDeliveryMethod>) => {
    return (
      rowData && (
        <Table.Cell
          {...props}
          className="link-group no-padding-cell pt-2"
          align="right"
        >
          <Whisper
            placement="leftStart"
            trigger="click"
            speaker={renderMenu(rowData)}
          >
            <IconButton
              appearance="subtle"
              icon={<i className="bi bi-three-dots" />}
            />
          </Whisper>
        </Table.Cell>
      )
    );
  };

  const MinWeight = ({
    rowData,
    ...props
  }: CellProps<CleanLocalDeliveryMethod>) =>
    rowData &&
    rowData.minWeight && (
      <Table.Cell {...props}>{rowData.minWeight}</Table.Cell>
    );
  const MaxWeight = ({
    rowData,
    ...props
  }: CellProps<CleanLocalDeliveryMethod>) =>
    rowData &&
    rowData.maxWeight && (
      <Table.Cell {...props}>{rowData.maxWeight}</Table.Cell>
    );

  return (
    <>
      <div className="d-flex w-75 mr-auto z-3">
        <Rbac object={RbacObject.Supplier} action={'read:admin'}>
          <SupplierSelect />
        </Rbac>
      </div>

      <Table
        data={updatedData}
        loading={isValidating && !data}
        headerHeight={57}
        autoHeight
      >
        <Table.Column width={150} fullText>
          <Table.HeaderCell>Поставщик</Table.HeaderCell>
          <SupplierCell />
        </Table.Column>
        <Table.Column width={150} fullText>
          <Table.HeaderCell>Откуда</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => {
              return (rowData as CleanLocalDeliveryMethod).location?.name;
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={1} minWidth={100} fullText>
          <Table.HeaderCell>Название</Table.HeaderCell>
          <Table.Cell dataKey="name" />
        </Table.Column>
        <Table.Column flexGrow={1} minWidth={100} fullText>
          <Table.HeaderCell>Псевдоним</Table.HeaderCell>
          <Table.Cell dataKey="slug" />
        </Table.Column>
        <Table.Column flexGrow={1} minWidth={100}>
          <Table.HeaderCell>Приоритет</Table.HeaderCell>
          <Table.Cell dataKey="priority" />
        </Table.Column>
        <Table.Column flexGrow={1} minWidth={100}>
          <Table.HeaderCell>Цена Клиент</Table.HeaderCell>
          <Table.Cell dataKey="priceClient" />
        </Table.Column>
        <Table.Column flexGrow={1} minWidth={100}>
          <Table.HeaderCell>Цена Поставщик</Table.HeaderCell>
          <Table.Cell dataKey="priceSupplier" />
        </Table.Column>
        <Table.Column flexGrow={1} minWidth={100} fullText>
          <Table.HeaderCell>Min вес (кг)</Table.HeaderCell>
          <MinWeight />
        </Table.Column>
        <Table.Column flexGrow={1} minWidth={100} fullText>
          <Table.HeaderCell>Max вес (кг)</Table.HeaderCell>
          <MaxWeight />
        </Table.Column>
        <Table.Column flexGrow={1} minWidth={100}>
          <Table.HeaderCell>Включен</Table.HeaderCell>
          <ToggleCell
            dataKey="disabled"
            inverted={true}
            onChange={checkDeliveryMethod}
          />
        </Table.Column>
        <Table.Column flexGrow={1} minWidth={100}>
          <Table.HeaderCell>Самовывоз</Table.HeaderCell>
          <ToggleCell dataKey="selfDelivery" onChange={checkSelfDelivery} />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell> </Table.HeaderCell>
          <ActionCell />
        </Table.Column>
      </Table>
    </>
  );
};
