import React from 'react';
import { useForm } from 'react-hook-form';
import { decimalValidation, required } from '../../../utils/validations';
import { api } from '../../../services/api';
import { InputWrapper } from '../../common/input-wrapper';
import { getTopUpMethodTitle } from '../../../utils/common.utils';
import { useModalClose } from '../../../utils/useModalClose';

type Form = {
  fee: string;
};

type ChangeCommissionModalProps = {
  close: () => void;
  reject: () => void;
  fee: number;
  method: string;
};

export const ChangeCommissionModal: React.FC<ChangeCommissionModalProps> = ({
  close,
  reject,
  fee,
  method,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Form>({
    defaultValues: {
      fee: fee.toString(),
    },
  });

  function onSave(data: Form) {
    api.post(`/top-up/methods/${method}/fee`, data).then(() => {
      close();
    });
  }

  const modalRef = useModalClose(reject);

  return (
    <div className="modal-dialog" ref={modalRef}>
      <form className="modal-content" onSubmit={handleSubmit(onSave)}>
        <div className="modal-body">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h3>Изменить комиссию</h3>
            <div className="btn p-0" onClick={reject}>
              <i className="bi bi-x fs-36 color-gray-600" />
            </div>
          </div>
          <div className="row mt-3">
            <InputWrapper title="Способ пополнения" className="col">
              <input
                className="form-control"
                type="string"
                value={getTopUpMethodTitle(method)}
                disabled={true}
              />
            </InputWrapper>
          </div>
          <div className="row mt-3">
            <InputWrapper
              title="Размер комиссии"
              className="col"
              error={errors.fee?.message}
              required
            >
              <div className="input-group">
                <input
                  className="form-control"
                  type="number"
                  min={0}
                  step={0.01}
                  placeholder="Например, 5"
                  {...register('fee', {
                    ...required,
                    ...decimalValidation,
                  })}
                />
                <span className="input-group-text">%</span>
              </div>
            </InputWrapper>
          </div>
        </div>
        <div className="modal-footer justify-content-end mt-4">
          <button type="submit" className="btn btn-success">
            Сохранить
          </button>
        </div>
      </form>
    </div>
  );
};
