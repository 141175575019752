import classNames from 'classnames';
import tableStyle from './parcels-table.module.css';
import { IdCell } from '../../common/cards/id-cell-card.component';
import { ImagesCell } from '../../common/cards/images-cell-card.component';
import { NameCell } from '../../common/cards/name-cell-card.component';
import moment from 'moment/moment';
import { StatusCell } from '../../common/cards/status-cell-card.component';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Rbac, useRbac } from '../../common/Rbac';
import { RbacObject } from '../../../export-types/cleaned-types';
import { Link } from 'react-router-dom';

export type ParcelStatusProps =
  | 'onTheWayToStock'
  | 'receivedInStock'
  | 'sentToRussia'
  | 'canceled';
export type ParcelProps = {
  id: string;
  status: ParcelStatusProps;
  createdAt: string;
  internalId: number;
  user: {
    internalId: number;
  };
  requestFilesV2: [];
  name: string;
  receiptDate?: string;
  deliveryOrder: {
    id: string;
    internalId: number;
  };
  cancelReason: string;
  storageLocation?: string;
  supplier: {
    id: number;
    name: string;
    addressForParcels: string;
  };
  unreadMessages: number;
  trackNumber: string;
};

export type ParcelItemProps = {
  onClick: (data: ParcelProps) => void;
  data: ParcelProps;
  type: 'onTheWayToStock' | 'receivedInStock' | 'archive';
};

export function AdminParcelItem(props: ParcelItemProps) {
  const { isLoading, hasPermission } = useRbac();

  const canReadExtendedData =
    !isLoading && hasPermission(RbacObject.Purchase, 'read:extended-statuses');

  return (
    <div
      className={classNames(
        'position-relative row cursor-pointer g-0',
        tableStyle.tableRow,
      )}
      onClick={() => {
        props.onClick(props.data);
      }}
    >
      {(props.data?.unreadMessages ?? 0) > 0 && (
        <div className="position-absolute top-0 start-0">
          <span className="badge bg-green-500">
            {props.data.unreadMessages}
          </span>
        </div>
      )}
      <Rbac object={RbacObject.Purchase} action="read:extended-statuses">
        <div className="col-sm-2 col-xl-1">
          {props.data.supplier?.name ?? ''}
        </div>
      </Rbac>
      <div className="col-1">
        <Link
          to={`/parcels/${props.data.id}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <IdCell internalId={props.data.internalId} type="parcel" />
        </Link>
      </div>
      <div className="col-1">{props.data.user.internalId}</div>
      <div className="col-1">
        <ImagesCell imagesList={props.data.requestFilesV2} />
      </div>
      <div
        className={
          canReadExtendedData
            ? classNames({
                'col-sm-1 col-xl-2': props.type === 'archive',
                'col-sm-2 col-xl-3': props.type === 'receivedInStock',
                'col-sm-4 col-xl-5': props.type === 'onTheWayToStock',
              })
            : classNames({
                'col-2': props.type === 'archive',
                'col-3': props.type === 'receivedInStock',
                'col-4': props.type === 'onTheWayToStock',
              })
        }
      >
        <NameCell name={props.data.name} />
      </div>
      <div className="col-1">
        <div>{props.data.trackNumber}</div>
        <div className="color-gray-600 fs-14">Трек-номер</div>
      </div>
      {props.type === 'receivedInStock' && (
        <div className="ps-3 col-2">
          {props.data.storageLocation && (
            <>
              <div className="color-gray-800 fs-16">
                {props.data.storageLocation}
              </div>
              <div className="color-gray-600 fs-14">
                <FormattedMessage
                  defaultMessage="Место"
                  id="storage.label.place"
                  description="Место"
                />
              </div>
            </>
          )}
        </div>
      )}
      <div
        className={classNames('col-2 text-end', {
          'ps-3': props.type !== 'receivedInStock',
        })}
      >
        <div className="color-gray-800 fs-16">
          {moment(
            props.type === 'onTheWayToStock' || props.type === 'archive'
              ? props.data.createdAt
              : props.data.receiptDate,
          ).format('DD.MM.YYYY')}
        </div>
        <div className="color-gray-600 fs-14">
          {props.type === 'onTheWayToStock' || props.type === 'archive' ? (
            <FormattedMessage
              defaultMessage="Добавлена"
              id="parcel.status-label.added"
              description="Cтатус во вкладке"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Получена"
              id="parcel.status-label.received"
              description="Cтатус во вкладке"
            />
          )}
        </div>
      </div>
      {props.type === 'archive' && (
        <div
          className="col-3 text-end"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <StatusCell
            deliveryOrder={props.data.deliveryOrder}
            status={props.data.status}
            cancelReason={props.data.cancelReason}
          />
        </div>
      )}
    </div>
  );
}
