import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { DeliveryOrdersTabs } from './delivery-orders-tabs';
import { useLocalStorage } from '@mantine/hooks';
import Toggle from 'rsuite/Toggle';
import { FormattedMessage, useIntl } from 'react-intl';
import { RbacObject } from '../../../export-types/cleaned-types';
import { Rbac } from '../../common/Rbac';
import {
  SupplierSelect,
  useSelectSupplierQs,
} from '../purchase-order/supplier-select';
import { useSearchParamsData } from '../purchase-order/useSearchParamsData';
import { useProfile } from '../../../api/profile';

export type OutletContextParams = {
  debouncedInternalId: string;
  linked: boolean;
  expanded: boolean;
  collapsed: string[];
  onChangeCollapsed: (hidden: string[]) => void;
  selectSupplier: number | undefined;
  direction?: string;
};

export const DeliveryOrdersScreen = () => {
  const [direction, setDirection] = useState('asc');
  const [searchParamsData, setSearchParamsFunctions] = useSearchParamsData();
  const { data: user } = useProfile();

  const [internalId, setInternalId] = useState(
    searchParamsData['internal'] || '',
  );
  const [linked, setLinked] = React.useState(false);
  const [expanded, setExpanded] = useLocalStorage({
    key: 'delivery-order-expand',
    defaultValue: true,
  });
  const [collapsed, setCollapsed] = React.useState<string[]>([]);
  const [supplierId] = useSelectSupplierQs();

  useEffect(() => {
    if (searchParamsData['internal'] !== internalId) {
      setSearchParamsFunctions['internal'](internalId);
    }
  }, [internalId, setSearchParamsFunctions]);

  const intl = useIntl();

  const context: OutletContextParams = {
    debouncedInternalId: internalId,
    linked,
    expanded: expanded ?? true,
    collapsed,
    onChangeCollapsed: setCollapsed,
    selectSupplier: supplierId,
    direction,
  };

  const sortHandleChange = () => {
    setDirection(direction === 'asc' ? 'desc' : 'asc');
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center pb-4 ">
        <div className="d-flex mr-auto" style={{ width: '65%' }}>
          <input
            className="form-control w-25"
            type="text"
            placeholder={intl.formatMessage({
              defaultMessage: 'Поиск по номеру заказа/клиента',
              id: 'input.placeholder.searchByOrder/CustomerNumber',
              description: 'Поле ввода',
            })}
            value={searchParamsData['internal']}
            onChange={(event) => setInternalId(event.currentTarget.value)}
          />
          <Rbac object={RbacObject.Supplier} action={'read:admin'}>
            <SupplierSelect />
          </Rbac>
        </div>
        <div className="d-flex">
          <Toggle checked={linked} onChange={() => setLinked(!linked)} />
          <div className="ms-2">
            <FormattedMessage
              defaultMessage="Мои клиенты"
              id="switchButton.label.myCustomers"
              description="Надпись на переключателе"
            />
          </div>
        </div>
        <div className="d-flex">
          <Toggle checked={direction === 'desc'} onChange={sortHandleChange} />
          <div className="ms-2">
            <FormattedMessage
              defaultMessage="Сначала новые"
              id="switchButton.label.newOnesFirst"
              description="Надпись на переключателе"
            />
          </div>
        </div>
        <div className="d-flex align-items-center text-center">
          <button
            className="btn mr-4"
            onClick={() => {
              setExpanded(!expanded);
              setCollapsed([]);
            }}
          >
            {expanded ? (
              <i className="bi bi-chevron-down"></i>
            ) : (
              <i className="bi bi-chevron-up"></i>
            )}
          </button>
          <div className="ms-2">
            <FormattedMessage
              defaultMessage="Показать/скрыть заказы"
              id="switchButton.label.show/hideOrders"
              description="Надпись на переключателе"
            />
          </div>
        </div>
      </div>
      <div className="mb-4">
        <DeliveryOrdersTabs
          selectSupplier={supplierId}
          debouncedInternalId={internalId}
          linked={linked}
          expanded={expanded ?? true}
          collapsed={collapsed}
          onChangeCollapsed={setCollapsed}
        />
      </div>
      <Outlet context={context} />
    </>
  );
};
