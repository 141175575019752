export const IconDelayed = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M189 80L186.5 83L190 88V76L189 80Z" fill="#12B76A" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M190 76V50.8924C189.949 47.2541 188.79 43.7045 186.658 40.6579C184.525 37.6114 181.508 35.1937 177.958 33.6872L104.835 1.93444C101.913 0.660338 98.7259 0 95.5 0C92.2741 0 89.0874 0.660338 86.1647 1.93444L13.042 33.6872C9.49206 35.1937 6.47477 37.6114 4.34248 40.6579C2.21019 43.7045 1.0509 47.2541 1 50.8924V136.711C1.05429 140.281 2.17707 143.766 4.24165 146.773C6.30623 149.78 9.23042 152.188 12.6842 153.727L76.8038 182.915L78.5 169.672L18.5905 142.391C17.4119 141.894 16.4049 141.1 15.6825 140.099C14.96 139.098 14.5505 137.929 14.5 136.723V53.0273L77.165 81.5L82.6811 70L23.4167 43.0707L91.855 13.3521C93.0045 12.8832 94.2455 12.6412 95.5 12.6412C96.7545 12.6412 97.9955 12.8832 99.145 13.3521L167.583 43.0707L131.542 59.4478L154.551 63L176.5 53.0273V76L177 80L179.5 83L182.5 85H184L186.5 83L189 80L190 76Z"
        fill="#12B76A"
      />
      <rect
        x="53.287"
        y="16.9106"
        width="81.0615"
        height="11.1522"
        transform="rotate(24.0205 53.287 16.9106)"
        fill="#12B76A"
      />
      <ellipse cx="183" cy="78" rx="7" ry="8" fill="#12B76A" />
      <path
        d="M124.5 200C82.8705 200 49 166.13 49 124.5C49 82.8705 82.8705 49 124.5 49C166.13 49 200 82.8705 200 124.5C200 166.13 166.13 200 124.5 200ZM124.5 60.6154C89.2705 60.6154 60.6154 89.2705 60.6154 124.5C60.6154 159.729 89.2705 188.385 124.5 188.385C159.729 188.385 188.385 159.729 188.385 124.5C188.385 89.2705 159.729 60.6154 124.5 60.6154Z"
        fill="#C23330"
      />
      <path
        d="M153.293 159C152.047 159 150.779 158.585 149.727 157.742L121.149 134.665C119.789 133.558 119 131.908 119 130.154V89.7692C119 86.5846 121.561 84 124.716 84C127.871 84 130.431 86.5846 130.431 89.7692V127.385L156.86 148.731C159.318 150.727 159.718 154.35 157.752 156.842C156.631 158.262 154.962 159 153.293 159Z"
        fill="#C23330"
      />
    </svg>
  );
};
