import React from 'react';
import { CleanUser } from '../../../export-types/cleaned-types';

type ManagerProps = {
  manager: CleanUser;
};

export const Manager: React.FC<ManagerProps> = ({ manager }) => {
  return (
    <div>
      <div className="color-gray-800 fs-16 fw-500 text-truncate ">
        {manager.name}
      </div>
      <div className="color-gray-600 fs-14 fw-400">Менеджер</div>
    </div>
  );
};
