import ReactSelect from 'react-select';
import { CSSProperties, forwardRef } from 'react';
import React from 'react';
import { StateManagerAdditionalProps } from 'react-select/dist/declarations/src/useStateManager';

type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

type SelectProps = {
  className?: string;
  name?: string;
  title?: string;
  placeholder: string;
  options: Array<{ value: unknown; label: string }>;
  required?: boolean;
  withEmpty?: boolean;
  disabled?: boolean;
  error?: string;
  isMulti?: boolean;
  onChange: (...args: any) => any;
  value: any;
  style?: CSSProperties;
  selectClassName?: string;
};

export const Select = forwardRef<
  HTMLSelectElement,
  SelectProps & StateManagerAdditionalProps<unknown>
>(
  (
    {
      className,
      name,
      title,
      placeholder,
      options,
      required,
      withEmpty,
      disabled,
      error,
      isMulti,
      onChange,
      value,
      style,
      selectClassName,
      ...props
    },
    ref,
  ) => {
    function _onChange(data: typeof options | ArrayElement<typeof options>) {
      onChange({
        target: {
          ...data,
          name,
          value:
            data instanceof Array
              ? data.map(({ value }) => value)
              : data?.value,
        },
      });
    }

    function selectedValue() {
      if (value === undefined || !value) {
        return null;
      }
      if (value instanceof Array) {
        return value.map((v) => options.find((o) => o.value === v));
      }

      return options.find((o) => o.value === value);
    }

    function getOptions() {
      if (withEmpty) {
        return [{ value: null, label: 'Не выбрано' }, ...options];
      }
      return options;
    }

    return (
      <div className={className} style={style}>
        {title && (
          <div className="mb-2">
            {title} {required && <span className="color-red">*</span>}
          </div>
        )}
        <ReactSelect
          // @ts-ignore
          ref={ref}
          isDisabled={disabled}
          options={getOptions()}
          placeholder={placeholder}
          isMulti={isMulti}
          value={selectedValue()}
          // @ts-ignore
          onChange={(data) => _onChange(data)}
          className={selectClassName}
          {...props}
        />
        {!!error && <div className="color-red">{error}</div>}
      </div>
    );
  },
);
