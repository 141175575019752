import React from 'react';
import { NavLink } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher } from '../../../services/api';
import { RbacObject } from '../../../export-types/cleaned-types';
import { Rbac } from '../../common/Rbac';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelectSupplierQs } from './supplier-select';
import { Tab } from './admin-purchase-orders.component';

type PurchaseOrderStepsInfoProps = {
  idClient: number | undefined | null;
};

export const PurchaseOrderStepsInfo: React.FC<PurchaseOrderStepsInfoProps> = ({
  idClient,
}) => {
  const intl = useIntl();
  const WFP: Tab<RbacObject.Purchase> = {
    title: intl.formatMessage({
      id: 'orders.tab-label.redeem',
      defaultMessage: 'Выкупить',
      description: 'Надпись на вкладке',
    }),
    path: `/purchases/orders/in-work?internal=${idClient}`,
    name: 'inWork',
  };
  const tabs = [
    WFP,
    {
      title: intl.formatMessage({
        id: 'orders.tab-label.frozen',
        defaultMessage: 'Заморожен',
        description: 'Надпись на вкладке',
      }),
      path: `/purchases/orders/frozen?internal=${idClient}`,
      name: 'frozen',
    },
    {
      title: intl.formatMessage({
        id: 'orders.tab-label.inTransit',
        defaultMessage: 'В пути',
        description: 'Надпись на вкладке',
      }),
      path: `/purchases/orders/on-the-way?internal=${idClient}`,
      name: 'onTheWayToStock',
    },
    {
      title: intl.formatMessage({
        id: 'tabs.lable.inStock',
        defaultMessage: 'На складе',
        description: 'Надпись на вкладке',
      }),
      path: `/purchases/orders/received-in-stock?internal=${idClient}`,
      name: 'receivedInStock',
    },
    {
      title: intl.formatMessage({
        id: 'orders.tab-label.receivedInRetailStock',
        defaultMessage: 'На складе (Розница)',
        description: 'Значение Пункта/Надпись на вкладке',
      }),
      path: `/purchases/orders/received-in-retail-stock?internal=${idClient}`,
      name: 'receivedInRetailStock',
    },
  ];
  const [supplierId] = useSelectSupplierQs();

  const { data, isLoading } = useSWR(
    {
      url: `/purchase-order/total-by-status`,
      params: {
        supplierId: supplierId,
        internalId: idClient,
      },
    },
    fetcher,
  );

  const ordersCount = React.useMemo(() => {
    if (!data) {
      return {};
    }

    return {
      waitingForPayment: data.items.waitingForPayment || 0,
      inWork: data.items.inWork || 0,
      frozen: data.items.frozen || 0,
      onTheWayToStock: data.items.onTheWayToStock || 0,
      receivedInStock: data.items.receivedInStock || 0,
      receivedInRetailStock: data.items.receivedInRetailStock || 0,
      archive: (data.items.sentToRussia || 0) + (data.items.canceled || 0),
    };
  }, [data]);

  function renderTabCount(item: Tab) {
    if (isLoading) {
      return (
        <>
          (<i className="bi bi-three-dots color-gray-400" />)
        </>
      );
    }

    return `${ordersCount[item.name as keyof typeof ordersCount]}`;
  }

  return (
    <>
      <div className="fs-18 fw-600 color-gray-450 mb-3">
        <FormattedMessage
          defaultMessage="Информация о заказах"
          id="purchaseOrderSteps.title.infoOrders"
          description="Кнопка"
        />
      </div>
      <ul className="d-column-flex align-self-start list-unstyled mb-0">
        {tabs.map(({ rbac, ...item }) => {
          const tab = (
            <li className="nav-item p-2 px-0" key={item.title}>
              <NavLink
                to={item.path}
                target="_blank"
                rel="noopener noreferrer"
                className="color-gray-600 fw-400 text-decoration-none d-grid justify-content-between w-100 h-25 g-col-1 dashed"
                style={{ gridTemplateColumns: 'auto 1fr auto' }}
              >
                {item.title}
                <hr
                  className="m-0 w-a mt-auto h-25 mx-1"
                  style={{ borderTop: '1px dashed', opacity: '0.15' }}
                />
                <span className="w-fit ml-auto fw-bold">
                  {renderTabCount(item)}
                </span>
              </NavLink>
            </li>
          );

          if (rbac) {
            return (
              <Rbac key={item.title} object={rbac.object} action={rbac.action}>
                {tab}
              </Rbac>
            );
          }

          return tab;
        })}
      </ul>
    </>
  );
};
