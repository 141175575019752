import React from 'react';
import { useForm } from 'react-hook-form';
import { api } from '../../../services/api';
import { InputWrapper } from '../../common/input-wrapper';
import { required } from '../../../utils/validations';
import { FormattedMessage, useIntl } from 'react-intl';
import { useModalClose } from '../../../utils/useModalClose';
import { CleanUser } from '../../../export-types/cleaned-types';
import { ModalProps } from '../../../services/modals.service';

interface UserBanModalProps extends ModalProps {
  user: CleanUser;
}

export const UserBanModal: React.FC<UserBanModalProps> = ({
  close,
  user,
  reject,
}) => {
  const intl = useIntl();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<{
    type: 'full' | 'newPurchases';
    reason: string;
  }>();

  const onSubmit = handleSubmit((data) => {
    api
      .post(`/user/${user.id}/ban`, {
        type: data.type,
        reason: data.reason,
      })
      .then(() => {
        close();
      });
  });

  const modalRef = useModalClose(reject);

  return (
    <div className="modal-dialog mw-400" ref={modalRef}>
      <div className="modal-content">
        <div className="modal-header border-bottom-0 p-4">
          <button
            type="button"
            className="btn-close"
            onClick={() => reject()}
          />
        </div>
        <form className="modal-body pt-2 p-4" onSubmit={onSubmit}>
          <div className="text-center mb-4 mx-auto">
            <i className="bi bi-exclamation-circle-fill color-yellow fs-65 lh-1" />
            <h3 className="mt-3">
              <FormattedMessage
                defaultMessage="Вы хотите заблокировать клиента"
                id="userBanModal.select-label.banTitle"
                description="Заголовок в форме блокировке"
              />
            </h3>
            <div className="mt-3">
              <FormattedMessage
                defaultMessage="Укажите тип и причину блокировки"
                id="userBanModal.select-label.banSubtitle"
                description="Подзаголовок в форме блокировки"
              />
            </div>
          </div>

          <InputWrapper
            className="mb-3"
            title={intl.formatMessage({
              defaultMessage: 'Тип блокировки',
              id: 'userBanModal.select-label.type',
              description: 'Заголовок поля ввода',
            })}
            required
            error={errors.type?.message}
          >
            <select
              className="form-select"
              {...register('type', {
                ...required,
              })}
            >
              <option value="" hidden>
                <FormattedMessage
                  defaultMessage="Выберите тип"
                  id="userBanModal.select-label.chooseType"
                  description="Заголовок выпадающего окна"
                />
              </option>
              <option value="full">
                <FormattedMessage
                  defaultMessage="Полная блокировка"
                  id="userBanModal.select-label.full"
                  description="Выбор причины блокировки"
                />
              </option>
              <option value="newPurchases">
                <FormattedMessage
                  defaultMessage="Блокировка новых заказов"
                  id="userBanModal.select-label.newPurchases"
                  description="Выбор причины заморозки"
                />
              </option>
            </select>
          </InputWrapper>
          <InputWrapper
            className="mb-3"
            title={intl.formatMessage({
              defaultMessage: 'Причина блокировки',
              id: 'userBanModal.select-label.reason',
              description: 'Заголовок поля ввода',
            })}
            error={errors.reason?.message}
          >
            <textarea className="form-control" {...register('reason')} />
          </InputWrapper>
          <button className="btn btn-success w-100">
            <FormattedMessage
              defaultMessage="Блокировать клиента"
              id="userBanModal.btn-label.ban"
              description="Надпись на кнопке"
            />
          </button>
        </form>
      </div>
    </div>
  );
};
