import React from 'react';
import { getCurrencySymbol, toMoney } from '../../../utils/common.utils';
import { useCurrencyCurrent } from '../../../services/currency.service';

export const ActualCurrency = () => {
  const { data: currency } = useCurrencyCurrent();
  if (!currency) {
    return null;
  }

  return (
    <div className="bg-gray p-3 rounded-8 mb-3">
      <div className="fs-18 fw-600 color-gray-450">Текущий курс CNY</div>
      <div>
        <div className="row g-0 mb-2">
          <div className="col-3 fs-16 fw-500 color-gray-800">Китай</div>
          <div className="col text-nowrap">
            {getCurrencySymbol('cny')} ={' '}
            {toMoney(currency.cnyRubExternal, 'rub')}
          </div>
        </div>
        <div className="row g-0 pb-2">
          <div className="col-3 fs-16 fw-500 color-gray-800">Сайт</div>
          <div className="col text-nowrap">
            {getCurrencySymbol('cny')} ={' '}
            {toMoney(currency.cnyRubInternal, 'rub')}
          </div>
        </div>
        <div className="row g-0">
          <div className="col-3 fs-16 fw-500 color-gray-800">Партнёр</div>
          <div className="col text-nowrap">
            {getCurrencySymbol('cny')} ={' '}
            {currency.cnyRubPartner
              ? toMoney(currency.cnyRubPartner, 'rub')
              : '–'}
          </div>
        </div>
      </div>

      <div className="fs-18 fw-600 color-gray-450 mt-4">Текущий курс USD</div>
      <div>
        <div className="row g-0">
          <div className="col-3 fs-16 fw-500 color-gray-800">Китай</div>
          <div className="col text-nowrap">
            $ = {toMoney(currency.usdRubExternal)} ₽
          </div>
        </div>
        <div className="row g-0 mt-2">
          <div className="col-3 fs-16 fw-500 color-gray-800">Сайт</div>
          <div className="col text-nowrap">
            $ = {toMoney(currency.usdRubInternal)} ₽
          </div>
        </div>
        <div className="row g-0 mt-2">
          <div className="col-3 fs-16 fw-500 color-gray-800">Партнёр</div>
          <div className="col text-nowrap">
            $ = {currency.usdRubPartner ? toMoney(currency.usdRubPartner) : '?'}{' '}
            ₽
          </div>
        </div>
      </div>

      {currency.usdCny || currency.cnyUsd ? (
        <>
          <div className="fs-18 fw-600 color-gray-450 mt-4">Кросс-курс</div>
          <div className="row g-0">
            {currency.usdCny ? (
              <div className="col text-nowrap">
                1$ = {toMoney(currency.usdCny)} ¥
              </div>
            ) : null}
            {currency.cnyUsd ? (
              <div className="col text-nowrap">
                1¥ = {toMoney(currency.cnyUsd)} $
              </div>
            ) : null}
          </div>
        </>
      ) : null}
    </div>
  );
};
