import { useSearchParams } from 'react-router-dom';

export type SetSearchParamsFunction = (value: string | null) => void;

export const useSearchParamsData = (): [
  Record<string, string>,
  Record<string, SetSearchParamsFunction>,
] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const paramNames = ['internal', 'manager'];

  const searchParamsData: Record<string, string> = {};
  const setSearchParamsFunctions: Record<string, SetSearchParamsFunction> = {};

  paramNames.forEach((paramName) => {
    searchParamsData[paramName] = searchParams.get(paramName) || '';

    setSearchParamsFunctions[paramName] = (value) => {
      setSearchParams((prev: URLSearchParams) => {
        prev.delete(paramName);
        if (value) {
          prev.set(paramName, value);
        }
        return prev;
      });
    };
  });

  return [searchParamsData, setSearchParamsFunctions];
};
