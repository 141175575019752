import { useConversationNewMessages } from '../../../api/conversation';
import React from 'react';
import { toast } from 'react-toastify';
import { IdCell } from '../../common/cards/id-cell-card.component';
import { useNavigate } from 'react-router-dom';
import {
  CleanConversation,
  CleanProfile,
} from '../../../export-types/cleaned-types';

type ConversationAlertsProps = {
  user: CleanProfile;
};
export const ConversationAlerts = (props: ConversationAlertsProps) => {
  const navigate = useNavigate();

  const handleClick = React.useCallback(
    (data: CleanConversation) => {
      if (data.purchaseOrder) {
        if (data.purchaseOrder.type === 'purchase') {
          navigate(`/purchases/${data.purchaseOrder.id}`);
        } else {
          navigate(`/parcels/${data.purchaseOrder.id}`);
        }
      } else if (data.deliveryOrder) {
        navigate(`/deliveries/delivery-order/${data.deliveryOrder.id}`);
      }
    },
    [navigate],
  );

  const newMessageHandler = React.useCallback(
    (conversation: CleanConversation) => {
      if (conversation.lastMessage?.user.id === props.user.id) return;

      toast.success(
        <div onClick={() => handleClick(conversation)}>
          Новое сообщение в{' '}
          {conversation.purchaseOrder ? (
            <>
              заказе{' '}
              <IdCell
                internalId={conversation.purchaseOrder.internalId}
                type={conversation.purchaseOrder.type}
              />
            </>
          ) : (
            conversation.deliveryOrder && (
              <>
                доставке{' '}
                <IdCell
                  internalId={conversation.deliveryOrder.internalId}
                  type="delivery"
                />
              </>
            )
          )}
          . Нажмите чтобы посмотреть
        </div>,
        { toastId: `conversation-${conversation.id}` },
      );
    },
    [handleClick, props.user],
  );
  useConversationNewMessages(newMessageHandler, 'alerts');

  return null;
};
