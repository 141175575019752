import React from 'react';
import { useForm } from 'react-hook-form';
import { decimalValidation, required } from '../../../utils/validations';
import { api } from '../../../services/api';
import { InputWrapper } from '../../common/input-wrapper';
import ReactSelect from 'react-select';
import { getTopUpMethodTitle } from '../../../utils/common.utils';
import { useModalClose } from '../../../utils/useModalClose';

type Form = {
  limit: string;
};

type ChangeLimitBankCardModelProps = {
  close: () => void;
  reject: () => void;
  account: {
    id: string;
    method: 'sberbank' | 'alfabank' | 'tinkoff';
    limit: string;
    cardNumber: string;
    cardHolderName: string;
    currencies: Array<'cny' | 'usd' | 'rub'>;
  };
};

export const ChangeLimitBankCardModel: React.FC<
  ChangeLimitBankCardModelProps
> = ({ close, reject, account }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Form>({
    defaultValues: {
      limit: account.limit,
    },
  });

  function onSave(data: Form) {
    api
      .post(
        `/top-up/methods/${account.method}/accounts/${account.id}/limit`,
        data,
      )
      .then(() => {
        close();
      });
  }

  const modalRef = useModalClose(reject);

  return (
    <div className="modal-dialog" ref={modalRef}>
      <form className="modal-content" onSubmit={handleSubmit(onSave)}>
        <div className="modal-body">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h3>Изменить лимит банковской карты</h3>
            <div className="btn p-0" onClick={reject}>
              <i className="bi bi-x fs-36 color-gray-600" />
            </div>
          </div>
          <div className="row mt-3">
            <InputWrapper title="Способ пополнения" className="col">
              <input
                className="form-control"
                type="string"
                value={getTopUpMethodTitle(account.method)}
                disabled={true}
              />
            </InputWrapper>
          </div>
          <div className="row mt-3">
            <InputWrapper title="Номер карты" className="col">
              <div className="input-group">
                <input
                  className="form-control"
                  type="text"
                  value={account.cardNumber}
                  disabled={true}
                />
              </div>
            </InputWrapper>
          </div>
          <div className="row mt-3">
            <InputWrapper title="Имя получателя" className="col">
              <div className="input-group">
                <input
                  className="form-control"
                  type="text"
                  value={account.cardHolderName}
                  disabled={true}
                />
              </div>
            </InputWrapper>
          </div>
          <div className="row mt-3">
            <InputWrapper
              title="Максимальный лимит"
              className="col"
              error={errors.limit?.message}
              required
            >
              <div className="input-group">
                <input
                  className="form-control"
                  type="number"
                  min={0}
                  step={0.01}
                  placeholder="Например, 300 000"
                  {...register('limit', {
                    ...required,
                    ...decimalValidation,
                  })}
                />
                <span className="input-group-text">₽</span>
              </div>
            </InputWrapper>
          </div>
          <div className="row mt-3">
            <InputWrapper
              className="mb-3"
              title="Разрешенные к пополнению счета"
            >
              <ReactSelect
                options={['cny', 'usd']}
                isSearchable={false}
                isMulti={true}
                isDisabled={true}
                getOptionLabel={(option) => option.toUpperCase()}
                getOptionValue={(option) => option}
                value={account.currencies}
              />
            </InputWrapper>
          </div>
        </div>
        <div className="modal-footer justify-content-end mt-4">
          <button type="submit" className="btn btn-success">
            Сохранить
          </button>
        </div>
      </form>
    </div>
  );
};
