import React from 'react';
import Popover from 'rsuite/Popover';
import Dropdown from 'rsuite/Dropdown';
import Whisper from 'rsuite/Whisper';
import { CleanUser, RbacObject } from '../../export-types/cleaned-types';
import { Rbac, useRbac } from './Rbac';
import { ModalsService } from '../../services/modals.service';
import { LinkUserModal } from '../screens/users/link-user-model.component';
import { UnLinkUserModal } from '../screens/users/unlink-user-model.component';
import { SelfLinkUserModal } from '../screens/users/self-link-user-model.component';
import { useProfile } from '../../api/profile';

type ClientRequestInfoActionsProps = {
  user: CleanUser;
};

export const ClientRequestInfoActions: React.FC<
  ClientRequestInfoActionsProps
> = (props) => {
  const { data: user } = useProfile();

  const { hasPermission } = useRbac();

  const speaker = (
    { onClose, left, top, className }: any,
    ref: React.Ref<any>,
  ) => (
    <Popover ref={ref} className={className} style={{ left, top }} full>
      <Dropdown.Menu>
        {!props.user.manager && (
          <Rbac object={RbacObject.User} action={'link'}>
            <Dropdown.Item
              onSelect={function () {
                onClose();
                if (hasPermission(RbacObject.User, 'link:admin')) {
                  ModalsService.createModal(LinkUserModal, {
                    user: props.user,
                  });
                } else {
                  ModalsService.createModal(SelfLinkUserModal, {
                    user: props.user,
                  });
                }
              }}
            >
              <i className="bi bi-link" />
              <span className="ps-2">Привязать</span>
            </Dropdown.Item>
          </Rbac>
        )}
        {props.user.manager &&
          (hasPermission(RbacObject.User, 'unlink:admin') ||
            props.user.manager.id === user?.id) && (
            <Rbac object={RbacObject.User} action={'unlink'}>
              <Dropdown.Item
                onSelect={function () {
                  onClose();
                  ModalsService.createModal(UnLinkUserModal, {
                    user: props.user,
                  });
                }}
              >
                <i className="bi bi-link" />
                <span className="ps-2">Отвязать</span>
              </Dropdown.Item>
            </Rbac>
          )}
      </Dropdown.Menu>
    </Popover>
  );

  return (
    <Whisper placement="bottomEnd" trigger="click" speaker={speaker}>
      <button className="btn btn-link text-decoration-none">
        <i className="bi bi-three-dots" />
      </button>
    </Whisper>
  );
};
