import React from 'react';
import ReactSelect from 'react-select';
import { useSuppliers } from '../../../api/suppliers';
import { useLocalStorage } from '@mantine/hooks';
import '../../../sass/purchase-order-receipt-confirmation-modal.scss';
import { useSearchParams } from 'react-router-dom';

/**
 * @deprecated используйте useSelectSupplierQs()
 * @see useSelectSupplierQs
 */
export function useSelectSupplier() {
  return useLocalStorage<number | undefined>({
    key: 'supplier-select',
    deserialize: (value) => {
      if (value === 'undefined' || value === '"undefined"') {
        return undefined;
      }
      try {
        return value && JSON.parse(value);
      } catch {
        return value;
      }
    },
  });
}

export function useSelectSupplierQs() {
  const [
    localStorageSupplier,
    setLocalStorageSupplier,
    removeLocalStorageSupplier,
  ] = useSelectSupplier();
  const [searchParams, setSearchParams] = useSearchParams();

  const paramName = 'supplier';
  let searchParamsSupplier: number | undefined = Number(
    searchParams.get(paramName),
  );
  if (searchParamsSupplier < 1 || isNaN(searchParamsSupplier)) {
    searchParamsSupplier = undefined;
  }

  let supplierId: number | undefined =
    searchParamsSupplier || localStorageSupplier;
  if (!supplierId || supplierId < 1 || isNaN(supplierId)) {
    supplierId = undefined;
  }

  const setSupplier = (value: number | undefined) => {
    setLocalStorageSupplier(value);
    setSearchParams((prev) => {
      prev.delete(paramName);
      if (value) {
        prev.set(paramName, String(value));
      }
      return prev;
    });
  };

  if (searchParamsSupplier !== supplierId) {
    setSupplier(supplierId);
  }

  const removeSupplier = () =>
    setSearchParams((prev) => {
      removeLocalStorageSupplier();
      prev.delete(paramName);
      return prev;
    });

  return [supplierId, setSupplier, removeSupplier] as const;
}

export const SupplierSelect = ({
  skipLoading = false,
}: {
  skipLoading?: boolean;
}) => {
  const { data: suppliers, isLoading } = useSuppliers(skipLoading);

  const [selectSupplier, setSelectSupplier, removeValue] =
    useSelectSupplierQs();

  const options = suppliers?.map((supplier) => ({
    value: supplier.id,
    label: supplier.name,
  }));

  return (
    <ReactSelect
      classNamePrefix="custom-select"
      className="w-25 ms-2"
      placeholder="Поиск по поставщику"
      options={options}
      isSearchable={true}
      isClearable={true}
      value={options?.find((option) => option.value === selectSupplier)}
      onChange={(selectedOption) =>
        selectedOption?.value
          ? setSelectSupplier(selectedOption.value)
          : removeValue()
      }
      isLoading={isLoading}
    />
  );
};
