import Table, { CellProps } from 'rsuite/Table';
import { toMoney } from '../../../utils/common.utils';
import React from 'react';

type Cell = CellProps<Record<string, unknown>> & {
  currencyKey?: string;
  currency?: string;
};

export const TableMoneyCallComponent = ({
  rowData,
  dataKey,
  currencyKey,
  currency,
  ...props
}: Cell) => {
  if (!rowData || !dataKey || !rowData[dataKey]) return null;

  const value = rowData[dataKey] as string | number;
  return (
    <Table.Cell {...props}>
      {toMoney(
        value,
        (currencyKey && (rowData[currencyKey] as string | undefined)) ||
          currency,
      )}
    </Table.Cell>
  );
};
