import React from 'react';
import Table from 'rsuite/Table';
import useSWR from 'swr';
import { api, fetcher } from '../../../services/api';
import moment from 'moment';
import Toggle from 'rsuite/Toggle';
import { ImagesCell } from '../../common/cards/images-cell-card.component';
import { toMoney } from '../../../utils/common.utils';
import { RbacObject } from '../../../export-types/cleaned-types';
import { Rbac } from '../../common/Rbac';
import { ModalsService } from '../../../services/modals.service';
import { AddStoryModal } from './add-story-modal';

type StoryResponse = {
  id: string;
  name: string;
  cover: {
    id: string;
    type: string;
  };
  disabled: boolean;
  views: number;
  type: 'rutube' | 'youtube' | 'link' | 'slider';
  createdAt: 'string';
  priority: number;
};

export const MarketingStoriesTab = () => {
  const { data, isValidating, mutate, isLoading } = useSWR<{
    list: StoryResponse[];
  }>('/stories/list', fetcher);

  const [disableToggling, setDisableToggling] = React.useState<null | string>(
    null,
  );

  return (
    <Table
      loading={isLoading}
      data={data?.list}
      headerHeight={57}
      rowHeight={74}
      rowKey="id"
      locale={{
        loading: 'Загрузка',
        emptyMessage: 'Нет историй',
      }}
      autoHeight
    >
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>Обложка</Table.HeaderCell>
        <Table.Cell>
          {(rowData: StoryResponse) => (
            <ImagesCell imagesList={[rowData.cover]} height={48} width={48} />
          )}
        </Table.Cell>
      </Table.Column>
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>Тип</Table.HeaderCell>
        <Table.Cell verticalAlign="middle">
          {(rowData: StoryResponse) => {
            switch (rowData.type) {
              case 'link':
                return 'Ссылка';
              case 'youtube':
                return 'Видео';
              case 'rutube':
                return 'Видео';
              case 'slider':
                return 'Слайдер';
              default:
                return 'Неизвестный тип';
            }
          }}
        </Table.Cell>
      </Table.Column>
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>Название</Table.HeaderCell>
        <Table.Cell dataKey="name" verticalAlign="middle" />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>Дата создания</Table.HeaderCell>
        <Table.Cell verticalAlign="middle">
          {(rowData: StoryResponse) =>
            moment(rowData.createdAt).format('DD.MM.YYYY')
          }
        </Table.Cell>
      </Table.Column>
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>Просмотров</Table.HeaderCell>
        <Table.Cell verticalAlign="middle">
          {(rowData: StoryResponse) => toMoney(rowData.views)}
        </Table.Cell>
      </Table.Column>
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>Приоритет</Table.HeaderCell>
        <Table.Cell verticalAlign="middle" dataKey="priority" />
      </Table.Column>
      <Table.Column>
        <Table.HeaderCell>Включена</Table.HeaderCell>
        <Table.Cell verticalAlign="middle">
          {(rowData: StoryResponse) => {
            return (
              <Rbac object={RbacObject.Story} action={'write:admin'}>
                <Toggle
                  checked={!rowData.disabled}
                  loading={disableToggling === rowData.id}
                  disabled={isValidating}
                  onChange={async (value) => {
                    setDisableToggling(rowData.id);
                    try {
                      await api.put(`/stories/list/${rowData.id}`, {
                        disabled: !value,
                      });
                    } finally {
                      await mutate();
                      setDisableToggling(null);
                    }
                  }}
                />
              </Rbac>
            );
          }}
        </Table.Cell>
      </Table.Column>
      <Table.Column>
        <Table.HeaderCell children="" />
        <Table.Cell verticalAlign="middle">
          {(rowData: StoryResponse) => {
            return (
              <Rbac object={RbacObject.Story} action={'write:admin'}>
                <button
                  type="button"
                  className="btn px-3"
                  onClick={() => {
                    ModalsService.createModal(AddStoryModal, {
                      story: rowData,
                    }).then(() => mutate());
                  }}
                >
                  <i className="bi bi-pencil" />
                </button>

                <button
                  type="button"
                  className="btn px-3"
                  onClick={async () => {
                    const result = window.confirm(
                      `Вы действительно хотите удалить историю "${rowData.name}"?`,
                    );

                    if (!result) {
                      return;
                    }

                    try {
                      await api.delete(`/stories/list/${rowData.id}`);
                    } finally {
                      await mutate();
                    }
                  }}
                >
                  <i className="bi bi-trash" />
                </button>
              </Rbac>
            );
          }}
        </Table.Cell>
      </Table.Column>
    </Table>
  );
};
