import React, { useEffect, useState } from 'react';
import {
  formatDateValue,
  getCurrentDateValue,
  parseRawDateValue,
  RawDateValue,
} from '../../../utils/date-value';
import { Controller, useForm } from 'react-hook-form';
import isDate from 'validator/lib/isDate';
import {
  getReconciliationActUrl,
  ReconciliationActUrlParams,
} from '../../../utils/common.utils';
import { InputWrapper } from '../../common/input-wrapper';
import { Button, CustomProvider, DateRangePicker } from 'rsuite';
import { ruRU } from 'rsuite/locales';
import { ModalProps } from '../../../services/modals.service';
import { useSuppliers } from '../../../api/suppliers';
import { Select } from '../../common/select.component';
import { useRequired } from '../../../utils/validations';
import { useModalClose } from '../../../utils/useModalClose';
import { useSelectLanguage } from '../../common/SelectLanguage';
import { Period, useReportLink } from './useReportLink';
import { FormattedMessage, useIntl } from 'react-intl';
import { useOrderCategoriesList } from '../../../consts/db-values';

type Language = 'ru' | 'en' | 'zh';

type Form = {
  supplierId: number;
  period: Period;
  range?: RawDateValue[];
  currency: 'cny' | 'usd';
  language: Language | string;
};

export function isCorrectDate(v: string) {
  return isDate(`${v}`, {
    format: 'DD.MM.YYYY',
    delimiters: ['.'],
    strictMode: true,
  });
}

type Props = ModalProps & {
  kind: 'our' | 'their';
};

export const ReconciliationActModal: React.FC<Props> = ({ close, kind }) => {
  const intl = useIntl();

  const languages: Array<{ title: string; id: Language }> = [
    {
      title: intl.formatMessage({
        defaultMessage: 'Русский',
        id: 'ReconciliationActModal.title.Ru',
        description: 'Язык ввода',
      }),
      id: 'ru',
    },
    {
      title: intl.formatMessage({
        defaultMessage: 'Английский',
        id: 'ReconciliationActModal.title.En',
        description: 'Язык ввода',
      }),
      id: 'en',
    },
    {
      title: intl.formatMessage({
        defaultMessage: 'Китайский',
        id: 'ReconciliationActModal.title.Zh',
        description: 'Язык ввода',
      }),
      id: 'zh',
    },
  ];

  const periods: Array<{ title: string; id: Period }> = [
    {
      title: intl.formatMessage({
        defaultMessage: 'За все время',
        id: 'ReconciliationActModal.title.AllTime',
        description: 'Период',
      }),
      id: 'all_time',
    },
    {
      title: intl.formatMessage({
        defaultMessage: 'Текущая неделя',
        id: 'ReconciliationActModal.title.Week',
        description: 'Период',
      }),
      id: 'week',
    },
    {
      title: intl.formatMessage({
        defaultMessage: 'Текущий месяц',
        id: 'ReconciliationActModal.title.Month',
        description: 'Период',
      }),
      id: 'month',
    },
    {
      title: intl.formatMessage({
        defaultMessage: 'Этот год',
        id: 'ReconciliationActModal.title.Year',
        description: 'Период',
      }),
      id: 'year',
    },
    {
      title: intl.formatMessage({
        defaultMessage: 'Прошлая неделя',
        id: 'ReconciliationActModal.title.LastWeek',
        description: 'Период',
      }),
      id: 'last_week',
    },
    {
      title: intl.formatMessage({
        defaultMessage: 'Прошлый месяц',
        id: 'ReconciliationActModal.title.LastMonth',
        description: 'Период',
      }),
      id: 'last_month',
    },
    {
      title: intl.formatMessage({
        defaultMessage: 'Прошлый год',
        id: 'ReconciliationActModal.title.LastYear',
        description: 'Период',
      }),
      id: 'last_year',
    },
    {
      title: intl.formatMessage({
        defaultMessage: 'Выбрать диапазон',
        id: 'ReconciliationActModal.title.SelectRange',
        description: 'Период',
      }),
      id: 'range',
    },
  ];

  const required = useRequired();

  const { data: suppliers } = useSuppliers(kind === 'their');
  const [locale] = useSelectLanguage();

  const {
    control,
    formState: { isValid, errors },
    getValues,
    setValue,
    watch,
    trigger,
  } = useForm<Form>({
    mode: 'all',
    defaultValues: {
      period: 'all_time',
      language: locale ? locale : 'ru',
    },
  });

  useEffect(() => {
    setValue('language', locale);
  }, [locale]);

  const [currency, supplierId, period, range, language] = watch([
    'currency',
    'supplierId',
    'period',
    'range',
    'language',
  ]);

  React.useEffect(() => {
    trigger(['range']);
  }, [trigger, range]);

  const params: ReconciliationActUrlParams = {
    kind,
    currency,
    supplierId,
    language,
  };

  const reportLink = useReportLink(
    isValid,
    period,
    params,
    //@ts-expect-error
    getReconciliationActUrl as (params: ReconciliationActUrlParams) => string,
    range?.[0],
    range?.[1],
  );

  const modalRef = useModalClose(close);

  return (
    <CustomProvider locale={ruRU}>
      <div className="modal-dialog" ref={modalRef}>
        <form className="modal-content">
          <div className="modal-body">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <h3>
                <FormattedMessage
                  defaultMessage="Акт сверки"
                  id="ReconciliationActModal.title.act"
                  description="Заголовок"
                />
              </h3>
              <div className="btn p-0" onClick={close}>
                <i className="bi bi-x fs-36 color-gray-600" />
              </div>
            </div>
            {kind === 'our' ? (
              <Controller
                name="supplierId"
                control={control}
                rules={required}
                render={({ field }) => (
                  <Select
                    className="mb-3"
                    title="Поставщик"
                    placeholder="Выберите поставщика"
                    required
                    options={
                      suppliers?.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      })) ?? []
                    }
                    {...field}
                    error={errors.supplierId?.message}
                  />
                )}
              />
            ) : null}
            <Controller
              name="currency"
              control={control}
              rules={required}
              render={({ field }) => (
                <Select
                  className="mb-3"
                  title={intl.formatMessage({
                    defaultMessage: 'Валюта',
                    id: 'ReconciliationActModal.title.Сurrency',
                    description: 'Пояснение к полю ввода',
                  })}
                  placeholder={intl.formatMessage({
                    defaultMessage: 'Выберите валюту',
                    id: 'ReconciliationActModal.title.SelectСurrency',
                    description: 'Пояснение к полю ввода',
                  })}
                  required
                  options={[
                    {
                      value: 'cny',
                      label: intl.formatMessage({
                        defaultMessage: 'Юань',
                        id: 'ReconciliationActModal.title.Yuan',
                        description: 'Валюта',
                      }),
                    },
                    {
                      value: 'usd',
                      label: intl.formatMessage({
                        defaultMessage: 'Доллар',
                        id: 'ReconciliationActModal.title.Dollar',
                        description: 'Валюта',
                      }),
                    },
                  ]}
                  {...field}
                  error={errors.currency?.message}
                />
              )}
            />
            <Controller
              name="period"
              control={control}
              rules={{
                required: intl.formatMessage({
                  defaultMessage: 'Укажите период',
                  id: 'ReconciliationActModal.title.SpecifyPeriod',
                  description: 'Пояснение к полю ввода',
                }),
              }}
              render={({ field, fieldState }) => {
                return (
                  <InputWrapper
                    required
                    title={intl.formatMessage({
                      defaultMessage: 'Выберите период',
                      id: 'ReconciliationActModal.title.SelectPeriod',
                      description: 'Пояснение к полю ввода',
                    })}
                    error={fieldState.error?.message}
                    className="mb-3"
                  >
                    <select className="form-select" {...field}>
                      {periods.map((item) => {
                        return (
                          <option
                            value={item.id}
                            key={item.id}
                            className="mb-1 cursor-pointer bg-white py-3 pl-3 pr-7 text-base-lh-125 font-medium text-gray-800 hover:bg-green-100"
                          >
                            {item.title}
                          </option>
                        );
                      })}
                    </select>
                  </InputWrapper>
                );
              }}
            />
            {period === 'range' && (
              <Controller
                name="range"
                control={control}
                rules={{
                  validate: (value) => {
                    if (!value || value.length !== 2) return true;

                    const [start, end] = value;

                    if (!isCorrectDate(start) || !isCorrectDate(end)) {
                      return 'Укажите корректные даты';
                    }

                    if (
                      parseRawDateValue(start).isAfter(parseRawDateValue(end))
                    ) {
                      return 'Дата начала не должна быть позже даты окончания';
                    }

                    return true;
                  },
                }}
                render={({ field, fieldState }) => {
                  return (
                    <InputWrapper
                      title="Выберите период"
                      error={fieldState.error?.message}
                      className="mb-2 w-full text-base-lh-100"
                    >
                      <DateRangePicker
                        className={'w-100'}
                        format={'dd.MM.yyyy'}
                        isoWeek
                        showOneCalendar
                        onChange={(range) => {
                          if (range == null) field.onChange([]);
                          else {
                            field.onChange(
                              range.map((date) => formatDateValue(date)),
                            );
                          }
                        }}
                      />
                    </InputWrapper>
                  );
                }}
              />
            )}

            <Controller
              name="language"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <InputWrapper
                    required
                    title={intl.formatMessage({
                      defaultMessage: 'Выберите язык',
                      id: 'ReconciliationActModal.title.SelectLanguage',
                      description: 'Пояснение',
                    })}
                    error={fieldState.error?.message}
                    className="mb-3"
                  >
                    <select className="form-select" {...field}>
                      {languages.map((item) => {
                        return (
                          <option
                            value={item.id}
                            key={item.id}
                            className="mb-1 cursor-pointer bg-white py-3 pl-3 pr-7 text-base-lh-125 font-medium text-gray-800 hover:bg-green-100"
                          >
                            {item.title}
                          </option>
                        );
                      })}
                    </select>
                  </InputWrapper>
                );
              }}
            />
          </div>
          <div className="modal-footer justify-content-end">
            {reportLink ? (
              <Button
                as="a"
                href={reportLink}
                target="_blank"
                className="btn btn-success"
              >
                <FormattedMessage
                  defaultMessage="Скачать в Excel"
                  id="ReconciliationActModal.title.DownloadinExcel"
                  description="Кнопка"
                />
              </Button>
            ) : (
              <Button as="button" disabled={true} className="btn btn-success">
                <FormattedMessage
                  defaultMessage="Скачать в Excel"
                  id="ReconciliationActModal.title.DownloadinExcel"
                  description="Кнопка"
                />
              </Button>
            )}
          </div>
        </form>
      </div>
    </CustomProvider>
  );
};
