import React, { forwardRef, useRef } from 'react';
import Whisper, { WhisperInstance } from 'rsuite/Whisper';
import Popover from 'rsuite/Popover';
import Dropdown from 'rsuite/Dropdown';
import { AuthService } from '../../services/auth.service';
import { ROLES } from '../../consts/db-values';
import { useNavigate } from 'react-router-dom';
import { Avatar } from './avatar';
import { ModalsService } from '../../services/modals.service';
import { ChangePasswordModal } from '../screens/profile/change-password-modal.component';
import { ProfileModal } from '../screens/profile/profile-modal';
import { useProfile } from '../../api/profile';
import { useGlobalMutate } from '../../api/useGlobalMutate';

export const ProfileHeader: React.FC = () => {
  const ref = useRef<WhisperInstance>(null);
  const navigate = useNavigate();
  const { mutate } = useGlobalMutate();

  const { data: user } = useProfile();
  if (!user) {
    return null;
  }

  function handleSelectMenu(eventKey: number) {
    ref.current?.close();

    switch (eventKey) {
      case 1: {
        ModalsService.createModal(ProfileModal, { defaultValues: user! });
        return;
      }

      case 2: {
        ModalsService.createModal(ChangePasswordModal, {});
        return;
      }

      case 3: {
        AuthService.logout().then(async () => {
          await mutate();
          navigate('/');
        });
        return;
      }
    }
  }

  return (
    <div className="d-flex flex-row profile-header">
      <div className="d-flex flex-row align-items-center profile-header-profile-container">
        <div className="flex-grow-1">
          <div className="me-2 fs-18 text-truncate">
            {user.name?.split(' ')[0]}
          </div>
          {user.role === 'client' && !!user.internalId ? (
            <div className="color-gray-400">{user.internalId}</div>
          ) : (
            <div className="color-gray-400">
              {ROLES[user.role as keyof typeof ROLES]}
            </div>
          )}
        </div>

        <Whisper
          ref={ref}
          placement="bottomEnd"
          controlId="control-id-with-dropdown"
          trigger="click"
          speaker={<MenuPopover onSelect={handleSelectMenu} />}
        >
          <div className="cursor-pointer">
            <Avatar width={44} />
          </div>
        </Whisper>
      </div>
    </div>
  );
};

const MenuPopover = forwardRef<
  HTMLDivElement,
  { onSelect: (eventKey: any, event: React.SyntheticEvent) => void }
>(({ onSelect, ...rest }, ref) => (
  <Popover ref={ref} {...rest} full>
    <Dropdown.Menu onSelect={onSelect}>
      <Dropdown.Item eventKey={1}>
        <i className="bi bi-person" />
        <span className="ps-2">Профиль</span>
      </Dropdown.Item>
      <Dropdown.Item eventKey={2}>
        <i className="bi bi-person" />
        <span className="ps-2">Изменить пароль</span>
      </Dropdown.Item>
      <Dropdown.Item eventKey={3}>
        <i className="bi bi-box-arrow-right" />
        <span className="ps-2">Выйти</span>
      </Dropdown.Item>
    </Dropdown.Menu>
  </Popover>
));
