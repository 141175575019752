import React from 'react';
import { useForm } from 'react-hook-form';
import { InputWrapper } from '../../common/input-wrapper';
import { required } from '../../../utils/validations';
import { DeliveryOrderService } from '../../../services/delivery-order.service';
import { AlertService } from '../../../services/alert.service';
import { useModalClose } from '../../../utils/useModalClose';

type DeleteDeliveryModalProps = {
  close: () => void;
  reject: () => void;
  deliveryOrder: {
    id: string;
    internalId: string;
  };
};

type Form = {
  internalId: string;
};

export const DeleteDeliveryModal: React.FC<DeleteDeliveryModalProps> = ({
  deliveryOrder,
  close,
  reject,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<{
    internalId: string;
  }>({});

  function onSubmit(data: Form) {
    if (data.internalId !== deliveryOrder.internalId) return;
    DeliveryOrderService.deleteDeliveryOrder(deliveryOrder.id).then(() => {
      AlertService.success();
      close();
    });
  }

  const modalRef = useModalClose(reject);

  return (
    <div className="modal-dialog" ref={modalRef}>
      <form className="modal-content" onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h3>Удаление доставки</h3>
            <div className="btn p-0" onClick={reject}>
              <i className="bi bi-x fs-36 color-gray-600" />
            </div>
          </div>
          <InputWrapper
            title="Номер посылки"
            className="pt-3"
            error={errors.internalId?.message}
            required
          >
            <input
              className="form-control"
              type="text"
              {...register('internalId', {
                ...required,
                validate: {
                  equal: (v) =>
                    (typeof v === 'string' && v === deliveryOrder.internalId) ||
                    'Введите номер доставки',
                },
              })}
            />
          </InputWrapper>
        </div>
        <div className="modal-footer justify-content-end">
          <button type="submit" className="btn btn-success">
            Удалить
          </button>
        </div>
      </form>
    </div>
  );
};
