import React from 'react';
import Pagination from 'rsuite/Pagination';
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';
import useSWR from 'swr';
import { fetcher } from '../../../services/api';
import MyLoader from '../../common/loader.component';
import { ParcelItemProps, ParcelProps, ParcelStatusProps } from './parcel-item';
import { OutletContextParams } from './employee-parcels-screen';
import { usePaginationQs } from '../../../utils/usePagination';
import { useConversationNewMessages } from '../../../api/conversation';
import { CleanConversation } from '../../../export-types/cleaned-types';
import { useSearchParamsPaginationEffect } from '../purchase-order/useSearchParamsPaginationEffect';

type StatusProps = {
  type: 'onTheWayToStock' | 'receivedInStock' | 'archive';
  status: ReadonlyArray<ParcelStatusProps>;
  render: (data: ParcelItemProps) => React.ReactChild;
};

export const EmployeeParcelsTable: React.FC<StatusProps> = ({
  status,
  type,
  render,
}) => {
  const navigate = useNavigate();

  const { take, skip, handleSetPage, handleChangeLimit, page } =
    usePaginationQs(15);
  useSearchParamsPaginationEffect(handleSetPage);

  const { debouncedInternalId, linked, selectSupplierId } =
    useOutletContext<OutletContextParams>();

  const { data, error, isLoading, mutate } = useSWR<{
    total: number;
    items: Array<ParcelProps>;
  }>(
    () => ({
      url: '/parcels/v2',
      params: {
        status,
        linked,
        take,
        skip,
        internalId: debouncedInternalId ? debouncedInternalId : undefined,
        supplierId: selectSupplierId,
      },
    }),
    fetcher,
  );

  const newMessageHandler = React.useCallback(
    (updatedConversation: CleanConversation) => {
      if (
        data?.items.some(
          (order) => order.id === updatedConversation.purchaseOrder?.id,
        )
      ) {
        mutate();
      }
    },
    [data, mutate],
  );
  useConversationNewMessages(newMessageHandler, 'parcels');

  const handleClick = React.useCallback(
    (data: ParcelProps) => {
      navigate(`/parcels/${data.id}`);
    },
    [navigate],
  );

  if (isLoading) {
    return (
      <>
        <div className="pb-4">
          <MyLoader />
        </div>
      </>
    );
  }

  if (error || !data) {
    return (
      <>
        <div className="pb-4">Неизвестная ошибка</div>
      </>
    );
  }

  const total = data!.total;
  const items = data!.items;

  return (
    <>
      <div className="pb-4">
        {items.map((data) => render({ type, data, onClick: handleClick }))}
      </div>
      {total > take && (
        <div className="pb-4">
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="md"
            layout={['pager']}
            total={total}
            limitOptions={[15, 20]}
            limit={take}
            activePage={page}
            onChangePage={handleSetPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      )}
    </>
  );
};
