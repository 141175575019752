import React from 'react';
import { ModalsService } from '../../../services/modals.service';
import { PurchaseOrderEditOrderModal } from '../../screens/purchase-order/purchase-order-edit-order-modal';
import { CleanPurchaseOrder } from '../../../export-types/cleaned-types';

type PackageMethodCellProps = {
  order: CleanPurchaseOrder;
};

export const OrderCell: React.FC<PackageMethodCellProps> = ({ order }) => {
  function handleEdit(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    if (!order) {
      return;
    }
    ModalsService.createModal(PurchaseOrderEditOrderModal, { order });
  }

  return (
    <div className="pe-3 d-flex flex-row gap-2">
      <div>
        {order.landingBill && order.landingBill}
        {!order.landingBill &&
          order.status !== 'canceled' &&
          order.status !== 'waitingForPayment' && (
            <button
              className="btn btn-link text-decoration-none"
              type="button"
              onClick={handleEdit}
            >
              + Добавить
            </button>
          )}
      </div>
      <div className="color-gray-800 fs-16 fw-500  text-truncate">
        {order.orderLink && (
          <a
            href={order.orderLink}
            onClick={(e) => e.stopPropagation()}
            target="_blank"
            rel="noreferrer"
          >
            <i className="bi bi-link" />
          </a>
        )}
      </div>
    </div>
  );
};
