import React, { useMemo } from 'react';
import { Header } from '../../common/header.component';
import { DeliveryOrderService } from '../../../services/delivery-order.service';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DeliveryOrderStatus } from '../../common/delivery-order-status.component';
import { DeliveryOrderAssemblyModal } from './delivery-order-assembly-modal';
import { ModalsService } from '../../../services/modals.service';
import { AlertService } from '../../../services/alert.service';
import useSWR from 'swr';
import { fetcher } from '../../../services/api';
import { DeliveryOrderAssemblyInfo } from './delivery-order-assembly-info';
import { DeliveryOrderDeliveryInfo } from './delivery-order-delivery-info';
import { ClientInfo } from '../../common/client-info';
import { DeliveryOrderFinancesInfo } from './delivery-order-finances-info';
import { DeliveryOrderStatusTable } from './delivery-order-status-table';
import { TrackNumberFormModal } from './trackNumber-form-modal.component';
import classNames from 'classnames';
import btnStyle from './trackNumber-button.module.css';
import { OrderTransactionsInfo } from '../purchase-order/admin-order-transactions-info';
import { Rbac, useRbac } from '../../common/Rbac';
import {
  CleanConversationType,
  CleanDeliveryOrder,
  RbacObject,
} from '../../../export-types/cleaned-types';
import { DeliveryOrderPackageGroupInfo } from './delivery-order-package-group-info';
import { useProfile } from '../../../api/profile';
import { FormattedMessage } from 'react-intl';
import { SupplierInfo } from '../../common/SupplierInfo';
import { ConversationWindow } from '../../common/conversation-window';
import { DeleteDeliveryModal } from './delete-delivery-modal.component';
import { Nav } from 'rsuite';
import NotesInfo from '../../common/notes-info';
import { ConfirmModal } from '../../common/confirm-modal';
import { userIsSalesManager } from '../../../utils/common.utils';
import { DeliveryOrderCdekInfo } from './delivery-order-cdek-info';
import { WarningModal } from '../../common/warning-modal';

export const DeliveryOrderScreen: React.FC = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const { data: user } = useProfile();
  const { hasPermission } = useRbac();
  const { data: order, mutate: mutateOrder } = useSWR<CleanDeliveryOrder>(
    `/delivery-order/${id}`,
    fetcher,
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const tab = useMemo<CleanConversationType>(() => {
    const chat = searchParams.get('chat') || 'supplier';
    if (!['supplier', 'client'].includes(chat)) {
      return 'supplier';
    }
    return chat as CleanConversationType;
  }, [searchParams]);

  if (!order || !user) {
    return null;
  }

  function getBtnText() {
    if (!order) return null;

    if (
      order.compositeDelivery &&
      ['assembled', 'sentToRussia'].includes(order.status)
    ) {
      return null;
    }
    if (
      user?.role === 'employee' &&
      (order.status === 'sentToRecipient' ||
        order.status === 'sentToRussia' ||
        order.status === 'receivedInRussia')
    )
      return null;
    switch (order.status) {
      case 'processing':
        if (userIsSalesManager(user)) {
          return 'Разморозить';
        }
        return null;
      case 'assembly':
        if (!hasPermission(RbacObject.DeliveryOrder, 'write:markSentToRussia'))
          return null;
        if (order.compositeDelivery) {
          return (
            <FormattedMessage
              defaultMessage="Укомплектовать"
              id="delivery.btn-label.assembly"
              description="Надпись на кнопке"
            />
          );
        } else {
          return (
            <FormattedMessage
              defaultMessage="Подтвердить отправку в РФ"
              id="delivery.btn-label.confirmShipmentToTheRussianFederation"
              description="Надпись на кнопке/заголовок"
            />
          );
        }

      case 'sentToRussia':
        return hasPermission(RbacObject.DeliveryOrder, 'write:markSentToRussia')
          ? 'Подтвердить получение в РФ'
          : null;
      case 'receivedInRussia':
      case 'receivedInRetailRussia':
        return hasPermission(RbacObject.DeliveryOrder, 'write:markSentToRussia')
          ? 'Подтвердить отправку получателю'
          : null;
      case 'sentToRecipient':
        return hasPermission(RbacObject.DeliveryOrder, 'write:markDelivered')
          ? 'Клиент получил'
          : null;
    }
  }

  function getTrackNumberForm() {
    ModalsService.createModal(TrackNumberFormModal, {
      deliveryOrder: order!,
    }).then(() => mutateOrder());
  }

  function blockOrder() {
    if (!order) return null;

    if (order.status !== 'sentToRecipient') return null;

    const result = window.confirm(
      `Вы действительно хотите ${
        order.blocked ? 'разблокировать' : 'заблокировать'
      } доставку ?`,
    );
    if (!result) {
      return;
    }

    return DeliveryOrderService.updateDeliveryOrder(
      { blocked: !order.blocked },
      order.id,
    )
      .then(() => {
        AlertService.success();
      })
      .then(() => mutateOrder());
  }

  function deleteOrder() {
    if (
      !order ||
      (order.status !== 'assembly' && order.status !== 'processing')
    )
      return null;
    return ModalsService.createModal(DeleteDeliveryModal, {
      deliveryOrder: {
        id: order.id,
        internalId: order.internalId.toString(),
      },
    })
      .then(() => {
        navigate('/delivery-orders/orders');
      })
      .then(() => {
        AlertService.success('Доставка успешно удалена');
      });
  }

  function nextStep() {
    if (!order) return null;

    switch (order.status) {
      case 'processing':
        return ModalsService.createModal(ConfirmModal, {
          title: 'Разморозка доставки',
          buttonText: `Разморозить доставку`,
        }).then(() => {
          DeliveryOrderService.updateDeliveryOrder(
            { status: 'assembly' },
            order.id,
          )
            .then(() => {
              AlertService.success();
            })
            .then(() => mutateOrder());
        });

      case 'assembly':
        return ModalsService.createModal(DeliveryOrderAssemblyModal, {
          deliveryOrderId: order.id,
          composite: !!order.compositeDelivery,
        }).then(() => mutateOrder());
      case 'sentToRussia': {
        const result = window.confirm(
          'Вы действительно хотите отметить заказ полученным в РФ?',
        );
        if (!result) {
          return;
        }

        return DeliveryOrderService.updateDeliveryOrder(
          { status: 'receivedInRussia' },
          order.id,
        )
          .then(() => {
            AlertService.success();
          })
          .then(() => {
            return mutateOrder();
          })
          .then((updatedData) => {
            if (updatedData?.cdekOrder && !updatedData?.cdekOrder?.cdekUuid) {
              return ModalsService.createModal(WarningModal, {
                title: 'Доставка в CDEK не была создана',
                buttonText: 'Продолжить',
              });
            }
          });
      }
      case 'receivedInRetailRussia':
      case 'receivedInRussia': {
        const result = window.confirm(
          'Вы действительно хотите отметить заказ отправленным получателю?',
        );
        if (!result) {
          return;
        }

        return DeliveryOrderService.updateDeliveryOrder(
          { status: 'sentToRecipient' },
          order.id,
        )
          .then(() => {
            AlertService.success();
          })
          .then(() => mutateOrder());
      }
      case 'sentToRecipient': {
        const result = window.confirm(
          'Вы действительно хотите отметить заказ полученным клиентом?',
        );
        if (!result) {
          return;
        }

        return DeliveryOrderService.updateDeliveryOrder(
          { status: 'delivered' },
          order.id,
        )
          .then(() => {
            AlertService.success();
          })
          .then(() => mutateOrder())
          .catch((error) => {
            if (!error.response) throw error;

            if (
              error.response.status !== 409 ||
              !error.response.data ||
              !error.response.data.code
            ) {
              throw error;
            }

            if (error.response.data.code === 'blocked') {
              AlertService.error(
                'Доставка на блокировке выдачи клиенту! Снимите блокировку',
              );
            }
            console.log('error', error);
          });
      }
    }
  }

  if (!order || !user) {
    return null;
  }

  return (
    <div className="d-flex flex-column h-100">
      <Header>
        <div className="d-flex flex-row align-items-center">
          <h1 className="me-3 mb-0">
            <FormattedMessage
              defaultMessage="Заказ на доставку {value}"
              id="delivery.label.shippingOrder"
              description="Заголовок на странице"
              values={{ value: 'D' + order.internalId }}
            />
          </h1>
          <DeliveryOrderStatus status={order.status} />
        </div>
      </Header>
      <div className="d-flex flex-column flex-lg-row flex-grow-1 gap-4">
        <div className="col flex-grow-1">
          <div className="bg-gray p-3 pb-0 rounded-8 mb-3">
            <div className="fs-18 fw-600 color-gray-450 mb-3">
              <FormattedMessage
                defaultMessage="Статус заказа"
                id="purchaseOrderPage.label.orderStatus"
                description="Заголовок на странице"
              />
            </div>
            <DeliveryOrderStatusTable order={order} />
          </div>
          <div className="bg-gray p-3 rounded-8 my-3">
            <DeliveryOrderPackageGroupInfo id={order.id} />
          </div>
          <div className="bg-gray p-3 rounded-8">
            <DeliveryOrderDeliveryInfo id={order.id} />
            {order.status !== 'assembly' && (
              <>
                <hr />
                <DeliveryOrderAssemblyInfo id={order.id} />
              </>
            )}
          </div>

          <div className="bg-gray p-3 rounded-8 mt-3">
            <ClientInfo user={order.user!} />
          </div>
          <Rbac
            object={RbacObject.DeliveryOrder}
            action={['read:finances-client', 'read:finances-company']}
          >
            <div className="bg-gray p-3 rounded-8 mt-3">
              <DeliveryOrderFinancesInfo id={order.id} />
            </div>
          </Rbac>
          <Rbac object={RbacObject.DeliveryOrder} action={'read:notes'}>
            <NotesInfo type="delivery" id={order.id} />
          </Rbac>
          <Rbac object={RbacObject.DeliveryOrder} action={'read:transactions'}>
            <OrderTransactionsInfo type={'delivery-order'} id={order.id} />
          </Rbac>
          <Rbac
            object={RbacObject.DeliveryOrder}
            action={'read:finances-client'}
          >
            <SupplierInfo supplier={order.supplier?.name} />
          </Rbac>
        </div>

        <div className="col d-flex flex-column">
          <div className="d-flex flex-row justify-content-center gap-1 pb-2">
            {(order.status === 'receivedInRussia' ||
              order.status === 'receivedInRetailRussia') && (
              <Rbac object={RbacObject.Cdek} action={'read'}>
                <DeliveryOrderCdekInfo id={order.id} />
              </Rbac>
            )}
            {!!getBtnText() && (
              <>
                {order.status === 'sentToRecipient' && !order.trackNumber && (
                  <button
                    type="button"
                    className={classNames('btn', btnStyle.trackButton)}
                    onClick={getTrackNumberForm}
                  >
                    Указать трек-номер
                  </button>
                )}

                <div
                  className="btn btn-success d-flex align-items-center"
                  onClick={nextStep}
                >
                  {getBtnText()} | <i className="bi bi-check-lg fs-18" />
                </div>
                <Rbac object={RbacObject.DeliveryOrder} action={'delete'}>
                  {(order.status === 'assembly' ||
                    order.status === 'processing') && (
                    <div className="btn btn-success" onClick={deleteOrder}>
                      Удалить
                    </div>
                  )}
                </Rbac>
              </>
            )}
          </div>

          {order.status === 'sentToRecipient' && (
            <div className="d-flex flex-row justify-content-center mt-3">
              <div
                className={classNames('btn', btnStyle.trackButton)}
                onClick={blockOrder}
              >
                {order.blocked ? (
                  <FormattedMessage
                    defaultMessage="Снять блокировку"
                    id="deliveryOrderScreen.label.Unlock"
                    description="Заголовок"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="Блокировка выдачи"
                    id="deliveryOrderScreen.label.issueBlocking"
                    description="Заголовок"
                  />
                )}
              </div>
            </div>
          )}
          {!user.accesses.includes('fulfillment') ? (
            <div className="flex-grow-1 d-flex mb-4">
              <div className="flex-grow-1 d-flex mb-4 flex-column">
                <Nav
                  appearance="tabs"
                  activeKey={tab}
                  onSelect={(key) => setSearchParams({ chat: key })}
                >
                  <Nav.Item eventKey="supplier" className="bg-gray-50">
                    <FormattedMessage
                      defaultMessage="Поставщик"
                      id="TransactiosHistrory.lable.supplier"
                      description="Вкладка"
                    />
                  </Nav.Item>
                  {!user.supplier && (
                    <Nav.Item eventKey="client" className="bg-teal-100">
                      <FormattedMessage
                        defaultMessage="Клиент"
                        id="clientInfo.label.customer"
                        description="Заголовок на странице"
                      />
                    </Nav.Item>
                  )}
                </Nav>
                <ConversationWindow
                  id={order.id}
                  type="delivery"
                  internalId={order.internalId}
                  supplier={order.supplier}
                  client={order.user}
                  tab={tab}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
