import { v4 } from 'uuid';
import { state } from '../state';
import React from 'react';

const modals = state.select('modals');

export interface ModalProps {
  id?: string;
  close: (...args: any) => any;
  reject: () => void;
}

export class ModalsService {
  static async createModal<
    T extends ModalProps,
    CloseParams = Parameters<T['close']>[0],
  >(type: React.FC<T>, props?: Omit<T, 'id' | 'close' | 'reject'>) {
    const id = v4();
    return new Promise<CloseParams>((resolve, reject) => {
      try {
        modals.push({
          id,
          type,
          props: Object.assign({}, props, {
            id,
            close(result: CloseParams) {
              resolve(result);
              ModalsService.removeModal(id);
            },
            reject() {
              reject();
              ModalsService.removeModal(id);
            },
          }),
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  private static removeModal(id: string) {
    modals.select({ id }).unset();
  }
}
