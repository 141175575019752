import React from 'react';
import Pagination from 'rsuite/Pagination';
import { useNavigate, useOutletContext } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher } from '../../../services/api';
import MyLoader from '../../common/loader.component';
import { usePaginationQs } from '../../../utils/usePagination';
import { ConversationItemProps, ConversationStatus } from './conversation-item';
import { CleanConversation } from '../../../export-types/cleaned-types';
import moment from 'moment';
import _ from 'lodash';
import 'moment/locale/ru';
import { useConversationNewMessages } from '../../../api/conversation';
import { OutletContextParams } from './conversations-screen';
import { formatCalendar } from '../../../utils/date-value';

type StatusProps = {
  status: ConversationStatus | null;
  render: (data: ConversationItemProps) => React.ReactChild;
};

export const ConversationList: React.FC<StatusProps> = ({ status, render }) => {
  const navigate = useNavigate();

  const { take, skip, handleSetPage, handleChangeLimit, page } =
    usePaginationQs(25);
  const { debouncedInternalId, linked } =
    useOutletContext<OutletContextParams>();

  const { data, error, isLoading, mutate } = useSWR<{
    total: number;
    pinned: Array<CleanConversation>;
    conversations: Array<CleanConversation>;
  }>(
    () => ({
      url: '/conversation/list',
      params: {
        status: status ? [status] : [],
        take,
        skip,
        internalId: debouncedInternalId ? debouncedInternalId : undefined,
        linked,
      },
    }),
    fetcher,
  );

  const newMessageHandler = React.useCallback(
    (updatedConversation: CleanConversation) => {
      if (
        data?.conversations.some(
          (conversation) => conversation.id === updatedConversation.id,
        )
      ) {
        mutate();
      } else if (
        data?.pinned.some(
          (conversation) => conversation.id === updatedConversation.id,
        )
      ) {
        mutate();
      }
    },
    [data, mutate],
  );
  useConversationNewMessages(newMessageHandler, 'list');

  const handleClick = React.useCallback(
    (data: CleanConversation) => {
      if (data.purchaseOrder) {
        if (data.purchaseOrder.type === 'purchase') {
          navigate(`/purchases/${data.purchaseOrder.id}?chat=${data.type}`);
        } else {
          navigate(`/parcels/${data.purchaseOrder.id}?chat=${data.type}`);
        }
      } else if (data.deliveryOrder) {
        navigate(
          `/deliveries/delivery-order/${data.deliveryOrder.id}?chat=${data.type}`,
        );
      }
    },
    [navigate],
  );
  const handleChange = async () => {
    mutate();
  };

  if (isLoading) {
    return (
      <>
        <div className="pb-4">
          <MyLoader />
        </div>
      </>
    );
  }

  if (error || !data) {
    return (
      <>
        <div className="pb-4">Неизвестная ошибка</div>
      </>
    );
  }

  const total = data!.total;
  const items = _.chain(data!.conversations)
    .groupBy((conversation) => {
      const sentAt = moment(conversation.lastMessage?.sentAt);
      if (sentAt > moment().add(1, 'week').endOf('day')) {
        return 'Позже';
      }
      return formatCalendar(sentAt);
    })
    .map((value, key) => ({ day: key, conversations: value }))
    .value();
  const pinnedItems = data!.pinned;

  return (
    <>
      <div className="pb-4">
        {pinnedItems.length > 0 && (
          <div>
            <div className="color-gray-450 mb-2">Закрепленные</div>
            <div className="bg-white rounded w-100 border-top border-start border-end-green">
              {pinnedItems.map((data) =>
                render({
                  data,
                  pinned: true,
                  onClick: handleClick,
                  onChange: handleChange,
                }),
              )}
            </div>
          </div>
        )}
        {items.map(({ day, conversations }) => (
          <div className="my-2">
            <div className="color-gray-450 mb-2">{day}</div>
            <div className="bg-white rounded w-100 border-top border-end border-start">
              {conversations.map((data) =>
                render({
                  data,
                  pinned: false,
                  onClick: handleClick,
                  onChange: handleChange,
                }),
              )}
            </div>
          </div>
        ))}
      </div>

      {total > take && (
        <div className="pb-4">
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="md"
            layout={['pager']}
            total={total}
            limit={take}
            activePage={page}
            onChangePage={handleSetPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      )}
    </>
  );
};
