import React from 'react';
import { PaidStatus } from '../../common/paid-status.component';
import classNames from 'classnames';
import tableStyle from '../delivery/delivery-order-item.module.css';
import { IdCell } from '../../common/cards/id-cell-card.component';
import { UserInternalIdCell } from '../../common/cards/id-user-cell-card.component';
import { TariffCell } from '../../common/cards/tariff-cell-card.component';
import { LocalDelivery } from '../../common/cards/local-delivery-cell-card.component';
import { toMoney } from '../../../utils/common.utils';
import { ImagesCell } from '../../common/cards/images-cell-card.component';
import { DeliveryPeriodCell } from '../../common/cards/delivery-date-cell-card.component';
import { WeightVolumeCell } from '../../common/cards/weight-volume-cell-card.component';
import { PriceCell } from '../../common/cards/price-cell-card.component';
import moment from 'moment';
import { useRbac } from '../../common/Rbac';
import {
  CleanCompositeDelivery,
  CleanDeliveryOrder,
  RbacObject,
} from '../../../export-types/cleaned-types';
import { Manager } from '../../common/cards/manager-cell-card.component';

type CompositeDeliveryItemProps = {
  onClick: (rowData: { id: string }) => void;
  onDeliveryRowClick: (rowData: { id: string }) => void;
  status: string;
  data: CleanCompositeDelivery;
};

export function CompositeDeliveryTabItem(props: CompositeDeliveryItemProps) {
  const { isLoading, hasPermission } = useRbac();

  const canReadExtendedData =
    !isLoading &&
    hasPermission(RbacObject.DeliveryOrder, 'read:finances-client');

  const ItemPaidStatus = ({
    deliveryOrder,
  }: {
    deliveryOrder: CleanDeliveryOrder;
  }) => {
    if (!deliveryOrder.price)
      return <PaidStatus className="ms-3" type="preliminaryPrice" />;

    if (!!deliveryOrder.price && !deliveryOrder.paid)
      return <PaidStatus className="ms-3" type="waiting" />;

    if (deliveryOrder.paid) return <PaidStatus className="ms-3" type="paid" />;
  };

  return (
    <div className={classNames('row g-0 mb-3', tableStyle.rowContainer)}>
      <div
        className={classNames('row cursor-pointer g-0', tableStyle.tableRow)}
        onClick={() => props.onClick(props.data)}
      >
        <>
          <div className="col pe-1">
            <IdCell internalId={props.data.internalId} type="composite" />
          </div>
        </>
      </div>
      {props.data.deliveryOrders?.map((deliveryOrder) => (
        <div
          className={classNames(
            'row cursor-pointer g-0',
            tableStyle.purchaseTableRow,
          )}
          key={deliveryOrder.id}
          onClick={() => props.onDeliveryRowClick(deliveryOrder)}
        >
          {['assembly', 'assembled'].includes(deliveryOrder.status) && (
            <>
              <div className="col pe-1">
                <IdCell internalId={deliveryOrder.internalId} type="delivery" />
              </div>
              <div className="col">
                <UserInternalIdCell user={deliveryOrder.user} />
              </div>
              <div className="col-4 ">
                <TariffCell deliveryMethod={deliveryOrder.deliveryMethod!} />
              </div>
              {canReadExtendedData ? (
                <div className="col-3">
                  <LocalDelivery
                    localDelivery={deliveryOrder.localDeliveryMethod}
                  />
                </div>
              ) : null}
              {canReadExtendedData && deliveryOrder.user?.manager ? (
                <div className="col-1">
                  <Manager manager={deliveryOrder.user?.manager} />
                </div>
              ) : null}
              <div className="col-3 text-end">
                <div className="">
                  <div className="color-gray-800 fs-16 fw-500  text-truncate">
                    {toMoney(deliveryOrder.moderateProductPrice!, 'cny')}
                  </div>
                  <div className="color-gray-600 fs-14 fw-400">
                    Стоимость товаров
                  </div>
                </div>
              </div>
            </>
          )}
          {deliveryOrder.status === 'sentToRussia' && (
            <>
              <div className="col pe-1">
                <IdCell internalId={deliveryOrder.internalId} type="delivery" />
              </div>
              <div className="col">
                <UserInternalIdCell user={deliveryOrder.user} />
              </div>
              <div
                className={classNames(
                  canReadExtendedData ? 'col-2' : 'col-3',
                  'd-flex',
                )}
                onClick={(e) => e.stopPropagation()}
              >
                <ImagesCell imagesList={deliveryOrder.packedFilesV2} />
              </div>
              <div className="col-5 d-flex align-items-center">
                <DeliveryPeriodCell
                  deliveryMethod={deliveryOrder.deliveryMethod!}
                  sentDate={deliveryOrder.sentDate!}
                />
              </div>
              <div
                className={classNames(
                  canReadExtendedData ? 'col' : 'col-2 text-end',
                )}
              >
                <WeightVolumeCell
                  weight={deliveryOrder.weight}
                  volume={deliveryOrder.volume}
                />
              </div>
              {canReadExtendedData && deliveryOrder.user?.manager ? (
                <div className="col-1">
                  <Manager manager={deliveryOrder.user?.manager} />
                </div>
              ) : null}
              {canReadExtendedData ? (
                <div className="col-2 justify-content-end d-flex align-items-center">
                  <PriceCell price={deliveryOrder.price!} currency={'usd'} />
                  <ItemPaidStatus deliveryOrder={deliveryOrder} />
                </div>
              ) : null}
            </>
          )}
          {deliveryOrder.status === 'receivedInRetailRussia' && (
            <>
              <div className="col pe-1">
                <IdCell internalId={deliveryOrder.internalId} type="delivery" />
              </div>
              <div className="col">
                <UserInternalIdCell user={deliveryOrder.user} />
              </div>
              <div
                className={classNames(
                  canReadExtendedData ? 'col' : 'col-2',
                  'd-flex',
                )}
                onClick={(e) => e.stopPropagation()}
              >
                <ImagesCell imagesList={deliveryOrder.packedFilesV2} />
              </div>
              <div
                className={classNames(canReadExtendedData ? 'col-2' : 'col-3')}
              >
                <WeightVolumeCell
                  weight={deliveryOrder.weight}
                  volume={deliveryOrder.volume}
                />
              </div>
              <div
                className={classNames(
                  'col-2 ps-2',
                  canReadExtendedData ? 'text-start' : '',
                )}
              >
                <div className="color-gray-800 fs-16 fw-500">
                  {moment(props.data.receiveRfDate).format('DD.MM.YYYY')}
                </div>
                <div className="color-gray-600 fs-14">Получено</div>
              </div>
              <div
                className={classNames(
                  'col-3',
                  canReadExtendedData ? '' : 'text-end',
                )}
              >
                <LocalDelivery
                  localDelivery={deliveryOrder.localDeliveryMethod}
                />
              </div>
              {canReadExtendedData && deliveryOrder.user?.manager ? (
                <div className="col-1">
                  <Manager manager={deliveryOrder.user?.manager} />
                </div>
              ) : null}
              {canReadExtendedData ? (
                <div className="col-2 justify-content-end d-flex align-items-center">
                  <PriceCell price={deliveryOrder.price!} currency={'usd'} />
                  <ItemPaidStatus deliveryOrder={deliveryOrder} />
                </div>
              ) : null}
            </>
          )}
        </div>
      ))}
    </div>
  );
}
