import React from 'react';
import { useModalClose } from '../../utils/useModalClose';
import { FormattedMessage } from 'react-intl';

type ConfirmModalProps = {
  close: () => void;
  reject: () => void;
  title: string;
  buttonText: string;
};

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  close,
  reject,
  title,
  buttonText,
}) => {
  const modalRef = useModalClose(reject);

  return (
    <div className="modal-dialog mw-400" ref={modalRef}>
      <div className="modal-content">
        <div className="modal-header border-bottom-0 p-4">
          <button
            type="button"
            className="btn-close"
            onClick={() => reject()}
          />
        </div>
        <div className="modal-body pt-2 p-4">
          <div className="text-center mb-4 mx-auto">
            <i className="bi bi-exclamation-circle-fill color-yellow fs-65 lh-1" />
            <h3 className="mt-3">{title}</h3>
          </div>
          <button
            type="button"
            className="btn btn-success w-100 mb-3"
            onClick={() => close()}
          >
            {buttonText}
          </button>
          <button
            type="button"
            className="btn btn-light w-100"
            onClick={() => reject()}
          >
            <FormattedMessage
              defaultMessage="Отменить"
              id="receiptConfirmation.label.cancel"
              description="Надпись на кнопке"
            />
          </button>
        </div>
      </div>
    </div>
  );
};
