import React, { ChangeEventHandler } from 'react';
import { useRef } from 'react';
import { LocalImg } from './local-img.component';
import AddImg from '../../assets/add-img.svg';
import { getFileThumbnail } from '../../utils/common.utils';

type UploadFileProps = {
  file: File | null;
  currentFile: {
    id: string;
    key: string;
  } | null;
  onChange: (file: File | null) => void;
};

export const UploadQRCode: React.FC<UploadFileProps> = (props) => {
  const fileInput = useRef(null);

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    props.onChange(e.target.files?.length ? e.target.files[0] : null);
  };

  function removeFile() {
    props.onChange(null);
  }

  return (
    <div className="d-flex flex-row flex-wrap">
      <label className="cursor-pointer me-3">
        <img className="fit-cover" src={AddImg} width={64} height={64} />
        <input
          ref={fileInput}
          type="file"
          className="d-none"
          onChange={onChange}
        />
      </label>
      {!props.file && props.currentFile && (
        <img
          className="rounded-1 me-3 fit-cover"
          src={getFileThumbnail(props.currentFile.key)}
          width={64}
          height={64}
        />
      )}
      {props.file && (
        <div className="rounded-1 position-relative me-3">
          <i
            className="bi bi-x-circle-fill remove-file-btn"
            onClick={removeFile}
          />
          <LocalImg
            file={props.file}
            width={64}
            height={64}
            className="rounded"
          />
        </div>
      )}
    </div>
  );
};
