import React from 'react';
import { useForm } from 'react-hook-form';
import { InputWrapper } from './input-wrapper';
import { required } from '../../utils/validations';
import { useModalClose } from '../../utils/useModalClose';

type Form = { privateComment: string };

type ConfirmWithPrivateCommentModalProps = {
  close: (form: Form) => void;
  reject: () => void;
  title: string;
  buttonText: string;
};

export const ConfirmWithPrivateCommentModal: React.FC<
  ConfirmWithPrivateCommentModalProps
> = ({ close, reject, title, buttonText }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Form>();

  const onSubmit = handleSubmit(async (form: Form) => {
    close(form);
  });

  const modalRef = useModalClose(reject);

  return (
    <div className="modal-dialog mw-400" ref={modalRef}>
      <div className="modal-content">
        <div className="modal-header border-bottom-0 p-4">
          <button
            type="button"
            className="btn-close"
            onClick={() => reject()}
          />
        </div>
        <div className="modal-body pt-2 p-4">
          <div className="text-center mb-4 mx-auto">
            <i className="bi bi-exclamation-circle-fill color-yellow fs-65 lh-1" />
            <h3 className="mt-3">{title}</h3>
          </div>
          <form onSubmit={onSubmit}>
            <InputWrapper
              title="Комментарий для администратора"
              className="mb-4"
              error={errors.privateComment?.message}
              required
            >
              <textarea
                placeholder="Укажите здесь дополнительную информацию для администратора"
                className="form-control fs-16 lh-sm"
                {...register('privateComment', { ...required })}
              />
            </InputWrapper>
            <button type="submit" className="btn btn-success w-100 mb-3">
              {buttonText}
            </button>
            <button
              type="button"
              className="btn btn-light w-100"
              onClick={() => reject()}
            >
              Отменить
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
