import React from 'react';
import { useLocalStorage } from '@mantine/hooks';
import Select, { OnChangeValue } from 'react-select';
import '../../sass/SelectLanguage.scss';
import RUFlag from '../../assets/RU.png';
import ENFlag from '../../assets/EN.png';
import ZHFlag from '../../assets/ZH.png';

interface IOption {
  value: string;
  label: string;
  icon: string;
}

export function useSelectLanguage() {
  const [language, setLanguage] = useLocalStorage({
    key: 'language',
    defaultValue: 'ru',
  });

  return [language, setLanguage] as const;
}

export const SelectLanguage = () => {
  const [selectedLanguage, setSelectedLanguage] = useSelectLanguage();

  const options: IOption[] = [
    {
      value: 'ru',
      label: 'RU',
      icon: RUFlag,
    },
    {
      value: 'en',
      label: 'EN',
      icon: ENFlag,
    },
    {
      value: 'zh',
      label: 'ZH',
      icon: ZHFlag,
    },
  ];

  const getValue = () => {
    return options.find((l) => l.value === selectedLanguage) || null;
  };

  const onChange = (newValue: OnChangeValue<IOption, false>) => {
    setSelectedLanguage(newValue?.value ?? '');
  };

  return (
    <Select
      classNamePrefix="custom-select-language"
      value={getValue()}
      options={options}
      formatOptionLabel={(option) => (
        <div className="country-option">
          <img src={option.icon} />
          <span>{option.label}</span>
        </div>
      )}
      //@ts-ignore
      onChange={onChange}
      isLoading={false}
      isSearchable={false}
      placeholder="Language"
    />
  );
};
