import { api } from './api';
import { CleanDeliveryOrder } from '../export-types/cleaned-types';

export class DeliveryOrderService {
  static createDeliveryOrder(
    data: Object,
    internalId: number,
  ): Promise<{
    data: { deliveryOrder: CleanDeliveryOrder };
  }> {
    return api.post('/delivery-order/v2', { ...data, internalId });
  }

  static updateDeliveryOrder(data: unknown, id: string) {
    return api.put(`/delivery-order/${id}`, data);
  }

  static deleteDeliveryOrder(id: string) {
    return api.delete(`/delivery-order/${id}`);
  }
}
