import React from 'react';

type UserInternalIdCellProps = {
  user?: {
    internalId: string | number | null;
  };
};

export const UserInternalIdCell = ({ user }: UserInternalIdCellProps) => (
  <>{`${user?.internalId || 'Express'}`}</>
);
