import * as Sentry from '@sentry/react';
import { Navigate, Route, Routes } from 'react-router-dom';
import React from 'react';
import TerminalSearchScreen from './screens/terminal/terminal-search.screen';
import { Rbac } from './common/Rbac';
import { RbacObject } from '../export-types/cleaned-types';
import { AccessDenied } from './common/AccessDenied';
import { TerminalPurchaseOrder } from './screens/terminal/terminal-purchase-order.screen';
import { TerminalPurchaseOrderEditor } from './screens/terminal/terminal-purchase-order-editor.screen';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const TerminalRouter = () => {
  return (
    <SentryRoutes>
      <Route path="*" element={<Navigate to={'/terminal/search'} replace />} />
      <Route path="/terminal">
        <Route path="search" element={<TerminalSearchScreen />} />
        <Route
          path="purchases/:id"
          element={
            <Rbac
              object={RbacObject.Purchase}
              action={'read'}
              otherwise={() => <AccessDenied />}
            >
              <TerminalPurchaseOrder />
            </Rbac>
          }
        />
        <Route
          path="purchases/:id/edit"
          element={
            <Rbac
              object={RbacObject.Purchase}
              action={'read'}
              otherwise={() => <AccessDenied />}
            >
              <TerminalPurchaseOrderEditor />
            </Rbac>
          }
        />
      </Route>
    </SentryRoutes>
  );
};
