import React from 'react';

type IdCellProps = {
  internalId: number;
  type?: 'delivery' | 'purchase' | 'parcel' | 'composite';
};

export function getPrefixByType(
  type?: 'delivery' | 'purchase' | 'parcel' | 'composite',
) {
  switch (type) {
    case 'composite':
      return 'T';
    case 'delivery':
      return 'D';
    case 'parcel':
      return 'K';
    case 'purchase':
      return 'V';
    default:
      return 'V';
  }
}

export const IdCell: React.FC<IdCellProps> = ({ internalId, type }) => {
  return (
    <>
      {getPrefixByType(type)}
      {internalId}
    </>
  );
};
