import React from 'react';
import { InputWrapper } from '../../common/input-wrapper';
import { Gallery } from '../../common/gallery/gallery';
import {
  getCurrencySymbol,
  getFileV2ApiUrl,
} from '../../../utils/common.utils';
import useSWR from 'swr';
import { fetcher } from '../../../services/api';
import { useProfile } from '../../../api/profile';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment/moment';
import { CleanPurchaseOrder } from '../../../export-types/cleaned-types';

type PurchaseOrderWriteOffsProps = {
  id: string;
};

export const PurchaseOrderWriteOffs: React.FC<PurchaseOrderWriteOffsProps> = (
  props,
) => {
  const intl = useIntl();
  const { data: user } = useProfile();
  const { data: order } = useSWR<CleanPurchaseOrder>(
    `/purchase-order/${props.id}`,
    fetcher,
  );

  if (!order?.additionalWriteOffs || !user) {
    return null;
  }

  return (
    <div>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="fs-18 fw-600 color-gray-450">
          <FormattedMessage
            defaultMessage="Доплата"
            id="purchaseOrderWriteOffs.label.additionalWriteOffs"
            description="Заголовок на странице"
          />
        </div>
      </div>
      {order.additionalWriteOffs.map((writeOff, i) => (
        <div key={i}>
          <InputWrapper
            theme="light"
            className="mt-3"
            title={intl.formatMessage({
              defaultMessage: 'Фотографии подтверждения оплаты',
              id: 'input.label.photosOfReceipt',
              description: 'Заголовок поля ввода',
            })}
          >
            <Gallery className="mt-2">
              {writeOff.receiptFiles.map((file, index) => (
                <a href={getFileV2ApiUrl(file.id)} key={index}>
                  <img
                    className="rounded fit-cover me-2"
                    src={getFileV2ApiUrl(file.id, '64x64')}
                    width={64}
                    height={64}
                    alt=""
                  />
                </a>
              ))}
            </Gallery>
          </InputWrapper>
          <InputWrapper
            theme="light"
            className="mt-3"
            title={intl.formatMessage({
              defaultMessage: 'Сумма списания',
              id: 'input.label.writeOffAmount',
              description: 'Заголовок поля ввода',
            })}
          >
            {`${writeOff.amount} ${getCurrencySymbol(order.currency ?? 'cny')}`}
          </InputWrapper>
          <InputWrapper
            theme="light"
            className="mt-3"
            title={intl.formatMessage({
              defaultMessage: 'Описание',
              id: 'input.label.writeOffDescription',
              description: 'Заголовок поля ввода',
            })}
          >
            {writeOff.description ?? 'Отсутствует'}
          </InputWrapper>
          <InputWrapper
            theme="light"
            className="mt-3"
            title={intl.formatMessage({
              defaultMessage: 'Дата списания',
              id: 'input.label.writeOffDate',
              description: 'Заголовок поля ввода',
            })}
          >
            {moment(writeOff.createdAt).format('DD.MM.YYYY, HH:mm')}
          </InputWrapper>
          <hr />
        </div>
      ))}
    </div>
  );
};
