import React from 'react';
import Tooltip from 'rsuite/Tooltip';
import Whisper from 'rsuite/Whisper';

type PaidStatusProps = {
  type: 'preliminaryPrice' | 'waiting' | 'paid';
  className: string;
};

export const PaidStatus = ({ type, className }: PaidStatusProps) => {
  const tooltips = {
    preliminaryPrice: (
      <Tooltip>
        Примерная стоимость доставки на склад в РФ. Оплата станет доступна после
        обработки заказа и расчета финальной стоимости.
      </Tooltip>
    ),
    waiting: <Tooltip>Ожидание оплаты</Tooltip>,
    paid: <Tooltip>Оплачено</Tooltip>,
  };

  let color;
  switch (type) {
    case 'preliminaryPrice':
      color = 'blue';
      break;
    case 'waiting':
      color = 'orange';
      break;
    case 'paid':
      color = 'green';
      break;
    default:
      return null;
  }

  return (
    <Whisper placement="top" trigger="hover" speaker={tooltips[type]}>
      <div className={`${className} mark-dot ${color}`} />
    </Whisper>
  );
};
