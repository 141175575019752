import { Controller, useForm } from 'react-hook-form';
import { CURRENCIES } from '../../../consts/db-values';
import { TransactionService } from '../../../services/transaction.service';
import { AlertService } from '../../../services/alert.service';
import { required } from '../../../utils/validations';
import { Select } from '../../common/select.component';
import { Input } from '../../common/input.component';
import { getCurrencySymbol } from '../../../utils/common.utils';
import React, { useMemo, useRef } from 'react';
import { InputWrapper } from '../../common/input-wrapper';
import { ModalProps, ModalsService } from '../../../services/modals.service';
import { useGlobalMutate } from '../../../api/useGlobalMutate';
import { useModalClose } from '../../../utils/useModalClose';
import { useDeliveryOrders } from '../../../api/use-delivery-orders';
import { usePurchaseOrders } from '../../../api/use-purchase-orders';

type Order = {
  id: string;
  internalId: number;
};

type FieldValues = {
  currency: string;
  amount: number;
  privateComment: string;
  order: Order;
};

export const WriteOffCompanyModal = ({ close }: ModalProps) => {
  const writeOffType = useRef('');

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<FieldValues>();

  const formValues = watch();
  function getCurrencies() {
    return Object.keys(CURRENCIES).map((key) => ({
      label: CURRENCIES[key as keyof typeof CURRENCIES],
      value: key,
    }));
  }

  const { data: orders } = usePurchaseOrders();
  const { data: deliveryOrders } = useDeliveryOrders();

  const _orders = useMemo(() => {
    return [
      ...(orders?.items.map(({ internalId, id }) => ({
        label: `V ${internalId}`,
        value: id,
        type: 'order',
      })) || []),
      ...(deliveryOrders?.items.map(({ internalId, id }) => ({
        label: `D ${internalId}`,
        value: id,
        type: 'deliveryOrder',
      })) || []),
    ];
  }, [deliveryOrders, orders]);

  const { mutate } = useGlobalMutate();

  function onSave(writeOff: FieldValues) {
    const data = {
      ...writeOff,
      [writeOffType.current]: writeOff.order,
      type: 'writeOff',
    } as const;

    TransactionService.createCompanyTransaction(data).then(() => {
      AlertService.success();
      mutate();
      close();
    });
  }
  const modalRef = useModalClose(close);

  return (
    <div className="modal-dialog" ref={modalRef}>
      <form className="modal-content" onSubmit={handleSubmit(onSave)}>
        <div className="modal-body">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h3>Нерабочая форма. Списание со счета компании</h3>
            <div className="btn p-0" onClick={close}>
              <i className="bi bi-x fs-36 color-gray-600" />
            </div>
          </div>
          <Controller
            name="currency"
            control={control}
            rules={required}
            render={({ field }) => (
              <Select
                className="mb-3"
                title="Счёт списания"
                placeholder="Выберите счет"
                options={getCurrencies()}
                {...field}
                error={errors.currency?.message}
              />
            )}
          />
          <Input
            className="mb-3"
            title="Сумма списания"
            type="number"
            min={0}
            step={0.01}
            {...register('amount', {
              ...required,
            })}
            error={errors.amount?.message}
            postfix={getCurrencySymbol(formValues.currency)}
          />
          <Controller
            name="order"
            control={control}
            rules={required}
            render={({ field }) => (
              <Select
                className="mb-3"
                title="Заказ на выкуп / доставку"
                placeholder="Выберите заказ"
                options={_orders}
                {...field}
                onChange={(e) => {
                  writeOffType.current = e.target.type;
                  field.onChange(e);
                }}
                error={errors.order?.message}
              />
            )}
          />
          <InputWrapper
            title="Комментарий для администратора"
            error={errors.privateComment?.message}
            required
          >
            <textarea
              placeholder="Укажите здесь дополнительную информацию для администратора"
              className="form-control fs-16 lh-sm"
              {...register('privateComment', { ...required })}
            />
          </InputWrapper>
        </div>
        <div className="modal-footer justify-content-end">
          <button type="submit" className="btn btn-success">
            Добавить
          </button>
        </div>
      </form>
    </div>
  );
};
