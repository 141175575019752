import useSWR from 'swr';
import { api, fetcher } from '../../../services/api';
import React from 'react';
import { Table } from 'rsuite';
import moment from 'moment/moment';
import Toggle from 'rsuite/Toggle';
import IconButton from 'rsuite/IconButton';
import Whisper from 'rsuite/Whisper';
import Popover from 'rsuite/Popover';
import Dropdown from 'rsuite/Dropdown';
import { Link } from 'react-router-dom';
import { WhisperSpeaker } from '../../../utils/whisper-speaker';

type ClientExpensesPurchaseMarkup = {
  id: string;
  name: string;
  over: number;
  main: boolean;
  createdAt: string;
};

type ClientExpensesPurchaseMarkupsResponse = {
  list: Array<ClientExpensesPurchaseMarkup>;
};

export const ClientExpansesBasedCommissionsTableScreen: React.FC = () => {
  const { data, isValidating, mutate, isLoading } =
    useSWR<ClientExpensesPurchaseMarkupsResponse>(
      '/client-expenses-purchase-markups/admin',
      fetcher,
    );

  const [mainToggling, setMainToggling] = React.useState<null | string>(null);

  return (
    <Table
      loading={isLoading}
      data={data?.list}
      headerHeight={57}
      rowHeight={74}
      rowKey="id"
      locale={{
        loading: 'Загрузка',
        emptyMessage: 'Нет комиссий',
      }}
      autoHeight
    >
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>Название</Table.HeaderCell>
        <Table.Cell dataKey="name" verticalAlign="middle" />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>Период расчета, дней</Table.HeaderCell>
        <Table.Cell dataKey="over" verticalAlign="middle" />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>Дата создания</Table.HeaderCell>
        <Table.Cell verticalAlign="middle">
          {(rowData: ClientExpensesPurchaseMarkup) =>
            moment(rowData.createdAt).format('DD.MM.YYYY')
          }
        </Table.Cell>
      </Table.Column>
      <Table.Column>
        <Table.HeaderCell children="" />
        <Table.Cell verticalAlign="middle">
          {(rowData: ClientExpensesPurchaseMarkup) => {
            return (
              <Toggle
                checked={rowData.main}
                loading={mainToggling === rowData.id}
                disabled={isValidating || rowData.main}
                onChange={async () => {
                  const result = window.confirm(
                    `Вы действительно хотите переключить комиссию за выкуп на "${rowData.name}"?`,
                  );

                  if (!result) {
                    return;
                  }

                  setMainToggling(rowData.id);
                  try {
                    await api.put(
                      `/client-expenses-purchase-markups/admin/${rowData.id}/main`,
                    );
                  } finally {
                    await mutate();
                    setMainToggling(null);
                  }
                }}
              />
            );
          }}
        </Table.Cell>
      </Table.Column>
      <Table.Column>
        <Table.HeaderCell children="" />
        <Table.Cell verticalAlign="middle">
          {(rowData: ClientExpensesPurchaseMarkup) => {
            const speaker: WhisperSpeaker = (
              { onClose, left, top, className },
              ref,
            ) => (
              <Popover
                ref={ref}
                className={className}
                style={{ left, top }}
                full
              >
                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to={`${rowData.id}`}>
                    <i className="bi bi-pencil" />
                    <span className="ps-2">Изменить</span>
                  </Dropdown.Item>
                  {!rowData.main && (
                    <Dropdown.Item
                      onSelect={async () => {
                        onClose();
                        const result = window.confirm(
                          `Вы действительно хотите удалить комиссию за выкуп "${rowData.name}"?`,
                        );

                        if (!result) {
                          return;
                        }

                        try {
                          await api.delete(
                            `/client-expenses-purchase-markups/admin/${rowData.id}`,
                          );
                        } finally {
                          await mutate();
                        }
                      }}
                    >
                      <i className="bi bi-trash" />
                      <span className="ps-2">Удалить</span>
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Popover>
            );

            return (
              <Whisper placement="leftStart" trigger="click" speaker={speaker}>
                <IconButton
                  appearance="subtle"
                  icon={<i className="bi bi-three-dots" />}
                />
              </Whisper>
            );
          }}
        </Table.Cell>
      </Table.Column>
    </Table>
  );
};
