import { getFileV2ApiUrl } from '../../../utils/common.utils';
import { Gallery } from '../gallery/gallery';
import PdfIcon from '../../../assets/pdf-file.svg';
import React from 'react';

type ImageItemProps = {
  id: string;
  type: string;
};
type ImagesCellProps = {
  imagesList: ImageItemProps[];
  width?: number;
  height?: number;
};

export const ImagesCell: React.FC<ImagesCellProps> = ({
  imagesList,
  width = 48,
  height = 32,
}) => {
  if (!imagesList?.length) {
    return null;
  }

  let anchorAttributes;
  let thumbnail;
  const file = imagesList[0];
  const original = getFileV2ApiUrl(file.id);

  switch (file.type) {
    case 'image': {
      thumbnail = (
        <img
          className="rounded me-2 fit-cover"
          src={getFileV2ApiUrl(file.id, '48x32')}
          width={width}
          height={height}
          alt=""
        />
      );
      break;
    }
    case 'pdf': {
      thumbnail = (
        <img
          className="rounded me-2 fit-cover"
          src={PdfIcon}
          width={height}
          height={height}
          alt=""
        />
      );
      anchorAttributes = {
        'data-iframe': true,
        'data-src': original,
      };
      break;
    }
    case 'video': {
      thumbnail = (
        <video className="rounded me-2 fit-cover" width={width} height={height}>
          <source src={original} />
        </video>
      );
      anchorAttributes = {
        'data-video': JSON.stringify({
          source: [{ src: original }],
        }),
      };
      break;
    }
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Gallery>
        <a href={original} {...anchorAttributes} key={file.id}>
          {thumbnail}
        </a>
      </Gallery>
    </div>
  );
};
