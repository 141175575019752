import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  decimalValidationAdditionalServiseSum,
  required,
} from '../../../utils/validations';
import { AlertService } from '../../../services/alert.service';
import { api } from '../../../services/api';
import { InputWrapper } from '../../common/input-wrapper';
import { FormattedMessage, useIntl } from 'react-intl';
import { getCurrencySymbol } from '../../../utils/common.utils';

type OrderAdditionalServiseModalProps = {
  close: () => void;
  reject: () => void;
  orderId: string;
  side: string;
  setEditModeAdditionalService: (value: null) => void;
  orderType: 'purchase-order' | 'delivery-order';
  currency?: string;
};

type Form = {
  amount: string;
  comment: string;
  privateComment: string;
  type: string;
  side: string;
};

export const OrderAdditionalServiceModal: React.FC<
  OrderAdditionalServiseModalProps
> = ({ orderId, side, orderType, ...props }) => {
  const [operation, setOperation] = useState<string>(' ');
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
  } = useForm<Form>();

  const intl = useIntl();

  function handleOperationChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setOperation(event.target.value);
    setValue('type', event.target.value);
  }

  useEffect(() => {
    setValue('side', side);
  }, [side]);

  const onSubmit = handleSubmit(async (data) => {
    await api.post(`/${orderType}/${orderId}/additional-service-charge`, {
      amount: data.amount,
      comment: data.comment,
      privateComment: data.privateComment,
      type: data.type,
      side: data.side,
    });

    AlertService.success();
    props.close();
    props.setEditModeAdditionalService(null);
  });

  const currency = orderType === 'purchase-order' ? props.currency : 'usd';
  const currencySymbol = currency ? getCurrencySymbol(currency) : '';

  return (
    <div className="modal-dialog">
      <form className="modal-content" onSubmit={onSubmit}>
        <div className="modal-header border-bottom-0 p-4">
          <h3 className="text-center">
            {orderType === 'purchase-order' ? (
              <FormattedMessage
                defaultMessage="Изменить дополнительный расход {side}"
                id="purchaseOrderAdditionalModal.label.addAdditionalService"
                description="Заголовок модального окна"
                values={{ side: side === 'client' ? 'клиента' : 'фактический' }}
              />
            ) : (
              <FormattedMessage
                defaultMessage="Изменить дополнительный расход {side}"
                id="purchaseOrderAdditionalModal.label.addAdditionalService"
                description="Заголовок модального окна"
                values={{
                  side: side === 'client' ? 'клиента' : 'поставщика',
                }}
              />
            )}
          </h3>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              props.reject();
              props.setEditModeAdditionalService(null);
            }}
          />
        </div>

        <div className="modal-body pt-2 p-4">
          <InputWrapper title="Операция" className="mb-3">
            <select
              className="form-select"
              value={operation}
              onChange={handleOperationChange}
              required
            >
              <option value="">Выберите операцию</option>
              <option value="writeOff">Списать</option>
              <option value="refill">Вернуть</option>
            </select>
          </InputWrapper>

          <InputWrapper
            title={intl.formatMessage({
              defaultMessage: 'Cумма',
              id: 'purchaseOrderAdditionalModal.input-label.sum',
              description: 'Заголовок поля ввода',
            })}
            className="mb-3"
            error={errors.amount?.message}
            required
          >
            <div className="input-group">
              <input
                className="form-control"
                type="number"
                min={0}
                step={0.01}
                {...register('amount', {
                  ...required,
                  ...decimalValidationAdditionalServiseSum,
                })}
              />
              <span className="input-group-text">{currencySymbol}</span>
            </div>
          </InputWrapper>

          <InputWrapper
            title={intl.formatMessage(
              {
                defaultMessage:
                  'Комментарий {type, select, client {для клиента} supplier {для поставщика} other {}}',
                id: 'purchaseOrderAdditionalModal.input-label.comment',
                description: 'Заголовок поля ввода',
              },
              {
                type: side === 'client' ? 'client' : 'supplier',
              },
            )}
            className="mb-3"
            error={errors.comment?.message}
            required
          >
            <div className="input-group">
              <input
                className="form-control"
                {...register('comment', {
                  ...required,
                })}
              />
            </div>
          </InputWrapper>
          <InputWrapper
            title={intl.formatMessage({
              defaultMessage: 'Комментарий для администратора',
              id: 'purchaseOrderAdditionalModal.input-label.commentAdmin',
              description: 'Заголовок поля ввода',
            })}
            className="mb-3"
            error={errors.privateComment?.message}
            required
          >
            <div className="input-group">
              <input
                className="form-control"
                {...register('privateComment', { ...required })}
              />
            </div>
          </InputWrapper>
        </div>
        <div className="modal-footer justify-content-end">
          <button
            type="submit"
            className="btn btn-success"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Изменить"
                id="purchaseOrderAdditionalModal.status-label.change"
                description="Надпись на кнопке"
              />
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
