import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { fetcher } from '../../../services/api';
import { CleanAuditLog } from '../../../export-types/cleaned-types';
import { Table } from 'rsuite';
import Pagination from 'rsuite/Pagination';
import { diffWordsWithSpace } from 'diff';
import classNames from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { Select } from '../../common/select.component';
import { InputWrapper } from '../../common/input-wrapper';
import { useDebouncedValue } from '@mantine/hooks';
import { Checkbox } from '../../common/checkbox';
import { Header } from '../../common/header.component';
import { usePaginationQs } from '../../../utils/usePagination';

function diffChanges(log: CleanAuditLog) {
  const result = [];
  for (const [key, value] of Object.entries(log.data.diff)) {
    let oldValue = value.old;
    if (null === oldValue || undefined === oldValue) {
      oldValue = '';
    }

    if (Array.isArray(oldValue)) {
      oldValue = oldValue.map((item) => JSON.stringify(item));
    }
    if (`${oldValue}` === '[object Object]') {
      oldValue = JSON.stringify(oldValue);
    }

    let newValue = value.new;
    if (Array.isArray(newValue)) {
      newValue = newValue.map((item) => JSON.stringify(item));
    }
    if (`${newValue}` === '[object Object]') {
      newValue = JSON.stringify(newValue);
    }

    const diff = diffWordsWithSpace(`${oldValue}`, `${newValue}`);
    const row = (
      <div key={key}>
        <span className="fw-500">{key}:</span>{' '}
        <span>
          {diff.map((entry) => (
            <span
              key={JSON.stringify(entry)}
              className={classNames({
                'color-red': entry.removed,
                'line-through': entry.removed,
                'color-green': entry.added,
              })}
            >
              {entry.value}
            </span>
          ))}
        </span>
      </div>
    );
    result.push(row);
  }

  if (result.length === 0 && log.action !== 'delete') {
    return [<>Без изменений</>];
  }

  return result;
}

interface Form {
  object?: string;
  objectId?: number;
  objectInternalId?: number;
  objectUuid?: string;
  userId?: number;
  userInternalId?: number;
}

const Objects = {
  PurchaseOrder: 'Выкуп/Посылка',
  DeliveryOrder: 'Доставка',
  User: 'Пользователь',
  Transaction: 'Транзакция',
  TopUpMethod: 'Способ пополнения',
  TopUpCardToCardAccount: 'Способ пополнения: банк. карта',
  PurchaseOrderDeliveryTariff: 'Тариф на доставку (заказы)',
  PurchaseOrderClientExpensesPurchaseMarkup: 'Комиссия за выкуп (заказы)',
  DeliveryMethod: 'Способ доставки (доставки)',
  PackageMethod: 'Способ упаковки (доставки)',
  DeliveryOrderDeliveryMarkup: 'Комиссия на доставку (доставки)',
};

export const AuditLogScreen: React.FC = () => {
  const { take, skip, handleSetPage, handleChangeLimit, page } =
    usePaginationQs(10);
  // const [sort, setSort] = React.useState('createdAt');
  const sort = 'createdAt';
  // const [direction, setDirection] = React.useState('desc');
  const direction = 'desc';

  const {
    control,
    register,
    formState: { errors },
    watch,
  } = useForm<Form>();

  const [object] = useDebouncedValue(watch('object'), 200);
  const [objectId] = useDebouncedValue(watch('objectId'), 200);
  const [objectInternalId] = useDebouncedValue(watch('objectInternalId'), 200);
  const [objectUuid] = useDebouncedValue(watch('objectUuid'), 200);
  const [userId] = useDebouncedValue(watch('userId'), 200);
  const [userInternalId] = useDebouncedValue(watch('userInternalId'), 200);

  useEffect(() => {
    handleSetPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [object, objectId, objectInternalId, objectUuid, userId, userInternalId]);

  const { data, isLoading } = useSWR<{
    total: number;
    items: Array<CleanAuditLog>;
  }>(
    {
      url: `/audit-log`,
      params: {
        take,
        skip,
        sort,
        direction,
        object: object || undefined,
        objectId: objectId || undefined,
        objectInternalId: objectInternalId || undefined,
        objectUuid: objectUuid || undefined,
        userId: userId || undefined,
        userInternalId: userInternalId || undefined,
      },
    },
    fetcher,
  );

  const total = data?.total || 0;
  const [useAdvancedMode, setUseAdvancedMode] = useState(false);

  type Item = CleanAuditLog & { diff: React.JSX.Element[]; rowHeight: number };

  const items: Array<Item> =
    data?.items.map((item): Item => {
      const diff = diffChanges(item);
      const rowTextLines = Math.max(diff.length, useAdvancedMode ? 4 : 2);
      const rowHeight = Math.max(70, rowTextLines * 25 + 16);

      return { ...item, diff, rowHeight };
    }) || [];

  return (
    <div>
      <Header>
        <h1>Лог изменений</h1>
      </Header>

      <div className="pb-4">
        {useAdvancedMode ? (
          <InputWrapper
            title="Внутренний id пользователя"
            error={errors.userId?.message}
          >
            <input {...register('userId')} />
          </InputWrapper>
        ) : null}
        <InputWrapper
          title="Id пользователя (кто менял)"
          error={errors.userInternalId?.message}
        >
          <input {...register('userInternalId')} />
          <p className="rs-form-help-text">
            Например, для пользователя U3807 нужно указать 3807
          </p>
        </InputWrapper>

        <InputWrapper
          title="Объект (что меняли)"
          error={errors.object?.message}
        >
          <Controller
            name="object"
            control={control}
            render={({ field }) => (
              <Select
                placeholder="Показать все"
                className="mw-400 mb-3"
                style={{ zIndex: 5, position: 'relative' }}
                options={[
                  { value: '', label: 'Показать все' },
                  ...Object.entries(Objects).map(([value, label]) => ({
                    value,
                    label,
                  })),
                ]}
                {...field}
              />
            )}
          />
        </InputWrapper>

        {useAdvancedMode ? (
          <InputWrapper
            title="Внутренний id объекта"
            error={errors.objectId?.message}
          >
            <input {...register('objectId')} />
          </InputWrapper>
        ) : null}
        <InputWrapper
          title="Id объекта (который меняли)"
          error={errors.objectInternalId?.message}
        >
          <input {...register('objectInternalId')} />
          <p className="rs-form-help-text">
            Например, для посылки K6735 нужно указать 6735
          </p>
        </InputWrapper>
        {useAdvancedMode ? (
          <InputWrapper
            title="Внутренний uuid объекта"
            error={errors.objectUuid?.message}
          >
            <input {...register('objectUuid')} />
          </InputWrapper>
        ) : null}

        <Checkbox
          onChange={({ target: { checked } }) => setUseAdvancedMode(checked)}
          value={useAdvancedMode}
          title="Расширенный режим поиска и отображения"
        />
      </div>

      <Table
        data={items}
        headerHeight={57}
        autoHeight
        rowKey="id"
        loading={isLoading}
        // @ts-ignore
        rowHeight={(rowData: Item) =>
          rowData?.rowHeight ? rowData?.rowHeight : 46
        }
      >
        {useAdvancedMode ? (
          <Table.Column width={50}>
            <Table.HeaderCell>id</Table.HeaderCell>
            <Table.Cell dataKey="id" />
          </Table.Column>
        ) : null}
        <Table.Column width={200}>
          <Table.HeaderCell>Дата</Table.HeaderCell>
          <Table.Cell dataKey="createdAt">
            {(rowData: Item) =>
              new Date(rowData.createdAt).toLocaleDateString('ru-RU', {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              })
            }
          </Table.Cell>
        </Table.Column>
        <Table.Column width={200}>
          <Table.HeaderCell>Кто менял</Table.HeaderCell>
          <Table.Cell dataKey="user.id">
            {(rowData: Item) => (
              <div>
                {rowData.user?.name} <br />
                {useAdvancedMode ? (
                  <>
                    {`real id: ${rowData.userId}`} <br />
                  </>
                ) : null}
                {rowData.user?.internalId
                  ? `id пользователя: ${rowData.user.internalId}`
                  : null}
              </div>
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={220}>
          <Table.HeaderCell>Что менял</Table.HeaderCell>
          <Table.Cell dataKey="object">
            {(rowData: Item) => (
              <div>
                {Objects[rowData.object as keyof typeof Objects] ||
                  rowData.object}{' '}
                <br />
                {useAdvancedMode ? (
                  <>
                    {`real id: ${rowData.objectId || '–'}`} <br />
                  </>
                ) : null}
                {`id объекта: ${rowData.objectInternalId || '–'}`} <br />
                {useAdvancedMode ? (
                  <>{`uuid: ${rowData.objectUuid || '–'}`}</>
                ) : null}
              </div>
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Действие</Table.HeaderCell>
          <Table.Cell dataKey="action">
            {(rowData: Item) => {
              return (
                <span
                  className={classNames({
                    'color-red': rowData.action === 'delete',
                    'fw-500': rowData.action === 'delete',
                  })}
                >
                  {rowData.action === 'insert' ? 'create' : rowData.action}
                </span>
              );
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={3}>
          <Table.HeaderCell>Список изменений</Table.HeaderCell>
          <Table.Cell dataKey="diff">
            {(rowData: Item) => {
              return rowData.diff;
            }}
          </Table.Cell>
        </Table.Column>
      </Table>
      {total > take && (
        <div className="mt-4 pb-4">
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="md"
            layout={['pager']}
            total={total}
            limitOptions={[10, 20]}
            limit={take}
            activePage={page}
            onChangePage={handleSetPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      )}
    </div>
  );
};
