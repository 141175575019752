import { ModalProps } from '../../../services/modals.service';
import { KeyedMutator } from 'swr';
import React from 'react';
import { useForm } from 'react-hook-form';
import { api } from '../../../services/api';
import { AlertService } from '../../../services/alert.service';
import { CleanUser } from '../../../export-types/cleaned-types';
import { useModalClose } from '../../../utils/useModalClose';

interface UnLinkUserModalProps extends ModalProps {
  user?: CleanUser;
  mutate?: KeyedMutator<any>;
}

export const UnLinkUserModal = ({
  user,
  close,
  mutate,
}: UnLinkUserModalProps) => {
  const { handleSubmit } = useForm();

  const onSubmit = handleSubmit(async () => {
    api.post(`/user/${user?.id}/unlink`).then(() => {
      AlertService.success();
      if (mutate) {
        mutate();
      }
      close();
    });
  });

  const modalRef = useModalClose(close);

  return (
    <div className="modal-dialog mw-400" ref={modalRef}>
      <div className="modal-content">
        <div className="modal-header border-bottom-0 p-4">
          <button type="button" className="btn-close" onClick={() => close()} />
        </div>
        <div className="modal-body pt-2 p-4">
          <div className="text-center mb-4 mx-auto">
            <h3>Вы хотите отвязать менеджера от этого клиента?</h3>
          </div>
          <form onSubmit={onSubmit}>
            <button type="submit" className="btn btn-success w-100 mb-3">
              Отвязать
            </button>
            <button
              type="button"
              className="btn btn-light w-100"
              onClick={() => close()}
            >
              Отменить
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
