import useSWR from 'swr';
import { CleanSupplier } from '../export-types/cleaned-types';
import { fetcher } from '../services/api';

export function useSuppliers(skipLoading?: boolean) {
  const { data, isLoading, mutate } = useSWR<Array<CleanSupplier>>(
    skipLoading ? null : '/supplier',
    fetcher,
  );
  return { data, isLoading, mutate };
}
