import React, { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Header } from '../../common/header.component';
import { PurchaseOrderStatus } from '../../common/purchase-order-status.component';
import { PurchaseOrderPurchaseConfirmationModal } from './purchase-order-purchase-confirmation-modal';
import { PurchaseOrderReceiptConfirmationModal } from './purchase-order-receipt-confirmation-modal';
import { ModalsService } from '../../../services/modals.service';
import { PurchaseOrderFinancesInfo } from './purchase-order-finances-info';
import useSWR from 'swr';
import { api, fetcher } from '../../../services/api';
import { PurchaseOrderRequest } from './purchase-order-request';
import { PurchaseOrderPurchaseConfirmation } from './purchase-order-purchase-confirmation';
import { ReceiptConfirmation } from './receipt-confirmation';
import { ClientInfo } from '../../common/client-info';
import { AdminOrderStatusTable } from './admin-order-status-table.component';
import { Rbac } from '../../common/Rbac';
import {
  CleanConversationType,
  CleanPurchaseOrder,
  RbacObject,
} from '../../../export-types/cleaned-types';
import { OrderTransactionsInfo } from './admin-order-transactions-info';
import { useProfile } from '../../../api/profile';
import { FormattedMessage, useIntl } from 'react-intl';
import { PurchaseOrderFreezeModal } from './purchase-order-freeze-modal';
import { ConfirmModal } from '../../common/confirm-modal';
import { SupplierInfo } from '../../common/SupplierInfo';
import { ConversationWindow } from '../../common/conversation-window';
import { Nav } from 'rsuite';
import { PurchaseOrderWriteoffModal } from './purchase-order-write-off-modal';
import { PurchaseOrderWriteOffs } from './purchase-order-write-offs';
import moment from 'moment';
import NotesInfo from '../../common/notes-info';
import { PurchaseOrderInfo } from './purchase-order-info';
import { PurchaseOrderEditOrderModal } from './purchase-order-edit-order-modal';
import { PurchaseOrderStepsInfo } from './purchase-order-steps-info';

type StatusType = CleanPurchaseOrder['status'];

const purchaseInfoStatuses: StatusType[] = [
  'onTheWayToStock',
  'receivedInStock',
  'sentToRussia',
];
const receivedInfoStatuses: StatusType[] = ['receivedInStock', 'sentToRussia'];

export const AdminPurchaseOrderViewScreen: React.FC = () => {
  const intl = useIntl();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = useMemo<CleanConversationType>(() => {
    const chat = searchParams.get('chat') || 'supplier';
    if (!['supplier', 'client'].includes(chat)) {
      return 'supplier';
    }
    return chat as CleanConversationType;
  }, [searchParams]);
  const { data: user } = useProfile();
  const { data: order, mutate: orderMutate } = useSWR<CleanPurchaseOrder>(
    `/purchase-order/${id}`,
    fetcher,
  );

  function freezeOrder() {
    if (!order) return;

    ModalsService.createModal(PurchaseOrderFreezeModal, {
      order: { id: order.id },
    }).then(() => orderMutate());
  }

  function unfreezeOrder() {
    ModalsService.createModal(ConfirmModal, {
      title: 'Разморозка заказа',
      buttonText: `Разморозить заказ`,
    }).then(() => {
      if (!order) return;
      api.post(`/purchase-order/${order.id}/unfreeze`).then(() => {
        orderMutate();
      });
    });
  }

  function writeOffOrder() {
    if (!order) return;

    ModalsService.createModal(PurchaseOrderWriteoffModal, {
      order: { id: order.id, currency: order.currency ?? 'cny' },
    }).then(() => orderMutate());
  }

  function unblock(status: 'receivedInStock' | 'receivedInRetailStock') {
    ModalsService.createModal(ConfirmModal, {
      title:
        status === 'receivedInStock'
          ? 'Отправить заказ стандартным путем'
          : 'Отправить как розница',
      buttonText: `Разблокировать заказ`,
    }).then(() => {
      if (!order) return;
      api.post(`/purchase-order/${order.id}/unblock`, { status }).then(() => {
        orderMutate();
      });
    });
  }
  function nextStep() {
    if (!order) return;

    switch (order.status) {
      case 'inWork':
        return ModalsService.createModal(
          PurchaseOrderPurchaseConfirmationModal,
          { order },
        ).then(() => orderMutate());
      case 'onTheWayToStock':
        return ModalsService.createModal(
          PurchaseOrderReceiptConfirmationModal,
          {
            orderId: order.id,
            internalId: order.internalId,
            userInternalId: order.user?.internalId,
          },
        ).then(() => orderMutate());
    }
  }

  function getBtnText() {
    if (!order) return;

    switch (order.status) {
      case 'inWork':
        return (
          <FormattedMessage
            defaultMessage="Подтвердить выкуп"
            id="purchaseOrderPage.status-label.confirmRedemption"
            description="Надпись на кнопке"
          />
        );

      case 'onTheWayToStock':
        return (
          <FormattedMessage
            defaultMessage="Подтвердить получение на склад"
            id="order.status-label.confirmReceiptToWarehouse"
            description="Подтверждение получения"
          />
        );
    }
  }

  function changeOrder() {
    if (!order) {
      return;
    }
    ModalsService.createModal(PurchaseOrderEditOrderModal, { order }).then(() =>
      orderMutate(),
    );
  }

  if (!order || !user) {
    return null;
  }

  function getAdditionalService() {
    if (!order) return;

    switch (order.status) {
      case 'inWork':
        return ModalsService.createModal(
          PurchaseOrderPurchaseConfirmationModal,
          { order },
        ).then(() => orderMutate());
      case 'onTheWayToStock':
        return ModalsService.createModal(
          PurchaseOrderReceiptConfirmationModal,
          { orderId: order.id, internalId: order.internalId },
        ).then(() => orderMutate());
    }
  }

  return (
    <div className="d-flex flex-column h-100">
      <Header>
        <div className="d-flex flex-row align-items-center">
          <h1 className="me-3 mb-0">
            {intl.formatMessage(
              {
                defaultMessage: 'Заказ на выкуп {value}',
                id: 'purchaseOrderPage.label.redemptionOrder',
                description: 'Заголовок на странице',
              },
              {
                value: 'V' + order.internalId,
              },
            )}
          </h1>
          <PurchaseOrderStatus order={order} />
        </div>
      </Header>
      <div className="d-flex flex-column flex-lg-row flex-grow-1 gap-4">
        <div className="col flex-grow-1">
          <Rbac object={RbacObject.Purchase} action={'read:extended-statuses'}>
            <div className="bg-gray rounded-8 mb-3">
              <SupplierInfo
                supplier={order.supplier?.name}
                purchaseOrderId={order.id}
              />
            </div>
          </Rbac>
          <div className="bg-gray p-3 pb-0 rounded-8 mb-3">
            <div className="fs-18 fw-600 color-gray-450 mb-3">
              <FormattedMessage
                defaultMessage="Статус заказа"
                id="purchaseOrderPage.label.orderStatus"
                description="Заголовок на странице"
              />
            </div>
            <AdminOrderStatusTable order={order} />
          </div>
          <div className="bg-gray p-3 rounded-8">
            <PurchaseOrderRequest id={order.id} />
            {(purchaseInfoStatuses.includes(order.status) ||
              order.receiptFilesV2.length > 0) && (
              <>
                <hr />
                <PurchaseOrderPurchaseConfirmation id={order.id} />
                <hr />
                <PurchaseOrderInfo id={order.id} />
              </>
            )}
            {(receivedInfoStatuses.includes(order.status) ||
              order.productFilesV2?.length > 0 ||
              order.category ||
              (order.weight && order.volume)) && (
              <>
                <hr />
                <ReceiptConfirmation id={order.id} />
              </>
            )}
            {order.additionalWriteOffs &&
              order.additionalWriteOffs?.length > 0 && (
                <>
                  <hr />
                  <PurchaseOrderWriteOffs id={order.id} />
                </>
              )}
          </div>
          <div className="bg-gray p-3 rounded-8 mt-3">
            <ClientInfo user={order.user!} />
          </div>
          <div className="bg-gray p-3 rounded-8 mt-3">
            <PurchaseOrderStepsInfo idClient={order.user?.internalId} />
          </div>
          <Rbac
            object={RbacObject.Purchase}
            action={['read:finances-client', 'read:finances-company']}
          >
            <div className="bg-gray p-3 rounded-8 mt-3">
              <PurchaseOrderFinancesInfo id={order.id} />
            </div>
          </Rbac>
          <Rbac object={RbacObject.Purchase} action={'read:transactions'}>
            <OrderTransactionsInfo type={'purchase-order'} id={order.id} />
          </Rbac>
          <Rbac object={RbacObject.Purchase} action={'read:notes'}>
            <NotesInfo type="purchase" id={order.id} />
          </Rbac>
        </div>
        <div className="col d-flex flex-column">
          <div className="flex d-flex mb-3 justify-content-center">
            {/*<Rbac*/}
            {/*  object={RbacObject.Transaction}*/}
            {/*  action={['write:additional-service']}*/}
            {/*>*/}
            {/*<div className="mx-1 d-flex flex-row justify-content-center">*/}
            {/*  <div className="btn btn-success" onClick={getAdditionalService}>*/}
            {/*    <FormattedMessage*/}
            {/*      defaultMessage="Добавить доп. расход"*/}
            {/*      id="purchaseOrderPage.btn-label.addExpense"*/}
            {/*      description="Надпись на кнопке"*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}

            {/*</Rbac>*/}
            <Rbac
              object={RbacObject.Purchase}
              action={['confirmPurchase', 'confirmReceipt']}
            >
              {!!getBtnText() && (
                <div className="mx-1 d-flex flex-row justify-content-center">
                  <div className="btn btn-success" onClick={nextStep}>
                    {getBtnText()} | <i className="bi bi-check-lg fs-18" />
                  </div>
                </div>
              )}
            </Rbac>
            {order.status === 'onTheWayToStock' && (
              <Rbac
                object={RbacObject.Purchase}
                action={['confirmPurchase', 'confirmReceipt']}
              >
                <div className="mx-1 d-flex flex-row justify-content-center">
                  <div
                    className="btn btn-success"
                    onClick={() => changeOrder()}
                  >
                    <FormattedMessage
                      defaultMessage="Изменить ордер"
                      id="purchaseOrderPage.btn-label.changeOrder"
                      description="Надпись на кнопке"
                    />
                  </div>
                </div>
              </Rbac>
            )}
            {order.status === 'inWork' && (
              <Rbac object={RbacObject.Purchase} action={['freeze']}>
                <div className="mx-1 d-flex flex-row justify-content-center">
                  <div className="btn btn-success" onClick={freezeOrder}>
                    <FormattedMessage
                      defaultMessage="Заморозить"
                      id="purchaseOrderPage.btn-label.toFreeze"
                      description="Надпись на кнопке"
                    />
                  </div>
                </div>
              </Rbac>
            )}
            {order.status === 'frozen' && (
              <Rbac object={RbacObject.Purchase} action={['unfreeze']}>
                <div className="mx-1 d-flex flex-row justify-content-center">
                  <div className="btn btn-success" onClick={unfreezeOrder}>
                    <FormattedMessage
                      defaultMessage="Разморозить"
                      id="purchaseOrderPage.btn-label.unfreeze"
                      description="Надпись на кнопке"
                    />
                  </div>
                </div>
              </Rbac>
            )}
            {order.sendBlocked && (
              <Rbac
                object={RbacObject.Purchase}
                action={['confirmPurchase', 'confirmReceipt']}
              >
                <div className="mx-1 d-flex flex-row justify-content-center">
                  <div
                    className="btn btn-success"
                    onClick={() => unblock('receivedInRetailStock')}
                  >
                    <FormattedMessage
                      defaultMessage="Отправить как розница"
                      id="purchaseOrderPage.btn-label.SendRetail"
                      description="Надпись на кнопке"
                    />
                  </div>
                </div>
              </Rbac>
            )}
            {order.sendBlocked && (
              <Rbac
                object={RbacObject.Purchase}
                action={['confirmPurchase', 'confirmReceipt']}
              >
                <div className="mx-1 d-flex flex-row justify-content-center">
                  <div
                    className="btn btn-success"
                    onClick={() => unblock('receivedInStock')}
                  >
                    <FormattedMessage
                      defaultMessage="Отправка стандартным путем"
                      id="purchaseOrderPage.btn-label.shippingStandardRoute"
                      description="Надпись на кнопке"
                    />
                  </div>
                </div>
              </Rbac>
            )}
            {((order.deliveryOrder &&
              moment().diff(moment(order.deliveryOrder.createdAt), 'day') <=
                10) ||
              (order.cancelledAt &&
                moment().diff(moment(order.cancelledAt), 'day') <= 10) ||
              !['waitingForPayment', 'canceled', 'sentToRussia'].includes(
                order.status,
              )) && (
              <Rbac
                object={RbacObject.Purchase}
                action={['write:additionalWriteOff']}
              >
                <div className="mx-1 d-flex flex-row justify-content-center">
                  <div className="btn btn-success" onClick={writeOffOrder}>
                    <FormattedMessage
                      defaultMessage="Добавить списание"
                      id="purchaseOrderPage.btn-label.writeOff"
                      description="Надпись на кнопке"
                    />
                  </div>
                </div>
              </Rbac>
            )}
          </div>
          {!user.accesses.includes('fulfillment') ? (
            <div className="flex-grow-1 d-flex mb-4 flex-column">
              <Nav
                appearance="tabs"
                activeKey={tab}
                onSelect={(key) => setSearchParams({ chat: key })}
              >
                <Nav.Item eventKey="supplier" className="bg-gray-50">
                  <FormattedMessage
                    defaultMessage="Поставщик"
                    id="TransactiosHistrory.lable.supplier"
                    description="Вкладка"
                  />
                </Nav.Item>
                {!user.supplier && (
                  <Nav.Item eventKey="client" className="bg-teal-100">
                    <FormattedMessage
                      defaultMessage="Клиент"
                      id="clientInfo.label.customer"
                      description="Заголовок на странице"
                    />
                  </Nav.Item>
                )}
              </Nav>
              <ConversationWindow
                id={order.id}
                type="purchase"
                internalId={order.internalId}
                supplier={order.supplier}
                client={order.user}
                tab={tab}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
