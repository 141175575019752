import moment, { Moment, MomentInput } from 'moment/moment';

export const minDateValue = moment.min;
export const maxDateValue = moment.max;

export type DateValue = Moment;

export type RawDateValue =
  | `${number}.${number}.${number}`
  | `${number}-${number}-${number}`;

export function getCurrentDateValue(): Moment {
  return moment.utc();
}

export function parseRawDateValue(
  value: RawDateValue | string,
  format = 'DD.MM.YYYY',
): Moment {
  return moment.utc(value, format);
}

export function formatDateValue(
  value: MomentInput,
  format = 'DD.MM.YYYY',
): RawDateValue {
  return moment.utc(value).format(format) as RawDateValue;
}

export function formatCalendar(value: MomentInput): string {
  const date = moment(value);
  return date.startOf('day').calendar(null, {
    lastDay: '[Вчера]',
    sameDay: '[Сегодня]',
    nextDay: '[Завтра]',
    lastWeek: function (m) {
      const now = moment(m);
      if (now?.week() !== date.week()) {
        switch (date.day()) {
          case 0:
            return '[Прошлое] dddd';
          case 1:
          case 2:
          case 4:
            return '[Прошлый] dddd';
          case 3:
          case 5:
          case 6:
            return '[Прошлая] dddd';
          default:
            return '';
        }
      } else {
        if (date.day() === 2) {
          return '[Во] dddd';
        } else {
          return '[В] dddd';
        }
      }
    },
    nextWeek: 'dddd',
    sameElse: 'L',
  });
}
