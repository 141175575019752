import React, { useMemo, useState } from 'react';
import Table, { CellProps } from 'rsuite/Table';
import { ToggleCell } from '../../common/table/toggle-cell';
import { AlertService } from '../../../services/alert.service';
import Whisper from 'rsuite/Whisper';
import IconButton from 'rsuite/IconButton';
import { WhisperSpeaker } from '../../../utils/whisper-speaker';
import Popover from 'rsuite/Popover';
import Dropdown from 'rsuite/Dropdown';
import { ModalsService } from '../../../services/modals.service';
import { usePaginationQs } from '../../../utils/usePagination';
import Pagination from 'rsuite/Pagination';
import MyLoader from '../../common/loader.component';
import { RouterOutput, trpcSwr } from '../../../api/trpc';
import { AddOtcRecommendation } from './AddOtcRecommendation';
import { ImagesCell } from '../../common/cards/images-cell-card.component';
import Toggle from 'rsuite/Toggle';
import { Tooltip } from 'rsuite';

export type Item =
  RouterOutput['otcRecommendationAdmin']['list']['items'][number];

export const OtcRecommendationTab = () => {
  const { take, skip, handleSetPage, handleChangeLimit, page } =
    usePaginationQs(10);

  const [directionIdAsc, setDirectionIdAsc] = useState<'asc' | undefined>();

  const { data, mutate, isLoading, error } =
    trpcSwr.otcRecommendationAdmin.list.useSWR({
      take,
      skip,
      orderBy: directionIdAsc ? { id: 'asc' } : undefined,
    });

  const hasEnoughBoost = useMemo(() => {
    if (data?.items) {
      const boostNotNullCount = data.items.filter(
        (item) => item.boost !== null,
      ).length;
      return boostNotNullCount < 2;
    }
  }, [data?.items]);

  const { trigger } = trpcSwr.otcRecommendationAdmin.update.useSWRMutation();

  async function toggleDisabled(rowData: Item, value: boolean) {
    await trigger({ id: rowData.id, data: { disabled: value } });
    await mutate();

    AlertService.success();
  }

  const renderMenu =
    (item: Item, hasEnoughBoost?: boolean): WhisperSpeaker =>
    ({ onClose, left, top, className }, ref) => {
      const handleSelect = (eventKey: number | string | undefined) => {
        onClose();
        if (eventKey === 'edit') {
          ModalsService.createModal(AddOtcRecommendation, {
            item,
            hasEnoughBoost,
          }).then(() => mutate());
        }
      };

      return (
        <Popover ref={ref} className={className} style={{ left, top }} full>
          <Dropdown.Menu onSelect={handleSelect}>
            <Dropdown.Item eventKey={'edit'}>
              <i className="bi bi-pencil" />
              <span className="ps-2">Изменить</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Popover>
      );
    };

  const ActionCell = ({ rowData, dataKey, ...props }: CellProps<Item>) => {
    return (
      rowData && (
        <Table.Cell
          {...props}
          className="link-group no-padding-cell"
          align="right"
        >
          <Whisper
            placement="leftStart"
            trigger="click"
            speaker={renderMenu(rowData, hasEnoughBoost)}
          >
            <IconButton
              appearance="subtle"
              icon={<i className="bi bi-three-dots" />}
            />
          </Whisper>
        </Table.Cell>
      )
    );
  };

  const ItemDeleted = ({ rowData, dataKey, ...props }: CellProps<Item>) => {
    const { data: item } =
      trpcSwr.otcommerce.BatchGetSimplifiedItemFullInfo.useSWR({
        itemId: rowData ? rowData.objectId : '',
      });

    const itemNotFoundTooltip = (
      <Tooltip className="fs-14 text-start lh-sm p-2" style={{ maxWidth: 380 }}>
        {item?.ErrorCode}
      </Tooltip>
    );

    if (item && item.ErrorCode !== 'Ok') {
      return (
        <Table.Cell {...props}>
          <Whisper
            placement="right"
            trigger="hover"
            speaker={itemNotFoundTooltip}
          >
            <i className="bi bi-exclamation-diamond-fill text-danger"></i>
          </Whisper>
        </Table.Cell>
      );
    }

    return rowData && <Table.Cell {...props}></Table.Cell>;
  };

  if (isLoading) {
    return (
      <>
        <div className="pb-4">
          <MyLoader />
        </div>
      </>
    );
  }

  if (error || !data) {
    return (
      <>
        <div className="pb-4">Неизвестная ошибка</div>
      </>
    );
  }

  const sortHandleChange = () => {
    setDirectionIdAsc(directionIdAsc === 'asc' ? undefined : 'asc');
  };
  return (
    <div>
      <div className="d-flex justify-content-end pb-4">
        <div className="d-flex ">
          <Toggle
            onChange={sortHandleChange}
            checked={directionIdAsc === 'asc'}
          />
          <div className="ms-2">Сортировка по ID</div>
        </div>
      </div>
      <Table
        loading={isLoading}
        data={data.items}
        headerHeight={57}
        rowHeight={74}
        autoHeight
        rowKey="id"
      >
        <Table.Column width={50}>
          <Table.HeaderCell>Id</Table.HeaderCell>
          <Table.Cell dataKey="id" />
        </Table.Column>
        <Table.Column width={50}>
          <Table.HeaderCell> </Table.HeaderCell>
          <ItemDeleted />
        </Table.Column>
        <Table.Column width={200}>
          <Table.HeaderCell>Артикул</Table.HeaderCell>
          <Table.Cell dataKey="objectId" />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Фото</Table.HeaderCell>
          <Table.Cell>
            {({ image }: Item) =>
              image ? (
                <ImagesCell imagesList={[image]} width={48} height={48} />
              ) : null
            }
          </Table.Cell>
        </Table.Column>
        <Table.Column width={150}>
          <Table.HeaderCell>Название товара</Table.HeaderCell>
          <Table.Cell dataKey="name" />
        </Table.Column>
        <Table.Column width={100}>
          <Table.HeaderCell>Просмотры</Table.HeaderCell>
          <Table.Cell>
            {({ views }: Item) => (views ? `${views}` : null)}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={100}>
          <Table.HeaderCell>Клики</Table.HeaderCell>
          <Table.Cell>
            {({ clicks }: Item) => (clicks ? `${clicks}` : null)}
          </Table.Cell>
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>CTR%</Table.HeaderCell>
          <Table.Cell>
            {({ ctr }: Item) => (ctr ? (ctr * 100).toFixed(6) : null)}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={150}>
          <Table.HeaderCell>Сумма выплаты</Table.HeaderCell>
          <Table.Cell dataKey="price" />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Выкуп</Table.HeaderCell>
          <Table.Cell>
            {({ approve }: Item) => (approve ? `${approve}%` : null)}
          </Table.Cell>
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Вес%</Table.HeaderCell>
          <Table.Cell>
            {({ weight }: Item) => (weight ? weight.toFixed(6) : null)}
          </Table.Cell>
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Разгон</Table.HeaderCell>
          <Table.Cell>
            {({ boost }: Item) => (boost ? `${boost}` : null)}
          </Table.Cell>
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Включен</Table.HeaderCell>
          <ToggleCell
            dataKey="disabled"
            inverted={true}
            onChange={toggleDisabled}
          />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Категория</Table.HeaderCell>
          <Table.Cell dataKey="tags" />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Склад</Table.HeaderCell>
          <Table.Cell dataKey="stock" />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell> </Table.HeaderCell>
          <ActionCell />
        </Table.Column>
      </Table>

      {data.total > take && (
        <Pagination
          className="mt-2 pb-4"
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={['pager']}
          total={data.total}
          limit={take}
          activePage={page}
          onChangePage={handleSetPage}
          onChangeLimit={handleChangeLimit}
        />
      )}
    </div>
  );
};
