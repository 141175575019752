import React from 'react';
import { toMoney } from '../../../utils/common.utils';
import { useBalance } from '../../../api/finances';
import { CleanMoneyBill } from '../../../export-types/cleaned-types';
import { useCurrencyCurrent } from '../../../services/currency.service';

function renderBalance(key: string | number, moneyBill: CleanMoneyBill) {
  return (
    <div key={key}>
      <div className="row">
        <div className="col-auto">
          <div className="p-3 rounded score-item cny d-flex flex-column justify-content-between mw-200">
            <div className="d-flex flex-row justify-content-between">
              <div className="fs-14">CNY</div>
              <div className="score-item__sign">¥</div>
            </div>
            <div className="text-start fs-24 text-black">
              {toMoney(moneyBill.cny, 'cny')}
            </div>
          </div>
        </div>
        <div className="col-auto">
          <div className="p-3 rounded score-item usd d-flex flex-column justify-content-between mw-200">
            <div className="d-flex flex-row justify-content-between">
              <div className="fs-14">USD</div>
              <div className="score-item__sign">$</div>
            </div>
            <div className="text-start fs-24 text-black">
              {toMoney(moneyBill.usd)} $
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const MoneyBillsComponent = () => {
  const { data: balance } = useBalance();
  const { data: currencies } = useCurrencyCurrent();

  if (!balance) {
    return null;
  }

  return (
    <div className="d-flex gap-4 mb-5">
      {renderBalance('common', balance)}
      <div className="col-auto">
        <div className="d-flex gap-2">
          <div className="score-item rounded-icon cny">¥</div>
          <p>
            CNY/RUB Поставщик -{' '}
            {toMoney(currencies?.cnyRubExternal as string, 'rub')}
          </p>
        </div>
        <div className="d-flex gap-2">
          <div className="score-item rounded-icon cny">¥</div>
          <p>
            CNY/RUB Сайт {toMoney(currencies?.cnyRubInternal as string, 'rub')}
          </p>
        </div>
      </div>
      <div className="col-auto">
        <div className="d-flex gap-2">
          <div className="score-item rounded-icon usd">$</div>
          <p>
            USD/RUB Поставщик{' '}
            {toMoney(currencies?.usdRubExternal as string, 'rub')}
          </p>
        </div>
        <div className="d-flex gap-2">
          <div className="score-item rounded-icon usd">$</div>
          <p>
            USD/RUB Сайт {toMoney(currencies?.usdRubInternal as string, 'rub')}
          </p>
        </div>
      </div>
    </div>
  );
};
