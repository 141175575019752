import { createSWRProxyHooks } from '@trpc-swr/client';
import type { AppRouter } from '../export-types/trpc-server';
import {
  CreateTRPCClientOptions,
  createTRPCProxyClient,
  httpBatchLink,
} from '@trpc/client';
import superjson from 'superjson';
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server';
import { Decimal } from 'decimal.js';

superjson.registerCustom<Decimal, string>(
  {
    isApplicable: (v): v is Decimal => Decimal.isDecimal(v),
    serialize: (v) => v.toJSON(),
    deserialize: (v) => new Decimal(v),
  },
  'decimal.js',
);

const config: CreateTRPCClientOptions<AppRouter> = {
  links: [
    httpBatchLink({
      url: '/api/trpc',
    }),
  ],
  transformer: superjson,
};

export const trpcSwr = createSWRProxyHooks<AppRouter>(config);

export const trpc = createTRPCProxyClient<AppRouter>(config);

export type RouterInput = inferRouterInputs<AppRouter>;
export type RouterOutput = inferRouterOutputs<AppRouter>;
