import React from 'react';
import Tooltip from 'rsuite/Tooltip';
import Whisper from 'rsuite/Whisper';

type BlockStatusProps = {
  blocked: boolean;
  label?: string;
  className: string;
};

export const BlockStatus = ({
  blocked,
  className,
  label,
}: BlockStatusProps) => {
  const tooltip = blocked ? (
    <Tooltip>{label ? label : 'Заблокирован'}</Tooltip>
  ) : (
    <Tooltip>В пути</Tooltip>
  );

  let color = blocked ? 'red' : 'green';

  return (
    <Whisper placement="top" trigger="hover" speaker={tooltip}>
      <div
        className={`d-flex justify-content-center align-items-center ${className} mark-dot ${color}`}
      >
        <i className="bi bi-lock fs-10  text-white text-center"> </i>
      </div>
    </Whisper>
  );
};
