import React, { useState } from 'react';
import Table, { CellProps } from 'rsuite/Table';
import { ToggleCell } from '../../common/table/toggle-cell';
import { AlertService } from '../../../services/alert.service';
import Whisper from 'rsuite/Whisper';
import IconButton from 'rsuite/IconButton';
import { WhisperSpeaker } from '../../../utils/whisper-speaker';
import Popover from 'rsuite/Popover';
import Dropdown from 'rsuite/Dropdown';
import MyLoader from '../../common/loader.component';
import { RouterOutput, trpcSwr } from '../../../api/trpc';
import { ImagesCell } from '../../common/cards/images-cell-card.component';
import { useNavigate } from 'react-router-dom';
import { PRODUCT_CATEGORIES } from './ProductData';
import { getCurrencySymbol } from '../../../utils/common.utils';
import { Decimal } from 'decimal.js';
import Pagination from 'rsuite/Pagination';
import { usePaginationQs } from '../../../utils/usePagination';

export type Item =
  RouterOutput['productCardPoizonAdmin']['list']['items'][number];

export const PoizonTab = () => {
  const { take, skip, handleSetPage, handleChangeLimit, page } =
    usePaginationQs(10);

  const { data, error, isLoading, mutate } =
    trpcSwr.productCardPoizonAdmin.list.useSWR(
      {
        take,
        skip,
      },
      {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      },
    );

  const { trigger } = trpcSwr.productCardPoizonAdmin.update.useSWRMutation();

  async function toggleDisabled(rowData: Item, value: boolean) {
    await trigger({ id: rowData.id, data: { disabled: value } });
    await mutate();

    AlertService.success();
  }
  const navigate = useNavigate();
  const renderMenu =
    (item: Item): WhisperSpeaker =>
    ({ onClose, left, top, className }, ref) => {
      const handleSelect = (eventKey: number | string | undefined) => {
        onClose();
        if (eventKey === 'edit') {
          navigate(`/poizon/card`, {
            state: {
              productId: item.productId,
              modificationId: item.modificationId,
            },
          });
        }
      };

      return (
        <Popover ref={ref} className={className} style={{ left, top }} full>
          <Dropdown.Menu onSelect={handleSelect}>
            <Dropdown.Item eventKey={'edit'}>
              <i className="bi bi-pencil" />
              <span className="ps-2">Изменить</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Popover>
      );
    };

  const ActionCell = ({ rowData, dataKey, ...props }: CellProps<Item>) => {
    return (
      rowData && (
        <Table.Cell
          {...props}
          className="link-group no-padding-cell"
          align="right"
        >
          <Whisper
            placement="leftStart"
            trigger="click"
            speaker={renderMenu(rowData)}
          >
            <IconButton
              appearance="subtle"
              icon={<i className="bi bi-three-dots" />}
            />
          </Whisper>
        </Table.Cell>
      )
    );
  };

  const ArticleCell = ({ rowData, dataKey, ...props }: CellProps<Item>) => {
    return (
      rowData && (
        <Table.Cell {...props}>
          {rowData.productId}-{rowData.modificationId}
        </Table.Cell>
      )
    );
  };

  const CategoryCell = ({ rowData, dataKey, ...props }: CellProps<Item>) => {
    const categoryLabel =
      rowData &&
      PRODUCT_CATEGORIES.find(
        (category) => category.value === rowData.category?.name,
      )?.label;

    return <Table.Cell {...props}>{categoryLabel}</Table.Cell>;
  };
  const SizeCell = ({ rowData, dataKey, ...props }: CellProps<Item>) => {
    if (!rowData) {
      return null;
    }
    const sizes = rowData.sizesAndPrices
      .map((item) => item.brandSize?.originalSize)
      .join(', ');

    return <Table.Cell {...props}>{sizes}</Table.Cell>;
  };
  const CurrencyCell = ({ rowData, dataKey, ...props }: CellProps<Item>) => {
    return (
      rowData && (
        <Table.Cell {...props}>
          {getCurrencySymbol(rowData.currency)}
        </Table.Cell>
      )
    );
  };

  const PriceCell = ({ rowData, dataKey, ...props }: CellProps<Item>) => {
    if (
      !rowData ||
      !rowData.sizesAndPrices ||
      rowData.sizesAndPrices.length === 0
    ) {
      return null;
    }

    const sizesAndPrices = rowData.sizesAndPrices;

    const minPrice = sizesAndPrices.reduce((min, obj) => {
      if (obj.priceProduct && obj.priceProduct < min) {
        return obj.priceProduct;
      }
      return min;
    }, sizesAndPrices[0].priceProduct);

    return (
      <Table.Cell {...props}>{new Decimal(minPrice).toFixed(2)}</Table.Cell>
    );
  };

  if (isLoading) {
    return (
      <>
        <div className="pb-4">
          <MyLoader />
        </div>
      </>
    );
  }

  if (error || !data) {
    return (
      <>
        <div className="pb-4">Неизвестная ошибка</div>
      </>
    );
  }

  return (
    <div>
      <Table
        loading={isLoading}
        data={data.items}
        headerHeight={57}
        rowHeight={74}
        autoHeight
        rowKey="id"
      >
        <Table.Column width={50}>
          <Table.HeaderCell>Id</Table.HeaderCell>
          <Table.Cell dataKey="id" />
        </Table.Column>
        <Table.Column width={200} fullText>
          <Table.HeaderCell>Оригинальный артикул </Table.HeaderCell>
          <Table.Cell dataKey="originalId" />
        </Table.Column>
        <Table.Column width={200} fullText>
          <Table.HeaderCell>Внутренний артикул</Table.HeaderCell>
          <ArticleCell />
        </Table.Column>
        <Table.Column width={150} fullText>
          <Table.HeaderCell>Фото обложки</Table.HeaderCell>
          <Table.Cell>
            {({ cover }: Item) =>
              cover ? (
                <ImagesCell imagesList={[cover]} width={48} height={48} />
              ) : null
            }
          </Table.Cell>
        </Table.Column>
        <Table.Column width={150} fullText>
          <Table.HeaderCell>Название товара</Table.HeaderCell>
          <Table.Cell dataKey="name" />
        </Table.Column>
        <Table.Column width={150} fullText>
          <Table.HeaderCell>Бренд</Table.HeaderCell>
          <Table.Cell dataKey="brand.name" />
        </Table.Column>
        <Table.Column width={150} fullText>
          <Table.HeaderCell>Оригинальные размеры</Table.HeaderCell>
          <SizeCell />
        </Table.Column>
        <Table.Column width={150} fullText>
          <Table.HeaderCell>Стоимость от</Table.HeaderCell>
          <PriceCell />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Валюта</Table.HeaderCell>
          <CurrencyCell />
        </Table.Column>
        <Table.Column width={150} fullText>
          <Table.HeaderCell>Категория</Table.HeaderCell>
          <CategoryCell />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Включен</Table.HeaderCell>
          <ToggleCell
            dataKey="disabled"
            inverted={true}
            onChange={toggleDisabled}
          />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell> </Table.HeaderCell>
          <ActionCell />
        </Table.Column>
      </Table>

      {data.total > take && (
        <Pagination
          className="mt-2 pb-4"
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={['pager']}
          total={data.total}
          limit={take}
          activePage={page}
          onChangePage={handleSetPage}
          onChangeLimit={handleChangeLimit}
        />
      )}
    </div>
  );
};
