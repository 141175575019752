import { Controller, useForm } from 'react-hook-form';
import { Input } from '../../common/input.component';
import { required } from '../../../utils/validations';
import { getCurrencySymbol } from '../../../utils/common.utils';
import React, { useMemo } from 'react';
import { ModalProps } from '../../../services/modals.service';
import { CleanPurchaseOrderDeliveryTariff } from '../../../export-types/cleaned-types';
import { useSuppliers } from '../../../api/suppliers';
import { Select } from '../../common/select.component';
import { useModalClose } from '../../../utils/useModalClose';

type Props = ModalProps & {
  title: string;
  tariff?: CleanPurchaseOrderDeliveryTariff;
};

type FieldProps = {
  priceTo: number | string;
  markup: number | string;
  supplierId: number;
};

export const PurchaseOrderDeliveryTariffModal: React.FC<Props> = ({
  close,
  reject,
  tariff,
  title,
}) => {
  const { data: suppliers } = useSuppliers();

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<FieldProps>({
    defaultValues: { ...tariff, supplierId: tariff?.supplier?.id },
  });

  const supplierId = watch('supplierId');
  const currencySymbol = useMemo(() => {
    if (!suppliers || !supplierId) {
      return;
    }
    const supplier = suppliers.find((supplier) => supplier.id === supplierId);

    return getCurrencySymbol(supplier?.purchaseCurrency || 'cny');
  }, [suppliers, supplierId]);

  function onSave(data: FieldProps) {
    close(data);
  }

  const modalRef = useModalClose(reject);

  return (
    <div className="modal-dialog" ref={modalRef}>
      <form className="modal-content" onSubmit={handleSubmit(onSave)}>
        <div className="modal-body">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h3>{title}</h3>
            <div className="btn p-0" onClick={reject}>
              <i className="bi bi-x fs-36 color-gray-600" />
            </div>
          </div>
          <Controller
            name="supplierId"
            control={control}
            rules={required}
            render={({ field }) => (
              <Select
                className="mb-3"
                title="Поставщик"
                placeholder="Выберите поставщика"
                options={
                  suppliers?.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  })) ?? []
                }
                {...field}
                error={errors.supplierId?.message}
              />
            )}
          />
          <h3 className="fs-18">Условия</h3>
          <div className="d-flex flex-row align-items-end">
            <Input
              title="Сумма заказа до"
              className="flex-grow-1"
              {...register(`priceTo`, {
                ...required,
              })}
              type="number"
              min={0}
              step={0.01}
              error={errors.priceTo?.message}
              postfix={currencySymbol}
            />
            <div className="ps-2 pe-2" />
            <Input
              title="Стоимость доставки"
              className="flex-grow-1"
              {...register(`markup`, {
                ...required,
              })}
              type="number"
              min={0}
              step={0.01}
              error={errors.markup?.message}
              postfix="%"
            />
          </div>
        </div>
        <div className="modal-footer justify-content-end">
          <button type="submit" className="btn btn-success">
            Сохранить
          </button>
        </div>
      </form>
    </div>
  );
};
