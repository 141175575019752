import React from 'react';
import Popover from 'rsuite/Popover';
import Dropdown from 'rsuite/Dropdown';
import Whisper from 'rsuite/Whisper';
import { CleanPurchaseOrder } from '../../../export-types/cleaned-types';

type OrderFinancesAdditionalProps = {
  onEdit: (type: 'client' | 'company') => void;
  orderType: 'purchase-order' | 'delivery-order';
};

export const OrderFinancesAdditional: React.FC<OrderFinancesAdditionalProps> = (
  props,
) => {
  const speaker = ({ onClose, className }: any, ref: React.Ref<any>) => (
    <Popover ref={ref} className={className} full>
      <Dropdown.Menu>
        <Dropdown.Item
          onSelect={function () {
            onClose();
            props.onEdit('client');
          }}
        >
          <span>Доп. расходы клиента</span>
        </Dropdown.Item>

        <Dropdown.Item
          onSelect={function () {
            onClose();
            props.onEdit('company');
          }}
        >
          Доп. расходы поставщика
        </Dropdown.Item>
      </Dropdown.Menu>
    </Popover>
  );

  return (
    <Whisper placement="bottomEnd" trigger="click" speaker={speaker}>
      <button className="btn btn-link text-decoration-none" type="button">
        Изменить
      </button>
    </Whisper>
  );
};
