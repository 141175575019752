import React from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { required } from '../../../utils/validations';
import { InputWrapper } from '../../common/input-wrapper';
import { fetcher } from '../../../services/api';
import { AlertService } from '../../../services/alert.service';
import { RouterOutput, trpcSwr } from '../../../api/trpc';

import { Select } from '../../common/select.component';

import { PRODUCT_CATEGORIES } from './ProductData';
import { useNavigate } from 'react-router-dom';
import { AddButton } from '../../common/add-button';
import { useGlobalMutate } from '../../../api/useGlobalMutate';
import { useBrands } from './useBrandsList';
import CreatableSelect from 'react-select/creatable';

type BrandSizesType = {
  id?: number;
  size: string;
  originalSize: string;
  footLength?: string;
};
type BrandName = {
  value?: number;
  label: string;
  __isNew__?: boolean;
};
type Form = {
  name: BrandName;
  category: string;
  brandSizes: BrandSizesType[];
};
type BrandFileItemType = RouterOutput['brandAdmin']['itemInfo'];

type BrandFileProps = {
  itemInfo?: BrandFileItemType | null;
  brandId?: number;
};

export const BrandFile = ({ itemInfo, brandId }: BrandFileProps) => {
  const navigate = useNavigate();
  const { mutate } = useGlobalMutate();

  const { data: brands } = useBrands();
  const brandNames =
    brands?.items.map((item) => ({
      name: item.name,
      id: item.id,
    })) || [];

  const defaultItemValues =
    itemInfo && !brandId
      ? {
          name: { value: itemInfo.brand?.id, label: itemInfo.brand?.name },
          category: itemInfo.name,
          brandSizes: itemInfo.brandSize.map((item) => ({
            id: item.id,
            size: item.size,
            originalSize: item.originalSize || '',
            footLength: item.footLength || '',
          })),
        }
      : itemInfo && brandId
        ? {
            name: { value: itemInfo.brand?.id, label: itemInfo.brand?.name },
            brandSizes: [
              {
                size: '',
                originalSize: '',
              },
            ],
          }
        : {
            brandSizes: [
              {
                size: '',
                originalSize: '',
              },
            ],
          };

  const {
    formState: { isSubmitting },
    handleSubmit,
    formState,
    control,
    watch,
  } = useForm<Form>({
    defaultValues: defaultItemValues,
  });

  const [category, name] = watch(['category', 'name']);
  const selectedCategory = PRODUCT_CATEGORIES.find(
    (categoryItem) => categoryItem.value === category,
  );

  const selectedSizes = selectedCategory ? selectedCategory.sizes : [];
  const selectedOriginalSizes = selectedCategory
    ? selectedCategory.originalSizes
    : [];

  const { trigger: triggerUpdate, error: updateError } =
    trpcSwr.brandAdmin.update.useSWRMutation({
      fetcher,
    });
  const { trigger: triggerCreate, error: createError } =
    trpcSwr.brandAdmin.create.useSWRMutation({
      fetcher,
    });

  if (createError) {
    AlertService.error(createError.message);
  }

  if (updateError) {
    AlertService.error(updateError.message);
  }

  const onSubmit = handleSubmit(async (form) => {
    const brandSizesData = form.brandSizes.map((item) => {
      return {
        id: item.id || null,
        size: item.size,
        originalSize: item.originalSize,
        footLength: item.footLength,
      };
    });

    const categoryData = {
      brandId: !form.name.__isNew__ ? form.name.value : undefined,
      name: form.category,
      brandSize: brandSizesData,
    };

    const data = {
      id: !form.name.__isNew__ ? form.name.value : null,
      name: form.name.label,
      category: categoryData,
    };
    if (itemInfo && !brandId) {
      await triggerUpdate({
        categoryId: itemInfo.id,
        data: data,
      });
      mutate();
    } else {
      await triggerCreate(data);
    }
    AlertService.success();
    navigate('/marketing/brands');
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'brandSizes',
  });

  return (
    <>
      <div className="container pb-5" style={{ marginTop: -70 }}>
        <h1 className="mb-4">
          {itemInfo ? 'Редактировать' : 'Добавить'} бренд и размеры
        </h1>
        <form onSubmit={onSubmit}>
          <InputWrapper
            theme="light"
            className="mt-3"
            title="Название бренда"
            required
            error={formState.errors.name?.message}
          >
            <Controller
              name="name"
              control={control}
              rules={{
                ...required,
              }}
              render={({ field }) => (
                <>
                  <CreatableSelect
                    {...field}
                    options={brandNames.map((brand) => ({
                      value: brand.id,
                      label: brand.name,
                    }))}
                    placeholder="Выберите бренд"
                    formatCreateLabel={(value) => `Добавить "${value}"`}
                  />
                  <div className="fs-14 color-gray-400 mt-1">
                    Выберите бренд из существующих или создайте новый
                  </div>
                </>
              )}
            />
          </InputWrapper>

          <Controller
            control={control}
            name="category"
            rules={{ ...required }}
            render={({ field, fieldState }) => (
              <Select
                {...field}
                options={PRODUCT_CATEGORIES}
                title="Категория товаров"
                className="mt-3"
                required
                placeholder="Выберите категорию"
                error={fieldState.error?.message}
              />
            )}
          />

          <h3 className="mt-3">Размерная сетка</h3>
          {fields.map((field, index) => (
            <div
              className="col-12 mb-3 mt-3 d-flex justify-content-between"
              key={field.id}
            >
              <div className="col-10">
                <div className="rs-text-green">№{index + 1}</div>
                <Controller
                  name={`brandSizes.${index}.originalSize`}
                  control={control}
                  rules={{ ...required }}
                  render={({ field, fieldState }) => (
                    <Select
                      {...field}
                      className="mt-3"
                      required
                      error={fieldState.error?.message}
                      options={selectedOriginalSizes}
                      title="Оригинальный размер"
                      placeholder="Укажите размер производителя"
                    />
                  )}
                />

                <Controller
                  control={control}
                  rules={{ ...required }}
                  name={`brandSizes.${index}.size`}
                  render={({ field, fieldState }) => (
                    <Select
                      {...field}
                      className="mt-3"
                      required
                      error={fieldState.error?.message}
                      options={selectedSizes}
                      title="Российский размер"
                      placeholder="Укажите российский размер"
                    />
                  )}
                />

                {selectedCategory && selectedCategory.value === 'shoes' ? (
                  <Controller
                    control={control}
                    name={`brandSizes.${index}.footLength`}
                    rules={{ ...required }}
                    render={({ field, fieldState }) => (
                      <InputWrapper
                        title="Длина стопы"
                        className="mt-3"
                        required
                        error={fieldState.error?.message}
                      >
                        <div className="input-group">
                          <input
                            className="form-control"
                            type="number"
                            min={0}
                            step={0.1}
                            {...field}
                          />
                          <span className="input-group-text">см</span>
                        </div>
                      </InputWrapper>
                    )}
                  />
                ) : null}
              </div>
              <div className="d-flex flex-row align-items-center">
                <i
                  className="bi bi-trash p-2 cursor-pointer hover-red"
                  onClick={() => remove(index)}
                />
              </div>
            </div>
          ))}
          <div className="mb-3">
            <AddButton
              onClick={() =>
                append({
                  size: '',
                  originalSize: '',
                  footLength: '',
                })
              }
            >
              Добавить размер
            </AddButton>
          </div>

          <div className="col-12 pt-3 mt-5">
            <button
              type="submit"
              className="btn btn-success w-100 mb-5"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : itemInfo ? (
                'Сохранить'
              ) : (
                'Добавить'
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
