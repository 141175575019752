import React from 'react';
import { useModalClose } from '../../../utils/useModalClose';
import { decimalValidation, required } from '../../../utils/validations';
import { InputWrapper } from '../../common/input-wrapper';
import { MediaInput, MediaInputValue } from '../../common/media-input';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { uploadFile } from '../../../services/file-service';
import * as Sentry from '@sentry/react';
import { api } from '../../../services/api';
import { AlertService } from '../../../services/alert.service';
import { Select } from '../../common/select.component';
import { useSuppliers } from '../../../api/suppliers';
import { useIsSupplier } from '../../../api/use-is-supplier';

type SupplierExpenseModalProps = {
  close: () => void;
  reject: () => void;
};
type Form = {
  supplierId: number | undefined;
  amount: string;
  comment: string;
  targetCurrency: string;
  receiptFiles: MediaInputValue[];
};
export const SupplierExpenseModal: React.FC<SupplierExpenseModalProps> = ({
  close,
  reject,
}) => {
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    control,
  } = useForm<Form>();
  const intl = useIntl();
  const isSupplier = useIsSupplier();
  const { data: suppliers } = useSuppliers(isSupplier);

  const onSubmit = handleSubmit(async (data) => {
    let receiptFile;
    try {
      const receiptFilesResponse = data.receiptFiles[0];
      receiptFile =
        receiptFilesResponse.type === 'new'
          ? await uploadFile(receiptFilesResponse.file)
          : { file: receiptFilesResponse.file };
    } catch (e) {
      Sentry.captureException(e, {
        extra: { debug: 'SupplierExpenseModal: файлы' },
      });
      return;
    }

    try {
      await api.post(`/transaction/expense`, {
        amount: data.amount,
        targetCurrency: data.targetCurrency,
        comment: data.comment,
        attachment: {
          id: receiptFile.file.id,
        },
        ...(data.supplierId ? { supplierId: data.supplierId } : undefined),
      });
    } catch (e) {
      Sentry.captureException(e, {
        extra: { debug: 'SupplierExpenseModal: api' },
      });
      return;
    }

    AlertService.success();
    close();
  });
  const modalRef = useModalClose(reject);
  return (
    <div className="modal-dialog mw-400" ref={modalRef}>
      <form className="modal-content" onSubmit={onSubmit}>
        <div className="modal-header border-bottom-0 p-4">
          <h3>
            <FormattedMessage
              defaultMessage="Добавить расходы"
              id="finances.supplier-expense.title"
              description="Заголовок окна"
            />
          </h3>
          <button
            type="button"
            className="btn-close"
            onClick={() => reject()}
          />
        </div>
        <div className="modal-body pt-2 p-4">
          {!isSupplier ? (
            <Controller
              name="supplierId"
              control={control}
              rules={required}
              render={({ field }) => (
                <Select
                  className="mb-3"
                  title="Поставщик"
                  placeholder="Выберите поставщика"
                  required
                  options={
                    suppliers?.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    })) ?? []
                  }
                  {...field}
                  error={errors.supplierId?.message}
                />
              )}
            />
          ) : null}
          <Controller
            control={control}
            name="receiptFiles"
            rules={{ ...required }}
            render={({ field, fieldState }) => (
              <InputWrapper
                title={intl.formatMessage({
                  defaultMessage: 'Фотографии подтверждения оплаты',
                  id: 'input.label.photosOfReceipt',
                  description: 'Заголовок поля ввода',
                })}
                className="mb-3"
                error={fieldState.error?.message}
                required
              >
                <MediaInput {...field} multiple />
              </InputWrapper>
            )}
          />
          <InputWrapper
            className="mb-3"
            title="Счёт cписания"
            error={errors.targetCurrency?.message}
            required
          >
            <select
              className="form-select"
              {...register('targetCurrency', {
                ...required,
              })}
            >
              <option value="" hidden>
                Выберите счет
              </option>
              <option value="usd">USD</option>
              <option value="cny">CNY</option>
            </select>
          </InputWrapper>
          <InputWrapper
            title={intl.formatMessage({
              defaultMessage: 'Сумма списания',
              id: 'input.label.writeOffAmount',
              description: 'Заголовок поля ввода',
            })}
            className="mb-3"
            error={errors.amount?.message}
            required
          >
            <div className="input-group">
              <input
                className="form-control"
                type="number"
                min={0}
                step={0.01}
                {...register('amount', {
                  ...required,
                  ...decimalValidation,
                })}
              />
            </div>
          </InputWrapper>
          <InputWrapper
            title={intl.formatMessage({
              defaultMessage: 'Комментарий',
              id: 'input.input-label.receiptComment',
              description: 'Заголовок поля ввода',
            })}
            className="mb-3"
            error={errors.comment?.message}
            required
          >
            <div className="input-group">
              <textarea
                className="form-control"
                {...register('comment', {
                  ...required,
                })}
              />
            </div>
          </InputWrapper>
        </div>
        <div className="modal-footer justify-content-end">
          <button
            type="submit"
            className="btn btn-success"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Списать"
                id="btn.label.createAdditionalWriteOff"
                description="Надпись на кнопке"
              />
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
