import { Input } from '../../common/input.component';
import { CurrencyService } from '../../../services/currency.service';
import { AlertService } from '../../../services/alert.service';
import { useForm } from 'react-hook-form';
import React from 'react';
import { ActualCurrency } from './ActualCurrency';
import { ModalProps } from '../../../services/modals.service';
import { Decimal } from 'decimal.js';
import { DECIMAL_PLACES, DECIMAL_STEP } from '../../../utils/common.utils';
import { useGlobalMutate } from '../../../api/useGlobalMutate';
import { useModalClose } from '../../../utils/useModalClose';

type FieldValues = {
  cnyRubExternal: string;
  cnyRubInternal: string;
  cnyRubPartner: string;

  usdRubExternal: string;
  usdRubInternal: string;
  usdRubPartner: string;

  usdCny: string;
  cnyUsd: string;
};

export const CurrencyModal = ({ close }: ModalProps) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
  } = useForm<FieldValues>();

  const { mutate: globalMutate } = useGlobalMutate();

  function onSave(currency: FieldValues) {
    CurrencyService.createCurrency(currency).then(async () => {
      AlertService.success();
      close();
      await globalMutate();
    });
  }

  function calculateCnyRubPartner() {
    const { usdRubPartner, usdRubExternal, cnyRubExternal } = getValues();
    if (!usdRubPartner || !usdRubExternal || !cnyRubExternal) {
      return;
    }

    const value = new Decimal(usdRubPartner)
      .div(new Decimal(usdRubExternal).div(new Decimal(cnyRubExternal)))
      .toFixed(DECIMAL_PLACES);
    setValue('cnyRubPartner', value, {
      shouldDirty: true,
      shouldValidate: true,
    });
  }

  const modalRef = useModalClose(close);

  return (
    <div className="modal-dialog" ref={modalRef}>
      <form className="modal-content" onSubmit={handleSubmit(onSave)}>
        <div className="modal-body">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h3>Добавить курс валют</h3>
            <div className="btn p-0" onClick={close}>
              <i className="bi bi-x fs-36 color-gray-600" />
            </div>
          </div>
          <ActualCurrency />
          <h4>Курс на пополнение CNY</h4>
          <div className="row mb-2">
            <div className="col-4">
              <Input
                type="number"
                min={0}
                step={DECIMAL_STEP}
                {...register('cnyRubExternal', {})}
                error={errors.cnyRubExternal?.message}
                title="CNY/RUB Китай"
                postfix="₽"
              />
            </div>
            <div className="col-4">
              <Input
                type="number"
                min={0}
                step={DECIMAL_STEP}
                {...register('cnyRubInternal', {})}
                error={errors.cnyRubInternal?.message}
                title="CNY/RUB Сайт"
                postfix="₽"
              />
            </div>
            <div className="col">
              <Input
                type="number"
                min={0}
                step={DECIMAL_STEP}
                {...register('cnyRubPartner', {})}
                error={errors.cnyRubPartner?.message}
                title="CNY/RUB Партнёр"
                postfix="₽"
              />
            </div>
          </div>
          <p className="text-end">
            <button
              className="btn btn-light btn-sm"
              onClick={calculateCnyRubPartner}
              tabIndex={-1}
              type="button"
            >
              Рассчитать Cny/Rub Партнёр
            </button>
          </p>

          <h4>Курс на пополнение USD</h4>
          <div className="row">
            <div className="col">
              <Input
                className="mb-4"
                type="number"
                min={0}
                step={DECIMAL_STEP}
                {...register('usdRubExternal', {})}
                error={errors.usdRubExternal?.message}
                title="USD/RUB Китай"
                postfix="₽"
              />
            </div>
            <div className="col">
              <Input
                className="mb-4"
                type="number"
                min={0}
                step={DECIMAL_STEP}
                {...register('usdRubInternal', {})}
                error={errors.usdRubInternal?.message}
                title="USD/RUB Сайт"
                postfix="₽"
              />
            </div>
            <div className="col">
              <Input
                className="mb-4"
                type="number"
                min={0}
                step={DECIMAL_STEP}
                {...register('usdRubPartner', {})}
                error={errors.usdRubPartner?.message}
                title="USD/RUB Партнёр"
                postfix="₽"
              />
            </div>
          </div>

          <h4>Курс обмена</h4>
          <div className="row">
            <div className="col">
              <Input
                className="mb-4"
                type="number"
                min={0}
                step={DECIMAL_STEP}
                {...register('usdCny', {})}
                error={errors.usdCny?.message}
                title="USD/CNY Кросс"
              />
            </div>
            <div className="col">
              <Input
                className="mb-4"
                type="number"
                min={0}
                step={DECIMAL_STEP}
                {...register('cnyUsd', {})}
                error={errors.cnyUsd?.message}
                title="CNY/USD Кросс"
              />
            </div>
            <div className="col"></div>
          </div>
        </div>
        <div className="modal-footer justify-content-end">
          <button type="submit" className="btn btn-success">
            Добавить
          </button>
        </div>
      </form>
    </div>
  );
};
