import React from 'react';
import Table, { CellProps } from 'rsuite/Table';
import Moment from 'moment';
import { MomentInput } from 'moment/moment';

export const TableDateCell = ({
  rowData,
  dataKey,
  ...props
}: CellProps<Record<string, unknown>>) => {
  if (!rowData || !dataKey || !rowData[dataKey]) return null;

  let moment = Moment(
    (rowData[dataKey] as string).split('T')[0] as MomentInput,
  );

  return (
    <Table.Cell {...props} verticalAlign="middle">
      {moment.format('DD.MM.YYYY')}
    </Table.Cell>
  );
};
