import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  decimalValidation,
  intValidation,
  required,
} from '../../../utils/validations';
import { InputWrapper } from '../../common/input-wrapper';
import { CleanPromotion } from '../../../export-types/cleaned-types';
import {
  formatDateValue,
  getCurrentDateValue,
  parseRawDateValue,
} from '../../../utils/date-value';
import { DatePicker } from 'rsuite';
import isDate from 'validator/lib/isDate';
import { api } from '../../../services/api';
import { AlertService } from '../../../services/alert.service';
import { useModalClose } from '../../../utils/useModalClose';
import { MediaInput, MediaInputValue } from '../../common/media-input';
import { uploadMediaInputFiles } from '../../../services/file-service';

function isCorrectDate(v: string) {
  return isDate(`${v}`, {
    format: 'DD.MM.YYYY',
    delimiters: ['.'],
    strictMode: true,
  });
}

type Form = {
  name: string;
  displayName: string;
  displayDescription: string;
  type: 'commission' | 'bonus';
  usage: 'allOrders' | 'firstOrder' | 'nextOrder';
  markup?: string;
  bonus?: number;
  bonusLifetime?: number;
  startDate: string;
  endDate: string;
  paymentDate: string;
  banner: MediaInputValue[];
};

type AddStoryModelProps = {
  close: () => void;
  reject: () => void;
  promotion?: CleanPromotion;
};

export const AddPromotionModal: React.FC<AddStoryModelProps> = ({
  close,
  reject,
  promotion,
}) => {
  const {
    formState: { isSubmitting },
    handleSubmit,
    control,
    getValues,
    watch,
  } = useForm<Form>({
    defaultValues: promotion
      ? {
          name: promotion.name,
          displayName: promotion.displayName,
          displayDescription: promotion.displayDescription,
          type: promotion.type,
          usage: promotion.usage,
          markup: promotion.markup,
          bonus: promotion.bonus,
          bonusLifetime: promotion.bonusLifetime,
          startDate: formatDateValue(promotion.startDate),
          endDate: formatDateValue(promotion.endDate),
          paymentDate: formatDateValue(promotion.paymentDate),
          banner: promotion.banner
            ? [
                {
                  type: 'exist' as const,
                  file: { id: promotion.banner.id, type: 'image' },
                },
              ]
            : [],
        }
      : {
          type: 'commission',
          usage: 'allOrders',
        },
  });
  const [type, start, end] = watch(['type', 'startDate', 'endDate']);
  const minDate = React.useMemo(
    () => formatDateValue(getCurrentDateValue()),
    [],
  );
  const onSubmit = handleSubmit(async (form) => {
    const banner = await uploadMediaInputFiles(form.banner);
    const data = {
      name: form.name,
      displayName: form.displayName,
      displayDescription: form.displayDescription,
      type: form.type,
      usage: form.usage,
      markup: form.type === 'commission' ? form.markup : undefined,
      bonus: form.type === 'bonus' ? form.bonus : undefined,
      bonusLifetime: form.type === 'bonus' ? form.bonusLifetime : undefined,
      startDate: parseRawDateValue(form.startDate).valueOf(),
      endDate: parseRawDateValue(form.endDate).valueOf(),
      paymentDate: parseRawDateValue(form.paymentDate).valueOf(),
      banner:
        banner.length > 0
          ? {
              id: banner[0].file.id,
            }
          : undefined,
    };
    if (promotion) {
      api.put(`/promotion/${promotion.id}`, data).then(() => {
        AlertService.success();
        close();
      });
    } else {
      api.post(`/promotion`, data).then(() => {
        AlertService.success();
        close();
      });
    }
  });

  const modalRef = useModalClose(reject);

  return (
    <div className="modal-dialog" ref={modalRef}>
      <form className="modal-content" onSubmit={onSubmit}>
        <div className="modal-header border-bottom-0 p-4">
          <h3>{promotion ? 'Редактировать' : 'Добавить'} акцию</h3>
          <button
            type="button"
            className="btn-close"
            onClick={() => reject()}
            disabled={isSubmitting}
          />
        </div>
        <div className="modal-body p-4">
          <Controller
            control={control}
            name="name"
            rules={{
              ...required,
            }}
            render={({ field, fieldState }) => (
              <InputWrapper
                title="Внутреннее название"
                error={fieldState.error?.message}
                required
              >
                <input className="form-control" type="text" {...field} />
              </InputWrapper>
            )}
          />
          <Controller
            control={control}
            name="displayName"
            rules={{
              ...required,
            }}
            render={({ field, fieldState }) => (
              <InputWrapper
                title="Название"
                error={fieldState.error?.message}
                required
              >
                <input className="form-control" type="text" {...field} />
              </InputWrapper>
            )}
          />
          <Controller
            control={control}
            name="displayDescription"
            rules={{
              ...required,
            }}
            render={({ field, fieldState }) => (
              <InputWrapper
                title="Описание"
                error={fieldState.error?.message}
                required
              >
                <textarea className="form-control" {...field} />
              </InputWrapper>
            )}
          />
          <Controller
            control={control}
            name="type"
            rules={{
              ...required,
            }}
            render={({ field, fieldState }) => (
              <InputWrapper
                theme="light"
                className="mt-3"
                title="Тип"
                required
                error={fieldState.error?.message}
              >
                <select {...field} className="form-select">
                  <option value={'commission'}>Комиссия за выкуп</option>
                  <option value={'bonus'}>Бонус</option>
                </select>
              </InputWrapper>
            )}
          />
          {type === 'commission' && (
            <Controller
              control={control}
              name="usage"
              rules={{
                ...required,
              }}
              render={({ field, fieldState }) => (
                <InputWrapper
                  theme="light"
                  className="mt-3"
                  title="Применимость"
                  required
                  error={fieldState.error?.message}
                >
                  <select
                    {...field}
                    className="form-select"
                    defaultValue={'allOrders'}
                  >
                    <option value={'allOrders'}>Все заказы</option>
                    <option value={'firstOrder'}>Первый заказ</option>
                    <option value={'nextOrder'}>Следующий выкуп</option>
                  </select>
                </InputWrapper>
              )}
            />
          )}
          {type === 'commission' && (
            <Controller
              control={control}
              name="markup"
              rules={{
                ...required,
                ...decimalValidation,
              }}
              render={({ field, fieldState }) => (
                <InputWrapper
                  theme="light"
                  className="mt-3"
                  title="Комиссия"
                  error={fieldState.error?.message}
                >
                  <div className="input-group">
                    <input className="form-control" type="number" {...field} />
                    <span className="input-group-text">%</span>
                  </div>
                </InputWrapper>
              )}
            />
          )}
          {type === 'bonus' && (
            <Controller
              control={control}
              name="bonus"
              rules={{
                ...required,
                ...intValidation,
              }}
              render={({ field, fieldState }) => (
                <InputWrapper
                  theme="light"
                  className="mt-3"
                  title="Бонус, руб"
                  error={fieldState.error?.message}
                  required
                >
                  <div className="input-group">
                    <input className="form-control" type="number" {...field} />
                    <span className="input-group-text">Руб</span>
                  </div>
                </InputWrapper>
              )}
            />
          )}
          {type === 'bonus' && (
            <Controller
              control={control}
              name="bonusLifetime"
              rules={{
                ...required,
                ...intValidation,
              }}
              render={({ field, fieldState }) => (
                <InputWrapper
                  theme="light"
                  className="mt-3"
                  title="Время жизни бонусов, дней"
                  required
                  error={fieldState.error?.message}
                >
                  <div className="input-group">
                    <input className="form-control" type="number" {...field} />
                    <span className="input-group-text">Дней</span>
                  </div>
                </InputWrapper>
              )}
            />
          )}
          <Controller
            control={control}
            rules={{ ...required }}
            name="banner"
            render={({ field, fieldState }) => (
              <InputWrapper
                theme="light"
                className="mt-3"
                title="Обложка акции"
                required
                error={fieldState.error?.message}
              >
                <MediaInput
                  {...field}
                  className="mt-2"
                  enableClipboardPasteHandler={false}
                />
                <div className="fs-14 color-gray-400 mt-1">
                  Размер формат .jpg или .png
                </div>
              </InputWrapper>
            )}
          />
          <Controller
            name="startDate"
            control={control}
            rules={{
              validate: (value) => {
                if (!value) return true;

                if (!isCorrectDate(value)) return 'Укажите корректную дату';

                const end = getValues('endDate');

                if (!end || !isCorrectDate(end)) return true;

                if (parseRawDateValue(value).isAfter(parseRawDateValue(end)))
                  return 'Дата не должна быть позже окончания';

                return true;
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <InputWrapper
                  title="Дата начала действия акции"
                  error={fieldState.error?.message}
                  className="mt-3"
                >
                  <DatePicker
                    className={'w-100'}
                    format={'dd.MM.yyyy'}
                    isoWeek={true}
                    shouldDisableDate={(d) => {
                      const fieldDate = end
                        ? parseRawDateValue(end).toDate()
                        : undefined;
                      if (fieldDate) {
                        return d > fieldDate;
                      } else {
                        return false;
                      }
                    }}
                    onChange={(v) => {
                      if (v === null) {
                        field.onChange(null);
                      } else {
                        field.onChange(formatDateValue(v));
                      }
                    }}
                    value={
                      field.value
                        ? parseRawDateValue(field.value).toDate()
                        : undefined
                    }
                  />
                </InputWrapper>
              );
            }}
          />
          <Controller
            name="paymentDate"
            control={control}
            rules={{
              validate: (value) => {
                if (!value) return true;

                if (!isCorrectDate(value)) return 'Укажите корректную дату';

                return true;
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <InputWrapper
                  title="Дата окончания периода оплаты по акции"
                  error={fieldState.error?.message}
                  className="mt-3"
                >
                  <DatePicker
                    className={'w-100'}
                    format={'dd.MM.yyyy'}
                    isoWeek={true}
                    shouldDisableDate={(d) => {
                      return d < parseRawDateValue(minDate).toDate();
                    }}
                    onChange={(v) => {
                      if (v === null) {
                        field.onChange(null);
                      } else {
                        field.onChange(formatDateValue(v));
                      }
                    }}
                    value={
                      field.value
                        ? parseRawDateValue(field.value).toDate()
                        : undefined
                    }
                  />
                </InputWrapper>
              );
            }}
          />

          <Controller
            name="endDate"
            control={control}
            rules={{
              validate: (value) => {
                if (!value) return true;

                if (!isCorrectDate(value)) return 'Укажите корректную дату';

                const start = getValues('startDate');

                if (!start || !isCorrectDate(start)) return true;

                if (parseRawDateValue(value).isBefore(parseRawDateValue(start)))
                  return 'Дата не должна быть раньше начальной';

                return true;
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <InputWrapper
                  title="Дата окончания действия акция"
                  error={fieldState.error?.message}
                  className="mt-3"
                >
                  <DatePicker
                    className={'w-100'}
                    shouldDisableDate={(d) => {
                      const fieldDate = start
                        ? parseRawDateValue(start).toDate()
                        : undefined;
                      if (fieldDate) {
                        return d < fieldDate;
                      } else {
                        return d < parseRawDateValue(minDate).toDate();
                      }
                    }}
                    format={'dd.MM.yyyy'}
                    isoWeek={true}
                    onChange={(v) => {
                      if (v === null) {
                        field.onChange(null);
                      } else {
                        field.onChange(formatDateValue(v));
                      }
                    }}
                    value={
                      field.value
                        ? parseRawDateValue(field.value).toDate()
                        : undefined
                    }
                  />
                </InputWrapper>
              );
            }}
          />
        </div>
        <div className="modal-footer justify-content-end">
          <button
            type="submit"
            className="btn btn-success"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : promotion ? (
              'Сохранить'
            ) : (
              'Добавить'
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
