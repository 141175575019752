import { api } from './api';

export class DeliveryAddressService {
  static createDeliveryAddress(data: unknown, internalId: number) {
    return api.post(`/delivery-address/client/${internalId}/address/`, data);
  }

  static updateDeliveryAddress(
    data: unknown,
    internalId: number,
    addressId: string,
  ) {
    return api.put(
      `/delivery-address/client/${internalId}/address/${addressId}`,
      data,
    );
  }

  static deleteDeliveryAddress(internalId: number, addressId: string) {
    return api.delete(
      `/delivery-address/client/${internalId}/address/${addressId}`,
    );
  }
}
