import React from 'react';
import { CleanUser } from '../../export-types/cleaned-types';
import { FormattedMessage } from 'react-intl';
import { ClientRequestInfoActions } from './client-request-info-actions';

type ClientInfoProps = {
  user: CleanUser;
};

export const ClientInfo: React.FC<ClientInfoProps> = (props) => {
  return (
    <div>
      <div className="d-flex flex-row justify-content-between">
        <div className="fs-18 fw-600 color-gray-450">
          <FormattedMessage
            defaultMessage="Клиент"
            id="clientInfo.label.customer"
            description="Заголовок на странице"
          />
        </div>
        <ClientRequestInfoActions user={props.user} />
      </div>

      <div className="d-flex flex-row">
        <div className="pe-3">
          <div
            className="bi bi-person-circle color-gray-600 lh-1"
            style={{ fontSize: 50 }}
          />
        </div>
        <div>
          <div className="fs-16 fw-500">
            {[props.user.name, props.user.internalId]
              .filter((item) => item)
              .join(', ')}
          </div>
          {props.user.phone && (
            <div className="color-gray-400">{props.user.phone}</div>
          )}
          {props.user.email && (
            <div className="color-gray-400">{props.user.email}</div>
          )}
        </div>
      </div>
      <div className="mt-3">
        <FormattedMessage
          defaultMessage="Менеджер: {value}"
          id="clientInfo.label.manager"
          description="Подзаголовок на странице"
          values={{ value: props.user.manager ? props.user.manager.name : '-' }}
        />
      </div>
    </div>
  );
};
