import React from 'react';
import {
  Control,
  Controller,
  useFieldArray,
  useForm,
  useFormState,
} from 'react-hook-form';
import { required, urlValidation } from '../../../utils/validations';
import { api } from '../../../services/api';
import { InputWrapper } from '../../common/input-wrapper';
import { MediaInput, MediaInputValue } from '../../common/media-input';
import {
  uploadFile,
  uploadMediaInputFiles,
} from '../../../services/file-service';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { AddButton } from '../../common/add-button';
import { Checkbox } from '../../common/checkbox';

type FormUrlData = {
  url: string;
};

type FormSliderDataItem = {
  file: MediaInputValue[];
  hasLink: boolean;
  url?: string;
  seeMore?: string;
};

type FormSliderData = {
  items: Array<FormSliderDataItem>;
};

type Form = {
  name: string;
  cover: MediaInputValue[];
  priority: number;
} & (
  | {
      type: 'youtube' | 'link' | 'rutube';
      data: FormUrlData;
    }
  | {
      type: 'slider';
      data: FormSliderData;
    }
);

type SliderDataProps = {
  control: Control<{ data: FormSliderData }>;
  register: UseFormRegister<{ data: FormSliderData }>;
};

type SliderDataItemProps = {
  control: Control<{ data: FormSliderData }>;
  register: UseFormRegister<{ data: FormSliderData }>;
  index: number;
  item: FormSliderDataItem;
  onRemove?: () => void;
};

const SliderDataItem: React.FC<SliderDataItemProps> = (props) => {
  const { errors } = useFormState({
    control: props.control,
    name: `data.items.${props.index}`,
  });

  const { onRemove } = props;

  return (
    <div className="col-12 d-flex">
      <div className="flex-grow-1">
        <Controller
          control={props.control}
          rules={{ ...required }}
          name={`data.items.${props.index}.file`}
          render={({ field, fieldState }) => (
            <InputWrapper
              theme="light"
              title="Изображение"
              required
              error={fieldState.error?.message}
            >
              <MediaInput
                {...field}
                className="mt-2"
                enableClipboardPasteHandler={false}
              />
              <div className="fs-14 color-gray-400 mt-1">
                Размер 1080х1920 px, формат .jpg или .png
              </div>
            </InputWrapper>
          )}
        />
        <Controller
          control={props.control}
          name={`data.items.${props.index}.hasLink`}
          render={({ field }) => {
            return (
              <>
                <div className="mt-3">
                  <Checkbox
                    title="Добавить кнопку"
                    checked={field.value}
                    ref={field.ref}
                    onChange={() => {
                      field.onChange(!field.value);
                    }}
                  />
                </div>
                {field.value && (
                  <>
                    <InputWrapper
                      title="Текст в кнопке"
                      className="mt-3"
                      error={
                        // @ts-ignore todo
                        errors?.data?.items?.at(props.index)?.seeMore?.message
                      }
                    >
                      <input
                        type="text"
                        className="form-control"
                        {...props.register(`data.items.${props.index}.seeMore`)}
                      />
                    </InputWrapper>
                    <InputWrapper
                      title="Ссылка"
                      className="mt-3"
                      error={
                        // @ts-ignore todo
                        errors?.data?.items?.at(props.index)?.url?.message
                      }
                      required
                    >
                      <input
                        type="text"
                        className="form-control"
                        {...props.register(`data.items.${props.index}.url`, {
                          ...required,
                          ...urlValidation,
                        })}
                      />
                    </InputWrapper>
                  </>
                )}
              </>
            );
          }}
        />
      </div>
      {onRemove && (
        <button
          type="button"
          className="btn d-flex border-0"
          onClick={() => onRemove()}
        >
          <i className="bi bi-trash"></i>
        </button>
      )}
    </div>
  );
};

const SliderData: React.FC<SliderDataProps> = (props) => {
  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: 'data.items',
  });

  React.useEffect(() => {
    if (fields.length === 0) {
      append({ hasLink: false, file: [] });
    }
  }, [append, fields.length]);

  const handleRemoveSlide = (index: number) => {
    remove(index);
  };

  return (
    <div className="row">
      {fields.map((item, index) => (
        <React.Fragment key={item.id}>
          <SliderDataItem
            index={index}
            item={item}
            control={props.control}
            register={props.register}
            onRemove={() => handleRemoveSlide(index)}
          />
          <div className="col-12">
            <hr />
          </div>
        </React.Fragment>
      ))}
      <div className="col-12">
        <AddButton onClick={() => append({ hasLink: false, file: [] })}>
          Добавить изображение
        </AddButton>
      </div>
    </div>
  );
};

type Item = {
  file: {
    id: string;
    type: string;
  };
  url?: string;
  seeMore?: string;
};

type AddStoryModelProps = {
  close: () => void;
  reject: () => void;
  story?: {
    id: string;
    name: string;
    priority: number;
    data?: {
      url?: string;
      items?: Item[];
    };
    cover: {
      id: string;
      type: string;
    };
    type: 'rutube' | 'youtube' | 'link' | 'slider';
  };
};

export const AddStoryModal: React.FC<AddStoryModelProps> = ({
  close,
  reject,
  story,
}) => {
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    control,
    watch,
  } = useForm<Form>({
    defaultValues: story
      ? story.type === 'youtube' ||
        story.type === 'link' ||
        story.type === 'rutube'
        ? {
            name: story.name,
            priority: story.priority,
            type: story.type,
            cover: [
              {
                type: 'exist' as const,
                file: { id: story.cover.id, type: 'image' },
              },
            ],
            data: {
              url: story.data?.url,
            },
          }
        : story.type === 'slider'
          ? {
              name: story.name,
              priority: story.priority,
              type: story.type,
              cover: [
                {
                  type: 'exist' as const,
                  file: { id: story.cover.id, type: 'image' },
                },
              ],
              data: story.data?.items && {
                items: story.data.items.map((item) => ({
                  file: [
                    {
                      type: 'exist' as const,
                      file: { id: item.file.id, type: 'image' },
                    },
                  ],
                  url: item.url,
                  seeMore: item.seeMore,
                  hasLink: item.url && item.seeMore ? true : false,
                })),
              },
            }
          : undefined
      : undefined,
  });

  const isEdit = typeof story !== 'undefined';

  const onSubmit = React.useMemo(
    () =>
      handleSubmit(async (data) => {
        const cover = await uploadMediaInputFiles(data.cover);

        const dataBlock = {
          name: data.name,
          priority: data.priority,
          cover: {
            id: cover[0].file.id,
          },
          data: {
            ...(await (async () => {
              switch (data.type) {
                case 'link':
                case 'youtube':
                  return {
                    type: data.type,
                    url: data.data.url,
                  };
                case 'rutube':
                  return {
                    type: data.type,
                    url: data.data.url,
                  };
                case 'slider':
                  const images = await Promise.all(
                    data.data.items.map(async (item) => {
                      const data = item.file[0];
                      return data.type === 'new'
                        ? uploadFile(data.file).then((data) => data.file)
                        : Promise.resolve(data.file);
                    }),
                  );

                  return {
                    type: data.type,
                    items: data.data.items.map((item, index) => ({
                      url: item.hasLink
                        ? item.url?.trim() || undefined
                        : undefined,
                      seeMore: item.hasLink
                        ? item.seeMore?.trim() || undefined
                        : undefined,
                      file: images[index],
                    })),
                  };
              }
            })()),
          },
        };

        if (isEdit && story) {
          await api.put(`/stories/list/${story.id}`, dataBlock).then(() => {
            close();
          });
        } else {
          await api.post(`/stories/list`, dataBlock).then(() => {
            close();
          });
        }
        close();
      }),
    [close, handleSubmit, isEdit, story],
  );
  const type = watch('type');

  const dataElement = React.useMemo(() => {
    switch (type) {
      case 'link':
        return (
          <InputWrapper
            title="Ссылка на товар"
            // @ts-ignore
            error={errors.data?.url?.message}
            required
          >
            <input
              type="text"
              placeholder="Например, https://ya.ru"
              className="form-control"
              {...register('data.url', { ...required, ...urlValidation })}
            />
          </InputWrapper>
        );
      case 'youtube':
        return (
          <InputWrapper
            title="Ссылка на видео"
            // @ts-ignore
            error={errors.data?.url?.message}
            required
          >
            <input
              type="text"
              placeholder="Например, https://youtu.be/mRfslP0vlmk"
              className="form-control"
              {...register('data.url', { ...required, ...urlValidation })}
            />
          </InputWrapper>
        );
      case 'rutube':
        return (
          <InputWrapper
            title="Ссылка на видео"
            // @ts-ignore
            error={errors.data?.url?.message}
            required
          >
            <input
              type="text"
              placeholder="Например, https://rutube.ru/play/embed/7915d8/"
              className="form-control"
              {...register('data.url', { ...required, ...urlValidation })}
            />
          </InputWrapper>
        );
      case 'slider':
        return (
          // @ts-ignore
          <SliderData control={control} register={register} />
        );
    }
  }, [type, errors, register, control]);

  return (
    <div className="modal-dialog">
      <form className="modal-content" onSubmit={onSubmit}>
        <div className="modal-header border-bottom-0 p-4">
          <h3>{isEdit ? 'Редактировать' : 'Добавить'} историю</h3>
          <button
            type="button"
            className="btn-close"
            onClick={() => reject()}
            disabled={isSubmitting}
          />
        </div>

        <div className="modal-body pt-2 p-4">
          <InputWrapper
            title="Внутреннее название"
            error={errors.name?.message}
            required
          >
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                {...register('name', {
                  ...required,
                })}
              />
            </div>
          </InputWrapper>

          <InputWrapper
            title="Приоритет"
            error={errors.priority?.message}
            required
          >
            <div className="input-group">
              <input
                className="form-control"
                type="number"
                {...register('priority', {
                  ...required,
                })}
              />
            </div>
            <div className="fs-14 color-gray-400">
              Влияет на сортировку. Чем меньше, тем выше в списке
            </div>
          </InputWrapper>

          <Controller
            control={control}
            rules={{ ...required }}
            name="cover"
            render={({ field, fieldState }) => (
              <InputWrapper
                theme="light"
                className="mt-3"
                title="Обложка истории"
                required
                error={fieldState.error?.message}
              >
                <MediaInput
                  {...field}
                  className="mt-2"
                  enableClipboardPasteHandler={false}
                />
                <div className="fs-14 color-gray-400 mt-1">
                  Размер 512х512 px, формат .jpg или .png
                </div>
              </InputWrapper>
            )}
          />

          <InputWrapper
            className="mt-3"
            title="Тип истории"
            error={errors.type?.message}
            required
          >
            <select
              className="form-select"
              {...register('type', {
                ...required,
              })}
            >
              <option value="" hidden>
                Выберите тип
              </option>
              <option value="rutube">RUTUBE видео</option>
              <option value="link">Ссылка</option>
              <option value="slider">Слайдер</option>
              <option value="youtube">YouTube видео</option>
            </select>
          </InputWrapper>

          <div className="mt-3">{dataElement}</div>
        </div>
        <div className="modal-footer justify-content-end">
          <button
            type="submit"
            className="btn btn-success"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : isEdit ? (
              'Сохранить'
            ) : (
              'Добавить'
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
