import React from 'react';
import { useForm } from 'react-hook-form';
import { ModalsService } from '../../services/modals.service';
import { toMoney } from '../../utils/common.utils';
import { PurchaseOrderService } from '../../services/purchase-order.service';
import { AdminNoMoneyModal } from './admin-no-money-modal';
import { useProfile } from '../../api/profile';
import { useModalClose } from '../../utils/useModalClose';
import { AlertService } from '../../services/alert.service';
import { useSuppliers } from '../../api/suppliers';

type AdminPayPurchaseOrderModalProps = {
  close: (result?: boolean | Promise<boolean | undefined>) => void;
  reject: () => void;
  price: string;
  purchaseOrderId: string;
  currencySupplierId: number;
};

export const AdminPayPurchaseOrderModal: React.FC<
  AdminPayPurchaseOrderModalProps
> = ({ close, price, purchaseOrderId, currencySupplierId, reject }) => {
  const { data: suppliers } = useSuppliers();

  const foundSupplier = suppliers?.find(
    (supplier) => supplier.id === currencySupplierId,
  );
  const currencySupplier = foundSupplier?.purchaseCurrency || '';

  const { handleSubmit, formState } = useForm();
  const { mutate } = useProfile();

  const onSubmit = handleSubmit(async () => {
    const result = await PurchaseOrderService.payPurchaseOrder(purchaseOrderId);
    mutate();

    if (result && 'status' in result && result.status === 'payed') {
      return close(true);
    }

    close(
      ModalsService.createModal(AdminNoMoneyModal, {
        description: `На счете клиента недостаточно средств для оплаты выбранного заказа.`,
        action: `Оплатить заказ в долг`,
        onAction(resolve: (result?: boolean) => void) {
          PurchaseOrderService.payPurchaseOrder(purchaseOrderId, {
            force: true,
          }).then((result) => {
            mutate();
            if (result && 'status' in result && result.status === 'payed') {
              return resolve(true);
            }

            AlertService.error('Ошибка оплаты: ' + JSON.stringify(result));

            return resolve();
          });
        },
      }),
    );
  });

  const modalRef = useModalClose(reject);

  return (
    <div className="modal-dialog mw-400" ref={modalRef}>
      <div className="modal-content">
        <div className="modal-header border-bottom-0 p-4">
          <button
            type="button"
            className="btn-close"
            onClick={() => reject()}
            disabled={formState.isSubmitting}
          />
        </div>
        <form className="modal-body pt-2 p-4" onSubmit={onSubmit}>
          <div className="text-center mb-4 mx-auto">
            <i className="bi bi-credit-card color-green fs-65 lh-1" />
            <h3 className="mt-3">Подтверждение оплаты</h3>
            <div className="mt-3">
              Вы подтверждаете оплату{' '}
              <strong>{toMoney(price, `${currencySupplier}`)}</strong> с баланса
              клиента?
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-success w-100 mb-3"
            disabled={formState.isSubmitting}
          >
            {formState.isSubmitting ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              `Оплатить ${toMoney(price, `${currencySupplier}`)}`
            )}
          </button>
          <button
            type="button"
            className="btn btn-light w-100"
            onClick={() => reject()}
            disabled={formState.isSubmitting}
          >
            Отмена
          </button>
        </form>
      </div>
    </div>
  );
};
