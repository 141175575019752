import React from 'react';
import Table from 'rsuite/Table';
import { TableMoneyCallComponent } from '../../common/table/table-money-call.component';
import Pagination from 'rsuite/Pagination';
import {
  CreateCurrencyV2,
  useCurrency,
} from '../../../services/currency.service';
import { usePaginationQs } from '../../../utils/usePagination';
import { CleanCurrency2 } from '../../../export-types/cleaned-types';
import { parseRawDateValue, RawDateValue } from '../../../utils/date-value';
import { DateRangePicker } from 'rsuite';
import { Controller, useForm } from 'react-hook-form';
import { InputWrapper } from '../../common/input-wrapper';
import { Select } from '../../common/select.component';
import moment from 'moment/moment';

const CurrencyType = {
  cnyRubExternal: 'CNY/RUB Поставщик',
  cnyRubInternal: 'CNY/RUB Сайт',
  cnyRubPartner: 'CNY/RUB Партнёр',
  usdRubExternal: 'USD/RUB Поставщик',
  usdRubInternal: 'USD/RUB Сайт',
  usdRubPartner: 'USD/RUB Партнёр',
  usdCny: 'USD/CNY Кросс',
  cnyUsd: 'CNY/USD Кросс',
};

const CurrencyTypeOptions = Object.entries(CurrencyType).map(
  ([name, text]) => ({ value: name, label: text }),
);

type Form = {
  dates: Array<string>;
  types: Array<CreateCurrencyV2>;
};

export const CurrencyTable = () => {
  const { take, skip, handleSetPage, handleChangeLimit, page } =
    usePaginationQs(10);

  const { control, register, watch } = useForm<Form>({
    defaultValues: {
      dates: [],
      types: [],
    },
  });

  const [types, dates] = watch(['types', 'dates']);

  const [startDate, endDate] = dates;

  const {
    data: currencies,
    error,
    isLoading,
  } = useCurrency(
    take,
    skip,
    types,
    parseRawDateValue(startDate).valueOf(),
    parseRawDateValue(
      moment(endDate || startDate, 'DD.MM.YYYY')
        .add(1, 'day')
        .format('DD-MM-YYYY'),
    ).valueOf(),
  );

  if (error) {
    return (
      <>
        <div className="pb-4">Неизвестная ошибка</div>
      </>
    );
  }
  const total = currencies?.total ?? 0;
  const items = currencies?.items ?? [];

  return (
    <div>
      <div className="row mb-1 ms-1 w-80 gap-2">
        <Controller
          name="dates"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <InputWrapper
                error={fieldState.error?.message}
                className="w-25 p-0"
              >
                <DateRangePicker
                  className={'w-100'}
                  format={'dd.MM.yyyy'}
                  placeholder={'Выберите дату'}
                  isoWeek={true}
                  showOneCalendar
                  ranges={[]}
                  onChange={(v) => {
                    if (v === null) field.onChange([]);
                    else {
                      field.onChange(
                        v.map(
                          (value) =>
                            moment(value).format('DD.MM.YYYY') as RawDateValue,
                        ),
                      );
                    }
                  }}
                ></DateRangePicker>
              </InputWrapper>
            );
          }}
        />
        <Select
          className={'w-25 p-0'}
          style={{ zIndex: 1000 }}
          placeholder="Поиск по типу"
          options={CurrencyTypeOptions}
          isMulti={true}
          value={types}
          {...register('types')}
        ></Select>
      </div>
      <Table data={items} headerHeight={57} autoHeight loading={isLoading}>
        <Table.Column width={230}>
          <Table.HeaderCell>Дата</Table.HeaderCell>
          <Table.Cell>
            {startDate !== endDate
              ? `${startDate} - ${endDate}`
              : (rowData) =>
                  moment((rowData as CleanCurrency2).createdAt).format(
                    'DD-MM-YYYY',
                  )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={200}>
          <Table.HeaderCell>Тип</Table.HeaderCell>
          <Table.Cell>
            {(rowData) =>
              CurrencyType[rowData.type as keyof typeof CurrencyType] ??
              rowData.type
            }
          </Table.Cell>
        </Table.Column>
        <Table.Column width={150}>
          <Table.HeaderCell>Курс</Table.HeaderCell>
          <TableMoneyCallComponent dataKey="rate" />
        </Table.Column>
      </Table>
      {total > take && (
        <div className="mt-4 pb-4">
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="md"
            layout={['pager']}
            total={total}
            limitOptions={[10, 20]}
            limit={take}
            activePage={page}
            onChangePage={handleSetPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      )}
    </div>
  );
};
