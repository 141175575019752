import React, { MouseEventHandler, PropsWithChildren } from 'react';

type AddButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export const AddButton: React.FC<PropsWithChildren<AddButtonProps>> = ({
  onClick,
  children,
}) => {
  return (
    <button
      type="button"
      className="btn btn-dashed d-flex w-100 flex-row justify-content-center"
      onClick={onClick}
    >
      <i className="bi bi-plus-circle me-2" />
      {children && ' '}
      {children}
    </button>
  );
};
