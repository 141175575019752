import React from 'react';
import { useForm } from 'react-hook-form';
import { InputWrapper } from '../../common/input-wrapper';
import { decimalValidation, required } from '../../../utils/validations';
import { api } from '../../../services/api';
import { useModalClose } from '../../../utils/useModalClose';

type PurchaseOrderCancelModalProps = {
  close: () => void;
  reject: () => void;
  order: {
    id: string;
  };
};

export const PurchaseOrderCancelModal: React.FC<
  PurchaseOrderCancelModalProps
> = ({ close, order, reject }) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    watch,
  } = useForm<
    | {
        reason: 'outOfStock' | 'notSatisfyRequest' | 'defectUponReceipt';
        refundAmount: string;
        reasonDetails: undefined;
      }
    | {
        reason: 'other';
        reasonDetails: string;
        refundAmount: string;
      }
  >();

  const onSubmit = handleSubmit((data) => {
    let textReason = data.reasonDetails;

    switch (data.reason) {
      case 'defectUponReceipt':
        textReason = 'Брак при получении';
        break;
      case 'notSatisfyRequest':
        textReason = 'Недостаточное количество товара';
        break;
      case 'outOfStock':
        textReason = 'Товара нет в наличии';
        break;
      default:
        ((reason: 'other') => void 0)(data.reason);
    }

    api
      .post(`/purchase-order/${order.id}/cancel`, {
        reason: textReason,
        refundAmount: data.refundAmount,
      })
      .then(() => {
        close();
      });
  });

  const modalRef = useModalClose(reject);

  return (
    <div className="modal-dialog mw-400" ref={modalRef}>
      <div className="modal-content">
        <div className="modal-header border-bottom-0 p-4">
          <button
            type="button"
            className="btn-close"
            onClick={() => reject()}
          />
        </div>
        <form className="modal-body pt-2 p-4" onSubmit={onSubmit}>
          <div className="text-center mb-4 mx-auto">
            <i className="bi bi-exclamation-circle-fill color-yellow fs-65 lh-1" />
            <h3 className="mt-3">Вы отменяете ранее оплаченный заказ</h3>
            <div className="mt-3">Укажите сумму возврата и причину отмены</div>
          </div>
          <InputWrapper
            className="mb-3"
            title="Сумма возврата"
            error={errors.refundAmount?.message}
          >
            <div className="input-group">
              <input
                type="number"
                min={0}
                step={0.01}
                className="form-control"
                {...register('refundAmount', {
                  ...decimalValidation,
                })}
              />
              <span className="input-group-text">¥</span>
            </div>
          </InputWrapper>
          <InputWrapper
            className="mb-3"
            title="Причина отмены"
            required
            error={errors.reason?.message}
          >
            <select
              className="form-select"
              {...register('reason', {
                ...required,
              })}
            >
              <option value="" hidden>
                Выберите причину
              </option>
              <option value="outOfStock">Товара нет в наличии</option>
              <option value="notSatisfyRequest">
                Недостаточное количество товара
              </option>
              <option value="defectUponReceipt">Брак при получении</option>
              <option value="other">Другое</option>
            </select>
          </InputWrapper>
          {watch('reason') === 'other' && (
            <InputWrapper
              className="mb-5"
              error={errors.reasonDetails?.message}
            >
              <textarea
                className="form-control"
                {...register('reasonDetails', {
                  ...required,
                })}
              />
            </InputWrapper>
          )}

          <button className="btn btn-success w-100" disabled={isSubmitting}>
            Отменить заказ
          </button>
        </form>
      </div>
    </div>
  );
};
