import React from 'react';
import Table, { CellProps } from 'rsuite/Table';
import { CleanUser } from '../../../export-types/cleaned-types';

type Props = {
  user?: CleanUser;
};
export const UserInternalIdCell = ({ rowData, ...props }: CellProps<Props>) => (
  <Table.Cell {...props}>
    {(rowData && rowData.user?.internalId) || 'Express'}
  </Table.Cell>
);
