export const IconRefund = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M196 100C196 152.934 152.934 196 100 196C47.066 196 4 152.934 4 100C4 96.684 6.684 94 10 94C13.316 94 16 96.684 16 100C16 146.318 53.682 184 100 184C146.318 184 184 146.318 184 100C184 53.682 146.318 16 100 16C77.674 16 56.948 24.678 41.356 40H64C67.316 40 70 42.684 70 46C70 49.316 67.316 52 64 52H28C24.684 52 22 49.316 22 46V10C22 6.684 24.684 4 28 4C31.316 4 34 6.684 34 10V30.468C51.702 13.572 74.962 4 100 4C152.934 4 196 47.066 196 100Z"
        fill="#E17D23"
      />
      <path
        d="M52.6767 139.844L94.465 158.866C96.2004 159.613 98.0888 160 100 160C101.911 160 103.8 159.613 105.535 158.866L147.312 139.847C149.288 138.97 150.962 137.593 152.144 135.874C153.326 134.155 153.969 132.162 154 130.12V81.0814C153.971 79.0024 153.308 76.974 152.09 75.2331C150.872 73.4922 149.147 72.1107 147.119 71.2498L105.334 53.1054C103.664 52.3773 101.843 52 100 52C98.1566 52 96.3356 52.3773 94.6656 53.1054L52.8811 71.2498C50.8526 72.1107 49.1284 73.4922 47.91 75.2331C46.6915 76.974 46.0291 79.0024 46 81.0814V130.12C46.031 132.161 46.6726 134.152 47.8524 135.87C49.0321 137.588 50.7031 138.965 52.6767 139.844ZM56.0517 133.366C55.3782 133.082 54.8028 132.629 54.39 132.057C53.9771 131.485 53.7432 130.817 53.7143 130.127V82.3013L96.1429 101.579V151.622L56.0517 133.366ZM143.941 133.366L103.857 151.622V101.579L146.286 82.3013V130.12C146.259 130.813 146.025 131.484 145.61 132.059C145.196 132.634 144.618 133.089 143.941 133.373V133.366ZM97.9171 59.6298C98.574 59.3618 99.2831 59.2235 100 59.2235C100.717 59.2235 101.426 59.3618 102.083 59.6298L141.19 76.6118L100 95.3285L58.8096 76.6118L97.9171 59.6298Z"
        fill="#E17D23"
      />
      <path
        d="M56.0517 133.366C55.3782 133.082 54.8028 132.629 54.39 132.057C53.9771 131.485 53.7432 130.817 53.7143 130.127V82.3013L96.1429 101.579V151.622L56.0517 133.366Z"
        fill="#E17D23"
      />
      <rect
        x="77.8319"
        y="62.4177"
        width="55.101"
        height="7.47414"
        transform="rotate(24.0205 77.8319 62.4177)"
        fill="#E17D23"
      />
    </svg>
  );
};
