import { api } from './api';

export class CommonService {
  static createDeliveryMarkup(data: unknown) {
    return api.post('/delivery-markup', data);
  }

  static deleteDeliveryMarkup(id: string) {
    return api.delete(`/delivery-markup/${id}`);
  }

  static updateDeliveryMarkup(id: string, data: unknown) {
    return api.put(`/delivery-markup/${id}`, data);
  }

  static createPackageMethod(packageMethod: unknown) {
    return api.post('/package-method', packageMethod);
  }

  static updatePackageMethod(id: string, data: unknown) {
    return api.put(`/package-method/${id}`, data);
  }

  static deletePackageMethod(id: string) {
    return api.delete(`/package-method/${id}`);
  }
}
