import { useEffect, useRef } from 'react';

const events = ['mousedown', 'touchstart'] as const;

export const useModalClose = (handlerCloseModal: () => void) => {
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent | TouchEvent) => {
      if (
        elementRef.current &&
        !elementRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).closest('.rs-picker-popup')
      ) {
        handlerCloseModal();
      }
    };
    events.forEach((event) => document.addEventListener(event, handleClick));

    return () => {
      events.forEach((event) =>
        document.removeEventListener(event, handleClick),
      );
    };
  }, [elementRef, handlerCloseModal]);

  return elementRef;
};
