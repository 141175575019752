import React from 'react';

import { trpcSwr } from '../../../api/trpc';

import { Header } from '../../common/header.component';

import { useLocation, useNavigate } from 'react-router-dom';
import { BrandFile } from './BrandFile';
import MyLoader from '../../common/loader.component';
import { useAddBrandItem, useAddCardPoizonItem } from './useAddCardPoizon';

export const AddBrand = () => {
  const { state } = useLocation() as {
    state?: { categoryId: number; brandId?: number };
  };

  const { data: itemInfo, isLoading: itemInfoLoading } = useAddBrandItem(state);

  if (itemInfoLoading && !itemInfo) {
    return (
      <>
        <div className="pb-4">
          <MyLoader />
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      <BrandFile itemInfo={itemInfo} brandId={state?.brandId} />
    </>
  );
};
