import { useRef, useState } from 'react';
import { useLocalStorage } from '@mantine/hooks';
import { TerminalPurchaseOrderItem } from './purchase-order-item.component';
import { usePurchaseOrders } from '../../../api/use-purchase-orders';
import { FormattedMessage, useIntl } from 'react-intl';

/** Меню для поиска заказа в меню ТСД. */
export default function TerminalSearchScreen() {
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);
  const [code, setCode] = useState('');

  // История поиска заказов пользователя хранится локально
  const [history, setHistory] = useLocalStorage({
    key: 'pdt-search-history',
    defaultValue: '[]',
  });

  // Получить список заказов с определенными статусами
  const { data } = usePurchaseOrders({
    internalId: code, // ID, по которому искать заказы
    status: ['onTheWayToStock', 'receivedInStock', 'receivedInRetailStock'],
  });

  // Очистка поля поиска
  function clear() {
    setCode('');
    inputRef.current?.focus();
  }

  /** Обновить историю поиска */
  function updateHistory(input: string) {
    if (input.length <= 0) return;

    setHistory((prev) => {
      // Получить историю поиска и добавить новый элемент
      const historyList = JSON.parse(prev) as string[];

      // Проверить, существует ли уже запись в истории
      if (!historyList.includes(input)) {
        historyList.unshift(input);

        // Если в истории больше 10 прошлых запросов, удалить последний
        if (historyList.length > 10) historyList.pop();
      }

      return JSON.stringify(historyList);
    });
  }

  // Удалить настоящий элемент из списка
  function clearHisoryByIndex(index: number) {
    setHistory((prev) => {
      // Получить историю поиска и удалить элемент
      const historyList = JSON.parse(prev) as string[];
      historyList.splice(index, 1);

      return JSON.stringify(historyList);
    });
  }

  return (
    <>
      {/* Контейнер для поля и результатов поиска */}
      <div className="d-flex flex-column gap-3 h-90 mx-2 pt-2">
        <div className="input-group">
          <input
            ref={inputRef}
            onBlur={() => updateHistory(code)}
            autoFocus
            className="form-control"
            placeholder={intl.formatMessage({
              defaultMessage: 'Номер заказа или клиента',
              id: 'terminal.search.placeholder',
              description: 'Заполнитель',
            })}
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <button
            onClick={() => {
              clear();
            }}
            className="input-group-text"
            id="basic-addon2"
          >
            <i className="bi bi-x"></i>
          </button>
        </div>

        <div
          className="d-flex flex-column gap-3"
          style={{ marginBottom: '72px' }}
        >
          {code ? (
            data?.items
              // Отсортировать и разместить заказы в пути выше складских
              .sort((a, b) => {
                if (
                  a.status === 'onTheWayToStock' &&
                  b.status !== 'onTheWayToStock'
                )
                  return -1;
                else if (
                  a.status !== 'onTheWayToStock' &&
                  b.status === 'onTheWayToStock'
                )
                  return 1;
                else return 0;
              })
              .map((order) => (
                <TerminalPurchaseOrderItem order={order} key={order.id} />
              ))
          ) : (
            // История поиска пользователя
            <section className="mx-2">
              {/* Показывать заголовок если есть запросы */}
              {JSON.parse(history).length > 0 && (
                <p className="color-gray-300">
                  {' '}
                  <FormattedMessage
                    defaultMessage="Искали ранее"
                    id="terminal.title.searchedBefore"
                    description="Заголовок Списка"
                  />
                </p>
              )}

              {/* Список предыдущих запросов пользователя */}
              <div className="color-gray-400 d-flex flex-column gap-3">
                {(JSON.parse(history) as string[]).map((text, index) => (
                  <div
                    className="d-flex text-start justify-content-between gap-2"
                    key={text}
                  >
                    <button
                      className="flex-grow-1 text-start bg-transparent"
                      onClick={() => setCode(text)}
                    >
                      {text}
                    </button>
                    <button
                      className="bg-transparent"
                      onClick={() => {
                        clearHisoryByIndex(index);
                      }}
                    >
                      <i className="bi bi-x"></i>
                    </button>
                  </div>
                ))}
              </div>
            </section>
          )}
        </div>
      </div>

      {/* Кнопка для сканирования заказов */}
      <button
        type="submit"
        className="btn btn-success mb-3"
        style={{
          position: 'fixed',
          bottom: '0',
          left: '10px',
          right: '10px',
        }}
      >
        <FormattedMessage
          defaultMessage="Сканировать"
          id="terminal.cta.scan"
          description="Кнопка"
        />
      </button>
    </>
  );
}
