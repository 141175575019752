import { api } from './api';

export class UserService {
  static createUser(user: unknown) {
    return api.post('/user', user);
  }

  static editUser(id: string, user: unknown) {
    return api.put(`/user/${id}`, user);
  }
}
