import { fetcher } from '../../services/api';
import useSWR from 'swr';
import { CleanNote, RbacObject } from '../../export-types/cleaned-types';
import React, { useState } from 'react';
import Whisper from 'rsuite/Whisper';
import Popover from 'rsuite/Popover';
import Dropdown from 'rsuite/Dropdown';
import { Rbac } from './Rbac';
import NoteForm from './note-form';
import NoteInfo from './note';

type NoteInfoProps = {
  type: 'delivery' | 'purchase';
  id: string;
};

export default function NotesInfo({ id, type }: NoteInfoProps) {
  const { data: notes, mutate } = useSWR<CleanNote[]>(
    type === 'delivery'
      ? `/delivery-order/${id}/notes`
      : `/purchase-order/${id}/notes`,
    fetcher,
  );
  const [create, setCreate] = useState(false);
  const speaker = (
    { onClose, left, top, className }: any,
    ref: React.Ref<any>,
  ) => (
    <Popover ref={ref} className={className} style={{ left, top }} full>
      <Dropdown.Menu>
        <Rbac
          object={
            type === 'delivery' ? RbacObject.DeliveryOrder : RbacObject.Purchase
          }
          action={'write:notes'}
        >
          <Dropdown.Item
            onSelect={function () {
              onClose();
              setCreate(true);
            }}
          >
            <i className="bi bi-plus" />
            <span className="ps-2">Добавить</span>
          </Dropdown.Item>
        </Rbac>
      </Dropdown.Menu>
    </Popover>
  );

  const handleForm = () => {
    setCreate(false);
    mutate();
  };

  return (
    <div className="bg-gray p-3 rounded-8 mt-3">
      <div className="d-flex flex-row justify-content-between">
        <div className="fs-18 fw-600 color-gray-450 mb-3">Заметки</div>
        <Whisper placement="bottomEnd" trigger="click" speaker={speaker}>
          <button className="btn btn-link text-decoration-none">
            <i className="bi bi-three-dots" />
          </button>
        </Whisper>
      </div>
      <div className="d-flex flex-column mb-4 mt-4 gap-3">
        {!notes?.length && <div className="fs-18 fw-600">Отсутствуют</div>}
        {notes?.map((note) => {
          return (
            <NoteInfo
              note={note}
              type={type}
              id={id}
              handleUpdate={() => mutate()}
            />
          );
        })}
      </div>
      {create && (
        <NoteForm
          type={type}
          id={id}
          handleSave={handleForm}
          handleCancel={handleForm}
        />
      )}
    </div>
  );
}
