import React, { PropsWithChildren } from 'react';
import { ProfileHeader } from './profile-header.component';
import { SelectLanguage } from './SelectLanguage';

export const Header: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <div className="d-flex flex-row justify-content-between mb-4">
      <div className="flex-grow-1 flex-shrink-1">{children}</div>
      <div className="mt-1">
        <SelectLanguage />
      </div>
      <div className="ms-5">
        <ProfileHeader />
      </div>
    </div>
  );
};
