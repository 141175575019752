import { NavLink } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useMode } from '../../utils/mode';
import { ModalsService } from '../../services/modals.service';
import { ConfirmModal } from './confirm-modal';
import { SelectLanguage } from './SelectLanguage';
import Logo from '../../assets/logo.svg';

/** Общее меню (хедер) для ТСД */
export const TerminalMenu = () => {
  const [, setMode] = useMode();
  const intl = useIntl();

  function changeMode() {
    ModalsService.createModal(ConfirmModal, {
      title: intl.formatMessage({
        defaultMessage: 'Покинуть режим ТСД?',
        id: 'terminal.cta.leavingMode',
        description: 'Кнопка',
      }),
      buttonText: intl.formatMessage({
        defaultMessage: 'Да',
        id: 'terminal.button.yes',
        description: 'Кнопка',
      }),
    }).then(() => setMode('desktop'));
  }

  return (
    <div className="w-100 d-flex justify-content-between align-items-center py-3 px-2">
      <NavLink
        to="/purchases"
        style={{
          border: '3px solid #404A5B',
          borderRadius: '20px',
        }}
        onClick={(e) => {
          e.preventDefault();
          changeMode();
        }}
      >
        <img width="48px" height="48px" src={Logo} alt="" />
      </NavLink>

      <SelectLanguage />
    </div>
  );
};
