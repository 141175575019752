export const MarkupLimitInfo = () => {
  const currency = '$';
  const markupCommissionLimit = 2;

  return (
    <div className="mt-4 d-flex align-items-center ">
      <span className="me-1">
        <i className="bi bi-check-circle-fill text-success"></i>
      </span>
      <span>
        Минимальный тариф от {markupCommissionLimit}
        {currency} за 1 кг веса
      </span>
    </div>
  );
};
