import useSWR, { SWRConfiguration } from 'swr';
import { CleanProfile } from '../export-types/cleaned-types';
import { fetcher } from '../services/api';

export const useProfile = (config?: SWRConfiguration) => {
  const { data, isLoading, mutate } = useSWR<CleanProfile>(
    '/profile',
    fetcher,
    config,
  );
  return { data, isLoading, mutate };
};
