import React from 'react';
import { Header } from '../../common/header.component';
import { useLocation } from 'react-router-dom';
import { useAddCardPoizonItem } from './useAddCardPoizon';
import MyLoader from '../../common/loader.component';
import { CardPoizonFile } from './CardPoizonFile';

export const AddCardPoizon = () => {
  const { state } = useLocation() as {
    state?: { productId: string; modificationId: string };
  };

  const { data: itemInfo, isLoading: itemLoading } =
    useAddCardPoizonItem(state);

  if (itemLoading && !itemInfo) {
    return (
      <>
        <div className="pb-4">
          <MyLoader />
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      <CardPoizonFile itemInfo={itemInfo} />
    </>
  );
};
