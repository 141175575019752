import { useForm, Controller } from 'react-hook-form';
import React, { useEffect } from 'react';
import { AlertService } from '../../../services/alert.service';
import { ModalsService } from '../../../services/modals.service';
import useSWR from 'swr';
import { api, fetcher } from '../../../services/api';
import { InputWrapper } from '../../common/input-wrapper';
import { decimalValidation, required } from '../../../utils/validations';
import { ConfirmModal } from '../../common/confirm-modal';
import { toMoney, getDeliveryPeriod } from '../../../utils/common.utils';
import { Checkbox } from '../../common/checkbox';
import isDecimal from 'validator/es/lib/isDecimal';
import { ConfirmWithCommentsModal } from '../../common/ConfirmWithCommentsModal';
import {
  CleanDeliveryOrder,
  CleanPackageGroup,
  RbacObject,
} from '../../../export-types/cleaned-types';
import { Rbac } from '../../common/Rbac';
import { OrderFinancesAdditional } from '../purchase-order/purchase-order-finances-additional-actions';
import { OrderAdditionalServiceModal } from '../purchase-order/purchase-order-additional-service-modal';
import { useGlobalMutate } from '../../../api/useGlobalMutate';
import {
  useOrderDeliveryPaths,
  useOrderPaths,
} from '../purchase-order/useOrderPaths';

type DeliveryOrderStatus =
  | 'processing'
  | 'assembly'
  | 'sentToRussia'
  | 'receivedInRussia'
  | 'sentToRecipient'
  | 'delivered';

type DeliveryMethod = {
  name: string;
  deliveryPeriodFrom: string;
  deliveryPeriodTo: string;
};

export type DeliveryOrder = CleanDeliveryOrder & {
  id: string;
  status: DeliveryOrderStatus;

  deliveryMethod: DeliveryMethod;
  packageMethods: string;
  packageGroups: Array<CleanPackageGroup> | undefined;

  deliveryPrice: string;
  insurancePrice: string;
  packagePrice: string;
  markupPrice: string;
  prePrice: string;

  moderateDeliveryPrice?: string;
  moderateInsurancePrice?: string;
  moderatePackagePrice?: string;
  moderateMarkupPrice?: string;
  price?: string;

  expenses?: {
    client: string;
    company: string;
  };

  serviceCharge: string;
  moderateServiceCharge: string;
};

type DeliveryOrderFinancesActionsProps = {
  order: DeliveryOrder;
  onEdit: (type: 'actual') => void;
};

const DeliveryOrderFinancesActions: React.FC<
  DeliveryOrderFinancesActionsProps
> = (props) => {
  return (
    <button
      className="btn btn-link text-decoration-none"
      type="button"
      onClick={() => {
        props.onEdit('actual');
      }}
    >
      Изменить
    </button>
  );
};

type DeliveryOrderFinancesInfoProps = {
  id: string;
};

function ClientExpensesTable(props: {
  preTotal: string;
  total?: string;
  actual?: string;
  serviceCharge?: string;
}) {
  return (
    <table className="table table-xs table-borderless mt-3 fw-bold align-middle">
      <tbody>
        <tr>
          <td>
            <div>Итого</div>
            <div className="color-gray-400 fw-400">К оплате клиенту</div>
          </td>
          <td className="text-end" style={{ width: '85px' }}>
            {toMoney(props.preTotal)} $
          </td>
          <td className="text-end" style={{ width: '140px' }}>
            {props.total ? (
              toMoney(Number(props.total) + Number(props.serviceCharge), 'usd')
            ) : props.serviceCharge ? (
              toMoney(Number(props.serviceCharge), 'usd')
            ) : (
              <i className="bi bi-three-dots color-gray-400" />
            )}
          </td>
        </tr>
        <tr>
          <td>Итого оплачено клиентом</td>
          <td colSpan={2} className="text-end">
            {props.actual ? (
              `${toMoney(props.actual)}  $`
            ) : (
              <i className="bi bi-three-dots color-gray-400" />
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function CompanyExpensesTable(props: {
  total?: string;
  actual?: string;
  currency: string;
  moderateServiceCharge?: string;
}) {
  return (
    <table className="table table-xs table-borderless mt-3 fw-bold align-middle">
      <tbody>
        <tr>
          <td>
            <div>Фактические затраты</div>
            <div className="fw-400 color-gray-400">
              на доставку, страховку и упаковку
            </div>
          </td>
          <td className="text-end">
            {props.total ? (
              toMoney(
                Number(props.total) + Number(props.moderateServiceCharge),
                'usd',
              )
            ) : props.moderateServiceCharge ? (
              toMoney(Number(props.moderateServiceCharge), 'usd')
            ) : (
              <i className="bi bi-three-dots color-gray-400" />
            )}
          </td>
        </tr>
        <tr>
          <td>
            <div>Фактических затрат оплачено</div>
          </td>
          <td className="text-end">
            {props.actual ? (
              `${toMoney(props.actual)}  $`
            ) : (
              <i className="bi bi-three-dots color-gray-400" />
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function CompanyProfitTable(props: { order: DeliveryOrder }) {
  return (
    <table className="table table-xs table-borderless mt-3 fw-bold align-middle">
      <tbody>
        <tr>
          <td>Прибыль по заказу</td>
          <td className="text-end">
            {props.order.moderateMarkupPrice &&
            props.order.moderateMarkupPrice !== '0' ? (
              toMoney(
                Number(props.order.moderateMarkupPrice) +
                  Number(props.order.serviceCharge) -
                  Number(props.order.moderateServiceCharge),
                props.order.currency ?? 'usd',
              )
            ) : props.order.serviceCharge &&
              props.order.serviceCharge !== '0' ? (
              toMoney(
                Number(props.order.serviceCharge) -
                  Number(props.order.moderateServiceCharge),
                props.order.currency ?? 'usd',
              )
            ) : (
              <i className="bi bi-three-dots color-gray-400" />
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function AdditionalService(props: { order: DeliveryOrder }) {
  const threeDots = <i className="bi bi-three-dots color-gray-400" />;
  return (
    <table className="table table-xs table-borderless fw-bold align-middle">
      <tbody>
        <tr>
          <td>Дополнительные расходы</td>
          <td className="text-end">
            {props.order.serviceCharge && props.order.serviceCharge !== '0'
              ? toMoney(
                  props.order.serviceCharge,
                  props.order.currency ?? 'usd',
                )
              : threeDots}
          </td>
          <td className="text-end">
            {props.order.moderateServiceCharge &&
            props.order.moderateServiceCharge !== '0'
              ? toMoney(
                  props.order.moderateServiceCharge,
                  props.order.currency ?? 'usd',
                )
              : threeDots}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
export const DeliveryOrderFinancesInfo: React.FC<
  DeliveryOrderFinancesInfoProps
> = ({ id }) => {
  const { data: order, mutate: mutateOrder } = useSWR<DeliveryOrder>(
    `/delivery-order/${id}`,
    fetcher,
  );

  const { countryFrom } = useOrderDeliveryPaths({ orderId: id });
  const [editMode, setEditMode] = React.useState<'actual' | null>(null);
  const { mutate } = useGlobalMutate();

  const [editModeAdditionalService, setEditModeAdditionalService] =
    React.useState<'client' | 'company' | null>(null);

  useEffect(() => {
    if (editModeAdditionalService && order) {
      ModalsService.createModal(OrderAdditionalServiceModal, {
        side: editModeAdditionalService,
        orderId: order?.id,
        orderType: 'delivery-order',
        setEditModeAdditionalService: setEditModeAdditionalService,
      }).then(() => mutate());
    }
  }, [editModeAdditionalService]);

  function handleEditAdditional(type: 'client' | 'company') {
    setEditModeAdditionalService(type);
  }

  const threeDots = <i className="bi bi-three-dots color-gray-400" />;

  function handleEdit(type: 'actual') {
    setEditMode(type);
  }

  function handleCancelEdit() {
    setEditMode(null);
  }

  function handleSave(data: DeliveryOrder) {
    mutateOrder(data);
    setEditMode(null);
  }

  if (!order) {
    return null;
  }

  switch (editMode) {
    case 'actual':
      return (
        <DeliveryOrderActualFinancesForm
          id={id}
          defaultValues={{
            moderateDeliveryPrice: order.moderateDeliveryPrice || undefined,
            moderateInsurancePrice: order.moderateInsurancePrice || undefined,
            moderatePackagePrice: order.moderatePackagePrice || undefined,
            moderateMarkupPrice: order.moderateMarkupPrice || undefined,
            moderateLocalDeliveryPrice:
              order.moderateLocalDeliveryPrice || undefined,
          }}
          onSave={handleSave}
          onCancel={handleCancelEdit}
        />
      );
  }

  return (
    <div>
      <div className="d-flex flex-row justify-content-between">
        <div className="fs-18 fw-600 color-gray-450">Финансы</div>
        {order.status !== 'assembly' && (
          <DeliveryOrderFinancesActions order={order} onEdit={handleEdit} />
        )}
      </div>
      <table className="table table-xs table-borderless mt-3 fw-bold align-middle">
        <thead>
          <tr>
            <th className="color-gray-400">Позиция</th>
            <th className="color-gray-400 text-end" style={{ width: '85px' }}>
              Предв.
            </th>
            <th className="color-gray-400 text-end" style={{ width: '140px' }}>
              Факт
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div>Доставка {countryFrom} — РФ</div>
              <div className="color-gray-400 fw-400">
                {getDeliveryPeriod(order.deliveryMethod)}
              </div>
            </td>
            <td className="text-end">{toMoney(order.deliveryPrice)} $</td>
            <td className="text-end">
              {order.moderateDeliveryPrice
                ? `${order.moderateDeliveryPrice} $`
                : threeDots}
            </td>
          </tr>
          <tr>
            <td>Страховой сбор</td>
            <td className="text-end">{toMoney(order.insurancePrice)} $</td>
            <td className="text-end">
              {order.moderateInsurancePrice
                ? `${toMoney(order.moderateInsurancePrice)} $`
                : threeDots}
            </td>
          </tr>
          <tr>
            <td>
              <div>Упаковка</div>
              <div className="color-gray-400 fw-400">
                {order.packageGroups?.length === 0
                  ? order.packageMethods
                  : order.packageGroups
                      ?.flatMap((group) => group.methods)
                      .map((method) => method.name)
                      .join(', ')}
              </div>
            </td>
            <td className="text-end">{toMoney(order.packagePrice)} $</td>
            <td className="text-end">
              {order.moderatePackagePrice
                ? `${toMoney(order.moderatePackagePrice)} $`
                : threeDots}
            </td>
          </tr>
          <tr>
            <td>Комиссия за доставку</td>
            <td className="text-end">{toMoney(order.markupPrice)} $</td>
            <td className="text-end">
              {order.moderateMarkupPrice
                ? `${toMoney(order.moderateMarkupPrice)} $`
                : threeDots}
            </td>
          </tr>
          <tr>
            <td>Транспортная компания</td>
            <td className="text-end">
              {toMoney(order.localDeliveryPrice ?? 0)} $
            </td>
            <td className="text-end">
              {order.moderateLocalDeliveryPrice
                ? `${order.moderateLocalDeliveryPrice} $`
                : threeDots}
            </td>
          </tr>
        </tbody>
      </table>
      <Rbac
        object={RbacObject.Transaction}
        action={['write:additional-service']}
      >
        <div className="d-flex justify-content-end">
          <div>
            <OrderFinancesAdditional
              onEdit={handleEditAdditional}
              orderType={'delivery-order'}
            />
          </div>
        </div>
      </Rbac>
      <AdditionalService order={order} />
      <hr className="mt-3" />
      <ClientExpensesTable
        preTotal={order.prePrice}
        total={order.price}
        actual={order.expenses?.client}
        serviceCharge={order.serviceCharge}
      />
      <Rbac object={RbacObject.DeliveryOrder} action={'read:finances-company'}>
        <hr className="mt-3" />
        <CompanyExpensesTable
          total={
            order.price
              ? (
                  Number(order.price) - Number(order.moderateMarkupPrice)
                ).toString()
              : undefined
          }
          actual={order.expenses?.company}
          currency={order.currency ?? 'cny'}
          moderateServiceCharge={order.moderateServiceCharge}
        />
        <hr className="mt-3" />
        <CompanyProfitTable order={order} />
      </Rbac>
    </div>
  );
};

async function updateActualFinances(
  id: string,
  data: {
    delivery: string;
    insurance: string;
    package: string;
    markup?: string;
    makeTransaction?: boolean;
    allowOverdraft?: boolean;
    comment?: string;
    localDelivery: string;
  },
): Promise<any> {
  return api
    .put(`/delivery-order/${id}/actual-finances`, data)
    .catch((error) => {
      if (!error.response) throw error;

      if (
        error.response.status !== 409 ||
        !error.response.data ||
        !error.response.data.code
      ) {
        throw error;
      }

      switch (error.response.data.code) {
        case 'need-make-transaction': {
          const amount = Number(error.response.data.data.amount);
          return ModalsService.createModal(ConfirmWithCommentsModal, {
            title:
              amount < 0
                ? 'Итоговая сумма заказа больше оплаченной клиентом'
                : 'Итоговая сумма заказа меньше оплаченной клиентом',
            buttonText:
              amount < 0
                ? `Списать у клиента ${Math.abs(amount)} $`
                : `Вернуть клиенту ${Math.abs(amount)} $`,
          }).then((form) => {
            return updateActualFinances(id, {
              ...data,
              makeTransaction: true,
              ...form,
            });
          });
        }
        case 'need-allow-overdraft': {
          return ModalsService.createModal(ConfirmModal, {
            title: 'На счете клиента недостаточно средств',
            buttonText: `Оплатить заказ в долг`,
          }).then(() =>
            updateActualFinances(id, { ...data, allowOverdraft: true }),
          );
        }
        default:
          AlertService.error('Неизвестная ошибка');
          throw error;
      }
    });
}

type DeliveryOrderActualFinancesFormProps = {
  id: string;
  defaultValues: {
    moderateDeliveryPrice?: string;
    moderateInsurancePrice?: string;
    moderatePackagePrice?: string;
    moderateMarkupPrice?: string;
    moderateLocalDeliveryPrice?: string;
  };
  onSave: (data: DeliveryOrder) => void;
  onCancel: () => void;
};

const DeliveryOrderActualFinancesForm: React.FC<
  DeliveryOrderActualFinancesFormProps
> = (props) => {
  const { data: order } = useSWR<DeliveryOrder>(
    `/delivery-order/${props.id}`,
    fetcher,
  );
  const { countryFrom } = useOrderDeliveryPaths({ orderId: props.id });
  const {
    register,
    formState,
    handleSubmit,
    watch,
    control,
    getValues,
    setValue,
  } = useForm<{
    moderateDeliveryPrice: string;
    moderateInsurancePrice: string;
    moderatePackagePrice: string;
    moderateMarkupPrice: string;
    calculateModerateMarkupPrice: boolean;
    moderateLocalDeliveryPrice: string;
  }>({
    defaultValues: {
      ...props.defaultValues,
      calculateModerateMarkupPrice: false,
    },
    mode: 'onChange',
  });

  const [calculationData, setCalculationData] = React.useState<{
    delivery?: string;
    insurance?: string;
    package?: string;
    markup?: string;
    localDelivery?: string;
  } | null>(null);

  useEffect(() => {
    let handler: ReturnType<typeof setTimeout>;

    const { unsubscribe } = watch((data) => {
      clearTimeout(handler);

      if (
        !isDecimal(data.moderateDeliveryPrice + '') ||
        !isDecimal(data.moderateInsurancePrice + '') ||
        !isDecimal(data.moderatePackagePrice + '') ||
        (!data.calculateModerateMarkupPrice &&
          !isDecimal(data.moderateMarkupPrice + '')) ||
        !isDecimal(data.moderateLocalDeliveryPrice + '')
      ) {
        return;
      }

      handler = setTimeout(() => {
        setCalculationData({
          delivery: data.moderateDeliveryPrice,
          insurance: data.moderateInsurancePrice,
          package: data.moderatePackagePrice,
          markup: data.calculateModerateMarkupPrice
            ? undefined
            : data.moderateMarkupPrice,
          localDelivery: data.moderateLocalDeliveryPrice,
        });
      }, 250);
    });

    const data = getValues();

    if (
      isDecimal(data.moderateDeliveryPrice + '') &&
      isDecimal(data.moderateInsurancePrice + '') &&
      isDecimal(data.moderatePackagePrice + '') &&
      isDecimal(data.moderateLocalDeliveryPrice + '')
    ) {
      setCalculationData({
        delivery: data.moderateDeliveryPrice,
        insurance: data.moderateInsurancePrice,
        package: data.moderatePackagePrice,
        markup: data.calculateModerateMarkupPrice
          ? undefined
          : data.moderateMarkupPrice,
        localDelivery: data.moderateLocalDeliveryPrice,
      });
    }

    return () => {
      clearTimeout(handler);
      unsubscribe();
    };
  }, [getValues, watch]);

  const calculateModerateMarkupPrice = watch('calculateModerateMarkupPrice');

  const { data: calculation } = useSWR<{
    totalClient: string;
    totalCompany: string;
    markup: string;
  }>(() => {
    if (!calculationData) throw new Error();

    return {
      url: '/delivery-order/calculate-actual',
      params: calculationData,
    };
  }, fetcher);

  React.useEffect(() => {
    if (!calculation || !calculateModerateMarkupPrice) return;

    setValue('moderateMarkupPrice', calculation.markup);
  }, [calculateModerateMarkupPrice, calculation, setValue]);

  const onSubmit = handleSubmit(async (data) => {
    const response = await updateActualFinances(props.id, {
      delivery: data.moderateDeliveryPrice,
      insurance: data.moderateInsurancePrice,
      package: data.moderatePackagePrice,
      markup: data.calculateModerateMarkupPrice
        ? undefined
        : data.moderateMarkupPrice,
      localDelivery: data.moderateLocalDeliveryPrice,
    });

    AlertService.success();
    props.onSave(response.data);
  });

  if (!order) {
    return null;
  }

  function handleCancel() {
    props.onCancel();
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="d-flex flex-row justify-content-between">
        <div className="fs-18 fw-600 color-gray-450">Финансы</div>
        <div>
          <button
            className="btn btn-link text-decoration-none"
            disabled={formState.isSubmitting}
          >
            {formState.isSubmitting ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              `Сохранить`
            )}
          </button>
          <button
            className="btn btn-link text-decoration-none"
            type="button"
            onClick={handleCancel}
            disabled={formState.isSubmitting}
          >
            Отменить
          </button>
        </div>
      </div>
      <table className="table table-xs table-borderless mt-3 fw-bold align-middle">
        <thead>
          <tr>
            <th className="color-gray-400">Позиция</th>
            <th className="color-gray-400 text-end" style={{ width: '85px' }}>
              Предв.
            </th>
            <th className="color-gray-400 text-end" style={{ width: '140px' }}>
              Факт
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div>Доставка {countryFrom} — РФ</div>
              <div className="color-gray-400 fw-400">
                {order.deliveryMethod.name},{' '}
                {getDeliveryPeriod(order.deliveryMethod)}
              </div>
            </td>
            <td className="text-end">{toMoney(order.deliveryPrice)} $</td>
            <td className="text-end">
              <InputWrapper
                className="w-101 d-inline-block"
                error={formState.errors.moderateDeliveryPrice?.message}
              >
                <div className="input-group">
                  <input
                    className="form-control"
                    type="number"
                    min={0}
                    step={0.01}
                    {...register('moderateDeliveryPrice', {
                      ...required,
                      ...decimalValidation,
                    })}
                  />
                  <span className="input-group-text">$</span>
                </div>
              </InputWrapper>
            </td>
          </tr>
          <tr>
            <td>Страховой сбор</td>
            <td className="text-end">{toMoney(order.insurancePrice)} $</td>
            <td className="text-end">
              <InputWrapper
                className="w-101 d-inline-block"
                error={formState.errors.moderateInsurancePrice?.message}
              >
                <div className="input-group">
                  <input
                    className="form-control"
                    type="number"
                    min={0}
                    step={0.01}
                    {...register('moderateInsurancePrice', {
                      ...required,
                      ...decimalValidation,
                    })}
                  />
                  <span className="input-group-text">$</span>
                </div>
              </InputWrapper>
            </td>
          </tr>
          <tr>
            <td>
              <div>Упаковка</div>
              <div className="color-gray-400 fw-400">
                {order.packageGroups?.length === 0
                  ? order.packageMethods
                  : order.packageGroups
                      ?.flatMap((group) => group.methods)
                      .map((method) => method.name)
                      .join(', ')}
              </div>
            </td>
            <td className="text-end">{toMoney(order.packagePrice)} $</td>
            <td className="text-end">
              <InputWrapper
                className="w-101 d-inline-block"
                error={formState.errors.moderatePackagePrice?.message}
              >
                <div className="input-group">
                  <input
                    className="form-control"
                    type="number"
                    min={0}
                    step={0.01}
                    {...register('moderatePackagePrice', {
                      ...required,
                      ...decimalValidation,
                    })}
                  />
                  <span className="input-group-text">$</span>
                </div>
              </InputWrapper>
            </td>
          </tr>
          <tr>
            <td>Комиссия за доставку</td>
            <td className="text-end">{order.markupPrice} $</td>
            <td className="text-end">
              <Controller
                name="moderateMarkupPrice"
                control={control}
                rules={{
                  ...required,
                  ...decimalValidation,
                }}
                render={({ field, fieldState }) => (
                  <InputWrapper
                    className="w-101 d-inline-block"
                    error={fieldState.error?.message}
                  >
                    <div className="input-group">
                      <input
                        className="form-control"
                        type="number"
                        min={0}
                        step={0.01}
                        disabled={calculateModerateMarkupPrice}
                        {...field}
                      />
                      <span className="input-group-text">$</span>
                    </div>
                  </InputWrapper>
                )}
              />
            </td>
          </tr>
          <tr>
            <td>Транспортная компания</td>
            <td className="text-end">{order.localDeliveryPrice} $</td>
            <td className="text-end">
              <Controller
                name="moderateLocalDeliveryPrice"
                control={control}
                rules={{
                  ...required,
                  ...decimalValidation,
                }}
                render={({ field, fieldState }) => (
                  <InputWrapper
                    className="w-101 d-inline-block"
                    error={fieldState.error?.message}
                  >
                    <div className="input-group">
                      <input
                        className="form-control"
                        type="number"
                        min={0}
                        step={0.01}
                        {...field}
                      />
                      <span className="input-group-text">$</span>
                    </div>
                  </InputWrapper>
                )}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={3} className="text-end">
              <Controller
                control={control}
                name="calculateModerateMarkupPrice"
                render={({ field }) => {
                  return (
                    <Checkbox
                      className="d-inline-block"
                      title="Авто-расчет комиссии"
                      {...field}
                      onChange={(event) => {
                        field.onChange(event.target.checked);
                      }}
                    />
                  );
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <AdditionalService order={order} />
      <hr className="mt-3" />
      <ClientExpensesTable
        preTotal={order.prePrice}
        total={calculation?.totalClient}
        actual={order.expenses?.client}
        serviceCharge={order.serviceCharge}
      />
      <hr className="mt-3" />
      <CompanyExpensesTable
        total={calculation?.totalCompany}
        actual={order.expenses?.company}
        currency={order.currency ?? 'cny'}
        moderateServiceCharge={order.moderateServiceCharge}
      />
      <hr className="mt-3" />
      <CompanyProfitTable order={order} />
    </form>
  );
};
