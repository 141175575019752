import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export function usePaginationQs(itemsPerPage: number) {
  if (itemsPerPage < 1 || isNaN(itemsPerPage)) {
    throw new Error('usePaginationQs: itemsPerPage must be greater than 1');
  }

  const [searchParams, setSearchParams] = useSearchParams();

  const takeParamName = 'take';
  let take = Number(searchParams.get(takeParamName) || itemsPerPage);
  if (take < 1 || isNaN(take)) {
    take = itemsPerPage;
  }
  const setTake = useCallback(
    (take: number) =>
      setSearchParams((prev) => {
        if (take < 1 || isNaN(take)) {
          take = itemsPerPage;
        }

        prev.delete(takeParamName);
        if (take && take !== itemsPerPage) {
          prev.set(takeParamName, String(take));
        }
        return prev;
      }),
    [itemsPerPage, setSearchParams],
  );

  const pageParamName = 'page';
  let page = Number(searchParams.get(pageParamName) || 1);
  if (page < 1 || isNaN(page)) {
    page = 1;
  }
  const handleSetPage = useCallback(
    (page: number) =>
      setSearchParams((prev) => {
        if (page < 1 || isNaN(page)) {
          page = 1;
        }

        prev.delete(pageParamName);
        if (page && page > 1) {
          prev.set(pageParamName, String(page));
        }
        return prev;
      }),
    [setSearchParams],
  );

  const skip = (page - 1) * take;

  return {
    take,
    skip,
    page,
    handleChangeLimit: setTake,
    handleSetPage,
  };
}
