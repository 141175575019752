import React from 'react';
import Popover from 'rsuite/Popover';
import Dropdown from 'rsuite/Dropdown';
import Whisper from 'rsuite/Whisper';
import { CleanPurchaseOrder } from '../../../export-types/cleaned-types';

type PurchaseOrderFinancesActionsProps = {
  order: CleanPurchaseOrder;
  onEdit: (type: 'client' | 'actual') => void;
};

export const PurchaseOrderFinancesActions: React.FC<
  PurchaseOrderFinancesActionsProps
> = (props) => {
  const speaker = (
    { onClose, left, top, className }: any,
    ref: React.Ref<any>,
  ) => (
    <Popover ref={ref} className={className} style={{ left, top }} full>
      <Dropdown.Menu>
        <Dropdown.Item
          onSelect={function () {
            onClose();
            props.onEdit('client');
          }}
        >
          Расчёт клиента
        </Dropdown.Item>
        {!['waitingForPayment', 'inWork'].includes(props.order.status) && (
          <Dropdown.Item
            onSelect={function () {
              onClose();
              props.onEdit('actual');
            }}
          >
            Фактические затраты
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Popover>
  );

  return (
    <Whisper placement="bottomEnd" trigger="click" speaker={speaker}>
      <button className="btn btn-link text-decoration-none" type="button">
        Изменить
      </button>
    </Whisper>
  );
};
