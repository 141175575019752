import React, { useEffect } from 'react';
import {
  Direction,
  TransactionService,
  useTransactionsList,
} from '../../../services/transaction.service';
import Table, { CellProps } from 'rsuite/Table';
import { TableDateCell } from '../../common/table/table-date-cell.component';
import { CURRENCIES, WALLETS } from '../../../consts/db-values';
import { TableMoneyCallComponent } from '../../common/table/table-money-call.component';
import { getFileV2ApiUrl } from '../../../utils/common.utils';
import { AlertService } from '../../../services/alert.service';
import { Gallery } from '../../common/gallery/gallery';
import { UserInternalIdCell } from '../../common/table/user-internal-id-cell.component';
import IconButton from 'rsuite/IconButton';
import PdfIcon from '../../../assets/pdf-file.svg';
import Pagination from 'rsuite/Pagination';
import {
  CleanTransaction,
  RbacObject,
} from '../../../export-types/cleaned-types';
import { Rbac, useRbac } from '../../common/Rbac';
import { useDebouncedValue } from '@mantine/hooks';
import { Controller, useForm } from 'react-hook-form';
import { usePaginationQs } from '../../../utils/usePagination';
import { SupplierCell } from '../../common/table/supplier-cell';
import { InputWrapper } from '../../common/input-wrapper';
import { DateRangePicker } from 'rsuite';
import { RawDateValue } from '../../../utils/date-value';
import moment from 'moment';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

type Form = {
  userIdInternal: string;
  accountCurrency: string;
  dates: string[];
};

const defaultItemsPerPage = 20;

export const RefillTransactionsTable = () => {
  const intl = useIntl();
  const { take, skip, handleSetPage, handleChangeLimit, page } =
    usePaginationQs(defaultItemsPerPage);
  const [direction, setDirection] = React.useState<Direction>('desc');

  const { isLoading: isRbacLoading, hasPermission } = useRbac();
  const canReadExtendedData =
    !isRbacLoading && hasPermission(RbacObject.Transaction, 'read:admin');

  const { register, control, watch } = useForm<Form>({
    defaultValues: {
      userIdInternal: '',
      accountCurrency: '',
      dates: [],
    },
  });

  const [userInternalIdDebounced] = useDebouncedValue(
    watch('userIdInternal'),
    200,
  );
  const [accountCurrencyDebounced] = useDebouncedValue(
    watch('accountCurrency'),
    200,
  );

  // Взять даты в диапозоне которых взять транзакции
  const [start, end] = watch(['dates'])[0];

  // Получить список транзакций с сервера
  const {
    data: transactions,
    isLoading,
    mutate,
  } = useTransactionsList({
    take,
    skip,
    direction,
    start,
    end,
    userInternalId: userInternalIdDebounced,
    accountCurrency: accountCurrencyDebounced,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [take]);

  const CurrencyCell = ({
    rowData,
    dataKey,
    ...props
  }: CellProps<CleanTransaction>) => {
    return (
      rowData && (
        <Table.Cell {...props}>
          {CURRENCIES[rowData.currency as keyof typeof CURRENCIES]}
        </Table.Cell>
      )
    );
  };

  const ReqTypeCell = ({
    rowData,
    dataKey,
    ...props
  }: CellProps<CleanTransaction>) => {
    if (!rowData) {
      return null;
    }

    let text =
      WALLETS[rowData.walletType as keyof typeof WALLETS] ||
      rowData.privateComment ||
      rowData.comment ||
      rowData.walletType;

    if (
      rowData.walletType === 'cryptomus' &&
      rowData.metadata?.cryptoCreatedFromCash
    ) {
      text = 'Криптовалюта (из наличных)';
    }

    return <Table.Cell {...props}>{text}</Table.Cell>;
  };

  const ProofTransaction = ({
    rowData,
    ...props
  }: CellProps<CleanTransaction>) => {
    if (!rowData) return null;
    if (!rowData.proofFile) {
      return (
        <Table.Cell
          {...props}
          className="no-padding-cell pt-2"
          align="center"
        />
      );
    }

    let anchorAttributes;
    let thumbnail = getFileV2ApiUrl(rowData.proofFile.id, '48x32');
    const original = getFileV2ApiUrl(rowData.proofFile.id);

    if (rowData.proofFile.type === 'pdf') {
      thumbnail = PdfIcon;
      anchorAttributes = {
        'data-iframe': true,
        'data-src': original,
      };
    }

    return (
      <Table.Cell {...props} className="no-padding-cell pt-2" align="center">
        <Gallery>
          <a href={original} {...anchorAttributes}>
            <img
              className="rounded fit-cover"
              src={thumbnail}
              width={rowData.proofFile.type === 'pdf' ? 32 : 48}
              height={32}
            />
          </a>
        </Gallery>
      </Table.Cell>
    );
  };

  const MoneyCell = (props: CellProps<CleanTransaction>) => {
    return (
      props.rowData && (
        <TableMoneyCallComponent {...props} currency={props.rowData.currency} />
      )
    );
  };

  const CheckedCell = ({ rowData, ...props }: CellProps<CleanTransaction>) => {
    const checkTransaction = (
      event: React.MouseEvent<HTMLDivElement>,
      transaction: CleanTransaction,
    ) => {
      if (transaction.status !== 'confirmed') {
        const result = window.confirm(
          'Вы действительно хотите подтвердить платёж?',
        );
        if (!result) {
          event.preventDefault();
          return;
        }
        console.log({ transaction });
        TransactionService.checkTransaction(transaction.id).then(() => {
          AlertService.success();
          mutate();
        });
      }
    };

    return (
      rowData && (
        <Rbac
          object={RbacObject.Transaction}
          action={'write:admin'}
          children={
            <Table.Cell {...props} align="center">
              <div
                className="form-check text-center d-inline-block"
                onClick={(event) => checkTransaction(event, rowData)}
                title={
                  rowData.status === 'confirmed'
                    ? 'Платёж подтверждён'
                    : 'Подтвердить платёж'
                }
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  disabled={rowData.status === 'confirmed'}
                  checked={rowData.status === 'confirmed'}
                  onClick={() => {}}
                />
              </div>
            </Table.Cell>
          }
          otherwise={() => (
            <Table.Cell {...props} align="center">
              <div
                className="form-check text-center d-inline-block"
                title={
                  rowData.status === 'confirmed'
                    ? 'Платёж подтверждён'
                    : 'Платёж не подтверждён'
                }
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  disabled
                  defaultChecked={rowData.status === 'confirmed'}
                />
              </div>
            </Table.Cell>
          )}
        />
      )
    );
  };

  const CancelCell = ({ rowData, ...props }: CellProps<CleanTransaction>) => {
    const handleCancel = (event: React.MouseEvent) => {
      const result = window.confirm('Вы действительно хотите отменить платёж?');
      if (!result) {
        event.preventDefault();
        return;
      }

      TransactionService.cancelTransaction(rowData!.id).then(() => {
        AlertService.success();
        mutate();
      });
    };

    return (
      <Rbac object={RbacObject.Transaction} action={'write:admin'}>
        <Table.Cell
          {...props}
          className="link-group no-padding-cell pt-2"
          align="right"
        >
          {rowData!.status !== 'canceled' &&
            rowData!.status !== 'confirmed' && (
              <IconButton
                appearance="subtle"
                icon={<i className="bi bi-x-circle" />}
                type="button"
                onClick={handleCancel}
                title={'Отменить платёж'}
              />
            )}
        </Table.Cell>
      </Rbac>
    );
  };

  const total = transactions?.total ?? 0;
  const items = transactions?.items ?? [];

  return (
    <div>
      <div className="row mb-1 ms-1 gap-2">
        <input
          style={{ fontSize: '14px' }}
          className="form-control col-auto w-25"
          type="number"
          min={0}
          step={1}
          placeholder={intl.formatMessage({
            defaultMessage: 'Поиск по id пользователя',
            id: 'TransactiosHistrory.placeholder.SearchByUserID',
            description: 'Поле ввода',
          })}
          {...register('userIdInternal')}
        />
        <select
          style={{ fontSize: '14px' }}
          className="form-control col-auto w-25"
          {...register('accountCurrency')}
        >
          <option value="">
            <FormattedMessage
              defaultMessage="Поиск по валюте счёта"
              id="TransactiosHistrory.lable.currency"
              description="Вкладка"
            />
          </option>
          <option value="usd">USD</option>
          <option value="cny">CNY</option>
        </select>
        <Controller
          name="dates"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <InputWrapper
                error={fieldState.error?.message}
                className="w-25 p-0"
              >
                <DateRangePicker
                  className={'w-100'}
                  format={'dd.MM.yyyy'}
                  placeholder={intl.formatMessage({
                    defaultMessage: 'Выберите дату',
                    id: 'RefillTransactiosTable.title.SelectDate',
                    description: 'Пояснение к полю ввода',
                  })}
                  isoWeek={true}
                  showOneCalendar
                  ranges={[]}
                  onChange={(v) => {
                    if (v === null) field.onChange([]);
                    else {
                      field.onChange(
                        v.map(
                          (value) =>
                            moment(value).format('DD.MM.YYYY') as RawDateValue,
                        ),
                      );
                    }
                  }}
                ></DateRangePicker>
              </InputWrapper>
            );
          }}
        />
      </div>

      <Table data={items} headerHeight={57} autoHeight loading={isLoading}>
        <Table.Column>
          <Table.HeaderCell>Tx Id</Table.HeaderCell>
          <Table.Cell dataKey="txid" />
        </Table.Column>
        <Table.Column fullText>
          <Table.HeaderCell>
            <button
              className="d-flex gap-1 w-100 h-100 bg-transparent"
              onClick={() => {
                setDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'));
              }}
            >
              <FormattedMessage
                defaultMessage="Дата"
                id="TransactiosHistrory.lable.date"
                description="Вкладка"
              />{' '}
              <i
                className={classNames(
                  'bi',
                  direction === 'asc' ? 'bi-sort-up' : 'bi-sort-down',
                )}
              ></i>
            </button>
          </Table.HeaderCell>
          <TableDateCell dataKey="createdAt" />
        </Table.Column>
        <Table.Column width={120} fullText>
          <Table.HeaderCell>Поставщик</Table.HeaderCell>
          <SupplierCell />
        </Table.Column>
        <Table.Column width={80} fullText>
          <Table.HeaderCell>
            <FormattedMessage
              defaultMessage="ID клиента"
              id="TransactiosHistrory.lable.ID"
              description="Вкладка"
            />
          </Table.HeaderCell>
          <UserInternalIdCell />
        </Table.Column>
        <Table.Column width={60} fullText>
          <Table.HeaderCell>
            <FormattedMessage
              defaultMessage="Счет"
              id="RefillTransactionsTable.lable.Check"
              description="Вкладка"
            />
          </Table.HeaderCell>
          <CurrencyCell />
        </Table.Column>
        {canReadExtendedData ? (
          <Table.Column minWidth={200} fullText flexGrow={1}>
            <Table.HeaderCell>Способ</Table.HeaderCell>
            <ReqTypeCell />
          </Table.Column>
        ) : null}
        {canReadExtendedData ? (
          <Table.Column fullText>
            <Table.HeaderCell>Номер счета / ID</Table.HeaderCell>
            <Table.Cell dataKey="cardNumber" />
          </Table.Column>
        ) : null}
        {canReadExtendedData ? (
          <Table.Column width={150} fullText>
            <Table.HeaderCell>Сумма пополнения</Table.HeaderCell>
            <TableMoneyCallComponent
              dataKey="userAmount"
              currencyKey="topUpCurrency"
            />
          </Table.Column>
        ) : null}
        {canReadExtendedData ? (
          <Table.Column width={100} fullText>
            <Table.HeaderCell>Курс (внутр.)</Table.HeaderCell>
            <TableMoneyCallComponent dataKey="currencyRateInternal" />
          </Table.Column>
        ) : null}
        <Table.Column width={150} fullText>
          <Table.HeaderCell>
            <FormattedMessage
              defaultMessage="Сумма зачисления"
              id="RefillTransactionsTable.lable.CreditAmount"
              description="Вкладка"
            />
          </Table.HeaderCell>
          <MoneyCell dataKey="amount" />
        </Table.Column>
        {canReadExtendedData ? (
          <Table.Column>
            <Table.HeaderCell>Платежка</Table.HeaderCell>
            <ProofTransaction />
          </Table.Column>
        ) : null}
        <Table.Column width={50}>
          <Table.HeaderCell children="" />
          <CheckedCell />
        </Table.Column>
        <Table.Column width={50}>
          <Table.HeaderCell children="" />
          <CancelCell />
        </Table.Column>
      </Table>

      {total > take && (
        <Pagination
          className="mt-4 pb-4"
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={['pager', '-', 'limit']}
          total={total}
          limitOptions={[defaultItemsPerPage, 50, 100]}
          limit={take}
          activePage={page}
          onChangePage={handleSetPage}
          onChangeLimit={handleChangeLimit}
        />
      )}
    </div>
  );
};
