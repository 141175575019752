import { InputWrapper } from '../../common/input-wrapper';
import {
  decimalValidation,
  intValidation,
  required,
} from '../../../utils/validations';
import { getCurrencySymbol } from '../../../utils/common.utils';
import { AddButton } from '../../common/add-button';
import React from 'react';
import {
  useFieldArray,
  useForm,
  DefaultValues,
  SubmitHandler,
} from 'react-hook-form';

export type Form = {
  name: string;
  over: number;
  tariffs: Array<{
    expensesTo: string;
    markup: string;
  }>;
};

type ClientExpansesBasedCommissionsFormProps = {
  defaultValues?: DefaultValues<Form>;
  onSubmit: SubmitHandler<Form>;
};

export const ClientExpansesBasedCommissionsForm: React.FC<
  ClientExpansesBasedCommissionsFormProps
> = (props) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Form>({
    defaultValues: props.defaultValues || {
      tariffs: [{}],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tariffs',
  });

  const onSubmit = React.useMemo(
    () => handleSubmit(props.onSubmit),
    [handleSubmit, props.onSubmit],
  );

  return (
    <form onSubmit={onSubmit}>
      <div className="row mb-3">
        <InputWrapper
          title="Название"
          error={errors.name?.message}
          className="col-9"
        >
          <input
            className="form-control"
            {...register('name', {
              ...required,
            })}
          />
        </InputWrapper>
        <InputWrapper
          title="Период расчёта"
          error={errors.over?.message}
          className="col-3"
        >
          <div className="input-group">
            <input
              className="form-control"
              type="text"
              {...register('over', {
                ...required,
                ...intValidation,
                min: {
                  value: 1,
                  message: 'Минимум 1 день',
                },
              })}
            />
            <span className="input-group-text">дней</span>
          </div>
        </InputWrapper>
      </div>
      <div className="row">
        <div className="col">
          <h3>Тарифная сетка</h3>
        </div>
      </div>
      <div className="row">
        <InputWrapper className="col-6" title="Оборот до" required />
        <InputWrapper className="col" title="Размер комиссии" required />
      </div>
      {fields.map((field, index) => (
        <div className="row mb-3" key={index}>
          <InputWrapper
            className="col-6"
            error={errors.tariffs?.[index]?.expensesTo?.message}
          >
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                {...register(`tariffs.${index}.expensesTo`, {
                  ...required,
                  ...decimalValidation,
                })}
              />
              <span className="input-group-text">
                {getCurrencySymbol('cny')}
              </span>
            </div>
          </InputWrapper>
          <InputWrapper
            className="col"
            error={errors.tariffs?.[index]?.markup?.message}
          >
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                {...register(`tariffs.${index}.markup`, {
                  ...required,
                  ...decimalValidation,
                })}
              />
              <span className="input-group-text">%</span>
            </div>
          </InputWrapper>
          <div className="col col-auto">
            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={() => remove(index)}
              disabled={fields.length === 1}
            >
              <i className="bi bi-trash p-2 cursor-pointer" />
            </button>
          </div>
        </div>
      ))}
      <div className="row mb-3">
        <div className="col-12">
          <AddButton onClick={() => append({ expensesTo: '', markup: '' })}>
            Добавить тариф
          </AddButton>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-12">
          <button
            type="submit"
            className="btn btn-success w-100"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'Сохранить'
            )}
          </button>
        </div>
      </div>
    </form>
  );
};
