import React from 'react';
import { useForm } from 'react-hook-form';
import { InputWrapper } from '../../common/input-wrapper';
import { decimalValidation, required } from '../../../utils/validations';
import { api } from '../../../services/api';
import { useModalClose } from '../../../utils/useModalClose';

type ParcelCancelModalProps = {
  close: () => void;
  reject: () => void;
  order: {
    id: string;
  };
};

export const ParcelCancelModal: React.FC<ParcelCancelModalProps> = ({
  close,
  order,
  reject,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm<
    | {
        reason: 'arrangedRefund' | 'mistakenAccounted';
        writeOffAmount: string;
        reasonDetails: undefined;
      }
    | {
        reason: 'other';
        reasonDetails: string;
        writeOffAmount: string;
      }
  >();

  const reasons = [
    {
      key: 'mistakenAccounted',
      text: 'Ошибочно оприходовали посылку на складе',
    },
    {
      key: 'arrangedRefund',
      text: 'Посылка пришла на склад, а клиент договорился с продавцом о возврате.',
    },
  ];

  const onSubmit = handleSubmit((data) => {
    let textReason = data.reasonDetails;

    if (data.reason !== 'other') {
      textReason = reasons.find((reason) => reason.key === data.reason)?.text;
    }

    api
      .post(`/parcels/${order.id}/cancel`, {
        reason: textReason,
        writeOffAmount: data.writeOffAmount,
      })
      .then(() => {
        close();
      });
  });

  const modalRef = useModalClose(reject);

  return (
    <div className="modal-dialog mw-400" ref={modalRef}>
      <div className="modal-content">
        <div className="modal-header border-bottom-0 p-4">
          <button
            type="button"
            className="btn-close"
            onClick={() => reject()}
          />
        </div>
        <form className="modal-body pt-2 p-4" onSubmit={onSubmit}>
          <div className="text-center mb-4 mx-auto">
            <i className="bi bi-exclamation-circle-fill color-yellow fs-65 lh-1" />
            <h3 className="mt-3">Вы отменяете посылку</h3>
            <div className="mt-3">Укажите сумму списания и причину отмены</div>
          </div>
          <InputWrapper
            className="mb-3"
            title="Сумма списания"
            error={errors.writeOffAmount?.message}
          >
            <div className="input-group">
              <input
                type="number"
                min={0}
                step={0.01}
                className="form-control"
                {...register('writeOffAmount', {
                  ...decimalValidation,
                })}
              />
              <span className="input-group-text">¥</span>
            </div>
          </InputWrapper>
          <InputWrapper
            className="mb-3"
            title="Причина отмены"
            required
            error={errors.reason?.message}
          >
            <select
              className="form-select"
              {...register('reason', {
                ...required,
              })}
            >
              <option value="" hidden>
                Выберите причину
              </option>
              {reasons.map((reason) => (
                <option value={reason.key}>{reason.text}</option>
              ))}
              <option value="other">Другое</option>
            </select>
          </InputWrapper>
          {watch('reason') === 'other' && (
            <InputWrapper
              className="mb-5"
              error={errors.reasonDetails?.message}
            >
              <textarea
                className="form-control"
                {...register('reasonDetails', {
                  ...required,
                })}
              />
            </InputWrapper>
          )}
          <button className="btn btn-success w-100">Отменить посылку</button>
        </form>
      </div>
    </div>
  );
};
