import React from 'react';
import { useForm } from 'react-hook-form';
import { InputWrapper } from '../../common/input-wrapper';
import { decimalValidation, required } from '../../../utils/validations';
import { useModalClose } from '../../../utils/useModalClose';

type Form = { refundAmount: string; privateComment: string };

type PurchaseOrderActualFinancesRefundModalProps = {
  close: (data: Form) => void;
  reject: () => void;
  defaultValues: {
    refundAmount: string;
  };
};

export const PurchaseOrderActualFinancesRefundModal: React.FC<
  PurchaseOrderActualFinancesRefundModalProps
> = (props) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<Form>({
    defaultValues: props.defaultValues,
  });

  const onSubmit = handleSubmit((data) => {
    props.close(data);
  });

  const modalRef = useModalClose(props.reject);

  return (
    <div className="modal-dialog mw-400" ref={modalRef}>
      <div className="modal-content">
        <div className="modal-header border-bottom-0 p-4">
          <button
            type="button"
            className="btn-close"
            onClick={() => props.reject()}
          />
        </div>
        <form className="modal-body pt-2 p-4" onSubmit={onSubmit}>
          <div className="text-center mb-4 mx-auto">
            <i className="bi bi-exclamation-circle-fill color-yellow fs-65 lh-1" />
            <h3 className="mt-3">Итоговая сумма затрат меньше оплаченной</h3>
          </div>
          <InputWrapper
            className="mb-3"
            title="Сумма возврата"
            error={errors.refundAmount?.message}
          >
            <div className="input-group">
              <input
                type="number"
                min={0}
                step={0.01}
                className="form-control"
                {...register('refundAmount', {
                  ...required,
                  ...decimalValidation,
                  min: {
                    value: 0,
                    message: 'Должно быть больше или равна 0',
                  },
                  max: {
                    value: Number(props.defaultValues.refundAmount),
                    message: `Должно быть меньше или равно ${props.defaultValues.refundAmount}`,
                  },
                })}
              />
              <span className="input-group-text">¥</span>
            </div>
          </InputWrapper>
          <InputWrapper
            title="Комментарий для администратора"
            className="mb-3"
            error={errors.privateComment?.message}
            required
          >
            <textarea
              placeholder="Укажите здесь дополнительную информацию для администратора"
              className="form-control fs-16 lh-sm"
              {...register('privateComment', { ...required })}
            />
          </InputWrapper>
          <button className="btn btn-success w-100 mb-3">
            Вернуть на баланс
          </button>
          <button
            type="button"
            className="btn btn-light w-100"
            onClick={() => props.reject()}
          >
            Отменить
          </button>
        </form>
      </div>
    </div>
  );
};
