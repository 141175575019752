import { useOrderCategories } from '../../../consts/db-values';
import React from 'react';

type PurchaseOrderCategory =
  | 'householdGoods'
  | 'smallHouseholdGoods'
  | 'clothes';

type NameCellProps = {
  name: string | null;
  category?: PurchaseOrderCategory | null;
};

export const NameCell: React.FC<NameCellProps> = ({ name, category }) => {
  const ORDER_CATEGORIES = useOrderCategories();
  return (
    <>
      <div className="color-gray-800 fs-16 text-truncate">{name}</div>
      <div className="color-gray-600 fs-14">
        {category && ORDER_CATEGORIES[category]}
      </div>
    </>
  );
};
