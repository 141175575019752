import React from 'react';
import { CleanPurchaseOrder } from '../../../export-types/cleaned-types';
import { useModalClose } from '../../../utils/useModalClose';
import { Controller, useForm } from 'react-hook-form';
import { PurchaseOrderItem } from './delivery-order-create-screen.component';
import classNames from 'classnames';
import { getFileV2ApiUrl } from '../../../utils/common.utils';
import { InputWrapper } from '../../common/input-wrapper';

type Form = {
  purchaseOrders: string[];
};

type AddPackageGroupModalProps = {
  data?: CleanPurchaseOrder[];
  close: () => void;
  reject: () => void;
  availablePurchaseOrders: string[];
  purchaseOrders: string[];
  onSubmit: (value: Form) => void;
};
export const AddPackageGroupModal: React.FC<AddPackageGroupModalProps> = (
  props,
) => {
  const { control, handleSubmit } = useForm<{
    purchaseOrders: string[];
  }>({ defaultValues: { purchaseOrders: props.purchaseOrders } });

  const modalRef = useModalClose(props.reject);

  const onSubmit = (value: Form) => {
    props.onSubmit(value);
    props.close();
  };

  return (
    <>
      <div className="modal-dialog" ref={modalRef}>
        <form
          id="addPackageGroupForm"
          className="modal-content"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="modal-body">
            <Controller
              name="purchaseOrders"
              control={control}
              render={({ field, fieldState }) => (
                <InputWrapper
                  error={fieldState.error?.message}
                  className="mb-4"
                >
                  {props.availablePurchaseOrders.map((order, i) => {
                    const purchase = props.data?.find(
                      (item: CleanPurchaseOrder) => item.id === order,
                    );

                    if (!purchase) {
                      return <></>;
                    }

                    const selected = field.value.includes(order);

                    return (
                      <div className="d-flex" key={i}>
                        <div
                          className="position-relative me-2"
                          style={{ width: '6rem' }}
                          onClick={() => {
                            if (!selected) {
                              field.onChange([...field.value, order]);
                            } else {
                              const newValue = [...field.value];
                              newValue.splice(newValue.indexOf(order), 1);
                              field.onChange(newValue);
                            }
                          }}
                        >
                          <div
                            className={classNames(
                              'position-absolute d-flex rounded-8 w-100 h-100 justify-content-center align-items-center cursor-pointer',
                              !selected
                                ? 'hover-showing bg-black bg-opacity-75'
                                : 'bg-green-500',
                            )}
                          >
                            <i
                              className={classNames(
                                'bi bi-check-lg cursor-pointer fs-3 hover-opacity color-white',
                              )}
                            />
                          </div>
                          <img
                            className="rounded w-100 "
                            src={getFileV2ApiUrl(
                              purchase.productFilesV2[0].id,
                              '64x64',
                            )}
                            alt=""
                          />
                        </div>
                        <PurchaseOrderItem key={order} order={purchase} />
                      </div>
                    );
                  })}
                </InputWrapper>
              )}
            />
          </div>
          <div className="modal-footer justify-content-end">
            <button
              type="button"
              className="btn btn-success"
              onClick={props.reject}
            >
              Отмена
            </button>
            <button type="submit" className="btn btn-success">
              Добавить
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
