import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CleanPurchaseOrder } from '../../export-types/cleaned-types';

import { Select } from './select.component';
import { useSuppliers } from '../../api/suppliers';

import { trpc } from '../../api/trpc';
import { AlertService } from '../../services/alert.service';

type RequestEditFormProps = {
  order: CleanPurchaseOrder | undefined;
  supplier?: string | null;
  onSave: (data: CleanPurchaseOrder) => void;
  onCancel: () => void;
};

export const SupplierInfoEdit: React.FC<RequestEditFormProps> = (props) => {
  const { data: suppliers } = useSuppliers();

  const { control, handleSubmit, formState, getValues, setValue } = useForm<{
    supplierId: string | null;
  }>({
    defaultValues: {
      supplierId: String(props.order?.supplier?.id),
    },
  });

  const [supplierOrder, setSupplierOrder] = useState(
    props.order?.supplier?.id || null,
  );

  const onSubmit = handleSubmit(async () => {
    if (props.order) {
      await trpc.purchaseOrder.admin.setSupplier.mutate({
        id: props.order?.id,
        supplierId: Number(supplierOrder),
      });

      AlertService.success();
      props.onSave(props.order);
    }
  });

  function handleCancel() {
    props.onCancel();
  }

  return (
    <form onSubmit={onSubmit} className="p-3 rounded-8 mt-3">
      <div className="d-flex flex-row justify-content-between">
        <div className="fs-18 fw-600 color-gray-450">Поставщик</div>
        <div>
          <button
            className="btn btn-link text-decoration-none"
            disabled={formState.isSubmitting}
          >
            {formState.isSubmitting ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'Сохранить'
            )}
          </button>
          <button
            className="btn btn-link text-decoration-none"
            type="button"
            disabled={formState.isSubmitting}
            onClick={handleCancel}
          >
            Отменить
          </button>
        </div>
      </div>
      <Controller
        name="supplierId"
        control={control}
        render={() => (
          <Select
            title="Привязанный к заказу поставщик"
            placeholder=""
            className="mb-3"
            options={
              suppliers
                ?.filter((supplier) => !supplier.disabled)
                .map(({ id, name }) => ({
                  value: id,
                  label: name,
                })) ?? []
            }
            withEmpty
            value={supplierOrder}
            onChange={({ target: { value } }) => setSupplierOrder(value)}
          />
        )}
      />
    </form>
  );
};
