import { Controller, useForm, useWatch } from 'react-hook-form';
import { required } from '../../../utils/validations';
import { Input } from '../../common/input.component';
import React from 'react';
import { ModalProps as BaseModalProps } from '../../../services/modals.service';
import { CleanSupplier } from '../../../export-types/cleaned-types';
import { AlertService } from '../../../services/alert.service';
import { useSuppliers } from '../../../api/suppliers';
import { api } from '../../../services/api';
import { useLocations } from '../../../api/locations';
import { Select } from '../../common/select.component';
import { InputWrapper } from '../../common/input-wrapper';
import { useModalClose } from '../../../utils/useModalClose';
import { Checkbox } from '../../common/checkbox';
import { useDeliveryMethods } from '../../../api/use-delivery-methods';

const CURRENCIES_OPTIONS = [
  {
    label: 'USD',
    value: 'usd',
  },
  {
    label: 'CNY',
    value: 'cny',
  },
];

type FieldValues = {
  name: string;
  addressForParcels: string;
  locationId?: number;
  purchaseCurrency?: string;
  deliveryCurrency?: string;
  deliveryOrderCombineRule?: {
    enabled: boolean;
    json?: string;
    deliveryMethodId?: string;
  };
  priority: number | null;
};

type ModalProps = BaseModalProps & {
  supplier?: CleanSupplier;
};

export const SupplierModal = ({ close, supplier }: ModalProps) => {
  const { mutate } = useSuppliers();
  const { data: locations } = useLocations();
  const { data: deliveryMethods } = useDeliveryMethods();

  const supplierDeliveryMethods = supplier
    ? deliveryMethods?.filter(
        ({ supplier: deliveryMethodSupplier }) =>
          deliveryMethodSupplier && deliveryMethodSupplier.id === supplier.id,
      )
    : [];

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm<FieldValues>({
    defaultValues: {
      name: supplier?.name,
      addressForParcels: supplier?.addressForParcels,
      locationId: supplier?.location?.id,
      purchaseCurrency: supplier?.purchaseCurrency,
      deliveryCurrency: supplier?.deliveryCurrency,
      deliveryOrderCombineRule: {
        enabled: false,
        ...supplier?.deliveryOrderCombineRule,
      },
      priority: supplier?.priority,
    },
  });

  const isEdit = !!supplier;
  const combineRuleEnabled = useWatch({
    control,
    name: 'deliveryOrderCombineRule.enabled',
    defaultValue: getValues('deliveryOrderCombineRule.enabled'),
  });

  function onSave(data: FieldValues) {
    const promise = isEdit
      ? api.put(`/supplier/${supplier.id}`, data)
      : api.post('/supplier', data);
    promise.then(() => {
      AlertService.success();
      close();
      return mutate();
    });
  }

  const modalRef = useModalClose(close);

  return (
    <div className="modal-dialog" ref={modalRef}>
      <form className="modal-content" onSubmit={handleSubmit(onSave)}>
        <div className="modal-body">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h3>{isEdit ? 'Редактировать' : 'Добавить'} поставщика</h3>
            <div className="btn p-0" onClick={close}>
              <i className="bi bi-x fs-36 color-gray-600" />
            </div>
          </div>
          <Input
            className="mb-3"
            title="Название"
            {...register('name', {
              ...required,
            })}
            error={errors.name?.message}
          />
          <Controller
            name="locationId"
            control={control}
            rules={required}
            render={({ field }) => (
              <Select
                className="mb-3"
                title="Локация"
                placeholder="Выберите локацию"
                options={
                  locations?.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  })) ?? []
                }
                {...field}
                error={errors.locationId?.message}
              />
            )}
          />
          {!isEdit && (
            <Controller
              name="purchaseCurrency"
              control={control}
              rules={required}
              render={({ field, fieldState }) => (
                <Select
                  className="mb-3"
                  title="Валюта заказов"
                  placeholder="Выберите валюту"
                  options={CURRENCIES_OPTIONS}
                  {...field}
                  error={fieldState.error?.message}
                />
              )}
            />
          )}
          {!isEdit && (
            <Controller
              name="deliveryCurrency"
              control={control}
              rules={required}
              render={({ field, fieldState }) => (
                <Select
                  className="mb-3"
                  title="Валюта доставок"
                  placeholder="Выберите валюту"
                  options={CURRENCIES_OPTIONS}
                  {...field}
                  error={fieldState.error?.message}
                />
              )}
            />
          )}
          <Input
            title="Приоритет"
            type="number"
            step="1"
            {...register('priority')}
            error={errors.priority?.message}
          />
          <div className="fs-14 color-gray-400">
            Влияет на сортировку. Чем меньше, тем выше в списке
          </div>

          <InputWrapper
            theme="light"
            className="mt-3"
            title="Адрес для посылок"
            error={errors.addressForParcels?.message}
          >
            <textarea
              rows={10}
              className="form-control"
              {...register('addressForParcels', {
                ...required,
              })}
            />
            <div className="">
              В адрес можно подставить Id пользователя, используйте{' '}
              <code>{'{user.internalId}'}</code>
            </div>
          </InputWrapper>
          {isEdit && (
            <Controller
              name="deliveryOrderCombineRule.enabled"
              control={control}
              render={({ field }) => (
                <Checkbox
                  className="mt-3"
                  title="Включить сборные доставки"
                  {...field}
                />
              )}
            />
          )}
          {isEdit && combineRuleEnabled && (
            <InputWrapper
              theme="light"
              className="mt-3"
              title="Правило сборной доставки"
              error={errors.deliveryOrderCombineRule?.json?.message}
            >
              <textarea
                rows={6}
                className="form-control"
                {...register('deliveryOrderCombineRule.json')}
              />
            </InputWrapper>
          )}
          {isEdit && combineRuleEnabled && (
            <Controller
              name="deliveryOrderCombineRule.deliveryMethodId"
              control={control}
              render={({ field }) => (
                <Select
                  className="mt-3"
                  title="Способ доставки"
                  placeholder="Выберите способ доставки"
                  options={
                    supplierDeliveryMethods?.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    })) ?? []
                  }
                  {...field}
                  error={
                    errors.deliveryOrderCombineRule?.deliveryMethodId?.message
                  }
                />
              )}
            />
          )}
        </div>
        <div className="modal-footer justify-content-end">
          <button type="submit" className="btn btn-success">
            {isEdit ? 'Сохранить' : 'Добавить'}
          </button>
        </div>
      </form>
    </div>
  );
};
