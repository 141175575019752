import Table, { CellProps } from 'rsuite/Table';
import { CleanSupplier } from '../../../export-types/cleaned-types';
import React from 'react';

export const SupplierCell = <T extends { supplier?: CleanSupplier }>({
  rowData,
  dataKey,
  ...props
}: CellProps<T>) => (
  <Table.Cell {...props}>
    {rowData?.supplier ? rowData.supplier.name : null}
  </Table.Cell>
);
