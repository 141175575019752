import { Controller, useForm } from 'react-hook-form';
import { TransactionService } from '../../../services/transaction.service';
import { AlertService } from '../../../services/alert.service';
import { required } from '../../../utils/validations';
import { Select } from '../../common/select.component';
import { CURRENCIES } from '../../../consts/db-values';
import { Input } from '../../common/input.component';
import { getCurrencySymbol } from '../../../utils/common.utils';
import { InputWrapper } from '../../common/input-wrapper';
import React, { useEffect } from 'react';
import { ModalProps } from '../../../services/modals.service';

import { useGlobalMutate } from '../../../api/useGlobalMutate';
import { useCurrencyCurrent } from '../../../services/currency.service';
import { useSuppliers } from '../../../api/suppliers';
import { useModalClose } from '../../../utils/useModalClose';

type FieldValues = {
  currency: string;
  amount: number;
  currencyRate: number;
  privateComment: string;
  supplierId: number;
};

export const RefillCompanyModal = ({ close }: ModalProps) => {
  const { data: currency } = useCurrencyCurrent();
  const { data: suppliers } = useSuppliers();

  const {
    register,
    control,
    formState: { errors, touchedFields },
    handleSubmit,
    watch,
    setValue,
  } = useForm<FieldValues>();

  const formValues = watch();

  function getCurrencies() {
    const currencies = ['cny', 'usd'];

    return currencies.map((currency) => ({
      label: CURRENCIES[currency as keyof typeof CURRENCIES],
      value: currency,
    }));
  }

  useEffect(() => {
    if (formValues.currency && currency) {
      const defaultCny = Number(currency.cnyRubExternal);
      const defaultUsd = Number(currency.usdRubExternal);
      const current = Number(formValues.currencyRate);

      if (
        (!current && !touchedFields.currencyRate) ||
        (formValues.currency === 'usd' && current === defaultCny) ||
        (formValues.currency === 'cny' && current === defaultUsd)
      ) {
        const rate = Number(
          formValues.currency === 'cny' ? defaultCny : defaultUsd,
        );
        setValue('currencyRate', rate, { shouldDirty: true });
      }
    }
  }, [
    setValue,
    formValues.currency,
    formValues.currencyRate,
    currency,
    touchedFields.currencyRate,
  ]);

  const { mutate } = useGlobalMutate();

  function onSave(refill: FieldValues) {
    TransactionService.createCompanyTransaction({
      ...refill,
      type: 'refill',
    }).then(() => {
      AlertService.success();
      mutate();
      close();
    });
  }

  const total = (formValues.amount * formValues.currencyRate).toFixed(2);

  const modalRef = useModalClose(close);

  return (
    <div className="modal-dialog" ref={modalRef}>
      <form className="modal-content" onSubmit={handleSubmit(onSave)}>
        <div className="modal-body">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h3>Добавить пополнение</h3>
            <div className="btn p-0" onClick={close}>
              <i className="bi bi-x fs-36 color-gray-600" />
            </div>
          </div>
          <Controller
            name="supplierId"
            control={control}
            rules={required}
            render={({ field }) => (
              <Select
                className="mb-3"
                title="Поставщик"
                placeholder="Выберите поставщика"
                options={
                  suppliers?.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  })) ?? []
                }
                {...field}
                error={errors.supplierId?.message}
              />
            )}
          />
          <Controller
            name="currency"
            control={control}
            rules={required}
            render={({ field }) => (
              <Select
                className="mb-3"
                title="Счёт пополнения"
                placeholder="Выберите счет"
                options={getCurrencies()}
                {...field}
                error={errors.currency?.message}
              />
            )}
          />
          <Input
            className="mb-3"
            title="Сумма пополнения"
            type="number"
            min={0}
            step={0.01}
            {...register('amount', {
              ...required,
            })}
            error={errors.amount?.message}
            postfix={getCurrencySymbol(formValues.currency)}
          />
          <Input
            className="mb-3"
            title="Курс"
            type="number"
            min={0}
            step={0.000001}
            {...register('currencyRate', {
              ...required,
            })}
            error={errors.amount?.message}
            postfix={
              formValues.currency
                ? `${getCurrencySymbol(
                    formValues.currency,
                  )}/${getCurrencySymbol('rub')}`
                : ''
            }
          />
          <Input
            className="mb-3"
            title="Итого"
            type="number"
            disabled
            value={total}
            postfix={getCurrencySymbol('rub')}
          />
          <InputWrapper
            title="Комментарий для администратора"
            error={errors.privateComment?.message}
            required
          >
            <textarea
              placeholder="Укажите здесь дополнительную информацию для администратора"
              className="form-control fs-16 lh-sm"
              {...register('privateComment', { ...required })}
            />
          </InputWrapper>
        </div>
        <div className="modal-footer justify-content-end">
          <button type="submit" className="btn btn-success">
            Добавить
          </button>
        </div>
      </form>
    </div>
  );
};
