// noinspection JSUnusedGlobalSymbols

export type ErrorResponse = Readonly<{
  ErrorCode: Exclude<ErrorCode, 'Ok'>;
  ErrorDescription: string;
  SubErrorCode: {
    Value: string;
  };
  ErrorDescriptionTemplate?: string;
  ErrorDescriptionArguments?: Record<
    string,
    Array<{ Name: string; Value: string }>
  >;
  RequestId: string;
  RequestTime: number;
  RequestTimeStatistic?: string;
  HasTranslateErrors?: true;
}>;
export type SuccessResponse<ResponseBody extends Record<string, unknown>> =
  Readonly<
    { ErrorCode: 'Ok' } & Omit<ErrorResponse, 'ErrorCode'> & ResponseBody
  >;
export type ApiResponse<S extends Record<string, unknown>> =
  | ErrorResponse
  | SuccessResponse<S>;

export function isErrorResponse<
  R extends ApiResponse<Record<string, unknown>>,
  // @ts-expect-error zzz
>(response: R): response is ErrorResponse {
  return response?.ErrorCode !== 'Ok';
}

export type ErrorCode =
  | 'Ok'
  | 'AccessDenied'
  | 'ContractViolation'
  | 'InternalError'
  | 'NotFound'
  | 'NotAvailable'
  | 'ValidationError'
  | 'AlreadyExists'
  | 'SessionExpired'
  | 'AccountIsBanned'
  | 'BusinessLogicError'
  | 'IncorrectKey'
  | 'TechnicalProblems'
  | 'TopapiProtocolError'
  | 'TopapiBusinessError'
  | 'TopapiUnknownError'
  | 'PaymentError'
  | 'LoginFailed'
  | 'EmailConfirmationFailed'
  | 'BatchError'
  | 'Waiting';
export type ItemErrorCode = 'Ok' | 'NotFound' | 'NotAvailable' | 'Expired';
export const Providers = [
  'Undefined',
  'Taobao',
  'Warehouse',
  'YahooJapan',
  'YahooJapanAuction',
  'Kitmall',
  'Alibaba1688',
  'Jd',
  'Dropwow',
  'EBay',
  'Alibaba',
  'Aliexpress',
  'Wildberries',
  'EBaySandbox',
  'Amazon',
  'Pinduoduo',
  'Lazada',
  'Shein',
  'AmazonChina',
  'AliexpressKorea',
  'AliexpressSaudiArabia',
  'AliexpressSingapore',
  'Trendyol',
] as const;
export type ProviderType = (typeof Providers)[number];
export type FilterReason =
  | 'ByItemId'
  | 'ByItemTitle'
  | 'ByCategoryId'
  | 'ByVendorId'
  | 'ByBrandId';
export type CategoryItemType = {
  Id: string;
  ErrorCode?: ItemErrorCode;
  HasError?: true;
  ProviderType?: ProviderType;
  UpdatedTime: string | null;
  IsHidden: boolean;
  IsVirtual: boolean;
  ExternalId: string;
  Name: string;
  IsParent: boolean;
  ParentId?: string;
  ApproxWeight: number | null;
  ProviderApproxWeight: number | null;
  IsSearch?: true;
  SearchMethod?: string;
  IsInternal: boolean;
  AvailableItemRatingListContentTypes?: Array<
    'Item' | 'Vendor' | 'Category' | 'SearchString' | 'Brand'
  >;
  RootPath?: {
    Content: Array<CategoryItemType>;
  };
  IconImageUrl?: string;
  MetaData?: Record<string, Array<{ Name: string; Value: string }>>;
  IsFiltered?: true;
  FilterReasons?: Array<FilterReason>;
};
/**
 * @see http://docs.otapi.net/ru/Documentations/Method?name=GetRootCategoryInfoList
 */
export type GetRootCategoryInfoList = {
  CategoryInfoList: {
    Content: Array<CategoryItemType>;
  };
};
/**
 * @see http://docs.otapi.net/ru/Documentations/Method?name=GetCategorySubcategoryInfoList
 */
export type GetCategorySubcategoryInfoList = GetRootCategoryInfoList;
export type PictureType = {
  /** @deprecated */
  Url: string;
  Small: PictureSizeType;
  Medium: PictureSizeType;
  Large: PictureSizeType;
};
export type PictureSizeType = { Url: string; Width: number; Height: number };
export type PriceType = {
  OriginalPrice: number;
  MarginPrice: number;
  OriginalCurrencyCode: string;
  ConvertedPriceList: {
    Internal: {
      Price: number;
      Sign: string;
      Code: string;
    };
    DisplayedMoneys: Array<{
      Price: number;
      Sign: string;
      Code: string;
    }>;
  };
};
export type ItemPrice = PriceType & {
  /** @deprecated */
  ConvertedPrice: string;
  /** @deprecated */
  ConvertedPriceWithoutSign: string;
  /** @deprecated */
  CurrencySign: string;
  /** @deprecated */
  CurrencyName: string;
  IsDeliverable: boolean;
  DeliveryPrice: PriceType;
  OneItemDeliveryPrice: PriceType;
  PriceWithoutDelivery: PriceType;
  OneItemPriceWithoutDelivery: PriceType;
  PriceWithoutDiscount?: PriceType;
  PriceWithoutDiscountAndDelivery?: PriceType;
};
export type Location = {
  City?: string;
  State?: string;
  Country?: string;
  District?: string;
  Zip?: string;
  Address?: string;
  AreaId?: string;
};
export const StuffStatuses = ['New', 'Unused', 'Second', 'Another'] as const;
export type StuffStatus = (typeof StuffStatuses)[number];
export type BatchSearchItemsFrameItem = {
  Id: string;
  ErrorCode: ItemErrorCode;
  HasError: boolean;
  ProviderType?: ProviderType;
  UpdatedTime: string | null;
  CreatedTime: string | null;
  Language?: string;
  Title?: string;
  IsTitleManuallyTranslated?: boolean;
  OriginalTitle?: string;
  CategoryId?: string;
  IsRatingListCategory?: boolean;
  ExternalCategoryId?: string;
  VendorId?: string;
  VendorName?: string;
  VendorDisplayName?: string;
  VendorScore: number;
  Description?: string;
  BrandId: string;
  BrandName?: string;
  TaobaoItemUrl?: string;
  ExternalItemUrl?: string;
  MainPictureUrl?: string;
  StuffStatus: StuffStatus;
  Volume: number;
  Price: ItemPrice;
  MasterQuantity: number;
  Pictures?: Array<
    PictureType & {
      IsMain: boolean;
    }
  >;
  Location?: Location;
  Features?: Array<string>;
  FeaturedValues?: Array<{ Name: string; Value: string }>;
  IsSellAllowed: boolean;
  SellDisallowReason?:
    | 'IsAuction'
    | 'IsSecondhand'
    | 'IsNotDeliverable'
    | 'IsFiltered'
    | 'IsInStock'
    | 'IsNotSelector'
    | 'IsIncomplete'
    | 'IsDeleted'
    | 'IsUnknownQuantity';
  PhysicalParameters?: {
    ApproxWeight: number;
    ProviderApproxWeight: number;
    Weight: number;
    ManualWeight: number;
    NetWeight: number;
    RoughWeight: number;
    Size: number;
    Length: number;
    Width: number;
    Height: number;
  };
  IsFiltered: boolean;
  FilterReasons?: Array<FilterReason>;
  PromotionPrice?: PriceType;
  PromotionPricePercent?: Array<{
    CurrencyCode: string;
    Percent: number;
  }>;
  QuantityRanges?: Array<{ MinQuantity: number; Price: ItemPrice }>;
};
export type BatchSearchItemsFrameVendorItem = {
  Id: string;
  ErrorCode?: 'Ok' | 'NotFound' | 'NotAvailable' | 'Expired';
  HasError?: true;
  ProviderType?: ProviderType;
  UpdatedTime: string | null;
  Name?: string;
  DisplayName?: string;
  ShopName?: string;
  Sex?: 'Male' | 'Female';
  Email?: string;
  PictureUrl?: string;
  DisplayPictureUrl?: string;
  Location?: Location;
  Credit: {
    Level: number;
    Score: number;
    TotalFeedbacks: number;
    PositiveFeedbacks: number;
  };
  Scores: {
    DeliveryScore: number;
    ItemScore: number;
    ServiceScore: number;
  };
  Features?: Array<string>;
  FeaturedValues?: Array<{
    Name: string;
    Value: string;
  }>;
};
export type BatchSearchItemsFrameBrand = {
  Id: string;
  ExternalId: string;
  ProviderType: ProviderType;
  Name: string;
  Description: string;
  PictureUrl: string;
  IsHidden: boolean;
  IsNameSearch: boolean;
  IsGlobal: boolean;
  ItemCount?: number;
};
export const Modules = ['Provider', 'Image', 'Reviews'] as const;
export type Module = (typeof Modules)[number];
/**
 * @see http://docs.otapi.net/ru/Documentations/Method?name=BatchSearchItemsFrame
 */
export type BatchSearchItemsFrame = {
  Result: {
    Items: {
      Items: {
        Content: Array<BatchSearchItemsFrameItem>;
        TotalCount: number;
      };
      Categories: {
        Content: Array<{
          Id: string;
          ErrorCode?: ItemErrorCode;
          HasError?: true;
          ProviderType?: ProviderType;
          UpdatedTime?: string | null;
          IsFiltered: boolean;
          FilterReasons?: Array<FilterReason>;
          ExternalId?: string;
          Name: string;
          IsInternal: boolean;
          IsHidden: boolean;
          ItemCount: number;
        }>;
      };
      Brands: { Content: Array<{ BrandId: string; BrandName: string }> };
      TranslatedItemTitle?: string;
      CurrentImageUrl?: string;
      Provider: ProviderType;
      SearchMethod: string;
      CurrentSort: string;
      CurrentFrameSize: number;
      MaximumPageCount: number;
      IsFoundByItemId?: boolean;
    };
    ItemsError: {
      ErrorCode: ErrorCode;
      ErrorDescription: string;
    };
    SubCategories?: { Content: Array<CategoryItemType> };
    SubCategoriesError: {
      ErrorCode: ErrorCode;
      ErrorDescription: string;
    };
    RootPath?: {
      Content: Array<CategoryItemType>;
    };
    RootPathError?: {
      ErrorCode: ErrorCode;
      ErrorDescription: string;
    };
    SearchProperties?: {
      Content: Array<{
        Id?: string;
        Name?: string;
        IsBrand?: boolean;
        IsCategory?: boolean;
        Values: Array<{
          Id: string;
          Name: string;
          Alias: string;
          ItemCount?: number | null;
        }>;
      }>;
    };
    SearchPropertiesError?: {
      ErrorCode: ErrorCode;
      ErrorDescription: string;
    };
    Vendor?: {
      Content: Array<BatchSearchItemsFrameVendorItem>;
    };
    VendorError?: {
      ErrorCode: ErrorCode;
      ErrorDescription: string;
    };
    Brand?: BatchSearchItemsFrameBrand;
    BrandError: {
      ErrorCode: ErrorCode;
      ErrorDescription: string;
    };
    Category?: CategoryItemType;
    CategoryError?: {
      ErrorCode: ErrorCode;
      ErrorDescription: string;
    };
    HintCategories?: { Content: Array<CategoryItemType> };
    HintCategoriesError?: {
      ErrorCode: ErrorCode;
      ErrorDescription: string;
    };
    AvailableSearchMethods?: {
      Content: Array<{
        Provider: ProviderType;
        SearchMethod: string;
        DisplayName: string;
        Flags: Array<'Extended'>;
        OptimalFrameSize: number;
        MaximumFrameSize: number;
        MaximumItemsCount: number;
        AvailableSorts: Array<{
          Field: string;
          Direction: 'Asc' | 'Desc';
          DisplayName: string;
        }>;
        Text: boolean;
        Category: boolean;
        Vendor: boolean;
        VendorLocation: boolean;
        Brand: boolean;
        Configurators: boolean;
        MultipleConfiguratorLogic: 'None' | 'All' | 'Any';
        PriceRange: boolean;
        VendorRatingRange?: boolean;
        MinVendorRating: number;
        MaxVendorRating?: number;
        VolumeRange: boolean;
        VolumeRangeLogic?: 'None' | 'Range' | 'OnlyMin' | 'OnlyMax';
        StuffStatus: boolean;
        Features: Array<{
          Name: string;
          CanBeTrue: boolean;
          CanBeFalse: boolean;
          DisplayName: string;
          DisplayNameForTrue: string;
          DisplayNameForFalse: string;
        }>;
        FirstLotRange?: boolean;
        UpdatedTimeRange: boolean;
        CreatedTimeRange: boolean;
        FirstLotRangeLogic: 'None' | 'Range' | 'OnlyMin' | 'OnlyMax';
        EnableMorphologyPreprocess: boolean;
        Module: Module;
        AllowSearchWithoutParameters: boolean;
        IsStorage: boolean;
      }>;
    };
    AvailableSearchMethodsError?: {
      ErrorCode: ErrorCode;
      ErrorDescription: string;
    };
  };
};
export const OutputModes = ['Full', 'TotalCount', 'Provider'] as const;
export type OutputMode = (typeof OutputModes)[number];
export const CategoryModes = [
  'Nothing',
  'External',
  'InternalLeaf',
  'CurrentLevel',
] as const;
export type CategoryMode = (typeof CategoryModes)[number];
/**
 * Должно быть хотя бы одно из полей: CategoryId, VendorName, VendorId, ItemTitle, BrandId, Configurators
 * @see http://docs.otapi.net/ru/Documentations/Method?name=BatchSearchItemsFrame
 */
export type SearchItemsParameters = {
  /** Идентификатор категории, в которой искать товары */
  CategoryId?: string;
  /** Поисковая строка по названию товара */
  ItemTitle?: string;
  /** Имя продавца */
  VendorName?: string;
  /** Идентификатор продавца */
  VendorId?: string;
  /** Идентификатор искомой торговой марки */
  BrandId?: string;
  /** Список поисковых свойств, представленных структурой */
  Configurators?: Array<{ PropertyId: string; ValueId: string }>;

  Provider?: ProviderType;
  SearchMethod?: string;
  VendorAreaId?: string;
  LanguageOfQuery?: string;
  MinPrice?: number;
  MaxPrice?: number;
  CurrencyCode?: string;
  MinVolume?: number;
  MaxVolume?: number;
  MinVendorRating?: number;
  MaxVendorRating?: number;
  OrderBy?: string;
  OutputMode?: OutputMode;
  CategoryMode?: CategoryMode;
  /** @deprecated */ IsTmall?: boolean;
  StuffStatus?: StuffStatus;
  Features?: Array<{ Name: string; Value: boolean }>;
  UseOptimalFrameSize?: boolean;
  MinFirstLot?: number;
  MaxFirstLot?: number;
  MinUpdatedTime?: string;
  MaxUpdatedTime?: string;
  MinCreatedTime?: string;
  MaxCreatedTime?: string;
  Module?: Module;
  ImageUrl?: string;
  ImageFileId?: string;
};
export const BatchSearchItemsFrameBlockListParams = [
  'SubCategories',
  'SearchProperties',
  'RootPath',
  'Vendor',
  'Brand',
  'Category',
  'HintCategories',
  'AvailableSearchMethods',
] as const;

/**
 * @see http://docs.otapi.net/ru/Documentations/Method?name=BatchSearchItemsFrame
 */
export type BatchSearchItemsFrameParams = {
  /**
   * Позиция первого запрошенного элемента из всего списка. Нумерация начинается с 0, а не с 1.
   * Не является номером страницы.
   * Если нужно получить это значение из номера страницы, нужно умножить на размер страницы (параметр frameSize)
   */
  framePosition?: number;
  /** сколько показать на странице (если не передавать, то будет включен xmlParameters.UseOptimalFrameSize) */
  frameSize?: number;
  xmlParameters?: SearchItemsParameters;
  /** @see https://docs.otcommerce.com/pages/viewpage.action?pageId=23889934#id-%D0%9F%D0%BE%D0%B8%D1%81%D0%BA(OTAPI)-%D0%94%D0%BE%D0%BF%D0%BE%D0%BB%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B5%D0%B1%D0%BB%D0%BE%D0%BA%D0%B8%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%B8%D0%B2BatchSearchItemsFrame */
  blockList?: Array<(typeof BatchSearchItemsFrameBlockListParams)[number]>;
};

export const BatchGetSimplifiedItemFullInfoBlockListParams = [
  'Vendor',
  'RootPath',
  'ProviderReviews',
  'MostPopularVendorItems16',
  'RecommendedItems',
  'Description',
  'OriginalDescription',
  'ProviderReviews',
] as const;

export type ItemParameters = {
  AllowIncomplete?: boolean;
  AllowDeleted?: boolean;
  ForceUpdate?: boolean;
  WaitingTime?: number;
  SourceAreaCode?: string;
  TargetAreaCode?: string;
};
/**
 * @see http://docs.otapi.net/ru/Documentations/Method?name=BatchGetSimplifiedItemFullInfo
 */
export type BatchGetSimplifiedItemFullInfoParams = {
  itemId: string;
  itemParameters?: ItemParameters;
  blockList?: Array<
    (typeof BatchGetSimplifiedItemFullInfoBlockListParams)[number]
  >;
};

export type CurrencyType = {
  Id: string;
  DisplayName: string;
};
export type RecommendedItem = {
  Id: string;
  ErrorCode?: ErrorCode;
  ProviderType?: ProviderType;
  UpdatedTime?: string | null;
  Availability: {
    Id: string;
    Value: boolean;
    DisplayName?: string;
    DisplayType?: 'Default' | 'Label' | 'StarRating' | 'TaobaoRating';
  };
  Title: string;
  Category: {
    Id: string;
    DisplayName: string;
  };
  Vendor?: {
    Id: string;
    DisplayName: string;
  };
  Brand?: {
    Id: string;
    DisplayName: string;
  };
  ExternalItemUrl?: string;
  Pictures?: Array<PictureType>;
  Features?: Array<{
    Id: string;
    Value: string;
    DisplayName?: string;
    DisplayType?: 'Default' | 'Label' | 'StarRating' | 'TaobaoRating';
  }>;
  IsFiltered?: true;
  FilterReasons?: Array<FilterReason>;
  Price: {
    OldPrice?: ConfigurationPriceType;
    Price?: ConfigurationPriceType;
    DiscountPercent?: number;
    InternalDelivery?: ConfigurationPriceType;
  };
  QuantityRanges?: Array<{
    OldPrice?: ConfigurationPriceType;
    Price?: ConfigurationPriceType;
    DiscountPercent?: number;
    InternalDelivery?: ConfigurationPriceType;
    MinQuantity: number;
    MaxQuantity?: number;
  }>;
};
export type BatchGetSimplifiedItemFullInfo = {
  Result: {
    Currency: CurrencyType;
    Item: {
      Id: string;
      ErrorCode?: ErrorCode;
      ProviderType?: ProviderType;
      UpdatedTime?: string | null;
      Availability: {
        Id: string;
        Value: boolean;
        DisplayName?: string;
        DisplayType?: 'Default' | 'Label' | 'StarRating' | 'TaobaoRating';
      };
      Title: string;
      Category: {
        Id: string;
        DisplayName: string;
      };
      Vendor?: {
        Id: string;
        DisplayName: string;
      };
      Brand?: {
        Id: string;
        DisplayName: string;
      };
      ExternalItemUrl?: string;
      Pictures?: Array<PictureType>;
      Features?: Array<{
        Id: string;
        Value: string;
        DisplayName?: string;
        DisplayType?: 'Default' | 'Label' | 'StarRating' | 'TaobaoRating';
      }>;
      IsFiltered?: true;
      FilterReasons?: Array<FilterReason>;
      Videos?: Array<{
        PreviewUrl: string;
        Url: string;
      }>;
      Configurators?: {
        MultiInputPropertyId?: string;
        Property: Array<{
          Id: string;
          DisplayName?: string;
          Value: Array<{
            Id: string;
            Name?: string;
            DisplayName?: string;
            Picture?: PictureType;
          }>;
        }>;
      };
      Properties?: Array<{
        Id: string;
        DisplayName?: string;
        Value: Array<{
          Id: string;
          Name?: string;
          DisplayName?: string;
          Picture?: PictureType;
        }>;
      }>;
      Weight?: { Value: number; DisplayName: string };
      BatchLotQuantity?: number;
      RelatedGroups?: Array<{
        Name: string;
        Items: Array<{ Id: string; Name: string }>;
      }>;
    };
    Description?: string;
    Vendor?: {
      Id: string;
      ErrorCode?: 'Ok' | 'NotFound' | 'NotAvailable' | 'Expired';
      HasError?: true;
      ProviderType?: ProviderType;
      UpdatedTime: string | null;
      Name: string;
      DisplayName?: string;
      ShopName?: string;
      Sex?: 'Male' | 'Female';
      Email?: string;
      PictureUrl?: string;
      DisplayPictureUrl?: string;
      Location?: Location;
      Features?: Array<{
        Id: string;
        Value: string;
        DisplayName?: string;
        DisplayType?: 'Default' | 'Label' | 'StarRating' | 'TaobaoRating';
      }>;
    };
    RootPath?: {
      Content: Array<CategoryItemType>;
    };
    VendorItems?: {
      Content: Array<RecommendedItem>;
      TotalCount: number;
    };
    RecommendedItems?: {
      Content: Array<RecommendedItem>;
      TotalCount: number;
    };
    ProviderReviews?: {
      Content: Array<{
        ItemId?: string;
        ConfigurationId?: string;
        Content: string;
        CreatedDate: string | null;
        Author?: string;
        Rating?: number;
        Result?: string;
        Images: Array<string>;
        Videos: Array<{
          PreviewUrl: string;
          Url: string;
        }>;
      }>;
      TotalCount: number;
    };
  };
};

export const BatchGetSimplifiedItemConfigurationInfoBlockListParams = [
  'AdditionalPrices',
  'DeliveryModes',
] as const;

export type ConfigurationInfoSelected = {
  ConfigurationId?: string;
  Quantity?: number;
  Property?: Array<{ Id: string; ValueId: string }>;
};

/**
 * @see http://docs.otapi.net/ru/Documentations/Method?name=BatchGetSimplifiedItemConfigurationInfo
 */
export type BatchGetSimplifiedItemConfigurationInfoParams = {
  itemId: string;
  itemParameters?: ItemParameters;
  xmlRequest?: {
    Current?: ConfigurationInfoSelected;
    Selected?: Array<ConfigurationInfoSelected>;
  };
  blockList?: Array<
    (typeof BatchGetSimplifiedItemConfigurationInfoBlockListParams)[number]
  >;
};
export type ConfigurationPriceType = {
  Base: number;
  Min?: number;
  Max?: number;
};
export type BatchGetSimplifiedItemConfigurationInfo = {
  Result: {
    Currency: CurrencyType;
    Configuration: {
      Availability: {
        Id: string;
        Value: boolean;
        DisplayName?: string;
        DisplayType?: 'Default' | 'Label' | 'StarRating' | 'TaobaoRating';
      };
      Properties?: {
        MultiInputPropertyId?: string;
        Property: Array<{
          Id: string;
          Value: Array<{
            Id: string;
            Disabled: boolean;
            Current: boolean;
            Selected: boolean;
          }>;
        }>;
      };
      Current: {
        OldPrice?: ConfigurationPriceType;
        Price?: ConfigurationPriceType;
        DiscountPercent?: number;
        InternalDelivery?: ConfigurationPriceType;
        IsFullConfiguration: boolean;
        ConfigurationId: string;
        AvailableQuantity: number;
        SalesCount: number;
      };
      MultiInputConfigurations?: Array<{
        OldPrice?: ConfigurationPriceType;
        Price?: ConfigurationPriceType;
        DiscountPercent?: number;
        InternalDelivery?: ConfigurationPriceType;
        IsFullConfiguration: boolean;
        ConfigurationId: string;
        AvailableQuantity: number;
        SalesCount: number;
        InputPropertyId: string;
        InputPropertyValueId: string;
      }>;
      QuantityRanges?: Array<{
        OldPrice?: ConfigurationPriceType;
        Price?: ConfigurationPriceType;
        DiscountPercent?: number;
        InternalDelivery?: ConfigurationPriceType;
        MinQuantity: number;
        MaxQuantity?: number;
      }>;
      TotalCost: number | null;
    };
    AdditionalPrices?: Array<{
      DisplayName: string;
      ShortDisplayName: string;
      Type: 'InternalDeliveryPerVendor' | 'InternalDeliveryPerItem';
      Name: string;
      Price: {
        OldPrice?: ConfigurationPriceType;
        Price?: ConfigurationPriceType;
        DiscountPercent?: number;
        InternalDelivery?: ConfigurationPriceType;
      };
    }>;
    DeliveryModes?: Array<{
      Id: string;
      Name?: string;
      Description?: string;
      Price: ConfigurationPriceType;
      IsPickupPointMode: boolean;
    }>;
  };
};

export const BatchSearchRatingListsParamsApplicationType = [
  'WebSite',
  'MobileApplication',
  'MobileApplication/Android',
  'MobileApplication/iOS',
] as const;

export type BatchSearchRatingListsParams = {
  applicationType?: (typeof BatchSearchRatingListsParamsApplicationType)[number];
  xmlSearchParameters: {
    RatingLists?: Array<{
      CategoryId?: string;
      ItemRatingType?: 'Best' | 'Popular' | 'Last' | 'Category';
      IsRandomSearch?: boolean;
      ContentType?: 'Item' | 'Vendor' | 'Category' | 'SearchString' | 'Brand';
      FramePosition?: number;
      FrameSize?: number;
    }>;
    UseDefaultParameters?: boolean;
  };
};

/**
 * @see http://docs.otapi.net/ru/Documentations/Method?name=BatchSearchRatingLists
 */
export type BatchSearchRatingLists = {
  Result: {
    Items: RatingList<BatchSearchItemsFrameItem>;
    Vendors: RatingList<BatchSearchItemsFrameVendorItem>;
    Categories: RatingList<CategoryItemType>;
    Brands: RatingList<BatchSearchItemsFrameBrand>;
    SearchStrings: RatingList<string>;
  };
};

type RatingList<ResultItem> = Array<{
  ContentType: 'Item' | 'Vendor' | 'Category' | 'SearchString' | 'Brand';
  CategoryId: string;
  ItemRatingType: 'Best' | 'Popular' | 'Last' | 'Category';
  HasError: boolean;
  Name: string;
  Order: number;
  Result: {
    Content: Array<ResultItem>;
    TotalCount: number;
  };
}>;

export const Languages = ['ru', 'zh-chs'] as const;
export type Language = (typeof Languages)[number] | undefined;
