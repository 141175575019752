import React from 'react';
import ContentLoader from 'react-content-loader';

const MyLoader: React.FC = (props) => (
  <ContentLoader
    speed={3}
    width="100%"
    height={604}
    backgroundColor="#f2f4f7"
    foregroundColor="#e4e7ec"
    {...props}
  >
    <rect x="0" y="0" rx="8" ry="8" width="100%" height="65" />
    <rect x="0" y="77" rx="8" ry="8" width="100%" height="65" />
    <rect x="0" y="154" rx="8" ry="8" width="100%" height="65" />
  </ContentLoader>
);

export default MyLoader;
