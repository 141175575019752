import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { api } from '../../../services/api';
import { AlertService } from '../../../services/alert.service';
import { InputWrapper } from '../../common/input-wrapper';
import { required } from '../../../utils/validations';
import { useDeliveriesMethod } from '../../../api/delivery';

type Form = {
  deliveryMethod?: string;
};

type RequestDeliveryMethodEditFormProps = {
  id: string;
  purchaseOrders: string[];
  deliveryMethod?: string;
  onSave: () => void;
  onCancel: () => void;
};

export const RequestDeliveryMethodEditForm: React.FC<
  RequestDeliveryMethodEditFormProps
> = (props) => {
  const { data } = useDeliveriesMethod(props.purchaseOrders);

  const { control, formState, handleSubmit } = useForm<Form>({
    defaultValues: {
      deliveryMethod: data?.delivery.find(
        (method) => method.name === props.deliveryMethod,
      )?.id,
    },
  });

  const onSubmit = handleSubmit(async (form) => {
    api
      .put(`/delivery-order/${props.id}/delivery-method`, {
        deliveryMethod: form.deliveryMethod,
      })
      .then(() => {
        AlertService.success();
        props.onSave();
      });
  });

  function handleCancel() {
    props.onCancel();
  }

  return (
    <form onSubmit={onSubmit}>
      <Controller
        control={control}
        name="deliveryMethod"
        rules={{
          ...required,
        }}
        render={({ field, fieldState }) => (
          <InputWrapper
            theme="light"
            className="mt-3"
            title="Доставка Китай — РФ"
            error={fieldState.error?.message}
          >
            <select {...field} className="form-select">
              {data?.delivery?.map((deliveryMethod) => (
                <option
                  value={deliveryMethod.id}
                  selected={deliveryMethod.name === props.deliveryMethod}
                >
                  {deliveryMethod.name}
                </option>
              ))}
            </select>
          </InputWrapper>
        )}
      />
      <div className="mt-2">
        <button
          className="btn btn-link text-decoration-none"
          disabled={formState.isSubmitting}
        >
          {formState.isSubmitting ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            'Сохранить'
          )}
        </button>
        <button
          className="btn btn-link text-decoration-none"
          type="button"
          disabled={formState.isSubmitting}
          onClick={handleCancel}
        >
          Отменить
        </button>
      </div>
    </form>
  );
};
