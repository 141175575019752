import React from 'react';
import { Header } from '../../common/header.component';
import { Rbac } from '../../common/Rbac';
import { CleanUser, RbacObject } from '../../../export-types/cleaned-types';
import { NavLink, Outlet } from 'react-router-dom';
import { ModalsService } from '../../../services/modals.service';
import { AddUserModal } from './add-user-modal.component';
import { useGlobalMutate } from '../../../api/useGlobalMutate';

export type UserProps = CleanUser & {
  moneyBill: { cny: number; usd: number };
  deliveryDebt: number | null;
};

export const UsersScreen = () => {
  const { mutate } = useGlobalMutate();

  const addUser = () => {
    ModalsService.createModal(AddUserModal, { mutate });
  };

  return (
    <>
      <Header>
        <div className="d-flex flex-row align-items-center">
          <h1>Пользователи</h1>
          <Rbac object={RbacObject.User} action={'write:admin'}>
            <div className="ps-4">
              <div className="btn btn-success" onClick={addUser}>
                <i className="bi bi-plus-circle-fill me-2" />
                Добавить
              </div>
            </div>
          </Rbac>
        </div>
      </Header>
      <div className="d-flex flex-row">
        <ul className="nav nav-pills mb-4">
          <li className="nav-item">
            <NavLink
              to="/users/clients"
              className="nav-link text-decoration-none"
            >
              Клиенты
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/users/employees"
              className="nav-link text-decoration-none"
            >
              Сотрудники
            </NavLink>
          </li>
        </ul>
      </div>
      <Outlet />
    </>
  );
};
