import React from 'react';
import { useModalClose } from '../../../utils/useModalClose';
import { decimalValidation, required } from '../../../utils/validations';
import { InputWrapper } from '../../common/input-wrapper';
import { MediaInput, MediaInputValue } from '../../common/media-input';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { uploadMediaInputFiles } from '../../../services/file-service';
import * as Sentry from '@sentry/react';
import { api } from '../../../services/api';
import { AlertService } from '../../../services/alert.service';
import { getCurrencySymbol } from '../../../utils/common.utils';

type PurchaseOrderWriteOffModalProps = {
  close: () => void;
  reject: () => void;
  order: {
    id: string;
    currency: string;
  };
};
type Form = {
  amount: string;
  description: string;
  receiptFiles: MediaInputValue[];
};
export const PurchaseOrderWriteoffModal: React.FC<
  PurchaseOrderWriteOffModalProps
> = ({ close, order, reject }) => {
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    control,
  } = useForm<Form>();
  const intl = useIntl();
  const onSubmit = handleSubmit(async (data) => {
    let receiptFilesResponse;
    try {
      receiptFilesResponse = await uploadMediaInputFiles(data.receiptFiles);
    } catch (e) {
      Sentry.captureException(e, {
        extra: { debug: 'PurchaseOrderWriteoffModal: файлы' },
      });
      return;
    }

    try {
      await api.post(`/purchase-order/${order.id}/additionalWriteOff`, {
        amount: data.amount,
        description: data.description,
        receiptFiles: receiptFilesResponse.map((response) => ({
          id: response.file.id,
        })),
      });
    } catch (e) {
      Sentry.captureException(e, {
        extra: { debug: 'PurchaseOrderWriteoffModal: api' },
      });
      return;
    }

    AlertService.success();
    close();
  });
  const modalRef = useModalClose(reject);
  return (
    <div className="modal-dialog mw-400" ref={modalRef}>
      <form className="modal-content" onSubmit={onSubmit}>
        <div className="modal-header border-bottom-0 p-4">
          <h3>
            <FormattedMessage
              defaultMessage="Добавить списание"
              id="order.status-label.additionalWriteOff"
              description="Добавление списания"
            />
          </h3>
          <button
            type="button"
            className="btn-close"
            onClick={() => reject()}
          />
        </div>
        <div className="modal-body pt-2 p-4">
          <Controller
            control={control}
            name="receiptFiles"
            rules={{ ...required }}
            render={({ field, fieldState }) => (
              <InputWrapper
                title={intl.formatMessage({
                  defaultMessage: 'Фотографии подтверждения оплаты',
                  id: 'input.label.photosOfReceipt',
                  description: 'Заголовок поля ввода',
                })}
                className="mb-3"
                error={fieldState.error?.message}
                required
              >
                <MediaInput {...field} multiple />
              </InputWrapper>
            )}
          />
          <InputWrapper
            title={intl.formatMessage({
              defaultMessage: 'Сумма списания',
              id: 'input.label.writeOffAmount',
              description: 'Заголовок поля ввода',
            })}
            className="mb-3"
            error={errors.amount?.message}
            required
          >
            <div className="input-group">
              <input
                className="form-control"
                type="number"
                min={0}
                step={0.01}
                {...register('amount', {
                  ...required,
                  ...decimalValidation,
                })}
              />
              <span className="input-group-text">
                {getCurrencySymbol(order.currency)}
              </span>
            </div>
          </InputWrapper>
          <InputWrapper
            title={intl.formatMessage({
              defaultMessage: 'Описание',
              id: 'input.label.writeOffDescription',
              description: 'Заголовок поля ввода',
            })}
            className="mb-3"
            error={errors.amount?.message}
            required
          >
            <div className="input-group">
              <textarea className="form-control" {...register('description')} />
            </div>
          </InputWrapper>
        </div>
        <div className="modal-footer justify-content-end">
          <button
            type="submit"
            className="btn btn-success"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Списать"
                id="btn.label.createAdditionalWriteOff"
                description="Надпись на кнопке"
              />
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
