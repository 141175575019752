import React from 'react';
import { useModalClose } from '../../../utils/useModalClose';
import { Controller, useForm } from 'react-hook-form';
import { Address } from '../../../api/delivery';
import { FormattedMessage, useIntl } from 'react-intl';
import Toggle from 'rsuite/Toggle';
import { InputWrapper } from '../../common/input-wrapper';
import { Company, InnSelect } from '../../common/inn-select';
import {
  passportValidation,
  phoneValidation,
} from '../../../utils/validations';
import { Input } from '../../common/input.component';
import {
  CdekPickupPoint,
  CdekPickupPointSelect,
} from '../../common/cdek-pickup-point-select';
import { CitySelect } from '../../common/city-select';

type Form = {
  name: string;
  phone: string;
  city: string;
  type: 'legal' | 'individual' | 'pickupPoint';
  company?: Company;
  passport?: string;
  cdekCode?: string;
};

type NewForm = {
  name: string;
  phone: string;
  city: string;
  type: 'legal' | 'individual' | 'pickupPoint';
  companyInn?: string;
  companyName?: string;
  passport?: string;
  cdekCode?: string;
};
type AddressFormModalProps = {
  isCdek?: boolean;
  defaultValues?: Address;
  close: () => void;
  reject: () => void;
  onSubmit: (value: Form) => void;
};

export const AddressFormModal: React.FC<AddressFormModalProps> = (props) => {
  const intl = useIntl();

  const { control, handleSubmit, watch, setValue } = useForm<Form>({
    defaultValues: props.defaultValues
      ? {
          ...props.defaultValues,
          company: {
            name: props.defaultValues.companyName,
            inn: props.defaultValues.companyInn,
          },
        }
      : {
          type: 'legal',
        },
    mode: 'onChange',
  });

  const modalRef = useModalClose(props.reject);

  const [type, company, city] = watch(['type', 'company', 'city']);

  const onSelectCompany = (company: Company) => {
    setValue('company', company);
  };

  const onSelectPickupPoint = (pickupPoint?: CdekPickupPoint) => {
    setValue('cdekCode', pickupPoint?.code);
  };

  const onSubmit = handleSubmit((form: Form) => {
    const result: NewForm = {
      type: form.type,
      name: form.name,
      phone: form.phone,
      city: form.city,
      passport: form.passport,
      cdekCode: form.cdekCode,
    };

    if (form.company && form.type === 'legal') {
      result.companyInn = form.company.inn;
      result.companyName = form.company.name;
    }
    props.onSubmit(result);
    props.close();
  });

  return (
    <>
      <div className="modal-dialog" ref={modalRef}>
        <form
          id="addressForm"
          className="modal-content p-2"
          onSubmit={onSubmit}
        >
          <h1>Добавить адрес и получателя</h1>

          <div className="mb-4  color-gray-600">
            <FormattedMessage
              defaultMessage="В&nbsp;случае неверного заполнения полей, повлекшего невозможность
                            сдачи груза, груз передается на&nbsp;платное хранение."
              id="AddressFormModal.label.cargoIsTransferredToPaidStorage"
              description="Заголовок в модальном окне"
            />
          </div>

          <Controller
            name="city"
            control={control}
            rules={{
              required: intl.formatMessage({
                defaultMessage: 'Укажите город доставки',
                id: 'AddressFormModal.input-label.specifyDeliveryCity',
                description: 'Пояснение к полю ввода',
              }),
            }}
            render={({ field, fieldState }) => (
              <InputWrapper
                required
                title={intl.formatMessage({
                  defaultMessage: 'Город доставки',
                  id: 'AddressFormModal.input-label.deliveryCity',
                  description: 'Заголовок поля ввода',
                })}
                className="w-100 mb-3"
                error={fieldState.error?.message}
              >
                <CitySelect
                  name={'city'}
                  value={field.value}
                  onSelectCity={(value) => {
                    field.onChange(value);
                  }}
                  placeholder={intl.formatMessage({
                    defaultMessage: 'Например, Краснодар',
                    id: 'AddressFormModal.placeholder-label.forExampleKrasnodar',
                    description: 'Поле ввода',
                  })}
                />
              </InputWrapper>
            )}
          />
          <Controller
            control={control}
            name="type"
            render={({ field }) => (
              <div className="d-flex gap-2 mb-3">
                <Toggle
                  checked={field.value === 'legal'}
                  onChange={(value: boolean) => {
                    if (value) {
                      field.onChange('legal');
                    } else {
                      field.onChange('individual');
                    }
                  }}
                />
                <FormattedMessage
                  defaultMessage="Получатель Юридическое лицо или ИП"
                  id="AddressFormModal.label.recipientLegalEntityOrIndividualEntrepreneur"
                  description="Переключатель"
                />
              </div>
            )}
          />
          {type === 'legal' && (
            <Controller
              name="company"
              control={control}
              rules={{
                required: intl.formatMessage({
                  defaultMessage: 'Укажите ИНН организации или ИП',
                  id: 'form.label.indicateTheTINOfTheOrganization...',
                  description: 'Пояснение к полю ввода',
                }),
              }}
              render={({ field, fieldState }) => {
                return (
                  <InputWrapper
                    required
                    title={intl.formatMessage({
                      defaultMessage: 'Название организации или ИП',
                      id: 'form.input-label.nameOfOrganizationOrIndividualEntrepreneur',
                      description: 'Заголовок поля ввода',
                    })}
                    error={fieldState.error?.message}
                    className="mb-3"
                  >
                    <InnSelect
                      {...field}
                      value={company as Company}
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Введите название компании или ИНН',
                        id: 'form.input-label.enterCompanyNameOrTaxIdentificationNumber',
                        description: 'Поле ввода',
                      })}
                      onSelectCompany={onSelectCompany}
                    />
                  </InputWrapper>
                );
              }}
            />
          )}
          <Controller
            name="name"
            control={control}
            rules={{
              required:
                type === 'individual'
                  ? intl.formatMessage({
                      defaultMessage: 'Укажите ФИО',
                      id: 'AddressFormModal.input-label.enterYourFullName',
                      description: 'Пояснение к полю ввода',
                    })
                  : intl.formatMessage({
                      defaultMessage: 'Укажите ФИО получателя',
                      id: 'AddressFormModal.input-label.enterTheRecipientFullName',
                      description: 'Пояснение к полю ввода',
                    }),
            }}
            render={({ field, fieldState }) => (
              <InputWrapper
                required
                title={
                  type === 'individual'
                    ? intl.formatMessage({
                        defaultMessage: 'ФИО',
                        id: 'AddressFormModal.input-label.fullName',
                        description: 'Заголовок поля ввода',
                      })
                    : intl.formatMessage({
                        defaultMessage: 'ФИО получателя',
                        id: 'AddressFormModal.input-label.recipientsName',
                        description: 'Заголовок поля ввода',
                      })
                }
                className="mb-4 text-base-lh-100"
                error={fieldState.error?.message}
              >
                <Input
                  {...field}
                  placeholder={intl.formatMessage({
                    defaultMessage: 'Укажите ФИО, как в паспорте',
                    id: 'AddressFormModal.placeholder-label.enterYourFullNameAsInYourPassport',
                    description: 'Заголовок поля ввода',
                  })}
                />
                {type === 'legal' && (
                  <div className="mt-1 fs-6 color-gray-600">
                    <FormattedMessage
                      defaultMessage="Будет использовано при оформлении доставки. Если будет
                                      получать сотрудник&nbsp;&mdash; не&nbsp;забудьте подготовить
                                      доверенность."
                      id="AddressFormModal.label.willBeUsedWhenMakingDelivery..."
                      description="Пояснение к полю ввода"
                    />
                  </div>
                )}
              </InputWrapper>
            )}
          />
          {(type === 'individual' || type === 'pickupPoint') && (
            <Controller
              name="passport"
              control={control}
              rules={{
                required: intl.formatMessage({
                  defaultMessage: 'Укажите серию и номер паспорта',
                  id: 'AddressFormModal.input-label.indicateTheSeriesAndNumberOfYourPassport',
                  description: 'Пояснение к полю ввода',
                }),
                ...passportValidation,
              }}
              render={({ field, fieldState }) => (
                <InputWrapper
                  required
                  title={intl.formatMessage({
                    defaultMessage: 'Серия, номер паспорта',
                    id: 'AddressFormModal.input-label.seriesPassportNumber',
                    description: 'Заголовок поля ввода',
                  })}
                  className="mb-4"
                  error={fieldState.error?.message}
                >
                  <Input
                    placeholder="Введите номер паспорта"
                    mask="9999 999999"
                    maskChar=""
                    {...field}
                  />
                </InputWrapper>
              )}
            />
          )}
          <Controller
            name="phone"
            control={control}
            rules={{
              required: intl.formatMessage({
                defaultMessage: 'Укажите номер телефона',
                id: 'AddressFormModal.input-label.enterYourPhoneNumber',
                description: 'Пояснение к полю ввода',
              }),
              ...phoneValidation,
            }}
            render={({ field, fieldState }) => (
              <InputWrapper
                required
                title={intl.formatMessage({
                  defaultMessage: 'Номер телефона',
                  id: 'form.input-label.phoneNumber',
                  description: 'Заголовок поля ввода',
                })}
                error={fieldState.error?.message}
                className="mb-4"
              >
                <Input placeholder="+7" mask="+7 (999) 999-99-99" {...field} />
                <div className="mt-1 text-gray-400">
                  <FormattedMessage
                    defaultMessage="По данному номеру ТК оповестит о прибытии заказа"
                    id="AddressFormModal.label.SKWillNotifyYouAboutTheArrivalOfYourOrderUsingThisNumber."
                    description="Заголовок поля ввода"
                  />
                </div>
              </InputWrapper>
            )}
          />
          {props.isCdek && (
            <Controller
              name="cdekCode"
              control={control}
              rules={{
                required: intl.formatMessage({
                  defaultMessage: 'Укажите адрес пункта выдачи заказов',
                  id: 'AddressFormModal.input-label.enterTheAddressOfTheOrderPickUpPoint',
                  description: 'Пояснение к полю ввода',
                }),
              }}
              render={({ field, fieldState }) => (
                <InputWrapper
                  required
                  title={intl.formatMessage({
                    defaultMessage: 'Адрес ПВЗ',
                    id: 'AddressFormModal.input-label.pickupPointAddress',
                    description: 'Заголовок поля ввода',
                  })}
                  className="mb-4"
                  error={fieldState.error?.message}
                >
                  <CdekPickupPointSelect
                    {...field}
                    value={field.value || ''}
                    placeholder={intl.formatMessage({
                      defaultMessage:
                        'Укажите адрес до какого пункта выдачи доставить',
                      id: 'AddressFormModal.placeholder-label.indicateTheAddressToWhichPointToDeliver',
                      description: 'Поле ввода',
                    })}
                    cityName={city}
                    onSelectPickupPoint={onSelectPickupPoint}
                  />
                </InputWrapper>
              )}
            />
          )}

          <div className="modal-footer justify-content-end">
            <button
              type="button"
              className="btn btn-success"
              onClick={props.reject}
            >
              Отмена
            </button>
            <button type="submit" className="btn btn-success">
              Добавить
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
