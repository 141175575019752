import Table, { CellProps } from 'rsuite/Table';
import Toggle from 'rsuite/Toggle';
import React from 'react';
import { DeliveryInsuranceService } from '../../../services/deliveryInsurance.service';
import Popover from 'rsuite/Popover';
import Dropdown from 'rsuite/Dropdown';
import Whisper from 'rsuite/Whisper';
import IconButton from 'rsuite/IconButton';
import { useNavigate } from 'react-router-dom';
import { CleanDeliveryInsurance } from '../../../export-types/cleaned-types';
import { WhisperSpeaker } from '../../../utils/whisper-speaker';
import useSWR, { SWRConfiguration } from 'swr';
import { fetcher } from '../../../services/api';
import { SupplierCell } from '../../common/table/supplier-cell';

export function useDeliveryInsurance(config?: SWRConfiguration) {
  const { data, isLoading, mutate } = useSWR<Array<CleanDeliveryInsurance>>(
    '/delivery-insurance',
    fetcher,
    config,
  );
  return { data, isLoading, mutate };
}
export const DeliveryInsuranceTab = () => {
  const navigate = useNavigate();
  const { data: deliveryInsurance, isLoading, mutate } = useDeliveryInsurance();

  function checkDeliveryInsurance(id: string, disabled: boolean) {
    DeliveryInsuranceService.updateDeliveryInsurance({ disabled }, id).then(
      () => {
        mutate();
      },
    );
  }

  const DisableCell = ({
    rowData,
    ...props
  }: CellProps<CleanDeliveryInsurance>) => {
    return (
      rowData && (
        <Table.Cell {...props}>
          <Toggle
            checked={!rowData.disabled}
            onChange={(val) => {
              checkDeliveryInsurance(rowData.id, !val);
            }}
          />
        </Table.Cell>
      )
    );
  };

  const renderMenu =
    (deliveryInsurance: CleanDeliveryInsurance): WhisperSpeaker =>
    ({ onClose, left, top, className }, ref) => {
      const handleSelect = (eventKey: number | string | undefined) => {
        onClose();

        if (eventKey === 1) {
          navigate(`/deliveries/delivery-insurance`, {
            state: deliveryInsurance,
          });
        } else if (eventKey === 2) {
          const result = window.confirm(
            `Вы действительно хотите удалить страховку «${deliveryInsurance.name}»?`,
          );
          if (!result) {
            return;
          }
          DeliveryInsuranceService.deleteDeliveryInsurance(
            deliveryInsurance.id,
          ).then(() => mutate());
        }
      };

      return (
        <Popover ref={ref} className={className} style={{ left, top }} full>
          <Dropdown.Menu onSelect={handleSelect}>
            <Dropdown.Item eventKey={1}>
              <i className="bi bi-pencil" />
              <span className="ps-2">Изменить</span>
            </Dropdown.Item>
            <Dropdown.Item eventKey={2}>
              <i className="bi bi-trash" />
              <span className="ps-2">Удалить</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Popover>
      );
    };

  const ActionCell = ({
    rowData,
    dataKey,
    ...props
  }: CellProps<CleanDeliveryInsurance>) => {
    return (
      rowData && (
        <Table.Cell
          {...props}
          className="link-group no-padding-cell pt-2"
          align="right"
        >
          <Whisper
            placement="leftStart"
            trigger="click"
            speaker={renderMenu(rowData)}
          >
            <IconButton
              appearance="subtle"
              icon={<i className="bi bi-three-dots" />}
            />
          </Whisper>
        </Table.Cell>
      )
    );
  };
  return (
    <Table
      data={deliveryInsurance}
      headerHeight={57}
      autoHeight
      loading={isLoading}
    >
      <Table.Column width={150}>
        <Table.HeaderCell>Поставщик</Table.HeaderCell>
        <SupplierCell />
      </Table.Column>

      <Table.Column flexGrow={1} minWidth={250}>
        <Table.HeaderCell>Название</Table.HeaderCell>
        <Table.Cell dataKey="name" />
      </Table.Column>

      <Table.Column flexGrow={0} width={80}>
        <Table.HeaderCell> </Table.HeaderCell>
        <DisableCell />
      </Table.Column>

      <Table.Column flexGrow={0} width={50}>
        <Table.HeaderCell> </Table.HeaderCell>
        <ActionCell />
      </Table.Column>
    </Table>
  );
};
