import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MediaInput, MediaInputValue } from '../../common/media-input';
import { uploadMediaInputFiles } from '../../../services/file-service';
import { api } from '../../../services/api';
import { AlertService } from '../../../services/alert.service';
import { InputWrapper } from '../../common/input-wrapper';
import {
  intValidation,
  required,
  urlValidation,
} from '../../../utils/validations';
import { CleanPurchaseOrder } from '../../../export-types/cleaned-types';
import Toggle from 'rsuite/Toggle';
import { useIntl } from 'react-intl';

type RequestEditFormProps = {
  id: string;
  defaultValues: {
    name: string;
    link: string;
    requestFiles: MediaInputValue[];
    requestCount: number | null;
    comment: string;
    autoSend: boolean;
  };
  onSave: (data: CleanPurchaseOrder) => void;
  onCancel: () => void;
};

export const RequestEditForm: React.FC<RequestEditFormProps> = (props) => {
  const intl = useIntl();
  const { control, register, handleSubmit, formState } = useForm<{
    name: string;
    link: string;
    requestFiles: MediaInputValue[];
    requestCount: number | null;
    comment: string;
    autoSend: boolean;
  }>({
    defaultValues: props.defaultValues,
  });

  const onSubmit = handleSubmit(async (form) => {
    const requestFilesResponse = await uploadMediaInputFiles(form.requestFiles);

    api
      .put(`/purchase-order/v2/admin/${props.id}/request`, {
        name: form.name.trim(),
        link: form.link.trim(),
        requestFiles: requestFilesResponse.map((response) => ({
          id: response.file.id,
        })),
        requestCount: form.requestCount ? Number(form.requestCount) : null,
        comment: form.comment.trim(),
        autoSend: form.autoSend,
      })
      .then(({ data }: { data: { purchaseOrder: CleanPurchaseOrder } }) => {
        AlertService.success();
        props.onSave(data.purchaseOrder);
      });
  });

  function handleCancel() {
    props.onCancel();
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="d-flex flex-row justify-content-between">
        <div className="fs-18 fw-600 color-gray-450">О заказе</div>
        <div>
          <button
            className="btn btn-link text-decoration-none"
            disabled={formState.isSubmitting}
          >
            {formState.isSubmitting ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'Сохранить'
            )}
          </button>
          <button
            className="btn btn-link text-decoration-none"
            type="button"
            disabled={formState.isSubmitting}
            onClick={handleCancel}
          >
            Отменить
          </button>
        </div>
      </div>
      <Controller
        control={control}
        name="autoSend"
        render={({ field, fieldState }) => (
          <InputWrapper
            theme="light"
            title="Автоотправка"
            error={fieldState.error?.message}
          >
            <Toggle checked={field.value} {...field} className="mt-2" />
          </InputWrapper>
        )}
      />
      <InputWrapper
        theme="light"
        className="mt-3"
        title="Название"
        error={formState.errors.name?.message}
      >
        <input
          type="text"
          placeholder="Название"
          className="form-control"
          {...register('name', { ...required })}
        />
      </InputWrapper>
      <InputWrapper
        theme="light"
        className="mt-3"
        title="Ссылка на товар"
        error={formState.errors.link?.message}
      >
        <input
          type="text"
          placeholder="Вставьте ссылку на страницу товара"
          className="form-control"
          {...register('link', { ...required, ...urlValidation })}
        />
      </InputWrapper>
      <div className="row">
        <div className="col-6">
          <InputWrapper
            theme="light"
            title={intl.formatMessage({
              defaultMessage: 'Кол-во товара',
              id: 'input.label.quantityOfGoods',
              description: 'Заголовок поля ввода',
            })}
          >
            <div className="input-group">
              <input
                className="form-control"
                type="number"
                min={0}
                step={1}
                {...register('requestCount')}
              />
              <span className="input-group-text">шт</span>
            </div>
          </InputWrapper>
        </div>
      </div>

      <Controller
        control={control}
        rules={{ ...required }}
        name="requestFiles"
        render={({ field, fieldState }) => (
          <InputWrapper
            theme="light"
            className="mt-3"
            title="Фотографии с модификациями товара"
            required
            error={fieldState.error?.message}
          >
            <MediaInput {...field} className="mt-2" multiple />
          </InputWrapper>
        )}
      />
      <InputWrapper theme="light" className="mt-3" title="Комментарий">
        <textarea className="form-control" {...register('comment')} />
      </InputWrapper>
    </form>
  );
};
