import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { decimalValidation, required } from '../../../utils/validations';
import { api } from '../../../services/api';
import { UserSelect, User } from '../../common/user-select';
import { InputWrapper } from '../../common/input-wrapper';
import { AlertService } from '../../../services/alert.service';
import { useModalClose } from '../../../utils/useModalClose';

type WithdrawClientModalProps = {
  close: () => void;
};

type Form = {
  user: User;
  currency: string;
  amount: string;
  publicComment: string;
  privateComment: string;
};

export const WithdrawClientModal: React.FC<WithdrawClientModalProps> = ({
  close,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<Form>({
    defaultValues: {},
  });

  const handleSave = (form: Form) => {
    const data = {
      clientId: form.user.id,
      currency: form.currency,
      amount: form.amount,
      publicComment: form.publicComment.trim(),
      privateComment: form.privateComment.trim(),
    };

    api
      .post('/transaction/client-withdraw', data)
      .then(() => {
        AlertService.success();
      })
      .then(() => {
        close();
      });
  };

  const modalRef = useModalClose(close);

  return (
    <div className="modal-dialog" ref={modalRef}>
      <form className="modal-content" onSubmit={handleSubmit(handleSave)}>
        <div className="modal-body">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h3>Добавить списание</h3>
            <div className="btn p-0" onClick={close}>
              <i className="bi bi-x fs-36 color-gray-600" />
            </div>
          </div>
          <Controller
            control={control}
            name="user"
            rules={{
              ...required,
            }}
            render={({ field, fieldState }) => (
              <InputWrapper
                className="mb-3"
                title="Клиент"
                required
                error={fieldState.error?.message}
              >
                <UserSelect placeholder="Клиент" {...field} />
              </InputWrapper>
            )}
          />
          <div className="row mb-3">
            <InputWrapper
              title="Сумма списания"
              className="col"
              error={errors.amount?.message}
              required
            >
              <div className="input-group">
                <input
                  className="form-control"
                  type="number"
                  min={0}
                  step={0.01}
                  {...register('amount', {
                    ...required,
                    ...decimalValidation,
                  })}
                />
              </div>
            </InputWrapper>
            <InputWrapper
              className="col-3"
              title="Валюта"
              error={errors.currency?.message}
              required
            >
              <select
                className="form-select"
                {...register('currency', {
                  ...required,
                })}
              >
                <option value="" hidden>
                  -
                </option>
                <option value="usd">USD</option>
                <option value="cny">CNY</option>
              </select>
            </InputWrapper>
          </div>
          <InputWrapper
            title="Комментарий для клиента"
            className="mb-3"
            error={errors.publicComment?.message}
            required
          >
            <textarea
              placeholder="Укажите здесь дополнительную информацию для клиента"
              className="form-control fs-16 lh-sm"
              {...register('publicComment', { ...required })}
            />
          </InputWrapper>
          <InputWrapper
            title="Комментарий для администратора"
            error={errors.privateComment?.message}
            required
          >
            <textarea
              placeholder="Укажите здесь дополнительную информацию для администратора"
              className="form-control fs-16 lh-sm"
              {...register('privateComment', { ...required })}
            />
          </InputWrapper>
        </div>
        <div className="modal-footer justify-content-end">
          <button type="submit" className="btn btn-success">
            Добавить
          </button>
        </div>
      </form>
    </div>
  );
};
