import { api, fetcher } from './api';
import useSWR, { SWRConfiguration } from 'swr';
import { CleanPurchaseOrderDeliveryTariff } from '../export-types/cleaned-types';

type UpdateTariff = {
  markup?: unknown;
  priceTo?: unknown;
  disabled: unknown;
  supplierId?: number;
};

export const usePurchaseOrderTariffs = (config?: SWRConfiguration) => {
  const { data, isLoading, mutate } = useSWR<
    Array<CleanPurchaseOrderDeliveryTariff>
  >('/purchase-order/tariff', fetcher, config);
  return { data, isLoading, mutate };
};

export class PurchaseOrderService {
  static createTariff(data: unknown) {
    return api.post('/purchase-order/tariff', data);
  }

  static updateTariff(id: string, data: UpdateTariff) {
    return api.put(`/purchase-order/tariff/${id}`, {
      disabled: data.disabled,
      priceTo: data.priceTo,
      markup: data.markup,
      supplierId: data.supplierId,
    });
  }

  static deleteTariff(id: string) {
    return api
      .delete(`/purchase-order/tariff/${id}`)
      .catch(async ({ data }) => {
        return data;
      });
  }

  static payPurchaseOrder(id: string, options?: { force: boolean }) {
    return api
      .post(`/purchase-order/${id}/pay`, options)
      .then<{ status: string }>(({ data }) => {
        return data;
      })
      .catch<Record<string, unknown> | undefined>(async ({ data }) => {
        return data;
      });
  }
}
