import React from 'react';
import { NavigateProps, Navigate } from 'react-router';
import { createSearchParams, useLocation } from 'react-router-dom';

export const NavigateWithRetpath: React.FC<NavigateProps> = (props) => {
  const location = useLocation();
  const retpath = React.useMemo(
    () => `${location.pathname}${location.search}${location.hash}`,
    [location],
  );

  const pathname = typeof props.to === 'string' ? props.to : props.to.pathname;

  const search = React.useMemo(() => {
    const searchParams = createSearchParams(
      typeof props.to !== 'string' ? props.to.search : undefined,
    );
    searchParams.set('retpath', retpath);

    return searchParams.toString();
  }, [props.to, retpath]);

  return (
    <Navigate
      {...props}
      to={{
        pathname,
        search,
        hash: typeof props.to !== 'string' ? props.to.hash : undefined,
      }}
    />
  );
};
