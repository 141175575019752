import axios, { AxiosRequestConfig } from 'axios';
import { AlertService } from './alert.service';
import { AuthService } from './auth.service';
import { state } from '../state';

const loaders = state.select('loaders');

export const api = axios.create({
  baseURL: '/api',
  timeout: 60_000,
  timeoutErrorMessage: 'Превышен таймаут. Вероятно проблемы с интернетом',
});

export type FetcherConfig = {
  url: string;
  params: AxiosRequestConfig['params'];
};

export const fetcher = (url: string | FetcherConfig) => {
  if (typeof url === 'string') {
    return api.get(url).then((res) => res.data);
  } else {
    return api.get(url.url, { params: url.params }).then((res) => res.data);
  }
};

api.interceptors.request.use(
  async function (config) {
    loaders.set(loaders.get() + 1);
    const token = await AuthService.getToken();
    if (token) {
      config.headers.Authorization = token;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

function collectValidationErrors(
  errors: Array<Record<string, unknown>>,
): Array<string> {
  return errors
    .flatMap((error) => {
      if (
        error.hasOwnProperty('children') &&
        Array.isArray(error.children) &&
        error.children.length > 0
      ) {
        return collectValidationErrors(error.children);
      }

      if (
        error.hasOwnProperty('constraints') &&
        error.constraints instanceof Object
      ) {
        return Object.values(error.constraints);
      }

      // should not happen
      return null;
    })
    .filter((e: unknown) => typeof e === 'string');
}

api.interceptors.response.use(
  function (response) {
    loaders.set(loaders.get() - 1);
    return response;
  },
  function (error) {
    loaders.set(loaders.get() - 1);

    if (!error.response) {
      AlertService.error(`Ошибка загрузки: "${error.message}"`);
      return Promise.reject(error);
    }

    const data = error.response.data;
    const status = error.response.status;

    let errorText = data && data.error && data.error.toString();

    if (status === 401) {
      return Promise.reject();
    }

    if (status === 403) {
      AlertService.error('Нет доступа');
      return Promise.reject();
    }

    if (status === 404) {
      AlertService.error(errorText || 'Не найдено');
      return Promise.reject();
    }

    if (status === 400) {
      if (data && Array.isArray(data.errors)) {
        errorText = collectValidationErrors(data.errors).join(', ');
      }

      AlertService.error(errorText || 'Ошибка валидации');
      return Promise.reject();
    }

    if (status === 409) {
      throw error;
    }

    AlertService.error(errorText || 'Ошибка сервера');
    return Promise.reject(data);
  },
);
