import useSWR from 'swr';
import { CleanPurchaseOrder } from '../export-types/cleaned-types';
import { fetcher } from '../services/api';

type Params = {
  take?: number;
  skip?: number;
  internalId?: string;
  status?: Array<string>;
  all?: boolean;
};

export function usePurchaseOrdersByInternalId(params: Params = {}) {
  const { data, error, isLoading, mutate } = useSWR<{
    items: Array<CleanPurchaseOrder>;
    total: number;
  }>(
    params.internalId ? { url: '/purchase-order/v2', params: params } : null,
    fetcher,
  );

  return { data, error, isLoading, mutate };
}
