import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

let isInited = false;

export function init() {
  if (isInited) return;

  const SENTRY_DSN =
    'https://7e5619f8f92347b988a0aad683931606@o4504988877258752.ingest.sentry.io/4504989549461504';
  let SENTRY_ENVIRONMENT = 'dev';

  if ('admin.express-today.ru' === window.location.host) {
    SENTRY_ENVIRONMENT = 'production';
  } else if (window.location.host.endsWith('express-today.space')) {
    SENTRY_ENVIRONMENT = 'testing';
  }

  Sentry.init({
    dsn: SENTRY_DSN,
    release: import.meta.env.VITE_SENTRY_RELEASE,
    environment: SENTRY_ENVIRONMENT,
    sampleRate: SENTRY_ENVIRONMENT === 'production' ? 1 : 0,
    tracesSampleRate: SENTRY_ENVIRONMENT === 'production' ? 1 : 0,
    replaysSessionSampleRate: SENTRY_ENVIRONMENT === 'production' ? 0.1 : 0,
    replaysOnErrorSampleRate: SENTRY_ENVIRONMENT === 'production' ? 1 : 0,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
    ],
  });

  isInited = true;
}

export function setSentryUser(user: Record<string, string>) {
  Sentry.setUser(user);
}
