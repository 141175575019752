import React from 'react';
import { Header } from '../../common/header.component';
import Dropdown from 'rsuite/Dropdown';
import { NavLink, useNavigate, Outlet } from 'react-router-dom';
import { ModalsService } from '../../../services/modals.service';
import { MarkupModal } from '../finance/markup-modal.component';
import { CommonService } from '../../../services/common.service';
import { AlertService } from '../../../services/alert.service';
import { useGlobalMutate } from '../../../api/useGlobalMutate';
import { FormattedMessage } from 'react-intl';
import { userIsSalesManager } from '../../../utils/common.utils';
import { useCurrentUser } from '../../current-user';

export const DeliveryScreen = () => {
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const { mutate } = useGlobalMutate();
  const isSalesManager = userIsSalesManager(currentUser.profile);

  function onSelectAdd(key: number) {
    switch (key) {
      case 1:
        return navigate('/deliveries/delivery-method');
      case 2:
        return navigate('/deliveries/package-method');
      case 3:
        ModalsService.createModal(MarkupModal, {
          title: 'Комиссия за доставку',
          subTitle: 'Стоимость доставки',
        })
          .then((data) => CommonService.createDeliveryMarkup(data))
          .then(() => {
            AlertService.success();
            mutate();
          });
        return;
      case 4:
        return navigate('/deliveries/local-delivery-method');
      case 5:
        return navigate('/deliveries/delivery-insurance');
      case 6:
        return navigate('/deliveries/delivery-order-create');
      default:
        return;
    }
  }

  return (
    <>
      <Header>
        <div className="d-flex flex-row align-items-center">
          <h1>
            <FormattedMessage
              defaultMessage="Доставки"
              id="delivery.label.shipping"
              description="Надпись на странице"
            />
          </h1>
          {isSalesManager ? (
            <div className="ps-4">
              <Dropdown
                toggleClassName="btn btn-success"
                placement="bottomEnd"
                title="Добавить"
                trigger="click"
                onSelect={onSelectAdd}
              >
                {currentUser.isAdmin && (
                  <>
                    {' '}
                    <Dropdown.Item eventKey={1}>
                      <i className="bi bi-truck" />
                      <span className="ps-2">Способ доставки</span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey={2}>
                      <i className="bi bi-box-seam" />
                      <span className="ps-2">Способ упаковки</span>
                    </Dropdown.Item>
                  </>
                )}
                {isSalesManager && (
                  <Dropdown.Item eventKey={6}>
                    <i className="bi bi-plus-circle-fill" />
                    <span className="ps-2">Создать доставку</span>
                  </Dropdown.Item>
                )}
                {currentUser.isAdmin && (
                  <>
                    <Dropdown.Item eventKey={3}>
                      <i className="bi bi-percent" />
                      <span className="ps-2">Комиссия за доставку</span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey={4}>
                      <i className="bi bi-truck" />
                      <span className="ps-2">Транспортную компанию</span>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey={5}>
                      <i className="bi bi-shield-check" />
                      <span className="ps-2">Страховку</span>
                    </Dropdown.Item>
                  </>
                )}
              </Dropdown>
            </div>
          ) : null}
        </div>
      </Header>
      <div className="d-flex flex-column">
        {currentUser.isAdmin ? (
          <ul className="nav nav-pills mb-4 d-inline-flex align-self-start">
            <li className="nav-item">
              <NavLink
                to="/deliveries/orders"
                className="nav-link text-decoration-none"
              >
                <FormattedMessage
                  defaultMessage="Доставки"
                  id="delivery.label.shipping"
                  description="Надпись на странице"
                />
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/deliveries/delivery"
                className="nav-link text-decoration-none"
              >
                <FormattedMessage
                  defaultMessage="Способы доставки"
                  id="delivery.label.shippingOptions"
                  description="Надпись на вкладке"
                />
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/deliveries/package"
                className="nav-link text-decoration-none"
              >
                <FormattedMessage
                  defaultMessage="Способы упаковки"
                  id="delivery.label.packaging"
                  description="Надпись на вкладке"
                />
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/deliveries/markups"
                className="nav-link text-decoration-none"
              >
                <FormattedMessage
                  defaultMessage="Комиссии на доставку"
                  id="delivery.label.shippingFee"
                  description="Надпись на вкладке"
                />
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/deliveries/local-delivery"
                className="nav-link text-decoration-none"
              >
                <FormattedMessage
                  defaultMessage="Транспортные компании"
                  id="delivery.label.shippingCompany"
                  description="Надпись на вкладке"
                />
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/deliveries/insurance"
                className="nav-link text-decoration-none"
              >
                <FormattedMessage
                  defaultMessage="Страховка"
                  id="delivery.label.insurance"
                  description="Надпись на вкладке"
                />
              </NavLink>
            </li>
          </ul>
        ) : null}
        <Outlet />
      </div>
    </>
  );
};
