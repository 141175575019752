import React from 'react';
import { FormattedMessage } from 'react-intl';

type WeightVolumeCellProps = {
  weight?: string | null;
  volume?: string | null;
};

export const WeightVolumeCell: React.FC<WeightVolumeCellProps> = ({
  weight,
  volume,
}) => (
  <div>
    {!weight ? (
      <>
        <i className="bi bi-three-dots color-gray-400" /> /{' '}
        <i className="bi bi-three-dots color-gray-400" />
      </>
    ) : (
      <>
        <FormattedMessage
          defaultMessage="{value} кг / "
          id="delivery.label.kg"
          description="Единица измерения"
          values={{ value: weight }}
        />
        <FormattedMessage
          defaultMessage="{value} м³"
          id="delivery.label.length"
          description="Единица измерения"
          values={{ value: volume }}
        />
      </>

      // `${weight} кг / ${volume} м³`
    )}
  </div>
);
