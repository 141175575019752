import React, { useRef, forwardRef, ChangeEventHandler } from 'react';
import { v4 } from 'uuid';
import classNames from 'classnames';

type CheckboxProps = {
  title?: string;
  checked?: boolean;
  value?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  disabled?: boolean;
};

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => {
    const id = useRef(v4());
    return (
      <div className={classNames('form-check m-0', props.className)}>
        <input
          className="form-check-input"
          type="checkbox"
          checked={
            typeof props.checked === 'undefined' ? props.value : props.checked
          }
          onChange={props.onChange}
          disabled={props.disabled}
          id={id.current}
          ref={ref}
        />
        <label className="form-check-label" htmlFor={id.current}>
          {props.title}
        </label>
      </div>
    );
  },
);
