import { RouterOutput, trpcSwr } from '../../../api/trpc';

export const useBrandsList = () => {
  const { data, isLoading, error } = trpcSwr.brandAdmin.list.useSWR();

  return { data, isLoading, error };
};

export const useBrands = () => {
  const { data, isLoading, error } = trpcSwr.brandAdmin.brands.useSWR();

  return { data, isLoading, error };
};
