import useSWR, { SWRConfiguration } from 'swr';
import { CleanDeliveryMethod } from '../export-types/cleaned-types';
import { fetcher } from '../services/api';

export function useDeliveryMethods(config?: SWRConfiguration) {
  const { data, isLoading, mutate } = useSWR<Array<CleanDeliveryMethod>>(
    '/delivery-method',
    fetcher,
    config,
  );
  return { data, isLoading, mutate };
}
