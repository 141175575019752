import { useForm } from 'react-hook-form';
import { Input } from '../../common/input.component';
import { required } from '../../../utils/validations';
import React from 'react';
import { ModalProps } from '../../../services/modals.service';
import { CleanDeliveryOrderDeliveryMarkup } from '../../../export-types/cleaned-types';
import { useModalClose } from '../../../utils/useModalClose';

interface Props extends ModalProps {
  title: string;
  subTitle?: string;
  currency?: string;
  markup?: CleanDeliveryOrderDeliveryMarkup;
  close: (resolve: CleanDeliveryOrderDeliveryMarkup) => void;
}

export const MarkupModal: React.FC<Props> = ({
  close,
  reject,
  markup,
  title,
  subTitle = 'Стоимость товара',
  currency = '$',
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: markup || {},
  });

  function onSave(data: CleanDeliveryOrderDeliveryMarkup) {
    close(data);
  }

  const modalRef = useModalClose(reject);

  return (
    <div className="modal-dialog" ref={modalRef}>
      <form className="modal-content" onSubmit={handleSubmit(onSave)}>
        <div className="modal-body">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h3>{title}</h3>
            <div className="btn p-0" onClick={reject}>
              <i className="bi bi-x fs-36 color-gray-600" />
            </div>
          </div>
          <h3 className="fs-18">Условия</h3>
          <div className="d-flex flex-row align-items-end">
            <Input
              title={subTitle}
              className="flex-grow-1"
              {...register(`priceTo`, {
                ...required,
              })}
              type="number"
              min={0}
              step={0.01}
              error={errors.priceTo?.message as string}
              postfix={currency}
            />
            <div className="ps-2 pe-2" />
            <Input
              title="   "
              className="flex-grow-1"
              {...register(`markup`, {
                ...required,
              })}
              type="number"
              min={0}
              step={0.01}
              error={errors.markup?.message as string}
              postfix="%"
            />
          </div>
        </div>
        <div className="modal-footer justify-content-end">
          <button type="submit" className="btn btn-success">
            Сохранить
          </button>
        </div>
      </form>
    </div>
  );
};
