import moment from 'moment';
import React from 'react';
import useSWR from 'swr';
import { fetcher } from '../../../services/api';
import { TransactionItem } from './transaction-item';
import { CleanTransaction } from '../../../export-types/cleaned-types';

type OrderTransactionsInfoProps = {
  type: 'purchase-order' | 'delivery-order';
  id: string;
};

export const OrderTransactionsInfo: React.FC<OrderTransactionsInfoProps> = ({
  type,
  id,
}) => {
  const { data: transactionsData } = useSWR<{ items: CleanTransaction[] }>(
    () => {
      return {
        url: `${type}/${id}/transactions`,
        params: {
          sort: 'createdAt',
          direction: 'asc',
        },
      };
    },
    fetcher,
  );

  const transactions = React.useMemo(() => {
    if (!transactionsData) return;
    if (Array.isArray(transactionsData.items)) {
      return transactionsData.items.filter(
        (item) =>
          !item.internalTransaction ||
          [
            'additionalServiceCharge',
            'additionalServiceChargeExpense',
            'refundAdditionalServiceChargeExpense',
            'refundAdditionalServiceCharge',
          ].includes(item.journal),
      );
    }
  }, [transactionsData]);

  const { groupTransactions } = React.useMemo(() => {
    if (!transactions) {
      return {
        groupTransactions: [],
      };
    }

    const groups = transactions.reduce(
      (
        groups: Record<string, Array<CleanTransaction>>,
        item: CleanTransaction,
      ) => {
        const date = moment(item.createdAt).format('DD.MM.YYYY');
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(item);
        return groups;
      },
      {},
    );

    const groupTransactions = Object.keys(groups).map((date) => {
      return {
        date,
        items: groups[date],
      };
    });

    return { groupTransactions };
  }, [transactions]);

  if (groupTransactions.length === 0) return null;

  return (
    <div className="bg-gray p-3 rounded-8 mt-3">
      <div className="fs-18 fw-600 color-gray-450 mb-3">Транзакции</div>
      {groupTransactions?.map((dateGroup) => {
        return (
          <div key={dateGroup.date}>
            <div className="fs-16 fw-500 color-gray-800 mb-2">
              {dateGroup.date}
            </div>
            {dateGroup.items.map((item: CleanTransaction) => (
              <TransactionItem key={item.id} transaction={item} />
            ))}
          </div>
        );
      })}
    </div>
  );
};
