import { ModalProps } from '../../../services/modals.service';
import { KeyedMutator } from 'swr';
import { User, UserSelect } from '../../common/user-select';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { required } from '../../../utils/validations';
import { InputWrapper } from '../../common/input-wrapper';
import { api } from '../../../services/api';
import { AlertService } from '../../../services/alert.service';
import { CleanUser } from '../../../export-types/cleaned-types';
import { useModalClose } from '../../../utils/useModalClose';

interface LinkUserModalProps extends ModalProps {
  user?: CleanUser;
  mutate?: KeyedMutator<any>;
}

export const LinkUserModal = ({ user, close, mutate }: LinkUserModalProps) => {
  const { control, handleSubmit } = useForm<{
    user: User;
  }>();

  const onSubmit = handleSubmit(async ({ user: manager }) => {
    api
      .post(`/user/${user?.id}/link`, {
        userId: manager.id,
      })
      .then(() => {
        AlertService.success();
        if (mutate) {
          mutate();
        }
        close();
      });
  });

  const modalRef = useModalClose(close);

  return (
    <div className="modal-dialog mw-400" ref={modalRef}>
      <div className="modal-content">
        <div className="modal-header border-bottom-0 p-4">
          <button type="button" className="btn-close" onClick={() => close()} />
        </div>
        <div className="modal-body pt-2 p-4">
          <div className="text-center mb-4 mx-auto">
            <h3>Выберите менджера для привязки</h3>
          </div>
          <form onSubmit={onSubmit}>
            <Controller
              control={control}
              name="user"
              rules={{
                ...required,
              }}
              render={({ field, fieldState }) => (
                <InputWrapper
                  className="mb-3"
                  title="Менеджер"
                  required
                  error={fieldState.error?.message}
                >
                  <UserSelect
                    placeholder="Выберите менеджера"
                    {...field}
                    roles={['employee', 'admin']}
                  />
                </InputWrapper>
              )}
            />
            <button type="submit" className="btn btn-success w-100 mb-3">
              Привязать
            </button>
            <button
              type="button"
              className="btn btn-light w-100"
              onClick={() => close()}
            >
              Отменить
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
