import useSWR, { SWRConfiguration } from 'swr';
import { CleanLocation } from '../export-types/cleaned-types';
import { fetcher } from '../services/api';

export function useLocations(config?: SWRConfiguration) {
  const { data, isLoading, mutate } = useSWR<Array<CleanLocation>>(
    '/location',
    fetcher,
    config,
  );
  return { data, isLoading, mutate };
}
