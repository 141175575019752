import React from 'react';
import { InputWrapper } from '../../common/input-wrapper';
import { Gallery } from '../../common/gallery/gallery';
import { getFileV2ApiUrl } from '../../../utils/common.utils';
import useSWR from 'swr';
import { api, fetcher } from '../../../services/api';
import { Controller, useForm } from 'react-hook-form';
import { AlertService } from '../../../services/alert.service';
import {
  UploadedFileV2,
  uploadMediaInputFiles,
} from '../../../services/file-service';
import { MediaInput, MediaInputValue } from '../../common/media-input';
import { required } from '../../../utils/validations';
import { useProfile } from '../../../api/profile';
import { FormattedMessage, useIntl } from 'react-intl';
import { Rbac } from '../../common/Rbac';
import { RbacObject } from '../../../export-types/cleaned-types';

type PurchaseOrder = {
  id: string;
  status:
    | 'waitingForPayment'
    | 'inWork'
    | 'onTheWayToStock'
    | 'receivedInStock'
    | 'sentToRussia'
    | 'canceled';
  receiptFilesV2: UploadedFileV2[];
};

type PurchaseOrderPurchaseConfirmationProps = {
  id: string;
};
export const PurchaseOrderPurchaseConfirmation: React.FC<
  PurchaseOrderPurchaseConfirmationProps
> = (props) => {
  const intl = useIntl();

  const { data: user } = useProfile();
  const { data: order, mutate: orderMutate } = useSWR<PurchaseOrder>(
    `/purchase-order/${props.id}`,
    fetcher,
  );

  const [editMode, setEditMode] = React.useState(false);

  if (!order || !user) {
    return null;
  }

  function handleSave(data: PurchaseOrder) {
    orderMutate(data);
    setEditMode(false);
  }

  function handleEdit() {
    setEditMode(true);
  }

  function handleCancel() {
    setEditMode(false);
  }

  if (editMode) {
    return (
      <PurchaseOrderPurchaseConfirmationForm
        id={order.id}
        defaultValues={{
          receiptFiles: order.receiptFilesV2.map((file) => ({
            type: 'exist',
            file,
          })),
        }}
        onSave={handleSave}
        onCancel={handleCancel}
      />
    );
  }

  return (
    <div>
      <div className="d-flex flex-row justify-content-between">
        <div className="fs-18 fw-600 color-gray-450">
          <FormattedMessage
            defaultMessage="Выкуп заказа"
            id="purchase.label.orderRedemption"
            description="Подзаголовок на странице"
          />
        </div>
        {order.status !== 'canceled' && (
          <Rbac object={RbacObject.Purchase} action={'write:confirmation'}>
            <button
              className="btn btn-link text-decoration-none"
              type="button"
              onClick={handleEdit}
            >
              <FormattedMessage
                defaultMessage="Изменить"
                id="delivery.btn-label.change"
                description="Кнопка"
              />
            </button>
          </Rbac>
        )}
      </div>
      {order.receiptFilesV2.length > 0 && (
        <InputWrapper
          theme="light"
          className="mt-3"
          title={intl.formatMessage({
            defaultMessage: 'Скриншот выкупа с итоговой суммой',
            id: 'purchaseOrderModal.input-label.screenshotFinalAmount',
            description: 'Заголовок поля ввода',
          })}
        >
          <Gallery className="mt-2">
            {order.receiptFilesV2.map((file, index) => (
              <a href={getFileV2ApiUrl(file.id)} key={index}>
                <img
                  className="rounded fit-cover me-2"
                  src={getFileV2ApiUrl(file.id, '64x64')}
                  width={64}
                  height={64}
                  alt=""
                />
              </a>
            ))}
          </Gallery>
        </InputWrapper>
      )}
    </div>
  );
};

type PurchaseOrderPurchaseConfirmationFormProps = {
  id: string;
  defaultValues: {
    receiptFiles: MediaInputValue[];
  };
  onSave: (data: PurchaseOrder) => void;
  onCancel: () => void;
};

const PurchaseOrderPurchaseConfirmationForm: React.FC<
  PurchaseOrderPurchaseConfirmationFormProps
> = (props) => {
  const intl = useIntl();

  const { control, handleSubmit, formState } = useForm<{
    receiptFiles: MediaInputValue[];
  }>({
    defaultValues: props.defaultValues,
  });

  const onSubmit = handleSubmit(async (form) => {
    const receiptFilesResponse = await uploadMediaInputFiles(form.receiptFiles);

    const { data }: { data: { purchaseOrder: PurchaseOrder } } = await api.put(
      `/purchase-order/v2/${props.id}/purchase-confirmation`,
      {
        receiptFiles: receiptFilesResponse.map((response) => ({
          id: response.file.id,
        })),
      },
    );

    props.onSave(data.purchaseOrder);
    AlertService.success();
  });

  function handleCancel() {
    props.onCancel();
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="d-flex flex-row justify-content-between">
        <div className="fs-18 fw-600 color-gray-450">
          <FormattedMessage
            defaultMessage="Выкуп заказа"
            id="purchase.label.orderRedemption"
            description="Подзаголовок на странице"
          />
        </div>
        <div>
          <button
            className="btn btn-link text-decoration-none"
            disabled={formState.isSubmitting}
          >
            {formState.isSubmitting ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Сохранить"
                id="receiptConfirmation.label.save"
                description="Надпись на кнопке"
              />
            )}
          </button>
          <button
            className="btn btn-link text-decoration-none"
            type="button"
            disabled={formState.isSubmitting}
            onClick={handleCancel}
          >
            <FormattedMessage
              defaultMessage="Отменить"
              id="receiptConfirmation.label.cancel"
              description="Надпись на кнопке"
            />
          </button>
        </div>
      </div>
      <Controller
        control={control}
        rules={{ ...required }}
        name="receiptFiles"
        render={({ field, fieldState }) => (
          <InputWrapper
            theme="light"
            className="mt-3"
            title={intl.formatMessage({
              defaultMessage: 'Скриншот выкупа с итоговой суммой',
              id: 'purchaseOrderModal.input-label.screenshotFinalAmount',
              description: 'Заголовок поля ввода',
            })}
            required
            error={fieldState.error?.message}
          >
            <MediaInput {...field} className="mt-2" multiple />
          </InputWrapper>
        )}
      />
    </form>
  );
};
