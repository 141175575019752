import { Header } from '../../common/header.component';
import { Input } from '../../common/input.component';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { fetcher } from '../../../services/api';
import { AlertService } from '../../../services/alert.service';
import { useSuppliers } from '../../../api/suppliers';
import { Select } from '../../common/select.component';

import { getCurrencySymbol } from '../../../utils/common.utils';
import { trpcSwr } from '../../../api/trpc';
import { ServiceChargeOptionMethod } from './service-charge-option-screen';
import {
  nonNegativeNumberValidation,
  required,
} from '../../../utils/validations';

export type FieldValues = {
  supplierId: number;
  name: string;
  priority: number | undefined;
  amountClient: string;
  commentClient: string | null;
  amountCompany: string;
  commentCompany: string | null;
};

export const ServiceChargeOptionForm = () => {
  const { data: suppliers } = useSuppliers();
  const navigate = useNavigate();

  const { state } = useLocation() as {
    state?: Partial<ServiceChargeOptionMethod>;
  };

  const { trigger: triggerUpdate } =
    trpcSwr.serviceChargeOption.admin.update.useSWRMutation({ fetcher });
  const { trigger: triggerCreate } =
    trpcSwr.serviceChargeOption.admin.create.useSWRMutation({ fetcher });

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setError,
  } = useForm<FieldValues>({
    defaultValues: {
      supplierId: state?.supplierId,
      name: state?.name,
      priority: state?.priority,
      amountClient: state?.amountClient,
      commentClient: state?.commentClient,
      amountCompany: state?.amountCompany,
      commentCompany: state?.commentCompany,
    },
  });
  const formValues = watch();
  const selectedSuppliers = suppliers?.find(
    (supplier) => supplier.id === formValues.supplierId,
  );

  useEffect(() => {
    if (formValues.amountClient === '0') {
      setError('commentClient', {
        type: '',
        message: '',
      });
    }
    if (formValues.amountCompany === '0') {
      setError('commentCompany', {
        type: '',
        message: '',
      });
    }
  }, [formValues.amountClient, formValues.amountCompany, setError]);

  const onSubmit = handleSubmit(async (data: FieldValues) => {
    data.priority = Number(formValues.priority);

    if (state?.id) {
      await triggerUpdate({
        id: state?.id,
        data: data,
      });
    } else {
      await triggerCreate(data);
    }
    AlertService.success();
    navigate('/purchases/service-charge-option');
  });

  return (
    <>
      <Header>
        <button
          className="btn"
          type="button"
          onClick={() => navigate('/purchases/service-charge-option')}
        >
          <i className="bi bi-x-lg"></i>
          <span className="ms-1">Закрыть</span>
        </button>
      </Header>
      <div className="container" style={{ marginTop: -40 }}>
        <h1 className="mb-4">Дополнительные расходы за выкуп</h1>
        <form onSubmit={onSubmit}>
          <Controller
            name="supplierId"
            control={control}
            rules={required}
            render={({ field }) => (
              <Select
                className="mb-3"
                title="Поставщик"
                placeholder="Выберите поставщика"
                options={
                  suppliers?.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  })) ?? []
                }
                {...field}
                error={errors.supplierId?.message}
              />
            )}
          />
          <Input
            className="mb-3"
            title="Название"
            placeholder=""
            {...register('name', {
              ...required,
            })}
            error={errors.name?.message}
          />
          <div className="row mb-3">
            <Input
              title="Приоритет"
              type="number"
              step="1"
              {...register('priority')}
              error={errors.priority?.message}
            />
            <div className="fs-14 color-gray-400">
              Влияет на сортировку. Чем меньше, тем выше в списке
            </div>
          </div>
          <div className="row">
            <div className="col-6 mb-3">
              <Input
                title="Сколько списать с клиента"
                type="number"
                placeholder=""
                {...register('amountClient', {
                  ...nonNegativeNumberValidation,
                })}
                error={errors.amountClient?.message}
                postfix={getCurrencySymbol(
                  selectedSuppliers?.purchaseCurrency || '',
                )}
              />
            </div>
            <div className="col-6 mb-3">
              <Input
                title="Комментарий для клиента"
                placeholder=""
                {...register('commentClient', {
                  validate: (value) => {
                    if (
                      !/^0(\.0{1,2})?$/.test(watch('amountClient')) &&
                      !value
                    ) {
                      return 'Заполните поле';
                    }
                  },
                })}
                error={errors.commentClient?.message}
              />
            </div>
            <div className="col-6 mb-3">
              <Input
                title="Сколько перевести поставщику"
                type="number"
                placeholder=""
                {...register('amountCompany', {
                  ...nonNegativeNumberValidation,
                })}
                error={errors.amountCompany?.message}
                postfix={getCurrencySymbol(
                  selectedSuppliers?.purchaseCurrency || '',
                )}
              />
            </div>
            <div className="col-6 mb-3">
              <Input
                title="Комментарий для поставщика"
                placeholder=""
                {...register('commentCompany', {
                  validate: (v) => {
                    if (!/^0(\.0{1,2})?$/.test(watch('amountCompany')) && !v) {
                      return 'Заполните поле';
                    }
                  },
                })}
                error={errors.commentCompany?.message}
              />
            </div>
            <div className="col-12 pt-3 mb-4">
              <button type="submit" className="btn btn-success w-100">
                Сохранить
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
