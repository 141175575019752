// noinspection JSUnusedGlobalSymbols

type CleanUserRole = 'admin' | 'employee' | 'client';
export type CleanUserAccess =
  | 'purchase'
  | 'warehouse'
  | 'salesManager'
  | 'supplierFinances'
  | 'marketer'
  | 'transit'
  | 'financier'
  | 'reader'
  | 'fulfillment';
export type CleanUser = {
  id: string;
  internalId: number | null;
  avatar?: CleanFile;
  disabled?: boolean;
  email?: string | null;
  name?: string | null;
  role?: CleanUserRole;
  phone?: string;
  accesses?: Array<CleanUserAccess>;
  createdAt?: string;
  updatedAt?: string;
  disableEmails?: boolean;
  supplier?: CleanSupplier;
  manager?: CleanUser;
  serviceMailing?: boolean;
  promotionMailing?: boolean;
  autoSend?: boolean;
  moderate?: boolean;
  moderateDeliveryOrder?: boolean;
  banType?: CleanBanType | null;
  banReason?: string | null;
  purchaseLimit?: string | null;
  autoWriteOffLimit?: string | null;
  otcommerceAsMainPage?: boolean;
  defaultSupplierId?: number | null;
};
export type CleanUserSelf = CleanUser &
  Required<
    Pick<CleanUser, 'avatar' | 'email' | 'name' | 'role' | 'phone' | 'accesses'>
  >;
export type CleanFile = {
  id: string;
  key: string;
  type: string;
};
export type CleanPurchaseOrderExpenses = CleanDeliveryOrderExpenses;
type CleanPurchaseOrderType = 'purchase' | 'parcel';

export type SizesAndPricesType = {
  id: number;
  priceProduct: string;
};

export type ProductCardType = {
  id: number;
  disabled: boolean;
  originalId: string;
  link: string;
  productId: string;
  modificationId: string;
  name: string;
  coverId: number;
  sizesAndPrices: SizesAndPricesType[];
  description: string;
  characteristics: string;
  color: string;
  currency: 'rub' | 'cny' | 'usd';
};

type CleanPurchaseOrderStatus =
  | 'waitingForPayment'
  | 'inWork'
  | 'onTheWayToStock'
  | 'receivedInStock'
  | 'receivedInRetailStock'
  | 'sentToRussia'
  | 'canceled'
  | 'frozen';
type CleanPurchaseOrderCategory =
  | 'clothes'
  | 'householdGoods'
  | 'smallHouseholdGoods';
export type CleanPurchaseOrder = {
  id: string;
  type: CleanPurchaseOrderType;
  internalId: number;
  status: CleanPurchaseOrderStatus;
  cancelReason: string | null;
  user?: CleanUser;
  name: string | null;
  category: CleanPurchaseOrderCategory | null;
  link: string | null;
  trackNumber: string | null;
  comment: string | null;
  /** Товар Клиент */
  productPrice?: string;
  /** К оплате клиенту */
  price?: string;
  /** Комиссия за выкуп Факт */
  orderMarkup?: string | null;
  /** Комиссия за выкуп Клиент */
  preMarkup?: string;
  /** Доставка товаров до склада Клиент */
  deliveryPrice?: string;
  /** Фактические затраты */
  moderateFinalPrice?: string | null;
  /** Доставка товаров по Факт */
  moderateDeliveryPrice?: string | null;
  /** Товар Факт */
  moderateProductPrice?: string | null;
  volume: string | null;
  weight: string | null;
  /** Количество товара (штук), клиент */
  requestCount: number | null;
  /** Количество товара (штук), склад */
  count: number | null;
  /** Количество мест, клиент */
  requestPlaces: number | null;
  /** Количество мест, склад */
  places: number | null;
  storageLocation?: string | null;
  requestFilesV2: Array<CleanFileDto>;
  productFilesV2: Array<CleanFileDto>;
  receiptFilesV2: Array<CleanFileDto>;
  purchaseDate: string | null;
  paidAt: string | null;
  receiptDate: string | null;
  deliveryOrder?: CleanDeliveryOrder;
  createdAt: string;
  expenses?: CleanPurchaseOrderExpenses;
  updatedAt?: string;
  cancelledAt: string | null;
  freezingReason?: string | null;
  supplier?: CleanSupplier;
  supplierId?: number;
  autoSend: boolean;
  sendBlocked: boolean;
  unreadMessages?: number;
  currency?: CleanSupplierCurrency;
  additionalWriteOffs?: CleanPurchaseOrderWriteOff[];
  orderLink?: string | null;
  orderNumber?: string | null;
  orderAccount?: string | null;
  landingBill?: string | null;
  transportCompany?: string | null;
  receiptComment?: string | null;
  processingFromTerminal?: boolean;
  /** Дополнительные услуги (клиент) */
  serviceCharge?: string;
  /** Дополнительные услуги (факт) */
  moderateServiceCharge?: string;
  otcommerceVendor?: CleanVendorInfo;
  productCardId?: number;
  productCard?: ProductCardType | null;
};

export type CleanVendorInfo = {
  providerType: string;
  vendorId: string;
  name: string;
  displayName: string | null;
  shopName: string | null;
};

export type CleanDeliveryMethodMethod =
  | 'air'
  | 'railway'
  | 'auto'
  | 'air_direct';
export type CleanDeliveryMethod = {
  id: string;
  name: string;
  method: CleanDeliveryMethodMethod;
  disabled?: boolean;
  deliveryPeriodFrom: number;
  deliveryPeriodTo: number | null;
  productCategories: Array<string>;
  tariffs: Array<{
    id: string;
    densityFrom: string;
    densityTo: string | null;
    price: string;
    createdAt: string;
  }>;
  forCalculator?: boolean;
  createdAt: string;
  updatedAt: string;
  supplier?: CleanSupplier;
  destination?: CleanLocation;
  minWeight?: string;
  maxWeight?: string;
};
type CleanAddressType = 'legal' | 'individual' | 'pickupPoint';
export type CleanAddress = {
  id: string;
  name: string;
  phone: string;
  city: string;
  type: CleanAddressType;
  companyInn: string | null;
  companyName: string | null;
  passport: string | null;
  pickupPoint: string | null;
  cdekCode: string | null;
  createdAt: string;
  updatedAt: string;
};
export type CleanDeliveryOrderExpenses =
  | {
      client: string;
      company?: string;
    }
  | undefined;
type CleanDeliveryOrderStatus =
  | 'processing'
  | 'assembly'
  | 'assembled'
  | 'sentToRussia'
  | 'receivedInRussia'
  | 'receivedInRetailRussia'
  | 'sentToRecipient'
  | 'delivered';
export type CleanDeliveryOrder = {
  id: string;
  internalId: number;
  user?: CleanUser;
  status: CleanDeliveryOrderStatus;
  sentDate: string | null;
  receiveRfDate: string | null;
  sentToRecipientDate: string | null;
  deliveredDate: string | null;
  trackNumber: string | null;
  packageMethods: string | null;
  purchaseOrders: Array<CleanPurchaseOrder> | undefined;
  packageGroups: Array<CleanPackageGroup> | undefined;
  moderateProductPrice?: string;
  deliveryMethod?: CleanDeliveryMethod;
  localDeliveryMethod: CleanLocalDeliveryMethod;
  address?: CleanAddress;
  packedFilesV2: Array<CleanFileDto>;
  waybillFilesV2: Array<CleanFileDto>;
  count: number | null;
  weight: string | null;
  volume: string | null;
  prePrice?: string;
  price?: string | null;
  deliveryPrice?: string | null;
  packagePrice?: string | null;
  insurancePrice?: string | null;
  markupPrice?: string | null;
  moderateDeliveryPrice?: string | null;
  moderatePackagePrice?: string | null;
  moderateInsurancePrice?: string | null;
  moderateMarkupPrice?: string | null;
  paid?: boolean;
  expenses?: CleanDeliveryOrderExpenses;
  createdAt: string;
  updatedAt?: string;
  blocked?: boolean;
  localDeliveryPrice?: string | null;
  moderateLocalDeliveryPrice?: string | null;
  supplier?: CleanSupplier;
  supplierId?: number;
  supplierLocation?: CleanLocation;
  compositeDelivery?: CleanCompositeDelivery;
  unreadMessages?: number;
  currency?: CleanSupplierCurrency;
  /** Дополнительные услуги (клиент) */
  serviceCharge?: string;
  /** Дополнительные услуги (факт) */
  moderateServiceCharge?: string;
  cdekOrder?: CleanCdekOrder;
};
export type CleanFileDto = {
  id: string;
  type: 'pdf' | 'image' | 'video' | 'unknown';
  size: number;
};

export type CleanPackageMethod = {
  id: string;
  disabled?: boolean;
  name: string;
  comment: string | null;
  createdAt: string;
  updatedAt: string;
  tariffs: Array<CleanPackageMethodTariff>;
  supplier?: CleanSupplier;
};
type CleanPackageMethodTariff = {
  id: string;
  volumeFrom: string;
  volumeTo: string | null;
  price: string;
  createdAt: string;
};
export type CleanMoneyBill = {
  cny: string;
  usd: string;
  bonus: string;
};

export type CleanBalanceSuppliers = Array<{
  id: number;
  name: string;
  balance: CleanMoneyBill;
}>;

export type CleanBalance = CleanMoneyBill & {
  suppliers?: CleanBalanceSuppliers;
};

export type CleanDeliveryOrderDeliveryMarkup = {
  id: string;
  priceTo: string;
  markup: string;
  createdAt?: string;
  updatedAt?: string;
};

const AuditLogObject = {
  AlipayWallet: 'AlipayWallet',
  Currency: 'Currency',
  DeliveryInsurance: 'DeliveryInsurance',
  DeliveryMethod: 'DeliveryMethod',
  DeliveryOrder: 'DeliveryOrder',
  DeliveryOrderDeliveryMarkup: 'DeliveryOrderDeliveryMarkup',
  DeliveryOrderPackageGroup: 'DeliveryOrderPackageGroup',
  PackageMethod: 'PackageMethod',
  PurchaseOrder: 'PurchaseOrder',
  PurchaseOrderClientExpensesPurchaseMarkup:
    'PurchaseOrderClientExpensesPurchaseMarkup',
  PurchaseOrderClientExpensesPurchaseMarkupTariff:
    'PurchaseOrderClientExpensesPurchaseMarkupTariff',
  PurchaseOrderDeliveryTariff: 'PurchaseOrderDeliveryTariff',
  TopUpCardToCardAccount: 'TopUpCardToCardAccount',
  TopUpMethod: 'TopUpMethod',
  Transaction: 'Transaction',
  User: 'User',
  WeChatWallet: 'WeChatWallet',
  AutoSendParameters: 'AutoSendParameters',
  CompositeDeliveryOrder: 'CompositeDeliveryOrder',
  Promotion: 'Promotion',
};
type AuditLogObject = (typeof AuditLogObject)[keyof typeof AuditLogObject];

export type CleanAuditLog = {
  id: number;
  createdAt: string;
  object: AuditLogObject;
  objectId: number | null;
  objectInternalId: number | null;
  objectUuid: string | null;
  action: 'insert' | 'update' | 'delete';
  userId: number | null;
  user: CleanUser | undefined;
  data: { diff: { [Key in string]: { new: unknown; old: unknown } } };
};

export type CleanProfile = CleanUserSelf & {
  permissions: Record<string, string[]>;
};

export enum RbacRole {
  Admin = 'ROLE_ADMIN',
  Client = 'ROLE_CLIENT',
  Employee = 'ROLE_EMPLOYEE',
  Warehouse = 'ROLE_WAREHOUSE',
  Purchase = 'ROLE_PURCHASE',
  SalesManager = 'ROLE_SALES_MANAGER',
  SupplierFinances = 'ROLE_SUPPLIER_FINANCES',
  Marketer = 'ROLE_MARKETER',
  Transit = 'ROLE_TRANSIT',
  Financier = 'ROLE_FINANCIER',
  Reader = 'ROLE_READER',
  Fulfillment = 'ROLE_FULFILLMENT',
}

export enum RbacObject {
  Account = 'Account',
  Affiliate = 'Affiliate',
  AuditLog = 'AuditLog',
  AutoSendParameters = 'AutoSendParameters',
  Balance = 'Balance',
  CompositeDeliveryOrder = 'CompositeDeliveryOrder',
  Conversation = 'Conversation',
  Currency = 'Currency',
  DeliveryInsurance = 'DeliveryInsurance',
  DeliveryMethod = 'DeliveryMethod',
  DeliveryOrder = 'DeliveryOrder',
  DeliveryAddress = 'DeliveryAddress',
  DeliveryOrderDeliveryMarkup = 'DeliveryOrderDeliveryMarkup',
  LocalDeliveryMethod = 'LocalDeliveryMethod',
  Location = 'Location',
  OtcRecommendation = 'OtcRecommendation',
  PackageMethod = 'PackageMethod',
  Parcel = 'Parcel',
  PoizonCard = 'PoizonCard',
  Promotion = 'Promotion',
  Purchase = 'Purchase',
  PurchaseMarkup = 'PurchaseMarkup',
  PurchaseOrderClientExpensesPurchaseMarkup = 'PurchaseOrderClientExpensesPurchaseMarkup',
  PurchaseOrderDeliveryTariff = 'PurchaseOrderDeliveryTariff',
  ServiceChargeOption = 'ServiceChargeOption',
  Story = 'Story',
  Supplier = 'Supplier',
  TopUpMethod = 'TopUpMethod',
  Transaction = 'Transaction',
  User = 'User',
  Cdek = 'Cdek',
}

export const RbacObjectActionsMap = {
  [RbacObject.Account]: ['read', 'write', 'connect'],
  [RbacObject.Affiliate]: ['read'],
  [RbacObject.AuditLog]: ['read'],
  [RbacObject.AutoSendParameters]: ['read', 'write'],
  [RbacObject.Balance]: ['convert'],
  [RbacObject.CompositeDeliveryOrder]: ['read', 'write'],
  [RbacObject.Conversation]: ['read:all'],
  [RbacObject.Currency]: ['read', 'write'],
  [RbacObject.DeliveryInsurance]: ['read', 'write'],
  [RbacObject.DeliveryMethod]: ['read', 'write'],
  [RbacObject.DeliveryAddress]: ['read', 'write', 'delete'],
  [RbacObject.DeliveryOrderDeliveryMarkup]: ['read', 'write'],
  [RbacObject.DeliveryOrder]: [
    'read',
    'read:available',
    'read:methods',
    'calculateActual',
    'read:transactions',
    'write:markSentToRussia',
    'read:assembly-info',
    'read:actual-finances',
    'write',
    'write:address',
    'delete',
    'read:finances-client',
    'read:finances-company',
    'read:transit',
    'write:markDelivered',
    'read:notes',
    'write:notes',
    'read:address',
  ],
  [RbacObject.LocalDeliveryMethod]: ['read', 'write'],
  [RbacObject.Location]: ['read:admin', 'write:admin'],
  [RbacObject.OtcRecommendation]: ['read:admin', 'write:admin'],
  [RbacObject.PackageMethod]: ['read', 'write'],
  [RbacObject.Parcel]: [
    'read',
    'write:admin-create',
    'write:client',
    'write:admin',
    'cancel',
  ],
  [RbacObject.PoizonCard]: ['read:admin', 'write:admin'],
  [RbacObject.Promotion]: ['read', 'write'],
  [RbacObject.PurchaseMarkup]: ['read'],
  [RbacObject.PurchaseOrderClientExpensesPurchaseMarkup]: ['read', 'write'],
  [RbacObject.PurchaseOrderDeliveryTariff]: ['read', 'write'],
  [RbacObject.Purchase]: [
    'read',
    'read:client-total',
    'read:actual-total',
    'read:transactions',
    'cancel',
    'write:client-finances',
    'write:actual-finances',
    'write:client',
    'write:admin-create',
    'confirmPurchase',
    'write:admin',
    'confirmReceipt',
    'freeze',
    'unfreeze',
    'read:finances-company',
    'read:finances-client',
    'read:extended-statuses',
    'read:finances-company',
    'delete:while-waiting-on-payment',
    'write:while-waiting-on-payment',
    'write:while-in-work',
    'write:confirmation',
    'write:additionalWriteOff',
    'read:notes',
    'write:notes',
    'write:receipt-data',
    'write:metadata',
    'write:supplier',
  ],
  [RbacObject.ServiceChargeOption]: ['read:admin', 'write:admin'],
  [RbacObject.Story]: ['read:client', 'read:admin', 'write:admin'],
  [RbacObject.Supplier]: ['read:admin', 'write:admin'],
  [RbacObject.TopUpMethod]: [
    'write',
    'write:proof',
    'read:accounts',
    'read:calculate',
    'read:admin',
    'read:client',
    'write:admin',
    'write:client',
  ],
  [RbacObject.Transaction]: [
    'read:admin',
    'write:admin',
    'read:supplier',
    'write:expenses',
    'write:additional-service',
  ],
  [RbacObject.User]: [
    'read:admin',
    'write:admin',
    'write:toggles',
    'link',
    'unlink',
    'impersonate',
    'link:admin',
    'unlink:admin',
  ],
  [RbacObject.Cdek]: ['read'],
} as const;

export type CleanCurrency = {
  id: number;
  uuid: string;
  cnyExternal: string;
  cnyInternal: string;
  usdExternal: string;
  usdInternal: string;
  usdRubExternal: string | null;
  cnyUsdExternal: string | null;
  usdRubInternal: string | null;
  cnyUsdInternal: string | null;
  usdRubPartner: string | null;
  cnyRubPartner: string | null;
  createdAt: string;
};

/**
 * @deprecated
 */
type DeprecatedCurrencyType = 'usdCnyCryptomus' | 'cnyUsdCryptomus';
type CurrencyType =
  | 'cnyRubExternal'
  | 'cnyRubInternal'
  | 'cnyRubPartner'
  | 'usdRubExternal'
  | 'usdRubInternal'
  | 'usdRubPartner'
  | 'usdCny'
  | 'cnyUsd'
  | DeprecatedCurrencyType;
export type CleanCurrency2 = {
  id: number;
  type: CurrencyType;
  rate: string;
  createdAt: string;
};

type TopupV2CreateRedirectResponse = {
  type: 'redirect';
  data: {
    url: string;
  };
};

type TopupV2CreateOpenTransactionPageResponse = {
  type: 'open_transaction_page';
  data: {
    id: string;
  };
};

type TopupV2CreatePopUpWindowResponse = {
  type: 'pop-up';
  data: {
    url: string;
  };
};

export type TopupV2CreateResponse =
  | TopupV2CreateRedirectResponse
  | TopupV2CreateOpenTransactionPageResponse
  | TopupV2CreatePopUpWindowResponse;

export interface CleanLocalDeliveryMethod {
  id: string;
  disabled?: boolean;
  name: string;
  slug: string;
  priority?: number | null;
  createdAt?: string;
  updatedAt?: string;
  supplier?: CleanSupplier;
  location?: CleanLocation;
  selfDelivery?: boolean;
  isFulfillment?: boolean;
  isCdek?: boolean;

  priceClient?: string;
  commentClient?: string | null;
  priceSupplier?: string;
  commentSupplier?: string | null;

  minWeight?: string;
  maxWeight?: string;
}

export type CleanPackageGroup = {
  id: number;
  methods: CleanPackageMethod[];
  purchaseOrders: CleanPurchaseOrder[];
};

export type CleanSupplierCurrency = 'usd' | 'cny' | 'try';

export type CleanSupplier = {
  id: number;
  name: string;
  addressForParcels: string;
  location?: CleanLocation;
  purchaseCurrency: CleanSupplierCurrency;
  deliveryCurrency: CleanSupplierCurrency;
  disabled: boolean;
  deliveryOrderCombineRule?: {
    enabled: boolean;
    json?: string;
    deliveryMethodId?: string;
  };
  priority?: number | null;
};

export type CleanPurchaseOrderDeliveryTariff = {
  id: string;
  disabled?: boolean;
  priceTo: string;
  markup: string;
  createdAt?: string;
  updatedAt?: string;
  supplier?: CleanSupplier;
};

export type CleanLocation = {
  id: number;
  name: string;
  displayName: string;
  country: string | null;
  city: string | null;
};

export type DirectionLocation = {
  id: number;
  name: string;
  country: string | null;
  city: string | null;
};

type DirectionSupplier = {
  id: number;
  address: string;
  purchaseCurrency: CleanSupplierCurrency;
  deliveryCurrency: CleanSupplierCurrency;
  priority?: number | null;
};

export type CleanDirection = {
  supplier: DirectionSupplier;
  from: DirectionLocation;
  to: Array<DirectionLocation>;
};

export type CleanAutoSendParameters = {
  id: number;
  supplier?: CleanSupplier;
  localDeliveryMethod?: CleanLocalDeliveryMethod;
  deliveryMethod?: CleanDeliveryMethodMethod | null;
  volume: string;
  price: string;
  count: number;
  weight: string;
};
export type CleanCompositeDeliveryExpenses =
  | {
      client: string;
      company?: string;
    }
  | undefined;
type CleanCompositeDeliveryStatus =
  | 'assembly'
  | 'sentToRussia'
  | 'receivedInRussia';
export type CleanCompositeDelivery = {
  id: string;
  internalId: number;
  status: CleanCompositeDeliveryStatus;
  sentDate: string | null;
  receiveRfDate: string | null;
  createdAt: string;
  updatedAt?: string;
  deliveryOrders: Array<CleanDeliveryOrder> | undefined;
  moderateProductPrice?: string;
  moderateDeliveryPrice?: string | null;
  moderatePackagePrice?: string | null;
  moderateInsurancePrice?: string | null;
  moderateMarkupPrice?: string | null;
  prePrice?: string;
  price?: string | null;
  deliveryPrice?: string | null;
  packagePrice?: string | null;
  insurancePrice?: string | null;
  markupPrice?: string | null;
  expenses?: CleanCompositeDeliveryExpenses;
};

export type LanguageCode = 'ru' | 'zh';

export type CleanMessage = {
  id: number;
  user: CleanUser;
  message: string;
  attachments: Array<CleanFile>;
  sentAt: string;
  read: boolean;
  languageCode: LanguageCode | null;
  translatedMessage: string | null;
};

export type CleanConversationType = 'client' | 'supplier';
export type CleanConversation = {
  id: string;
  purchaseOrder?: CleanPurchaseOrder;
  deliveryOrder?: CleanDeliveryOrder;
  type: CleanConversationType;
  lastMessage?: CleanMessage;
  unread: number;
  status?: string;
};

export type CleanCurrencyCurrentV2 = {
  cnyRubInternal: string;
  cnyRubExternal: string;
  cnyRubPartner: string;
  usdRubInternal: string;
  usdRubExternal: string;
  usdRubPartner: string;
  usdCny?: string;
  cnyUsd?: string;
};

export type CleanCurrencyCurrentCross = {
  usdCnyInternal: string;
  usdCnyExternal: string;
};

export type CleanPromotionType = 'commission' | 'bonus';
export type CleanPromotionUsage = 'allOrders' | 'firstOrder' | 'nextOrder';

export type CleanPromotion = {
  id: string;
  name?: string;
  displayName: string;
  displayDescription: string;
  type: CleanPromotionType;
  usage: CleanPromotionUsage;
  markup?: string;
  bonus?: number;
  bonusLifetime?: number;
  startDate: string;
  endDate: string;
  paymentDate: string;
  createdAt?: string;
  updatedAt?: string;
  disabled?: boolean;
  users?: CleanUser[];
  banner?: CleanFile;
  promocode?: string;
  referrer?: CleanUser;
  promocodeUsers?: number;
  referrals?: CleanUser[];
};

export type CleanDeliveryInsurance = {
  id: string;
  name: string;
  disabled?: boolean;
  tariffs: Array<{
    id: string;
    pricePerKgFrom: string;
    pricePerKgTo: string;
    percent: string;
    createdAt: string;
  }>;
  createdAt: string;
  updatedAt: string;
  supplier: CleanSupplier;
};
type CleanBanType = 'full' | 'newPurchases';
export const PromocodePattern = /^[a-zA-ZА-Я0-9!-]+$/;

export const ConversionModificator = 1;

export const ConversionLimit = 4;

type Journal =
  | 'additionalPaymentForDeliveryOrder'
  | 'additionalPaymentForPurchaseOrder'
  | 'additionalServiceCharge'
  | 'additionalServiceChargeExpense'
  | 'affiliateCommissionForDeliveryOrder'
  | 'affiliateCommissionForPurchaseOrder'
  | 'convertCurrency'
  | 'depositByAlipay'
  | 'depositByCard'
  | 'depositByCash'
  | 'depositByCryptomus'
  | 'depositByCryptocloud'
  | 'depositByInvoice'
  | 'depositByPayKeeperCard'
  | 'depositByPayKeeperSbp'
  | 'depositByPayselection'
  | 'depositByPromotion'
  | 'depositBySbp'
  | 'depositByWechat'
  | 'depositCompany'
  | 'expensesForDeliveryOrder'
  | 'expensesForPurchaseOrder'
  | 'paymentForDeliveryOrder'
  | 'paymentForPurchaseOrder'
  | 'refundAdditionalServiceCharge'
  | 'refundAdditionalServiceChargeExpense'
  | 'refundForDeliveryOrder'
  | 'refundForPurchaseOrder'
  | 'supplierExpense'
  | 'withdraw';

export type CleanTransaction = {
  amount: string;
  metadata: Partial<{
    /** Конвертация баланса: id связанного списания */ relatedWithdrawalTransactionId: number;
    /** Конвертация баланса: id связанного зачисления */ relatedRefillTransactionId: number;
    /** Крипта партнёр: признак */ cryptoCreatedFromCash: true;
    /** Крипта партнёр: uuid транзакции из которой создали */ originalCashTransactionUuid: string;
    /** Бонусные рубли: id промо акции */ promotionId: number;
    /** Бонусные рубли: дата экспирации */ bonusExpiresAt: string;
  }> | null;
  accountName?: string;
  walletType: string | null;
  fee?: string | null;
  txid: number;
  userAmount?: string | null;
  currencyRateInternal?: string | null;
  type: 'writeOff' | 'refill';
  privateComment?: string | null;
  externalAmount?: string | null;
  createdAt: string;
  feeAmount?: string | null;
  journal: Journal;
  createdBy?: CleanUser;
  supplier?: CleanSupplier;
  comment: string | null;
  currency: string;
  id: string;
  currencyRateExternal?: string | null;
  user: { internalId: number };
  status: 'canceled' | 'draft' | 'processing' | 'confirmed';
  updatedAt: string;
  operationName?: string;
  statusDescription?: string;
  proofFileId?: number | null;
  proofFile?: CleanProofFile;
  cardHolderName?: string | null;
  qrFile?: CleanFile;
  cardNumber?: string | null;
  billNumber?: number;
  billDate?: string;
  billLink?: string;
  invoiceLink?: string;
  internalTransaction?: true;
  purchaseOrder?: CleanPurchaseOrder;
  bonus?: true;
  deliveryOrder?: CleanDeliveryOrder;
  cryptomusInvoices?: Array<CleanCryptopmusInvoice>;
  topUpCurrency: 'usd' | 'rub' | 'cny' | 'usdt' | 'bonus' | null;
  cryptocloudInvoices?: Array<unknown>;
  sbpFile?: CleanFile;
};
export type CleanProofFile = {
  id: string;
  originalName: string;
  type: string;
};
export type CleanCryptopmusInvoice = {
  status: string;
  network: 'tron' | 'eth' | 'ton';
  txid: string;
  amount: string;
  createdAt: string;
};

export type CleanPurchaseOrderWriteOff = {
  amount: string;
  description?: string;
  createdAt: string;
  receiptFiles: CleanFile[];
};

export type CleanNote = {
  id: number;
  user: CleanUser;
  noteText: string;
  createdAt: string;
  updatedAt: string;
};

export const CdekTariffCode = 136;
export const CdekServices = [
  { code: 'NOTIFY_ORDER_DELIVERY' },
  { code: 'NOTIFY_ORDER_CREATED' },
];

export type CleanCdekOrder = {
  id: number;
  deliveryOrderId: number;
  cdekUuid: string;
  receiptUuid: string | null;
  barcodeUuid: string | null;
};
