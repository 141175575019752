import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { api } from '../../../services/api';
import { AlertService } from '../../../services/alert.service';
import { InputWrapper } from '../../common/input-wrapper';
import { usePackageMethods } from '../../../api/delivery';
import { CleanPackageGroup } from '../../../export-types/cleaned-types';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { IdCell } from '../../common/cards/id-cell-card.component';
import ReactSelect from 'react-select';

export type PackageGroup = {
  id?: number;
  orders: string[];
  methods: string[];
};

type Form = {
  packageGroups: PackageGroup[];
};

type RequestPackageMethodEditFormProps = {
  id: string;
  packageGroups: CleanPackageGroup[];
  packageMethods: string | null;
  onSave: () => void;
  onCancel: () => void;
};

export const RequestPackageMethodEditForm: React.FC<
  RequestPackageMethodEditFormProps
> = ({ packageGroups, ...props }) => {
  const { control, formState, handleSubmit, watch } = useForm<Form>({
    defaultValues: {
      packageGroups:
        packageGroups?.map((group) => ({
          id: group.id,
          orders: group.purchaseOrders.map((order) => order.id),
          methods: group.methods.map((order) => order.id),
        })) ?? [],
    },
  });

  const [groups] = watch(['packageGroups']);
  const { data, isLoading } = usePackageMethods(groups);

  const onSubmit = handleSubmit(async (form) => {
    api.put(`/delivery-order/${props.id}/package-methods`, form).then(() => {
      AlertService.success();
      props.onSave();
    });
  });

  function handleCancel() {
    props.onCancel();
  }

  return (
    <form onSubmit={onSubmit}>
      <Controller
        control={control}
        name="packageGroups"
        render={({ field, fieldState }) => (
          <InputWrapper
            theme="light"
            className="mt-3"
            title=""
            error={fieldState.error?.message}
          >
            {field.value?.map((group, i) => (
              <div key={i}>
                <div className="color-gray-450">Упаковка №{i + 1}</div>
                <table className="table table-responsive table-borderless rounded  mt-3 bg-white">
                  <thead className="color-gray-600 border-bottom">
                    <tr>
                      <td>
                        <ReactSelect
                          value={
                            data
                              ? data[i].filter((method) =>
                                  group.methods.includes(method.id),
                                )
                              : []
                          }
                          isLoading={isLoading}
                          onChange={(v) => {
                            group.methods = v.map((method) => method.id);
                            field.onChange(field.value);
                          }}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          options={data ? data[i] : []}
                          isMulti
                        />
                      </td>
                      <td className="text-end">
                        <FormattedMessage
                          defaultMessage="Место хранения"
                          id="storage.label.storagePlace"
                          description="Информация о месте хранения"
                        />
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {packageGroups &&
                      packageGroups[i].purchaseOrders.map((order) => (
                        <tr key={order.id}>
                          <td>
                            <NavLink
                              className="btn-link cursor-pointer text-decoration-underline"
                              to={`/${
                                order.type === 'purchase'
                                  ? 'purchases'
                                  : 'parcels'
                              }/${order.id}`}
                            >
                              <IdCell
                                internalId={order.internalId}
                                type={order.type}
                              />
                            </NavLink>{' '}
                          </td>
                          <td className="text-end">{order.storageLocation}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ))}
          </InputWrapper>
        )}
      />
      <div className="mt-2">
        <button
          className="btn btn-link text-decoration-none"
          disabled={formState.isSubmitting}
        >
          {formState.isSubmitting ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            'Сохранить'
          )}
        </button>
        <button
          className="btn btn-link text-decoration-none"
          type="button"
          disabled={formState.isSubmitting}
          onClick={handleCancel}
        >
          Отменить
        </button>
      </div>
    </form>
  );
};
