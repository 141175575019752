import React, { useEffect } from 'react';
import Table, { CellProps } from 'rsuite/Table';
import { SupplierCell } from '../../common/table/supplier-cell';
import Pagination from 'rsuite/Pagination';
import { useDebouncedValue, useInputState } from '@mantine/hooks';
import { CleanUser, RbacObject } from '../../../export-types/cleaned-types';
import { usePaginationQs } from '../../../utils/usePagination';
import { ACCESSES } from '../../../consts/db-values';
import { Rbac } from '../../common/Rbac';
import Whisper from 'rsuite/Whisper';
import IconButton from 'rsuite/IconButton';
import { WhisperSpeaker } from '../../../utils/whisper-speaker';
import Popover from 'rsuite/Popover';
import Dropdown from 'rsuite/Dropdown';
import { ModalsService } from '../../../services/modals.service';
import { AuthService } from '../../../services/auth.service';
import { ProfileService } from '../../../services/profile.service';
import { AddUserModal } from './add-user-modal.component';
import { LinkUserModal } from './link-user-model.component';
import { UnLinkUserModal } from './unlink-user-model.component';
import { useUsers } from '../../../api/users';

export type UserProps = CleanUser & {
  moneyBill: { cny: number; usd: number };
  deliveryDebt: number | null;
};

export const UsersEmployeesTable = () => {
  const defaultTake = 16;

  const { take, skip, handleSetPage, handleChangeLimit, page } =
    usePaginationQs(defaultTake);

  const [internalId, setInternalId] = useInputState('');
  const [debouncedInternalId] = useDebouncedValue(internalId, 200);

  const { data, isLoading, mutate } = useUsers(
    ['admin', 'employee'],
    debouncedInternalId,
    null,
    null,
    take,
    skip,
  );

  useEffect(() => {
    setInternalId('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const AccessesCell = ({
    rowData,
    dataKey,
    ...props
  }: CellProps<UserProps>) => {
    if (!rowData) return null;
    let str: string;
    if (rowData.role === 'admin') {
      str = 'Администратор';
    } else {
      str = rowData.accesses
        ? rowData.accesses
            .map((a) => ACCESSES[a as keyof typeof ACCESSES] ?? a)
            .join(' / ')
        : '';
    }
    return <Table.Cell {...props}>{str}</Table.Cell>;
  };

  const renderMenu =
    (user: UserProps): WhisperSpeaker =>
    ({ onClose, left, top, className }, ref) => {
      const handleSelect = async (eventKey: string | undefined) => {
        onClose();
        if (eventKey === '1') {
          ModalsService.createModal(AddUserModal, { user, mutate });
        }
        if (eventKey === 'impersonate') {
          await AuthService.impersonate(user.id);
          await ProfileService.getCurrentUser();
          window.location.replace('/');
        }
        if (eventKey === '4') {
          ModalsService.createModal(LinkUserModal, { user, mutate });
        }
        if (eventKey === '5') {
          ModalsService.createModal(UnLinkUserModal, { user, mutate });
        }
      };

      return (
        <Popover ref={ref} className={className} style={{ left, top }} full>
          <Dropdown.Menu onSelect={handleSelect}>
            <Dropdown.Item eventKey={'1'}>
              <i className="bi bi-pencil" />
              <span className="ps-2">Изменить</span>
            </Dropdown.Item>
            {user.role !== 'client' && (
              <Rbac object={RbacObject.User} action={'impersonate'}>
                <Dropdown.Item eventKey="impersonate">
                  <i className="bi bi-person-badge" />
                  <span className="ps-2">Залогиниться</span>
                </Dropdown.Item>
              </Rbac>
            )}
            {/* <Dropdown.Item eventKey={'3'}>
              <i className="bi bi-trash" />
              <span className="ps-2">Удалить</span>
            </Dropdown.Item> */}
          </Dropdown.Menu>
        </Popover>
      );
    };

  const ActionCell = ({ rowData, dataKey, ...props }: CellProps<UserProps>) => {
    return (
      rowData && (
        <Table.Cell
          {...props}
          className="link-group no-padding-cell pt-2"
          align="right"
        >
          <Rbac object={RbacObject.User} action={'write:admin'}>
            <Whisper
              placement="leftStart"
              trigger="click"
              speaker={renderMenu(rowData)}
            >
              <IconButton
                appearance="subtle"
                icon={<i className="bi bi-three-dots" />}
              />
            </Whisper>
          </Rbac>
        </Table.Cell>
      )
    );
  };

  const total = data?.total;
  const items = data?.items;

  return (
    <>
      <Table data={items} headerHeight={57} autoHeight loading={isLoading}>
        <Table.Column width={150}>
          <Table.HeaderCell>Поставщик</Table.HeaderCell>
          <SupplierCell />
        </Table.Column>
        <Table.Column flexGrow={0}>
          <Table.HeaderCell>ID</Table.HeaderCell>
          <Table.Cell dataKey="internalId" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Имя</Table.HeaderCell>
          <Table.Cell dataKey="name" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Номер телефона</Table.HeaderCell>
          <Table.Cell dataKey="phone" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.Cell dataKey="email" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Права</Table.HeaderCell>
          <AccessesCell />
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell> </Table.HeaderCell>
          <ActionCell />
        </Table.Column>
      </Table>

      {total && total > take && (
        <div className="mt-4 pb-4">
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="md"
            layout={['pager']}
            total={total}
            limitOptions={[15, 20]}
            limit={take}
            activePage={page}
            onChangePage={handleSetPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      )}
    </>
  );
};
