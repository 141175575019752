import React from 'react';
import { useModalClose } from '../../utils/useModalClose';

type Resolve = (result?: boolean) => void;

type AdminNoMoneyModalProps = {
  close: Resolve;
  reject: () => void;
  description: string;
  action: string;
  onAction: (resolve: Resolve) => void;
};

export const AdminNoMoneyModal: React.FC<AdminNoMoneyModalProps> = (props) => {
  const modalRef = useModalClose(props.close);

  return (
    <div className="modal-dialog mw-400" ref={modalRef}>
      <div className="modal-content">
        <div className="modal-body p-2">
          <div className="d-flex flex-row justify-content-end">
            <div className="btn p-0 lh-1" onClick={() => props.close()}>
              <i className="bi bi-x fs-36 color-gray-600" />
            </div>
          </div>
          <div className="text-center mb-5">
            <i className="bi bi-exclamation-circle-fill color-red fs-65 lh-1" />
            <h3>Недостаточно средств</h3>
            <div>{props.description}</div>
          </div>
          <div
            className="btn btn-success w-100 mb-3"
            onClick={() => {
              props.onAction(props.close);
            }}
          >
            {props.action}
          </div>
          <div className="btn btn-light w-100" onClick={() => props.close()}>
            Отмена
          </div>
        </div>
      </div>
    </div>
  );
};
