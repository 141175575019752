import React from 'react';
import { useNavigate } from 'react-router-dom';
import Popover from 'rsuite/Popover';
import Dropdown from 'rsuite/Dropdown';
import { AlertService } from '../../../services/alert.service';
import { api } from '../../../services/api';
import Whisper from 'rsuite/Whisper';
import {
  CleanPurchaseOrder,
  RbacObject,
} from '../../../export-types/cleaned-types';
import { Rbac } from '../../common/Rbac';
import { ModalsService } from '../../../services/modals.service';
import { PurchaseOrderCancelModal } from './purchase-order-cancel-modal';

type RequestInfoActionsProps = {
  order: CleanPurchaseOrder;
  onChange: () => void;
  onEdit: () => void;
};

export const RequestInfoActions: React.FC<RequestInfoActionsProps> = (
  props,
) => {
  const navigate = useNavigate();

  const speaker = (
    { onClose, left, top, className }: any,
    ref: React.Ref<any>,
  ) => (
    <Popover ref={ref} className={className} style={{ left, top }} full>
      <Dropdown.Menu>
        <Dropdown.Item
          onSelect={function () {
            onClose();
            props.onEdit();
          }}
        >
          <i className="bi bi-pencil" />
          <span className="ps-2">Изменить</span>
        </Dropdown.Item>
        {props.order.status !== 'waitingForPayment' && (
          <Rbac object={RbacObject.Purchase} action={'cancel'}>
            <Dropdown.Item
              onSelect={function () {
                onClose();
                ModalsService.createModal(PurchaseOrderCancelModal, {
                  order: { id: props.order.id },
                }).then(() => {
                  AlertService.success('Заказ успешно отменен');
                });
              }}
            >
              <i className="bi bi-x-circle" />
              <span className="ps-2">Отменить</span>
            </Dropdown.Item>
          </Rbac>
        )}
        {props.order.status === 'waitingForPayment' && (
          <Dropdown.Item
            onSelect={function () {
              onClose();
              api
                .delete(`/purchase-order/${props.order.id}`)
                .then(() => {
                  navigate('/purchase-orders');
                })
                .then(() => {
                  AlertService.success('Заказ успешно удален');
                });
            }}
          >
            <i className="bi bi-x-circle" />
            <span className="ps-2">Удалить</span>
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Popover>
  );

  return (
    <Whisper placement="bottomEnd" trigger="click" speaker={speaker}>
      <button className="btn btn-link text-decoration-none">
        <i className="bi bi-three-dots" />
      </button>
    </Whisper>
  );
};
