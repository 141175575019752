import React from 'react';
import { Link } from 'react-router-dom';
import shopping from '../../assets/shopping-bag-01.svg';
import packagePlus from '../../assets/package-plus.svg';
import parcelStyle from './parcel-modal.module.css';
import { Rbac } from './Rbac';
import { RbacObject } from '../../export-types/cleaned-types';
import { useModalClose } from '../../utils/useModalClose';

type ParcelModalProps = {
  isUser?: boolean;
  close: () => void;
  reject: () => void;
};

export const ParcelModal: React.FC<ParcelModalProps> = (props) => {
  const modalRef = useModalClose(props.reject);

  return (
    <div className={parcelStyle.parcelModal} ref={modalRef}>
      <div className={parcelStyle.parcelContainer}>
        <div className={parcelStyle.parcelTitle}>
          <div>
            <h3>Выберите услугу</h3>
          </div>

          <button
            type="button"
            className="btn-close"
            onClick={() => props.reject()}
          />
        </div>
        <div className={parcelStyle.parcelBody}>
          <Rbac object={RbacObject.Purchase} action={'write:admin-create'}>
            <Link
              to="/purchases/form"
              className={parcelStyle.parcelCard}
              onClick={() => props.close()}
            >
              <img
                src={shopping}
                alt=""
                width="32px"
                height="32px"
                className={parcelStyle.parcelCardImage}
              />
              <div className={parcelStyle.parcelCardTitle}>
                Заказать выкуп товара у&nbsp;поставщика или с&nbsp;маркетплейса
              </div>
              <div className={parcelStyle.parcelCardDescription}>
                Выкупим товар, примем на свой склад, проверим и пришлём
                фотоотчёт.
              </div>
            </Link>
          </Rbac>
          <Rbac object={RbacObject.Parcel} action={'write:admin-create'}>
            <Link
              to="/purchases/parcel-form"
              className={parcelStyle.parcelCard}
              onClick={() => props.close()}
            >
              <img
                src={packagePlus}
                alt=""
                width="32px"
                height="32px"
                className={parcelStyle.parcelCardImage}
              />
              <div className={parcelStyle.parcelCardTitle}>
                Добавить ожидаемую посылку
              </div>
              <div className={parcelStyle.parcelCardDescription}>
                Самостоятельно выкупили товар? Отправляйте его на наш склад!
                Мы всё проверим, упакуем и отправим в Россию.
              </div>
            </Link>
          </Rbac>
        </div>
      </div>
    </div>
  );
};
