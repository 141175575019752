import React from 'react';
import useSWR from 'swr';
import { api, fetcher } from '../../../services/api';
import Table, { CellProps } from 'rsuite/Table';
import { WhisperSpeaker } from '../../../utils/whisper-speaker';
import Popover from 'rsuite/Popover';
import Dropdown from 'rsuite/Dropdown';
import Whisper from 'rsuite/Whisper';
import IconButton from 'rsuite/IconButton';
import { toMoney } from '../../../utils/common.utils';
import { ModalsService } from '../../../services/modals.service';
import { AutoSendParametersEditModal } from './auto-send-parameters-edit-modal';
import {
  CleanLocalDeliveryMethod,
  CleanSupplier,
} from '../../../export-types/cleaned-types';
import { AlertService } from '../../../services/alert.service';

type AutoSendParameters = {
  weight: string;
  id: number;
  volume: string;
  count: number;
  price: string;
  deliveryMethod: string;
  localDeliveryMethod: CleanLocalDeliveryMethod;
  supplier: CleanSupplier;
};
export const AutoSendParametersScreen: React.FC = () => {
  const { data, mutate, isLoading } = useSWR<AutoSendParameters[]>(
    '/auto-send',
    fetcher,
  );
  const PriceCell = ({
    rowData,
    dataKey,
    ...props
  }: CellProps<AutoSendParameters>) =>
    rowData && (
      <Table.Cell {...props}>
        {toMoney(
          rowData[dataKey as keyof AutoSendParameters] as string,
          rowData?.supplier?.purchaseCurrency ?? 'cny',
        )}
      </Table.Cell>
    );
  const WeightCell = ({
    rowData,
    dataKey,
    ...props
  }: CellProps<AutoSendParameters>) =>
    rowData && (
      <Table.Cell {...props}>
        {rowData[dataKey as keyof AutoSendParameters] as string} кг
      </Table.Cell>
    );

  const VolumeCell = ({
    rowData,
    dataKey,
    ...props
  }: CellProps<AutoSendParameters>) =>
    rowData && (
      <Table.Cell {...props}>
        {rowData[dataKey as keyof AutoSendParameters] as string} м3
      </Table.Cell>
    );

  const SupplierCell = ({ rowData, ...props }: CellProps<AutoSendParameters>) =>
    rowData && <Table.Cell {...props}>{rowData.supplier?.name}</Table.Cell>;

  const DeliveryMethodCell = ({
    rowData,
    ...props
  }: CellProps<AutoSendParameters>) =>
    rowData && <Table.Cell {...props}>{rowData.deliveryMethod}</Table.Cell>;

  const LocalDeliveryMethodCell = ({
    rowData,
    ...props
  }: CellProps<AutoSendParameters>) =>
    rowData && (
      <Table.Cell {...props}>{rowData.localDeliveryMethod?.name}</Table.Cell>
    );

  function onSelectAdd(rowData: any, eventKey: string | undefined) {
    switch (eventKey) {
      case '1': {
        ModalsService.createModal(AutoSendParametersEditModal, {
          defaultValues: {
            supplierId: rowData?.supplier?.id,
            localDeliveryMethodId: rowData?.localDeliveryMethod?.id,
            ...rowData,
          },
        }).then((data) => {
          api
            .put(`/auto-send/${rowData.id}`, {
              count: Number(data.count),
              weight: data.weight,
              volume: data.volume,
              price: data.price,
              deliveryMethod: data.deliveryMethod,
              localDeliveryMethodId: data.localDeliveryMethodId,
              supplierId: data.supplierId,
            })
            .then(() => {
              AlertService.success();
              mutate();
            });
        });
      }
    }
  }
  return (
    <Table
      loading={isLoading}
      data={data}
      headerHeight={57}
      rowKey="id"
      locale={{
        loading: 'Загрузка',
        emptyMessage: 'Нет параметров',
      }}
      autoHeight
    >
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>Вес</Table.HeaderCell>
        <WeightCell dataKey="weight" />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>Объем</Table.HeaderCell>
        <VolumeCell dataKey="volume" />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>Количество</Table.HeaderCell>
        <Table.Cell dataKey="count" />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>Цена</Table.HeaderCell>
        <PriceCell dataKey="price" />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>Способ доставки</Table.HeaderCell>
        <DeliveryMethodCell />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>ТК</Table.HeaderCell>
        <LocalDeliveryMethodCell />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>Поставщик</Table.HeaderCell>
        <SupplierCell />
      </Table.Column>
      <Table.Column>
        <Table.HeaderCell children="" />
        <Table.Cell className="link-group no-padding-cell pt-2" align="right">
          {(rowData) => {
            const speaker: WhisperSpeaker = (
              { onClose, left, top, className },
              ref,
            ) => (
              <Popover
                ref={ref}
                className={className}
                style={{ left, top }}
                full
              >
                <Dropdown.Menu
                  onSelect={(eventKey) => onSelectAdd(rowData, eventKey)}
                >
                  <Dropdown.Item eventKey="1">
                    <i className="bi bi-pencil" />
                    <span className="ps-2">Изменить</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Popover>
            );

            return (
              <Whisper placement="leftStart" trigger="click" speaker={speaker}>
                <IconButton
                  appearance="subtle"
                  icon={<i className="bi bi-three-dots" />}
                />
              </Whisper>
            );
          }}
        </Table.Cell>
      </Table.Column>
    </Table>
  );
};
