import { useEffect, useState } from 'react';

/**
 * @see https://github.com/childrentime/reactuse/blob/ace84597fabea6326afa1c08dac95542fae69d7b/packages/core/src/useObjectUrl/index.ts
 */
export default function useObjectUrl(
  object: Blob | MediaSource | undefined,
): string | undefined {
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    if (object) {
      setUrl(URL.createObjectURL(object));
    }

    return () => {
      if (url) {
        URL.revokeObjectURL(url);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [object]);

  return url;
}
