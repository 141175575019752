import React from 'react';
import { InputWrapper } from '../../common/input-wrapper';
import useSWR from 'swr';
import { fetcher } from '../../../services/api';
import { useProfile } from '../../../api/profile';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  CleanPurchaseOrder,
  RbacObject,
} from '../../../export-types/cleaned-types';
import { Rbac } from '../../common/Rbac';
import { ModalsService } from '../../../services/modals.service';
import { PurchaseOrderEditOrderModal } from './purchase-order-edit-order-modal';

type PurchaseOrderWriteOffsProps = {
  id: string;
};

export const PurchaseOrderInfo: React.FC<PurchaseOrderWriteOffsProps> = (
  props,
) => {
  const intl = useIntl();
  const { data: user } = useProfile();
  const { data: order, mutate: orderMutate } = useSWR<CleanPurchaseOrder>(
    `/purchase-order/${props.id}`,
    fetcher,
  );

  if (!order || !user) {
    return null;
  }

  function handleEdit() {
    if (!order) {
      return;
    }
    ModalsService.createModal(PurchaseOrderEditOrderModal, { order }).then(() =>
      orderMutate(),
    );
  }

  return (
    <div>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="fs-18 fw-600 color-gray-450">
          <FormattedMessage
            defaultMessage="Ордер"
            id="purchaseOrderWriteOffs.label.orderInfo"
            description="Заголовок на странице"
          />
        </div>
        {order.status === 'onTheWayToStock' && (
          <Rbac object={RbacObject.Purchase} action={'confirmReceipt'}>
            <button
              className="btn btn-link text-decoration-none"
              type="button"
              onClick={handleEdit}
            >
              Изменить
            </button>
          </Rbac>
        )}
      </div>
      {order.orderLink && (
        <InputWrapper
          theme="light"
          className="mt-3 text-truncate"
          title={intl.formatMessage({
            defaultMessage: 'Ссылка на ордер',
            id: 'purchaseOrderModal.input-label.orderLink',
            description: 'Заголовок поля ввода',
          })}
        >
          <a
            className="text-black text-wrap text-break"
            href={order.orderLink}
            target="_blank"
            rel="noreferrer"
          >
            {order.orderLink}
          </a>
        </InputWrapper>
      )}
      {order.orderNumber && (
        <InputWrapper
          theme="light"
          className="mt-3 text-truncate"
          title={intl.formatMessage({
            defaultMessage: 'Номер ордера',
            id: 'purchaseOrderModal.input-label.orderNumber',
            description: 'Заголовок поля ввода',
          })}
        >
          <div>{order.orderNumber}</div>
        </InputWrapper>
      )}
      {order.orderAccount && (
        <InputWrapper
          theme="light"
          className="mt-3 text-truncate"
          title={intl.formatMessage({
            defaultMessage: 'Аккаунт',
            id: 'purchaseOrderModal.input-label.orderAccount',
            description: 'Заголовок поля ввода',
          })}
        >
          <div>{order.orderAccount}</div>
        </InputWrapper>
      )}
      {order.landingBill && (
        <InputWrapper
          theme="light"
          className="mt-3 text-truncate"
          title={intl.formatMessage({
            defaultMessage: '№ ТТН',
            id: 'purchaseOrderModal.input-label.landingBill',
            description: 'Заголовок поля ввода',
          })}
        >
          <div>{order.landingBill}</div>
        </InputWrapper>
      )}
      {order.landingBill && (
        <InputWrapper
          theme="light"
          className="mt-3 text-truncate"
          title={intl.formatMessage({
            defaultMessage: 'Название транспортной компании',
            id: 'purchaseOrderModal.input-label.transportCompany',
            description: 'Заголовок поля ввода',
          })}
        >
          <div>{order.transportCompany}</div>
        </InputWrapper>
      )}
    </div>
  );
};
