import React from 'react';
import { useForm } from 'react-hook-form';
import { Input } from '../../common/input.component';
import { required } from '../../../utils/validations';
import { AlertService } from '../../../services/alert.service';
import { ProfileService } from '../../../services/profile.service';
import { ModalProps } from '../../../services/modals.service';
import { useModalClose } from '../../../utils/useModalClose';

type Fields = {
  password: string;
  passwordCheck: string;
};

export const ChangePasswordModal: React.FC<ModalProps> = ({
  close,
  reject,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Fields>();

  function onSave({ password, passwordCheck }: Fields) {
    if (password !== passwordCheck) {
      return AlertService.error('Пароли не совпадают');
    }

    ProfileService.setPassword({ password, passwordCheck }).then(() => {
      AlertService.success();
      close();
    });
  }

  const modalRef = useModalClose(reject);

  return (
    <div className="modal-dialog" ref={modalRef}>
      <form className="modal-content" onSubmit={handleSubmit(onSave)}>
        <div className="modal-body">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h3>Изменить пароль</h3>
            <div className="btn p-0" onClick={reject}>
              <i className="bi bi-x fs-36 color-gray-600" />
            </div>
          </div>
          <Input
            className="mb-4"
            title="Придумайте пароль"
            autoComplete="new-password"
            {...register('password', {
              ...required,
            })}
            error={errors.password?.message}
            type="password"
          />
          <Input
            title="Повторите пароль"
            autoComplete="new-password"
            {...register('passwordCheck', {
              ...required,
            })}
            error={errors.passwordCheck?.message}
            type="password"
          />
        </div>
        <div className="modal-footer">
          <div className="d-flex flex-row justify-content-end">
            <button type="submit" className="btn btn-success">
              Сохранить
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
