import { Input } from '../../common/input.component';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { AuthService } from '../../../services/auth.service';
import { ProfileService } from '../../../services/profile.service';
import { phoneValidation, required } from '../../../utils/validations';
import React from 'react';
import { SubmitHandler } from 'react-hook-form/dist/types/form';

type Fields = {
  phone: string;
  password: string;
};

type Props = {
  userIsClient: boolean;
};

export const LoginScreen = ({ userIsClient }: Props) => {
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<Fields>();

  const onSubmit: SubmitHandler<Fields> = ({ phone, password }) => {
    AuthService.login({ username: phone, password }).then(() =>
      ProfileService.getCurrentUser(),
    );
  };

  return (
    <div className="full-height d-flex flex-column justify-content-between">
      <div className="flex-grow-1 d-flex justify-content-center align-items-center">
        <div className="auth-wrapper">
          <h3 className="mb-4">Войти в личный кабинет</h3>
          <form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
            {userIsClient ? (
              <div className="mb-4 color-red">
                Этот аккаунт другого домена
                <br />
                这个账号是另外一个域名的
              </div>
            ) : null}
            <Controller
              name="phone"
              control={control}
              rules={{ ...required, ...phoneValidation }}
              render={({ field }) => (
                <Input
                  title="Номер телефона"
                  className="mb-4"
                  placeholder="+7"
                  mask="+7 (999) 999-99-99"
                  {...field}
                  error={errors.phone?.message}
                />
              )}
            />
            <Input
              type="password"
              {...register('password', {
                ...required,
              })}
              error={errors.password?.message}
              title="Пароль"
            />
            <Link
              to="/forgot-password"
              className="btn-link d-block link-success mb-4"
            >
              Забыли пароль?
            </Link>
            <button type="submit" className="btn btn-success w-100">
              Войти
            </button>
          </form>
        </div>
      </div>
      <div />
    </div>
  );
};
