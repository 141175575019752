import { usePurchaseOrderStatuses } from '../../consts/db-values';
import { NavLink } from 'react-router-dom';
import React from 'react';
import { CleanPurchaseOrder } from '../../export-types/cleaned-types';
import { FormattedMessage } from 'react-intl';

type Props = {
  order: CleanPurchaseOrder;
};

export const PurchaseOrderStatus: React.FC<Props> = ({ order }) => {
  const PURCHASE_ORDER_STATUSES = usePurchaseOrderStatuses();
  const res =
    order.status === 'inWork' ? (
      <FormattedMessage
        defaultMessage="Выкупить"
        id="purchaseOrderStatus.status-label.redeem"
        description="Статус заказа"
      />
    ) : order.sendBlocked ? (
      'Заблокирован'
    ) : (
      PURCHASE_ORDER_STATUSES[
        order.status as keyof typeof PURCHASE_ORDER_STATUSES
      ]
    );

  return order.deliveryOrder ? (
    <NavLink
      className={`purchase-order-status ${order.status} text-decoration-none`}
      to={`/deliveries/delivery-order/${order.deliveryOrder.id}`}
    >
      {res}
      {order.status === 'canceled' ? ` / ${order.cancelReason}` : undefined}
      {` D${order.deliveryOrder.internalId}`}
    </NavLink>
  ) : (
    <div className={`purchase-order-status ${order.status}`}>
      {res}
      {order.status === 'canceled' ? ` / ${order.cancelReason}` : undefined}
    </div>
  );
};
