import React, { ReactElement, ReactNode } from 'react';

const CheckedIcon = (props: { status?: string | null }) => {
  switch (props.status) {
    case 'done':
      return (
        <i
          className="bi bi-circle-fill"
          style={{ color: '#12B76A', fontSize: '16px' }}
        ></i>
      );
    case 'active':
      return (
        <i
          className="bi bi-record-circle"
          style={{ color: '#FF7B0D', fontSize: '16px' }}
        ></i>
      );
    case 'canceled':
      return (
        <i
          className="bi bi-circle-fill"
          style={{ color: '#F23E2C', fontSize: '16px' }}
        ></i>
      );
    default:
      return (
        <i
          className="bi bi-circle-fill"
          style={{ color: '#DEE2E6', fontSize: '16px' }}
        ></i>
      );
  }
};

type StatusBlockProps = {
  title?: ReactNode;
  subtitle?: ReactNode;
  status?: 'done' | 'active' | 'canceled';
  action?: ReactElement;
  connector?: boolean;
};

export const StatusBlock: React.FC<StatusBlockProps> = ({
  title,
  subtitle,
  action,
  status,
  connector,
}) => {
  return (
    <li className="d-flex">
      <div className="d-flex flex-column ">
        <CheckedIcon status={status && status} />
        {connector && <div className={`align-self-center _status-line`}></div>}
      </div>
      <div className="ms-3 d-flex flex-column flex-grow-1 pb-3">
        <div
          className={status ? 'fs-16 fw-500' : 'fs-16 fw-400 color-gray-450'}
        >
          {title}
        </div>
        <div className="fs-14 fw-400 color-gray-450">
          {subtitle && subtitle}
        </div>
        {action && <div className="mt-3">{action}</div>}
      </div>
    </li>
  );
};
