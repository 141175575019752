import React from 'react';
import { Header } from '../../common/header.component';
import { NavLink, Outlet } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDebouncedState } from '@mantine/hooks';
import Toggle from 'rsuite/Toggle';

export type OutletContextParams = {
  debouncedInternalId: string;
  linked: boolean;
};

export const ConversationScreen = () => {
  const intl = useIntl();
  const tabs = [
    {
      title: intl.formatMessage({
        id: 'conversation.status.all',
        defaultMessage: 'Все',
        description: 'Надпись на вкладке',
      }),
      path: 'all',
      name: 'all' as const,
    },
    {
      title: intl.formatMessage({
        id: 'conversation.status.unread',
        defaultMessage: 'Не прочитано',
        description: 'Надпись на вкладке',
      }),
      path: 'unread',
      name: 'debtor' as const,
    },
    {
      title: intl.formatMessage({
        id: 'conversation.status.refund',
        defaultMessage: 'Возврат',
        description: 'Надпись на вкладке',
      }),
      path: 'refund',
      name: 'refund' as const,
    },

    {
      title: intl.formatMessage({
        id: 'conversation.status.defectCheck',
        defaultMessage: 'Проверка на брак',
        description: 'Надпись на вкладке',
      }),
      path: 'defectCheck',
      name: 'defectCheck' as const,
    },
    {
      title: intl.formatMessage({
        id: 'conversation.status.delayed',
        defaultMessage: 'Задержка',
        description: 'Надпись на вкладке',
      }),
      path: 'delayed',
      name: 'delayed' as const,
    },
    {
      title: intl.formatMessage({
        id: 'conversation.status.adminAssistance',
        defaultMessage: 'Помощь админа',
        description: 'Надпись на вкладке',
      }),
      path: 'adminAssistance',
      name: 'adminAssistance' as const,
    },
    {
      title: intl.formatMessage({
        id: 'conversation.status.archive',
        defaultMessage: 'Архив',
        description: 'Надпись на вкладке',
      }),
      path: 'archive',
      name: 'archive' as const,
    },
  ];

  const [internalId, setInternalId] = useDebouncedState('', 200);
  const [linked, setLinked] = React.useState(false);

  const context: OutletContextParams = {
    debouncedInternalId: internalId,
    linked,
  };

  return (
    <>
      <Header>
        <div className="d-flex flex-row">
          <h1>
            <FormattedMessage
              defaultMessage="Диалоги"
              id="conversation.label.title"
              description="Заголовок страницы"
            />
          </h1>
        </div>
      </Header>
      <div className="d-flex justify-content-between text-center align-items-center pb-4 ">
        <ul className="nav nav-pills d-inline-flex">
          {tabs.map((item) => (
            <li className="nav-item" key={item.title}>
              <NavLink to={item.path} className="nav-link text-decoration-none">
                {item.title}
              </NavLink>
            </li>
          ))}
        </ul>
        <div>
          <input
            className="form-control"
            type="text"
            placeholder={intl.formatMessage({
              defaultMessage: 'Поиск по номеру заказа/клиента',
              id: 'input.placeholder.searchByOrder/CustomerNumber',
              description: 'Поле ввода',
            })}
            onChange={(event) => setInternalId(event.currentTarget.value)}
          />
        </div>
        <div className="d-flex">
          <Toggle checked={linked} onChange={() => setLinked(!linked)} />
          <div className="ms-2">
            <FormattedMessage
              defaultMessage="Мои клиенты"
              id="switchButton.label.myCustomers"
              description="Надпись на переключателе"
            />
          </div>
        </div>
      </div>
      <Outlet context={context} />
    </>
  );
};
