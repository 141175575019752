import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Header } from '../../common/header.component';
import {
  ClientExpansesBasedCommissionsForm,
  Form,
} from './client-expanses-based-commissions-form';
import useSWR from 'swr/immutable';
import { api, fetcher } from '../../../services/api';
import { AlertService } from '../../../services/alert.service';

export const ClientExpansesBasedCommissionsEditScreen: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data, isLoading, mutate } = useSWR<{ item: Form }>(
    `/client-expenses-purchase-markups/admin/${id}`,
    fetcher,
  );

  const handleSubmit = React.useCallback(
    async (data: Form) => {
      await api.put(`/client-expenses-purchase-markups/admin/${id}`, data);
      await mutate();
      AlertService.success('Комиссия на доставку обновлена');
      navigate(-1);
    },
    [id, mutate, navigate],
  );

  return (
    <>
      <Header />
      <div className="container" style={{ marginTop: -70 }}>
        <h1 className="mb-4">Редактирование комиссии за выкуп</h1>
        {isLoading && <span className="spinner-border spinner-border-sm" />}
        {data && (
          <ClientExpansesBasedCommissionsForm
            onSubmit={handleSubmit}
            defaultValues={data.item}
          />
        )}
      </div>
    </>
  );
};
