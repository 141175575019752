import React from 'react';
import Table, { CellProps } from 'rsuite/Table';
import Whisper from 'rsuite/Whisper';
import IconButton from 'rsuite/IconButton';
import { WhisperSpeaker } from '../../../utils/whisper-speaker';
import Popover from 'rsuite/Popover';
import Dropdown from 'rsuite/Dropdown';
import MyLoader from '../../common/loader.component';
import { RouterOutput } from '../../../api/trpc';
import { useNavigate } from 'react-router-dom';
import { PRODUCT_CATEGORIES } from './ProductData';
import { useBrandsList } from './useBrandsList';
import { useProcessedBrands } from './useProcessedBrands';

export type Item = RouterOutput['brandAdmin']['list']['items'][number];

const formatOriginalSize = (originalSize: string) => {
  const match = originalSize.match(/([A-Z]+)?([\d.,]+(?:[\.,]\d+)?)/);
  if (match) {
    const prefix = match[1] ? `${match[1]} ` : '';
    if (match[1]) {
      return `${prefix}${match[2]}`;
    } else {
      return originalSize;
    }
  }
  return originalSize;
};

export const BrandsTab = () => {
  const { data: brandsList, isLoading, error } = useBrandsList();
  const navigate = useNavigate();

  const processedData = useProcessedBrands(brandsList);

  const renderMenu =
    (item: Item): WhisperSpeaker =>
    ({ onClose, left, top, className }, ref) => {
      const handleSelect = (eventKey: number | string | undefined) => {
        onClose();
        if (eventKey === 'edit') {
          navigate(`/brands/brand`, {
            state: {
              categoryId: item.category.id,
            },
          });
        }
        if (eventKey === 'new') {
          navigate(`/brands/brand`, {
            state: {
              categoryId: item.category.id,
              brandId: item.id,
            },
          });
        }
      };

      return (
        <Popover ref={ref} className={className} style={{ left, top }} full>
          <Dropdown.Menu onSelect={handleSelect}>
            <Dropdown.Item eventKey={'edit'}>
              <i className="bi bi-pencil" />
              <span className="ps-2">Изменить</span>
            </Dropdown.Item>
            <Dropdown.Item eventKey={'new'}>
              <i className="bi bi-pencil" />
              <span className="ps-2">Добавить новую категорию бренда</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Popover>
      );
    };

  const ActionCell = ({ rowData, dataKey, ...props }: CellProps<Item>) => {
    return (
      rowData && (
        <Table.Cell
          {...props}
          className="link-group no-padding-cell"
          align="right"
        >
          <Whisper
            placement="leftStart"
            trigger="click"
            speaker={renderMenu(rowData)}
          >
            <IconButton
              appearance="subtle"
              icon={<i className="bi bi-three-dots" />}
            />
          </Whisper>
        </Table.Cell>
      )
    );
  };

  const CategoryCell = ({ rowData, dataKey, ...props }: CellProps<Item>) => {
    const categoryLabel =
      rowData &&
      PRODUCT_CATEGORIES.find(
        (category) => category.value === rowData.category.name,
      )?.label;

    return <Table.Cell {...props}>{categoryLabel}</Table.Cell>;
  };

  const OriginalSizesCell = ({
    rowData,
    dataKey,
    ...props
  }: CellProps<Item>) => {
    if (!rowData || !rowData.category.brandSize) {
      return null;
    }
    const originalSizes = rowData.category.brandSize
      .map((size) => formatOriginalSize(size.originalSize))
      .join(', ');

    return <Table.Cell {...props}>{originalSizes}</Table.Cell>;
  };

  if (isLoading) {
    return (
      <>
        <div className="pb-4">
          <MyLoader />
        </div>
      </>
    );
  }

  if (error || !brandsList) {
    return (
      <>
        <div className="pb-4">Неизвестная ошибка</div>
      </>
    );
  }

  return (
    <div>
      <Table
        loading={isLoading}
        data={processedData}
        headerHeight={57}
        rowHeight={74}
        autoHeight
        rowKey="id"
      >
        <Table.Column width={50} verticalAlign="middle">
          <Table.HeaderCell>Id</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => <>{rowData.rowSpanId > 0 ? rowData.id : ''}</>}
          </Table.Cell>
        </Table.Column>

        <Table.Column width={400} verticalAlign="middle" fullText>
          <Table.HeaderCell>Название бренда</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => (
              <>{rowData.rowSpanId > 0 ? rowData.rowSpanName : ''}</>
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={250} verticalAlign="middle" fullText>
          <Table.HeaderCell>Категории товаров</Table.HeaderCell>
          <CategoryCell />
        </Table.Column>
        <Table.Column width={250} verticalAlign="middle" fullText>
          <Table.HeaderCell>Оригинальные размеры</Table.HeaderCell>
          <OriginalSizesCell />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell> </Table.HeaderCell>
          <ActionCell />
        </Table.Column>
      </Table>
    </div>
  );
};
