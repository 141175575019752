import { api, fetcher } from './api';
import useSWR from 'swr';
import {
  CleanCurrency2,
  CleanCurrencyCurrentV2,
} from '../export-types/cleaned-types';

export type CreateCurrencyV2 = {
  cnyRubExternal: string;
  cnyRubInternal: string;
  cnyRubPartner: string;

  usdRubExternal: string;
  usdRubInternal: string;
  usdRubPartner: string;

  usdCny: string;
  cnyUsd: string;
};

export class CurrencyService {
  static createCurrency(currency: CreateCurrencyV2) {
    return api.post('/currency/v3', currency).then(({ data }) => {
      return data;
    });
  }
}

export function useCurrency(
  take?: number,
  skip?: number,
  types?: Array<CreateCurrencyV2>,
  start?: number,
  end?: number,
) {
  let url = `/currency/v3`;
  if (start) {
    url += '/byDate';
    skip = 0;
  }
  return useSWR<{ total: number; items: Array<CleanCurrency2> }>(
    {
      url: url,
      params: {
        take,
        skip,
        types,
        start,
        end,
      },
    },
    fetcher,
  );
}

export function useCurrencyCurrent() {
  return useSWR<CleanCurrencyCurrentV2>('/currency/current/v2', fetcher);
}
