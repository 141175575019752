import React, { useEffect, useState } from 'react';
import {
  Direction,
  TransactionService,
  useTransactionsList,
} from '../../../services/transaction.service';
import Table, { CellProps } from 'rsuite/Table';
import { TableDateCell } from '../../common/table/table-date-cell.component';
import { CURRENCIES, WALLETS } from '../../../consts/db-values';
import { TableMoneyCallComponent } from '../../common/table/table-money-call.component';
import { AlertService } from '../../../services/alert.service';
import { UserInternalIdCell } from '../../common/table/user-internal-id-cell.component';
import IconButton from 'rsuite/IconButton';
import Pagination from 'rsuite/Pagination';
import {
  CleanTransaction,
  RbacObject,
} from '../../../export-types/cleaned-types';
import { Rbac, useRbac } from '../../common/Rbac';
import { useDebouncedValue } from '@mantine/hooks';
import { Controller, useForm } from 'react-hook-form';
import { usePaginationQs } from '../../../utils/usePagination';
import { SupplierCell } from '../../common/table/supplier-cell';
import { Checkbox, DateRangePicker, Drawer } from 'rsuite';
import classNames from 'classnames';
import { FinanceExportModal } from './finance-export-modal';
import { ModalsService } from '../../../services/modals.service';
import { Select } from '../../common/select.component';
import { InputWrapper } from '../../common/input-wrapper';
import { useSuppliers } from '../../../api/suppliers';
import { getFileV2ApiUrl } from '../../../utils/common.utils';
import PdfIcon from '../../../assets/pdf-file.svg';
import { Gallery } from '../../common/gallery/gallery';
import { FormattedMessage, useIntl } from 'react-intl';
import { required } from '../../../utils/validations';
import { trpcSwr } from '../../../api/trpc';
import { SavedFiltersDrawer } from './saved-filters-drawer';
import moment from 'moment';
import { useIsSupplier } from '../../../api/use-is-supplier';

export type FieldsForm = {
  userIdInternal: string;
  fields: (typeof fields)[number]['value'][];
};

type SaveFilterForm = {
  name: string;
};

export type FilterForm = {
  filters: {
    type: 'writeOff' | 'refill' | 'all';
    dates?: [Date, Date];
    userIdInternal?: string;
    txid?: number;
    totalLimit: {
      from: string;
      to: string;
    };
    adminComment?: number;
    clientComment?: number;
    purchaseConfirmed?: number;
    refillLimit: {
      from: string;
      to: string;
    };
    writeoffLimit: {
      from: string;
      to: string;
    };
    supplierId?: string;
    userCurrency?: ('usd' | 'cny')[];
    journal?: CleanTransaction['journal'] | 'none';
    isProofFile?: number;
    currencyRateExternal: {
      from: string;
      to: string;
    };
    currencyRateInternal: {
      from: string;
      to: string;
    };
    cardNumber: string;
    walletType: string[];
  };
  enabledFilters: (typeof filters)[number]['value'][];
};

const journals = {
  additionalPaymentForDeliveryOrder:
    'Списание дополнительное за Выкуп (Доставка)',
  additionalPaymentForPurchaseOrder: 'Списание дополнительное за Выкуп (Выкуп)',
  additionalServiceCharge: 'Дополнительные услуги',
  additionalServiceChargeExpense: 'Затраты на дополнительные услуги',
  affiliateCommissionForDeliveryOrder: 'Комиссия партнёра за Доставку',
  affiliateCommissionForPurchaseOrder: 'Комиссия партнёра за Выкуп',
  convertCurrency: 'Конвертация валюты',
  depositByAlipay: 'Пополнение Alipay',
  depositByCard: 'Пополнение с карты',
  depositByCash: 'Перевод Поставщику',
  depositByCryptomus: 'Пополнение Cryptomus',
  depositByCryptocloud: 'Пополнение Cryptocloud',
  depositByInvoice: 'Пополнение баланса',
  depositByPayKeeperCard: 'Пополнение Paykeeper',
  depositByPayKeeperSbp: 'Пополнение Paykeeper СБП',
  depositByPayselection: 'Пополнение Payselection',
  depositByPromotion: 'Зачисление бонусов',
  depositBySbp: 'Пополнение СБП',
  depositByWechat: 'Пополнение Wechat',
  depositCompany: 'Перевод Поставщику',
  expensesForDeliveryOrder: 'Затраты на Доставку',
  expensesForPurchaseOrder: 'Затраты на Выкуп',
  paymentForDeliveryOrder: 'Оплата Доставки',
  paymentForPurchaseOrder: 'Оплата Выкупа',
  refundAdditionalServiceCharge: 'Возврат дополнительных расходов',
  refundAdditionalServiceChargeExpense: 'Возврат дополнительных расходов',
  refundForDeliveryOrder: 'Возврат оплаты клиенту за Доставку',
  refundForPurchaseOrder: 'Возврат оплаты клиенту за Выкуп',
  supplierExpense: 'Списание средств поставщиком',
  withdraw: 'Вывод',
};

// Список полей, который можно отобразить
const fields = [
  { label: 'Номер операции', value: 'number' },
  { label: 'Тип операции', value: 'operationType' },
  { label: 'Дата', value: 'date' },
  { label: 'Поставщик', value: 'supplier' },
  { label: 'ID клиента', value: 'clientID' },
  { label: 'Валюта', value: 'invoice' },
  { label: 'Внутренний Курс', value: 'rate' },
  { label: 'Сумма', value: 'amount' },
  { label: 'Итого', value: 'total' },
  { label: 'Комментарий для администратора', value: 'adminComment' },
  { label: 'Статья транзакций', value: 'journal' },
  { label: 'Статус заказа', value: 'orderStatus' },
  { label: 'Внешний курс', value: 'externalRate' },
  { label: 'Способ пополнения / списания', value: 'walletType' },
  { label: 'Номер счёта / ID', value: 'cardNumber' },
  { label: 'Платёжка', value: 'payment' },
  { label: 'Комментарий для клиента', value: 'clientComment' },
];

export function usePurchaseOrderStatusLabel() {
  const intl = useIntl();

  return {
    waitingForPayment: intl.formatMessage({
      defaultMessage: 'Ожидает оплаты',
      id: 'actiosHistrory.lable.waitingForPayment',
      description: 'Статус заказа',
    }),
    inWork: intl.formatMessage({
      defaultMessage: 'Выкупить',
      id: 'actiosHistrory.lable.winWork',
      description: 'Статус заказа',
    }),
    onTheWayToStock: intl.formatMessage({
      defaultMessage: 'В пути',
      id: 'actiosHistrory.lable.onTheWayToStock',
      description: 'Статус заказа',
    }),
    receivedInStock: intl.formatMessage({
      defaultMessage: 'На складе',
      id: 'actiosHistrory.lable.receivedInStock',
      description: 'Статус заказа',
    }),
    receivedInRetailStock: intl.formatMessage({
      defaultMessage: 'На складе (Розница)',
      id: 'orders.tab-label.receivedInRetailStock',
      description: 'Значение Пункта/Надпись на вкладке',
    }),
    sentToRussia: intl.formatMessage({
      defaultMessage: 'В пути на склад РФ',
      id: 'actiosHistrory.lable.sentToRussia',
      description: 'Статус заказа',
    }),
    canceled: intl.formatMessage({
      defaultMessage: 'Отменен',
      id: 'order.status-label.canceled',
      description: 'Статус заказа',
    }),
    frozen: intl.formatMessage({
      defaultMessage: 'Заморожен',
      id: 'orders.tab-label.frozen',
      description: 'Надпись на вкладке',
    }),
  };
}

export function useDeliveryOrderStatusLabel() {
  const intl = useIntl();

  return {
    processing: intl.formatMessage({
      defaultMessage: 'В процессе',
      id: 'TransactiosHistrory.lable.processing',
      description: 'Статус заказа',
    }),
    assembly: intl.formatMessage({
      defaultMessage: 'На комплектации',
      id: 'delivery.status-label.onPicking',
      description: 'Статус доставки',
    }),
    assembled: intl.formatMessage({
      defaultMessage: 'Укомплектован',
      id: 'TransactiosHistrory.lable.assembled',
      description: 'Надпись на вкладке',
    }),
    sentToRussia: intl.formatMessage({
      defaultMessage: 'На пути на склад РФ',
      id: 'TransactiosHistrory.lable.sentToRussia',
      description: 'Надпись на вкладке',
    }),
    receivedInRussia: intl.formatMessage({
      defaultMessage: 'На складе РФ',
      id: 'TransactiosHistrory.lable.receivedInRussia',
      description: 'Надпись на вкладке',
    }),
    receivedInRetailRussia: intl.formatMessage({
      defaultMessage: 'На складе в РФ (Розница)',
      id: 'TransactiosHistrory.lable.receivedInRetailRussia',
      description: 'Надпись на кнопке/вкладке',
    }),
    sentToRecipient: intl.formatMessage({
      defaultMessage: 'Переданые в ТК',
      id: 'deliveryOrders.tab-label.transferredToTheShippingCompany',
      description: 'Надпись на вкладке',
    }),
    delivered: intl.formatMessage({
      defaultMessage: 'Полученные',
      id: 'deliveryOrders.tab-label.received',
      description: 'Надпись на вкладке',
    }),
  };
}

// Фильтры, которые можно отобразить и скрыть
const filters = [
  { label: 'Номер операции', value: 'number' },
  { label: 'Поставщик', value: 'supplier' },
  { label: 'Способ пополнения / списания', value: 'walletType' },
  { label: 'Статья транзакций', value: 'journal' },
  { label: 'Платёжка', value: 'proofFile' },
  { label: 'Валюта пользователя', value: 'userCurrency' },
  { label: 'Итого', value: 'total' },
  { label: 'Номер счёта / ID', value: 'cardNumber' },
  { label: 'Сумма пополнения', value: 'refill' },
  { label: 'Сумма списания', value: 'writeoff' },
  { label: 'Внутренний курс', value: 'currencyRateInternal' },
  { label: 'Внешний курс', value: 'currencyRateExternal' },
  { label: 'Статус заказа', value: 'orderStatus' },
  { label: 'Комментарий для администратора', value: 'adminComment' },
  { label: 'Комментарий для клиента', value: 'clientComment' },
];

const defaultItemsPerPage = 20;

export const TransactionsHistoryTable = () => {
  const intl = useIntl();

  const purchaseOrderStatusLabel = usePurchaseOrderStatusLabel();
  const deliveryOrderStatusLabel = useDeliveryOrderStatusLabel();

  const isSupplier = useIsSupplier();
  const { data: suppliers } = useSuppliers(isSupplier);
  const { take, skip, handleSetPage, handleChangeLimit, page } =
    usePaginationQs(defaultItemsPerPage);
  const [direction, setDirection] = React.useState<Direction>('desc');

  const { isLoading: isRbacLoading, hasPermission } = useRbac();
  const canReadExtendedData =
    !isRbacLoading && hasPermission(RbacObject.Transaction, 'read:admin');

  const [openFilters, setOpenFilters] = useState(false); // Открыть меню с фильтрами
  const [openFieldSettings, setOpenFieldSettings] = useState(false); // Отркыть меню для отображение отображаемых полей

  const { trigger: saveFilter } =
    trpcSwr.savedFilters.saveFilter.useSWRMutation();
  const { mutate: mutateSavedFilter } = trpcSwr.savedFilters.list.useSWR();

  const [openSavedFilters, setOpenSavedFilters] = useState(false);

  const {
    handleSubmit: handleSubmitFields,
    setValue: setValueFields,
    control: controlFields,
    watch: watchFields,
    register: registerFields,
  } = useForm<FieldsForm>({
    defaultValues: {
      userIdInternal: '',
      fields: [
        'number',
        'operationType',
        'date',
        'supplier',
        'clientID',
        'invoice',
        'rate',
        'total',
        'amount',
        'adminComment',
      ],
    },
  });

  const {
    register: registerFilters,
    setValue: setValueFilters,
    watch: watchFilters,
    control: controlFilters,
  } = useForm<FilterForm>({
    defaultValues: {
      filters: {
        userIdInternal: '',
        userCurrency: ['cny', 'usd'],
      },
      enabledFilters: [],
    },
  });

  const {
    handleSubmit: handleSubmitFilterForm,
    control: controlSaveFilter,
    getValues: getSaveFilterValues,
    reset: resetSaveFilter,
  } = useForm<SaveFilterForm>({
    defaultValues: {
      name: '',
    },
  });

  const [isInitialized, setIsInitialized] = useState(false);

  // Функция для сохранения данных в localStorage
  const saveToLocalStorage = (key: string, data: any) => {
    localStorage.setItem(key, JSON.stringify(data));
  };

  // Функция для загрузки данных из localStorage
  const loadFromLocalStorage = (key: string) => {
    const savedData = localStorage.getItem(key);
    return savedData ? JSON.parse(savedData) : null;
  };

  // Восстанавливаем данные формы из LocalStorage при загрузке компонента
  useEffect(() => {
    const storedFieldsForm = loadFromLocalStorage('fields-form-data');
    const storedFilterForm = loadFromLocalStorage('filters-form-data');

    if (storedFieldsForm) {
      setValueFields('userIdInternal', storedFieldsForm.userIdInternal);
      setValueFields('fields', []);
      storedFieldsForm.fields.forEach((field: string, index: number) => {
        setValueFields(`fields.${index}`, field);
      });
    }

    if (storedFilterForm) {
      setValueFilters('filters', storedFilterForm.filters);
      setValueFilters('enabledFilters', storedFilterForm.enabledFilters);
    }

    // Устанавливаем флаг, что формы инициализированы
    setIsInitialized(true);
  }, [setValueFields, setValueFilters]);

  // Следим за изменениями данных в формах и сохраняем их в LocalStorage, только после инициализации
  useEffect(() => {
    if (!isInitialized) return;

    const subscriptionFields = watchFields((value) => {
      saveToLocalStorage('fields-form-data', value);
    });
    const subscriptionFilters = watchFilters((value) => {
      saveToLocalStorage('filters-form-data', value);
    });

    return () => {
      subscriptionFields.unsubscribe();
      subscriptionFilters.unsubscribe();
    };
  }, [watchFields, watchFilters, isInitialized]);

  const selectedFields = watchFields('fields');
  const selectedFilters = watchFilters('enabledFilters');
  const [filtersData] = useDebouncedValue(watchFilters('filters'), 200);

  const [userInternalIdDebounced] = useDebouncedValue(
    watchFields('userIdInternal'),
    200,
  );

  const {
    data: transactions,
    isLoading,
    mutate,
  } = useTransactionsList({
    take,
    skip,
    direction,
    userInternalId: userInternalIdDebounced
      ? userInternalIdDebounced
      : filtersData.userIdInternal
        ? filtersData.userIdInternal
        : undefined,
    type: filtersData.type === 'all' ? undefined : filtersData.type,
    start: filtersData.dates ? filtersData.dates[0] : undefined,
    end: filtersData.dates ? filtersData.dates[1] : undefined,
    transactionId:
      filtersData.txid && selectedFilters.includes('number')
        ? Number(filtersData.txid)
        : undefined,
    totalFrom:
      filtersData.totalLimit &&
      filtersData.totalLimit.from &&
      selectedFilters.includes('total')
        ? Number(filtersData.totalLimit.from)
        : undefined,
    totalTo:
      filtersData.totalLimit &&
      filtersData.totalLimit.to &&
      selectedFilters.includes('total')
        ? Number(filtersData.totalLimit.to)
        : undefined,
    isAdminComment: (() => {
      if (!selectedFilters.includes('adminComment')) return undefined;
      if (filtersData.adminComment === 1) return true;
      if (filtersData.adminComment === 2) return false;
      return undefined;
    })(),
    isClientComment: (() => {
      if (!selectedFilters.includes('clientComment')) return undefined;
      if (filtersData.clientComment === 1) return true;
      if (filtersData.clientComment === 2) return false;
      return undefined;
    })(),
    status: (() => {
      if (!selectedFilters.includes('orderStatus'))
        return ['draft', 'processing', 'canceled', 'confirmed'];
      if (filtersData.purchaseConfirmed === 1) return ['confirmed'];
      if (filtersData.purchaseConfirmed === 2)
        return ['draft', 'processing', 'canceled'];
      return ['draft', 'processing', 'canceled', 'confirmed'];
    })(),
    invoice: selectedFilters.includes('userCurrency')
      ? filtersData.userCurrency
      : ['usd', 'cny'],
    writeOffAmountFrom:
      filtersData.writeoffLimit &&
      filtersData.writeoffLimit.from &&
      selectedFilters.includes('writeoff')
        ? Number(filtersData.writeoffLimit.from)
        : undefined,
    writeOffAmountTo:
      filtersData.writeoffLimit &&
      filtersData.writeoffLimit.to &&
      selectedFilters.includes('writeoff')
        ? Number(filtersData.writeoffLimit.to)
        : undefined,
    refillAmountFrom:
      filtersData.refillLimit &&
      filtersData.refillLimit.from &&
      selectedFilters.includes('refill')
        ? Number(filtersData.refillLimit.from)
        : undefined,
    refillAmountTo:
      filtersData.refillLimit &&
      filtersData.refillLimit.to &&
      selectedFilters.includes('refill')
        ? Number(filtersData.refillLimit.to)
        : undefined,
    supplierId:
      filtersData.supplierId &&
      filtersData.supplierId !== 'none' &&
      selectedFilters.includes('supplier')
        ? Number(filtersData.supplierId)
        : undefined,
    journal: (() => {
      if (filtersData.journal === 'none') return [];
      if (selectedFilters.includes('journal') && filtersData.journal)
        return [filtersData.journal];
      return filtersData.cardNumber
        ? [
            'depositByCard',
            'depositByWechat',
            'depositByAlipay',
            'depositByCash',
          ]
        : undefined;
    })(),
    isProofFile: (() => {
      if (!selectedFilters.includes('proofFile')) return undefined;
      if (filtersData.isProofFile === 1) return true;
      if (filtersData.isProofFile === 2) return false;
      return undefined;
    })(),
    currencyRateInternalFrom:
      filtersData.currencyRateInternal &&
      filtersData.currencyRateInternal.from &&
      selectedFilters.includes('currencyRateInternal')
        ? Number(filtersData.currencyRateInternal.from)
        : undefined,
    currencyRateInternalTo:
      filtersData.currencyRateInternal &&
      filtersData.currencyRateInternal.to &&
      selectedFilters.includes('currencyRateInternal')
        ? Number(filtersData.currencyRateInternal.to)
        : undefined,
    currencyRateExternalFrom:
      filtersData.currencyRateExternal &&
      filtersData.currencyRateExternal.from &&
      selectedFilters.includes('currencyRateExternal')
        ? Number(filtersData.currencyRateExternal.from)
        : undefined,
    currencyRateExternalTo:
      filtersData.currencyRateExternal &&
      filtersData.currencyRateExternal.to &&
      selectedFilters.includes('currencyRateExternal')
        ? Number(filtersData.currencyRateExternal.to)
        : undefined,
    cardNumber:
      selectedFilters.includes('cardNumber') && filtersData.cardNumber
        ? filtersData.cardNumber
        : undefined,
    walletType: selectedFilters.includes('walletType')
      ? filtersData.walletType
      : undefined,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [take]);

  useEffect(() => {
    if (
      transactions?.total &&
      Math.ceil((transactions?.total || 0) / take) < page
    )
      handleSetPage(Math.ceil((transactions?.total || 0) / take));
  }, [transactions?.total, take, page]);

  const CurrencyCell = ({
    rowData,
    dataKey,
    ...props
  }: CellProps<CleanTransaction>) => {
    return (
      rowData && (
        <Table.Cell {...props}>
          {CURRENCIES[rowData.currency as keyof typeof CURRENCIES]}
        </Table.Cell>
      )
    );
  };

  const MoneyCell = (props: CellProps<CleanTransaction>) => {
    return (
      props.rowData && (
        <TableMoneyCallComponent {...props} currency={props.rowData.currency} />
      )
    );
  };

  const CheckedCell = ({ rowData, ...props }: CellProps<CleanTransaction>) => {
    const checkTransaction = (
      event: React.MouseEvent<HTMLDivElement>,
      transaction: CleanTransaction,
    ) => {
      if (transaction.status !== 'confirmed') {
        const result = window.confirm(
          'Вы действительно хотите подтвердить платёж?',
        );
        if (!result) {
          event.preventDefault();
          return;
        }

        TransactionService.checkTransaction(transaction.id).then(() => {
          AlertService.success();
          mutate(); // Обновление данных
        });
      }
    };

    return (
      rowData && (
        <Rbac
          object={RbacObject.Transaction}
          action={'write:admin'}
          children={
            <Table.Cell {...props} align="center">
              <div
                className="form-check text-center d-inline-block"
                onClick={(event) => checkTransaction(event, rowData)}
                title={
                  rowData.status === 'confirmed'
                    ? 'Платёж подтверждён'
                    : 'Подтвердить платёж'
                }
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  disabled={rowData.status === 'confirmed'}
                  checked={rowData.status === 'confirmed'}
                  onChange={() => {}}
                />
              </div>
            </Table.Cell>
          }
          otherwise={() => (
            <Table.Cell {...props} align="center">
              <div
                className="form-check text-center d-inline-block"
                title={
                  rowData.status === 'confirmed'
                    ? 'Платёж подтверждён'
                    : 'Платёж не подтверждён'
                }
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  disabled
                  defaultChecked={rowData.status === 'confirmed'} // Используем defaultChecked для disabled инпутов
                />
              </div>
            </Table.Cell>
          )}
        />
      )
    );
  };

  const CancelCell = ({ rowData, ...props }: CellProps<CleanTransaction>) => {
    const handleCancel = (event: React.MouseEvent) => {
      const result = window.confirm('Вы действительно хотите отменить платёж?');
      if (!result) {
        event.preventDefault();
        return;
      }

      TransactionService.cancelTransaction(rowData!.id).then(() => {
        AlertService.success();
        mutate();
      });
    };

    return (
      <Rbac object={RbacObject.Transaction} action={'write:admin'}>
        <Table.Cell
          {...props}
          className="link-group no-padding-cell pt-2"
          align="right"
        >
          {rowData!.status !== 'canceled' &&
            rowData!.status !== 'confirmed' && (
              <IconButton
                appearance="subtle"
                icon={<i className="bi bi-x-circle" />}
                type="button"
                onClick={handleCancel}
                title={'Отменить платёж'}
              />
            )}
        </Table.Cell>
      </Rbac>
    );
  };

  const ReqTypeCell = ({
    rowData,
    dataKey,
    ...props
  }: CellProps<CleanTransaction>) => {
    if (!rowData) {
      return null;
    }

    let text =
      WALLETS[rowData.walletType as keyof typeof WALLETS] ||
      rowData.privateComment ||
      rowData.comment ||
      rowData.walletType;

    if (
      rowData.walletType === 'cryptomus' &&
      rowData.metadata?.cryptoCreatedFromCash
    ) {
      text = 'Криптовалюта (из наличных)';
    }

    return <Table.Cell {...props}>{text}</Table.Cell>;
  };

  const total = transactions?.total ?? 0;
  const items = transactions?.items ?? [];

  async function handleFinanceExport() {
    return ModalsService.createModal(FinanceExportModal, {});
  }

  return (
    <div>
      <Drawer
        backdrop="static"
        open={openFieldSettings}
        onClose={() => setOpenFieldSettings(false)}
      >
        <Drawer.Header>
          <Drawer.Title>
            <FormattedMessage
              defaultMessage="Поля для отображения"
              id="TransactiosHistrory.title.FieldsToDisplay"
              description="Заголовок фильтра"
            />
          </Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <form
            className="h-100 d-flex flex-column justify-content-between"
            onSubmit={handleSubmitFields(() => setOpenFieldSettings(false))}
          >
            <div className="row">
              {fields.map((field) => (
                <div key={field.value} className="col-6">
                  <Controller
                    name="fields"
                    control={controlFields}
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        value={field.value}
                        checked={value?.includes(field.value)}
                        onChange={(checked) => {
                          const newValue = value.includes(checked as string)
                            ? value.filter((v) => v !== field.value)
                            : [...value, field.value];
                          onChange(newValue);
                        }}
                      >
                        {field.label}
                      </Checkbox>
                    )}
                  />
                </div>
              ))}
            </div>
            <button type="submit" className="btn btn-success w-100">
              <FormattedMessage
                defaultMessage="Готово"
                id="TransactiosHistrory.btn.Ready"
                description="Кнопка"
              />
            </button>
          </form>
        </Drawer.Body>
      </Drawer>
      <Drawer
        backdrop="static"
        open={openFilters}
        onClose={() => setOpenFilters(false)}
      >
        <Drawer.Header>
          <Drawer.Title>Фильтр истории операций</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body className="d-flex flex-column justify-content-between">
          <div>
            {/* Сами Фильтры */}
            <div className="d-flex flex-column gap-4">
              <form
                onSubmit={handleSubmitFilterForm(async () => {
                  try {
                    const parameters = {
                      enabledFilters: selectedFilters,
                      fields: selectedFields,
                      filters: filtersData,
                      ...(userInternalIdDebounced
                        ? { userIdInternal: userInternalIdDebounced }
                        : null),
                    };
                    const data = getSaveFilterValues();
                    await saveFilter({ parameters, name: data.name });
                    setOpenFilters(false);
                    AlertService.success('Фильтр сохранен');
                    resetSaveFilter();
                    mutateSavedFilter();
                  } catch (error) {
                    AlertService.error(error as string);
                  }
                })}
              >
                <div className="d-flex">
                  <Controller
                    name="name"
                    control={controlSaveFilter}
                    rules={{ ...required }}
                    render={({ field, fieldState }) => (
                      <InputWrapper
                        title="Название"
                        className="w-100 "
                        error={fieldState.error?.message}
                      >
                        <div className="input-group col-4">
                          <input
                            className="form-control rounded-8-i"
                            type="text"
                            defaultValue={field.value}
                            onChange={field.onChange}
                          />
                          <button
                            type="submit"
                            className="btn btn-success mx-1 rounded-8 h-50 align-self-end rounded-8-i"
                          >
                            Сохранить
                          </button>
                        </div>
                      </InputWrapper>
                    )}
                  />
                </div>
              </form>
              {/* Фильтр на тип операции */}
              <Controller
                name="filters.type"
                control={controlFilters}
                render={({ field }) => (
                  <Select
                    title={intl.formatMessage({
                      defaultMessage: 'Тип',
                      id: 'TransactiosHistrory.lable.Type',
                      description: 'Вкладка',
                    })}
                    placeholder={intl.formatMessage({
                      defaultMessage: 'Тип',
                      id: 'TransactiosHistrory.lable.Type',
                      description: 'Вкладка',
                    })}
                    options={[
                      {
                        value: 'all',
                        label: intl.formatMessage({
                          defaultMessage: 'Все',
                          id: 'TransactiosHistrory.lable.All',
                          description: 'Выпадающий список',
                        }),
                      },
                      {
                        value: 'writeOff',
                        label: intl.formatMessage({
                          defaultMessage: 'Списание',
                          id: 'TransactiosHistrory.lable.WriteOff',
                          description: 'Вкладка',
                        }),
                      },
                      {
                        value: 'refill',
                        label: intl.formatMessage({
                          defaultMessage: 'Пополнение',
                          id: 'TransactiosHistrory.lable.Refill',
                          description: 'Вкладка',
                        }),
                      },
                    ]}
                    {...field}
                  />
                )}
              />

              {/* Фильтр на даты */}
              <div>
                <div className="mb-2">
                  <FormattedMessage
                    defaultMessage="Дата"
                    id="TransactiosHistrory.lable.date"
                    description="Вкладка"
                  />
                </div>
                <Controller
                  name="filters.dates"
                  control={controlFilters}
                  rules={{ ...required }}
                  render={({ field, fieldState }) => (
                    <InputWrapper
                      error={fieldState.error?.message}
                      className="p-0"
                    >
                      <DateRangePicker
                        onClean={() => field.onChange([])}
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Дата',
                          id: 'TransactiosHistrory.lable.date',
                          description: 'Вкладка',
                        })}
                        format={'dd.MM.yyyy'}
                        value={filtersData.dates}
                        isoWeek={true}
                        showOneCalendar
                        ranges={[]}
                        className="w-100"
                        onChange={(v) => {
                          if (v === null) field.onChange([]);
                          else {
                            field.onChange(v);
                          }
                        }}
                      />
                    </InputWrapper>
                  )}
                />
              </div>

              {/* Поиск по ID клиента */}
              <div>
                <div className="mb-2">Поиск по ID клиента</div>
                <input
                  className="form-control w-100"
                  type="string"
                  min={0}
                  step={1}
                  placeholder="Поиск по ID клиента"
                  {...registerFilters('filters.userIdInternal')}
                />
              </div>

              {/* Способ пополнения / списания */}
              {selectedFilters.includes('walletType') && (
                <Controller
                  name="filters.walletType"
                  control={controlFilters}
                  render={({ field }) => (
                    <Select
                      title="Способ пополнения / списания"
                      placeholder="Без фильтра на способ пополнения / списания"
                      isMulti
                      options={[
                        ...Object.entries(WALLETS).map((w) => ({
                          value: w[0],
                          label: w[1],
                        })),
                      ]}
                      {...field}
                    />
                  )}
                />
              )}

              {/* Номер операции */}
              {selectedFilters.includes('number') && (
                <div>
                  <div className="mb-2">
                    <FormattedMessage
                      defaultMessage="Номер операции"
                      id="TransactiosHistrory.lable.TransactionNumber"
                      description="Вкладка"
                    />
                  </div>
                  <Controller
                    name="filters.txid"
                    control={controlFilters}
                    render={({ field }) => (
                      <input
                        className="form-control"
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Номер операции',
                          id: 'TransactiosHistrory.lable.TransactionNumber',
                          description: 'Вкладка',
                        })}
                        {...field}
                        value={field.value || ''}
                      />
                    )}
                  />
                </div>
              )}

              {/* Поставщик */}
              {selectedFilters.includes('supplier') && (
                <div>
                  <div className="mb-2">
                    <FormattedMessage
                      defaultMessage="Поставщик"
                      id="TransactiosHistrory.lable.supplier"
                      description="Вкладка"
                    />
                  </div>
                  <Controller
                    name="filters.supplierId"
                    control={controlFilters}
                    render={({ field }) => (
                      <Select
                        className="flex-grow-1"
                        placeholder="Выберите поставщика"
                        required
                        options={[
                          { value: 'none', label: 'Без поставщика' },
                          ...(suppliers?.map(({ id, name }) => ({
                            value: id,
                            label: name,
                          })) ?? []),
                        ]}
                        {...field}
                      />
                    )}
                  />
                </div>
              )}

              {/* Статья транзакций */}
              {selectedFilters.includes('journal') && (
                <div>
                  <div className="mb-2">Статья транзакций</div>
                  <Controller
                    name="filters.journal"
                    control={controlFilters}
                    render={({ field }) => (
                      <Select
                        className="flex-grow-1"
                        placeholder="Выберите статью транзакций"
                        required
                        options={[
                          { value: 'none', label: 'Без статьи транзакций' },
                          ...(Object.entries(journals).map((j) => ({
                            value: j[0],
                            label: j[1],
                          })) ?? []),
                        ]}
                        {...field}
                      />
                    )}
                  />
                </div>
              )}

              {/* Платёжка */}
              {selectedFilters.includes('proofFile') && (
                <div>
                  <div className="mb-2">Платёжка</div>
                  <Controller
                    name="filters.isProofFile"
                    control={controlFilters}
                    render={({ field }) => {
                      return (
                        <div className="d-flex gap-4 flex-row">
                          <div>
                            <input
                              id="purchase-confirmed-yes"
                              type="checkbox"
                              checked={field.value === 1}
                              onChange={() => {
                                field.onChange(field.value === 1 ? 0 : 1);
                              }}
                            />
                            <label
                              htmlFor="purchase-confirmed-yes"
                              className="ms-2"
                            >
                              Есть
                            </label>
                          </div>
                          <div>
                            <input
                              id="purchase-confirmed-no"
                              type="checkbox"
                              checked={field.value === 2}
                              onChange={() => {
                                field.onChange(field.value === 2 ? 0 : 2);
                              }}
                            />
                            <label
                              htmlFor="purchase-confirmed-no"
                              className="ms-2"
                            >
                              Нет
                            </label>
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>
              )}

              {/* Валюта пользователя */}
              {selectedFilters.includes('userCurrency') && (
                <div>
                  <div className="mb-2">Валюта пользователя</div>
                  <Controller
                    name="filters.userCurrency"
                    control={controlFilters}
                    render={({ field }) => {
                      return (
                        <div className="d-flex gap-4 flex-row">
                          <div>
                            <input
                              id="user-currency-cny"
                              type="checkbox"
                              checked={field.value?.includes('cny')}
                              onChange={() => {
                                const newValue = field.value?.includes('cny')
                                  ? field.value.filter((v) => v !== 'cny')
                                  : [...(field.value || []), 'cny'];
                                field.onChange(newValue);
                              }}
                            />
                            <label htmlFor="user-currency-cny" className="ms-2">
                              ¥ (CYN)
                            </label>
                          </div>
                          <div>
                            <input
                              id="user-currency-usd"
                              type="checkbox"
                              checked={field.value?.includes('usd')}
                              onChange={() => {
                                const newValue = field.value?.includes('usd')
                                  ? field.value.filter((v) => v !== 'usd')
                                  : [...(field.value || []), 'usd'];
                                field.onChange(newValue);
                              }}
                            />
                            <label htmlFor="user-currency-usd" className="ms-2">
                              $ (USD)
                            </label>
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>
              )}

              {/* Итого */}
              {selectedFilters.includes('total') && (
                <div>
                  <div className="mb-2">Итого</div>

                  <div className="d-flex gap-4 flex-row align-items-center">
                    <Controller
                      name="filters.totalLimit.from"
                      control={controlFilters}
                      render={({ field }) => {
                        return (
                          <input
                            className="form-control"
                            placeholder="От"
                            {...field}
                            onChange={(e) => {
                              e.preventDefault();
                              const value = e.target.value;
                              if (/^[0-9]*$/.test(value))
                                field.onChange(
                                  value.charAt(0) === '0' && value.length > 1
                                    ? value.substring(1)
                                    : value,
                                );
                            }}
                            value={field.value || ''}
                          />
                        );
                      }}
                    />
                    <div>—</div>
                    <Controller
                      name="filters.totalLimit.to"
                      control={controlFilters}
                      render={({ field }) => {
                        return (
                          <input
                            className="form-control"
                            placeholder="До"
                            {...field}
                            onChange={(e) => {
                              e.preventDefault();
                              const value = e.target.value;
                              if (/^[0-9]*$/.test(value))
                                field.onChange(
                                  value.charAt(0) === '0' && value.length > 1
                                    ? value.substring(1)
                                    : value,
                                );
                            }}
                            value={field.value || ''}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              )}

              {/* Номер счёта / ID */}
              {selectedFilters.includes('cardNumber') && (
                <div>
                  <div className="mb-2">Номер счёта / ID</div>
                  <Controller
                    name="filters.cardNumber"
                    control={controlFilters}
                    render={({ field }) => (
                      <input
                        className="form-control"
                        placeholder="Номер счёта / ID"
                        {...field}
                        value={field.value || ''}
                      />
                    )}
                  />
                </div>
              )}

              {/* Сумма пополнения */}
              {selectedFilters.includes('refill') && (
                <div>
                  <div className="mb-2">Сумма пополнения</div>
                  <div className="d-flex gap-4 flex-row align-items-center">
                    <Controller
                      name="filters.refillLimit.from"
                      control={controlFilters}
                      render={({ field }) => {
                        return (
                          <input
                            className="form-control"
                            placeholder="От"
                            {...field}
                            value={field.value || ''}
                            onChange={(e) => {
                              e.preventDefault();
                              const value = e.target.value;
                              if (/^[0-9]*$/.test(value))
                                field.onChange(
                                  value.charAt(0) === '0' && value.length > 1
                                    ? value.substring(1)
                                    : value,
                                );
                            }}
                          />
                        );
                      }}
                    />
                    <div>—</div>
                    <Controller
                      name="filters.refillLimit.to"
                      control={controlFilters}
                      render={({ field }) => {
                        return (
                          <input
                            className="form-control"
                            placeholder="До"
                            {...field}
                            value={field.value || ''}
                            onChange={(e) => {
                              e.preventDefault();
                              const value = e.target.value;
                              if (/^[0-9]*$/.test(value))
                                field.onChange(
                                  value.charAt(0) === '0' && value.length > 1
                                    ? value.substring(1)
                                    : value,
                                );
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              )}

              {/* Cумма списания */}
              {selectedFilters.includes('writeoff') && (
                <div>
                  <div className="mb-2">Сумма списания</div>

                  <div className="d-flex gap-4 flex-row align-items-center">
                    <Controller
                      name="filters.writeoffLimit.from"
                      control={controlFilters}
                      render={({ field }) => {
                        return (
                          <input
                            className="form-control"
                            placeholder="От"
                            {...field}
                            value={field.value || ''}
                            onChange={(e) => {
                              e.preventDefault();
                              const value = e.target.value;
                              if (/^[0-9]*$/.test(value))
                                field.onChange(
                                  value.charAt(0) === '0' && value.length > 1
                                    ? value.substring(1)
                                    : value,
                                );
                            }}
                          />
                        );
                      }}
                    />
                    <div>—</div>
                    <Controller
                      name="filters.writeoffLimit.to"
                      control={controlFilters}
                      render={({ field }) => {
                        return (
                          <input
                            className="form-control"
                            placeholder="До"
                            {...field}
                            value={field.value || ''}
                            onChange={(e) => {
                              e.preventDefault();
                              const value = e.target.value;
                              if (/^[0-9]*$/.test(value))
                                field.onChange(
                                  value.charAt(0) === '0' && value.length > 1
                                    ? value.substring(1)
                                    : value,
                                );
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              )}

              {/* Внутренний курс */}
              {selectedFilters.includes('currencyRateInternal') && (
                <div>
                  <div className="mb-2">Внутренний курс</div>

                  <div className="d-flex gap-4 flex-row align-items-center">
                    <Controller
                      name="filters.currencyRateInternal.from"
                      control={controlFilters}
                      render={({ field }) => {
                        return (
                          <input
                            className="form-control"
                            placeholder="От"
                            {...field}
                            onChange={(e) => {
                              e.preventDefault();
                              const value = e.target.value;
                              if (/^[0-9]*$/.test(value))
                                field.onChange(
                                  value.charAt(0) === '0' && value.length > 1
                                    ? value.substring(1)
                                    : value,
                                );
                            }}
                            value={field.value || ''}
                          />
                        );
                      }}
                    />
                    <div>—</div>
                    <Controller
                      name="filters.currencyRateInternal.to"
                      control={controlFilters}
                      render={({ field }) => {
                        return (
                          <input
                            className="form-control"
                            placeholder="До"
                            {...field}
                            onChange={(e) => {
                              e.preventDefault();
                              const value = e.target.value;
                              if (/^[0-9]*$/.test(value))
                                field.onChange(
                                  value.charAt(0) === '0' && value.length > 1
                                    ? value.substring(1)
                                    : value,
                                );
                            }}
                            value={field.value || ''}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              )}

              {/* Внешний курс */}
              {selectedFilters.includes('currencyRateExternal') && (
                <div>
                  <div className="mb-2">Внешний курс</div>

                  <div className="d-flex gap-4 flex-row align-items-center">
                    <Controller
                      name="filters.currencyRateExternal.from"
                      control={controlFilters}
                      render={({ field }) => {
                        return (
                          <input
                            className="form-control"
                            placeholder="От"
                            {...field}
                            value={field.value || ''}
                            onChange={(e) => {
                              e.preventDefault();
                              const value = e.target.value;
                              if (/^[0-9]*$/.test(value))
                                field.onChange(
                                  value.charAt(0) === '0' && value.length > 1
                                    ? value.substring(1)
                                    : value,
                                );
                            }}
                          />
                        );
                      }}
                    />
                    <div>—</div>
                    <Controller
                      name="filters.currencyRateExternal.to"
                      control={controlFilters}
                      render={({ field }) => {
                        return (
                          <input
                            className="form-control"
                            placeholder="До"
                            {...field}
                            value={field.value || ''}
                            onChange={(e) => {
                              e.preventDefault();
                              const value = e.target.value;
                              if (/^[0-9]*$/.test(value))
                                field.onChange(
                                  value.charAt(0) === '0' && value.length > 1
                                    ? value.substring(1)
                                    : value,
                                );
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              )}

              {/* Статус заказа */}
              {selectedFilters.includes('orderStatus') && (
                <div>
                  <div className="mb-2">
                    <FormattedMessage
                      defaultMessage="Статус заказа"
                      id="purchaseOrderPage.label.orderStatus"
                      description="Заголовок на странице"
                    />
                  </div>
                  <Controller
                    name="filters.purchaseConfirmed"
                    control={controlFilters}
                    render={({ field }) => {
                      return (
                        <div className="d-flex gap-4 flex-row">
                          <div>
                            <input
                              id="purchase-confirmed-yes"
                              type="checkbox"
                              checked={field.value === 1}
                              onChange={() => {
                                field.onChange(field.value === 1 ? 0 : 1);
                              }}
                            />
                            <label
                              htmlFor="purchase-confirmed-yes"
                              className="ms-2"
                            >
                              Подтвержден
                            </label>
                          </div>
                          <div>
                            <input
                              id="purchase-confirmed-no"
                              type="checkbox"
                              checked={field.value === 2}
                              onChange={() => {
                                field.onChange(field.value === 2 ? 0 : 2);
                              }}
                            />
                            <label
                              htmlFor="purchase-confirmed-no"
                              className="ms-2"
                            >
                              Не подтвержден
                            </label>
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>
              )}

              {/* Комментарии для администратора */}
              {selectedFilters.includes('adminComment') && (
                <div>
                  <div className="mb-2">Комментарий для администратора</div>
                  <Controller
                    name="filters.adminComment"
                    control={controlFilters}
                    render={({ field }) => {
                      return (
                        <div className="d-flex gap-4 flex-row">
                          <div>
                            <input
                              id="admin-comment-yes"
                              type="checkbox"
                              checked={field.value === 1}
                              onChange={() => {
                                field.onChange(field.value === 1 ? 0 : 1);
                              }}
                            />
                            <label htmlFor="admin-comment-yes" className="ms-2">
                              Есть
                            </label>
                          </div>
                          <div>
                            <input
                              id="admin-comment-no"
                              type="checkbox"
                              checked={field.value === 2}
                              onChange={() => {
                                field.onChange(field.value === 2 ? 0 : 2);
                              }}
                            />
                            <label htmlFor="admin-comment-no" className="ms-2">
                              Нет
                            </label>
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>
              )}

              {/* Комментарии для клиента */}
              {selectedFilters.includes('clientComment') && (
                <div>
                  <div className="mb-2">Комментарий для клиента</div>
                  <Controller
                    name="filters.clientComment"
                    control={controlFilters}
                    render={({ field }) => {
                      return (
                        <div className="d-flex gap-4 flex-row">
                          <div>
                            <input
                              id="client-comment-yes"
                              type="checkbox"
                              checked={field.value === 1}
                              onChange={() => {
                                field.onChange(field.value === 1 ? 0 : 1);
                              }}
                            />
                            <label
                              htmlFor="client-comment-yes"
                              className="ms-2"
                            >
                              Есть
                            </label>
                          </div>
                          <div>
                            <input
                              id="client-comment-no"
                              type="checkbox"
                              checked={field.value === 2}
                              onChange={() => {
                                field.onChange(field.value === 2 ? 0 : 2);
                              }}
                            />
                            <label htmlFor="client-comment-no" className="ms-2">
                              Нет
                            </label>
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>
              )}
            </div>

            {/* Галочки для включения / отключения фильтров */}
            <div className="row gap-3 w-100 ms-2 mt-4">
              <h3 className="p-0 text-secondary">Изменить фильтры</h3>
              <div className="row w-100">
                {filters.map((filter) => (
                  <Controller
                    key={filter.value}
                    name="enabledFilters"
                    control={controlFilters}
                    render={({ field: { onChange, value } }) => {
                      const isChecked = value.includes(filter.value);
                      return (
                        <div className="col-6 p-1">
                          <input
                            type="checkbox"
                            id={`filter-${filter.value}`} // Уникальный id для каждого чекбокса
                            className="d-none" // Скрываем чекбокс
                            checked={isChecked}
                            onChange={() => {
                              const newValue = isChecked
                                ? value.filter((v) => v !== filter.value)
                                : [...value, filter.value];
                              onChange(newValue);
                            }}
                          />
                          <label
                            htmlFor={`filter-${filter.value}`} // Связь label с checkbox
                            className="btn btn-light border text-start w-100 h-100 rounded p-1 d-flex align-items-center gap-2"
                          >
                            {isChecked ? (
                              <i className="bi bi-dash text-danger" />
                            ) : (
                              <i className="bi bi-plus text-success" />
                            )}{' '}
                            {filter.label}
                          </label>
                        </div>
                      );
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
          <button
            onClick={() => setOpenFilters(false)}
            className="btn btn-success mt-4"
          >
            Готово
          </button>
        </Drawer.Body>
      </Drawer>

      <SavedFiltersDrawer
        open={openSavedFilters}
        onClose={() => setOpenSavedFilters(false)}
        onSelect={(parameters) => {
          setValueFields('userIdInternal', parameters?.userIdInternal);
          setValueFields('fields', []);
          parameters.fields.forEach((field: string, index: number) => {
            setValueFields(`fields.${index}`, field);
          });
          const dates = parameters?.filters.dates;
          let formattedDates;
          if (dates?.length) {
            formattedDates = [...dates?.map((date) => moment(date).toDate())];
          }
          setValueFilters('filters', {
            ...parameters?.filters,
            dates: (formattedDates as [Date, Date]) || undefined,
          });
          setValueFilters('enabledFilters', parameters?.enabledFilters);
        }}
      />
      <div className="d-flex mb-4 ms-1 gap-2">
        <Rbac object={RbacObject.Transaction} action={'read:admin'}>
          <button
            className="btn btn-light border"
            onClick={() => setOpenFieldSettings(true)}
          >
            Отображаемые поля ({selectedFields.length}/{fields.length})
          </button>
          <button
            className="btn btn-light border"
            onClick={() => setOpenFilters(true)}
          >
            Фильтры ({selectedFilters.length}/{filters.length})
          </button>
          <button
            className="btn btn-light border"
            onClick={() => setOpenSavedFilters(true)}
          >
            Сохраненные фильтры
          </button>
        </Rbac>
        <input
          className="form-control w-25"
          type="string"
          min={0}
          step={1}
          placeholder={intl.formatMessage({
            defaultMessage: 'Поиск по ID клиента',
            id: 'TransactiosHistrory.placeholder.SearchByClientID',
            description: 'Поле ввода',
          })}
          {...registerFields('userIdInternal')}
        />

        <Rbac object={RbacObject.Transaction} action={'read:admin'}>
          <div
            className="btn btn-success ms-auto"
            onClick={handleFinanceExport}
          >
            <i className="bi bi-file-earmark-excel me-2" />
            Экспорт транзакций
          </div>
        </Rbac>
      </div>

      <Table data={items} headerHeight={57} autoHeight loading={isLoading}>
        {/* Tx Id */}
        {selectedFields.includes('number') && (
          <Table.Column fullText>
            <Table.HeaderCell>Tx Id</Table.HeaderCell>
            <Table.Cell dataKey="txid" />
          </Table.Column>
        )}
        {/* Тип */}
        {selectedFields.includes('operationType') && (
          <Table.Column fullText width={120}>
            <Table.HeaderCell>
              <FormattedMessage
                defaultMessage="Тип"
                id="TransactiosHistrory.lable.Type"
                description="Вкладка"
              />
            </Table.HeaderCell>
            <Table.Cell>
              {(rowData) => {
                return rowData?.type === 'refill' ? (
                  <span className="text-success">
                    <FormattedMessage
                      defaultMessage="Пополнение"
                      id="TransactiosHistrory.lable.Refill"
                      description="Вкладка"
                    />
                  </span>
                ) : (
                  <span className="text-danger">
                    <FormattedMessage
                      defaultMessage="Списание"
                      id="TransactiosHistrory.lable.WriteOff"
                      description="Вкладка"
                    />
                  </span>
                );
              }}
            </Table.Cell>
          </Table.Column>
        )}
        {/* Дата */}
        {selectedFields.includes('date') && (
          <Table.Column fullText>
            <Table.HeaderCell>
              <button
                className="d-flex gap-1 w-100 h-100 bg-transparent"
                onClick={() => {
                  setDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'));
                }}
              >
                <FormattedMessage
                  defaultMessage="Дата"
                  id="TransactiosHistrory.lable.date"
                  description="Вкладка"
                />{' '}
                <i
                  className={classNames(
                    'bi',
                    direction === 'asc' ? 'bi-sort-up' : 'bi-sort-down',
                  )}
                ></i>
              </button>
            </Table.HeaderCell>
            <TableDateCell dataKey="createdAt" />
          </Table.Column>
        )}
        {/* Поставщик */}
        {selectedFields.includes('supplier') && (
          <Table.Column width={120} fullText>
            <Table.HeaderCell>
              <FormattedMessage
                defaultMessage="Поставщик"
                id="TransactiosHistrory.lable.supplier"
                description="Вкладка"
              />
            </Table.HeaderCell>
            <SupplierCell />
          </Table.Column>
        )}
        {/* ID клиента */}
        {selectedFields.includes('clientID') && (
          <Table.Column width={100} fullText>
            <Table.HeaderCell>
              <FormattedMessage
                defaultMessage="ID клиента"
                id="TransactiosHistrory.lable.ID"
                description="Вкладка"
              />
            </Table.HeaderCell>
            <UserInternalIdCell />
          </Table.Column>
        )}
        {/* Счет */}
        {selectedFields.includes('invoice') && (
          <Table.Column width={60} fullText>
            <Table.HeaderCell>
              <FormattedMessage
                defaultMessage="Валюта"
                id="ReconciliationActModal.title.Сurrency"
                description="Пояснение к полю ввода"
              />
            </Table.HeaderCell>
            <CurrencyCell />
          </Table.Column>
        )}
        {/* Курс (условный доступ через canReadExtendedData) */}
        {canReadExtendedData && selectedFields.includes('rate') && (
          <Table.Column width={100} fullText>
            <Table.HeaderCell>Внутренний Курс</Table.HeaderCell>
            <TableMoneyCallComponent dataKey="currencyRateInternal" />
          </Table.Column>
        )}
        {/* Сумма */}
        {selectedFields.includes('amount') && (
          <Table.Column width={150} fullText>
            <Table.HeaderCell>
              <FormattedMessage
                defaultMessage="Сумма"
                id="TransactiosHistrory.lable.Sum"
                description="Вкладка"
              />
            </Table.HeaderCell>
            <MoneyCell dataKey="amount" />
          </Table.Column>
        )}
        {/* Итого */}
        {canReadExtendedData && selectedFields.includes('total') && (
          <Table.Column width={150} fullText>
            <Table.HeaderCell>Итого</Table.HeaderCell>
            <TableMoneyCallComponent
              dataKey="userAmount"
              currencyKey="topUpCurrency"
            />
          </Table.Column>
        )}
        {/* Комментарий для администратора */}
        {canReadExtendedData && selectedFields.includes('adminComment') && (
          <Table.Column flexGrow={1} fullText>
            <Table.HeaderCell>Комментарий для администратора</Table.HeaderCell>
            <Table.Cell dataKey="privateComment" />
          </Table.Column>
        )}
        {/* Статья транзакции из журнала */}
        {selectedFields.includes('journal') && canReadExtendedData ? (
          <Table.Column fullText flexGrow={1}>
            <Table.HeaderCell>Статья транзакций</Table.HeaderCell>
            <Table.Cell>
              {(rowData: CleanTransaction) => journals[rowData.journal]}
            </Table.Cell>
          </Table.Column>
        ) : null}
        {/* Статус заказа */}
        {selectedFields.includes('orderStatus') && (
          <Table.Column flexGrow={1} fullText>
            <Table.HeaderCell>
              <FormattedMessage
                defaultMessage="Статус заказа"
                id="purchaseOrderPage.label.orderStatus"
                description="Заголовок на странице"
              />
            </Table.HeaderCell>
            <Table.Cell>
              {(rowData: CleanTransaction) => {
                if (rowData.purchaseOrder)
                  return purchaseOrderStatusLabel[rowData.purchaseOrder.status];
                if (rowData.deliveryOrder)
                  return deliveryOrderStatusLabel[rowData.deliveryOrder.status];
              }}
            </Table.Cell>
          </Table.Column>
        )}
        {/* Внешний курс */}
        {selectedFields.includes('externalRate') && (
          <Table.Column flexGrow={1} fullText>
            <Table.HeaderCell>Внешний курс</Table.HeaderCell>
            <Table.Cell dataKey="currencyRateExternal" />
          </Table.Column>
        )}
        {/* Способ пополнения / списания */}
        {selectedFields.includes('walletType') && canReadExtendedData ? (
          <Table.Column minWidth={200} fullText flexGrow={1}>
            <Table.HeaderCell>Способ пополнения / списания</Table.HeaderCell>
            <ReqTypeCell />
          </Table.Column>
        ) : null}
        {/* Номер счета или ID */}
        {selectedFields.includes('cardNumber') && canReadExtendedData ? (
          <Table.Column fullText>
            <Table.HeaderCell>Номер счета / ID</Table.HeaderCell>
            <Table.Cell dataKey="cardNumber" />
          </Table.Column>
        ) : null}
        {/* Платежка */}
        {selectedFields.includes('payment') && canReadExtendedData ? (
          <Table.Column fullText>
            <Table.HeaderCell>Платежка</Table.HeaderCell>
            <Table.Cell className="no-padding-cell pt-2" align="center">
              {(rowData: CleanTransaction) => {
                if (!rowData || !rowData.proofFile) return null;
                if (!rowData.proofFile && rowData.proofFileId)
                  return (
                    <>
                      <i
                        className="bi bi-x text-danger"
                        style={{ width: '100%' }}
                      />{' '}
                      <span className="text-danger">Удалено</span>
                    </>
                  );

                let anchorAttributes;
                let thumbnail = getFileV2ApiUrl(rowData.proofFile.id, '48x32');
                const original = getFileV2ApiUrl(rowData.proofFile.id);

                if (rowData.proofFile.type === 'pdf') {
                  thumbnail = PdfIcon;
                  anchorAttributes = {
                    'data-iframe': true,
                    'data-src': original,
                  };
                }

                return (
                  <Gallery>
                    <a href={original} {...anchorAttributes}>
                      <img
                        className="rounded fit-cover"
                        src={thumbnail}
                        width={rowData.proofFile.type === 'pdf' ? 32 : 48}
                        height={32}
                      />
                    </a>
                  </Gallery>
                );
              }}
            </Table.Cell>
          </Table.Column>
        ) : null}
        {/* Комментарий для клиента */}
        {selectedFields.includes('clientComment') && (
          <Table.Column flexGrow={1} fullText>
            <Table.HeaderCell>Комментарий для клиента</Table.HeaderCell>
            <Table.Cell dataKey="comment" />
          </Table.Column>
        )}
        {/* Прочие поля */}
        <Table.Column width={50} fullText>
          <Table.HeaderCell children="" />
          <CheckedCell />
        </Table.Column>
        <Table.Column width={50} fullText>
          <Table.HeaderCell children="" />
          <CancelCell />
        </Table.Column>
      </Table>

      {total > take && (
        <Pagination
          className="mt-4 pb-4"
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={['pager', '-', 'limit']}
          total={total}
          limitOptions={[defaultItemsPerPage, 50, 100]}
          limit={take}
          activePage={page}
          onChangePage={handleSetPage}
          onChangeLimit={handleChangeLimit}
        />
      )}
    </div>
  );
};
