import React from 'react';
import { InputWrapper } from '../../common/input-wrapper';
import { Gallery } from '../../common/gallery/gallery';
import { getFileV2ApiUrl, userIsAdmin } from '../../../utils/common.utils';
import useSWR from 'swr';
import { api, fetcher } from '../../../services/api';
import { Controller, useForm } from 'react-hook-form';
import {
  decimalValidation,
  intValidation,
  required,
} from '../../../utils/validations';
import { AlertService } from '../../../services/alert.service';
import {
  UploadedFileV2,
  uploadMediaInputFiles,
} from '../../../services/file-service';
import { MediaInput, MediaInputValue } from '../../common/media-input';
import { useProfile } from '../../../api/profile';
import { FormattedMessage, useIntl } from 'react-intl';

type DeliveryOrder = {
  id: string;
  packedFilesV2: UploadedFileV2[];
  waybillFilesV2: UploadedFileV2[];
  count?: number;
  weight?: string;
  volume?: string;
};

type DeliveryOrderAssemblyInfoProps = {
  id: string;
};
export const DeliveryOrderAssemblyInfo: React.FC<
  DeliveryOrderAssemblyInfoProps
> = (props) => {
  const intl = useIntl();

  const { data: user } = useProfile();
  const { data: order, mutate: orderMutate } = useSWR<DeliveryOrder>(
    `/delivery-order/${props.id}`,
    fetcher,
  );

  const [editMode, setEditMode] = React.useState(false);

  if (!order || !user) {
    return null;
  }

  function handleSave(data: DeliveryOrder) {
    orderMutate(data);
    setEditMode(false);
  }

  function handleEdit() {
    setEditMode(true);
  }

  function handleCancel() {
    setEditMode(false);
  }

  if (editMode) {
    return (
      <ReceivedEditForm
        id={order.id}
        defaultValues={{
          packedFiles: order.packedFilesV2.map((file) => ({
            type: 'exist',
            file,
          })),
          waybillFiles: order.waybillFilesV2.map((file) => ({
            type: 'exist',
            file,
          })),
          count: String(order.count),
          weight: String(order.weight),
          volume: String(order.volume),
        }}
        onSave={handleSave}
        onCancel={handleCancel}
      />
    );
  }

  return (
    <div>
      <div className="d-flex flex-row justify-content-between">
        <div className="fs-18 fw-600 color-gray-450">
          <FormattedMessage
            defaultMessage="Комплектация"
            id="delivery.label.onPicking"
            description="Подзаголовок на странице"
          />
        </div>
        {userIsAdmin(user) && (
          <button
            className="btn btn-link text-decoration-none"
            type="button"
            onClick={handleEdit}
          >
            <FormattedMessage
              defaultMessage="Изменить"
              id="delivery.btn-label.change"
              description="Кнопка"
            />
          </button>
        )}
      </div>
      {order.packedFilesV2.length > 0 && (
        <InputWrapper
          theme="light"
          className="mt-3"
          title={intl.formatMessage({
            defaultMessage: 'Фото упакованного товара',
            id: 'deliveryAssemblyModal.input-label.photoOfThePackagedGoods',
            description: 'Заголовок поля ввода',
          })}
        >
          <Gallery className="mt-2">
            {order.packedFilesV2.map((file, index) => (
              <a href={getFileV2ApiUrl(file.id)} key={index}>
                <img
                  className="rounded fit-cover me-2"
                  src={getFileV2ApiUrl(file.id, '64x64')}
                  width={64}
                  height={64}
                />
              </a>
            ))}
          </Gallery>
        </InputWrapper>
      )}
      {order.waybillFilesV2.length > 0 && (
        <InputWrapper
          theme="light"
          className="mt-3"
          title={intl.formatMessage({
            defaultMessage: 'Транспортная накладная',
            id: 'deliveryAssemblyModal.input-label.consignment',
            description: 'Заголовок поля ввода',
          })}
        >
          <Gallery className="mt-2">
            {order.waybillFilesV2.map((file, index) => (
              <a href={getFileV2ApiUrl(file.id)} key={index}>
                <img
                  className="rounded fit-cover me-2"
                  src={getFileV2ApiUrl(file.id, '64x64')}
                  width={64}
                  height={64}
                />
              </a>
            ))}
          </Gallery>
        </InputWrapper>
      )}
      {!!order.count && (
        <div className="row mt-3">
          <div className="col-4">
            <InputWrapper
              theme="light"
              className="mt-3"
              title={intl.formatMessage({
                defaultMessage: 'Кол-во мест',
                id: 'deliveryAssemblyModal.input-label.amountOfPlaces',
                description: 'Заголовок поля ввода',
              })}
            >
              <div>{order.count}</div>
            </InputWrapper>
          </div>
          <div className="col-4">
            <InputWrapper
              theme="light"
              className="mt-3"
              title={intl.formatMessage({
                defaultMessage: 'Вес',
                id: 'input.label.weight',
                description: 'Заголовок поля ввода',
              })}
            >
              <div>{order.weight} кг</div>
            </InputWrapper>
          </div>
          <div className="col-4">
            <InputWrapper
              theme="light"
              className="mt-3"
              title={intl.formatMessage({
                defaultMessage: 'Объём',
                id: 'input.label.volume',
                description: 'Заголовок поля ввода',
              })}
            >
              <div>{order.volume} м³</div>
            </InputWrapper>
          </div>
        </div>
      )}
    </div>
  );
};

type ReceivedEditFormProps = {
  className?: string;
  id: string;
  defaultValues: {
    packedFiles: MediaInputValue[];
    waybillFiles: MediaInputValue[];
    count?: string;
    weight?: string;
    volume?: string;
  };
  onSave: (data: DeliveryOrder) => void;
  onCancel: () => void;
};

const ReceivedEditForm: React.FC<ReceivedEditFormProps> = (props) => {
  const intl = useIntl();

  const { register, control, handleSubmit, formState } = useForm<{
    packedFiles: MediaInputValue[];
    waybillFiles: MediaInputValue[];
    count: string;
    weight: string;
    volume: string;
  }>({
    defaultValues: props.defaultValues,
  });

  const onSubmit = handleSubmit(async (data) => {
    const [packedFiles, waybillFiles] = await Promise.all([
      uploadMediaInputFiles(data.packedFiles),
      uploadMediaInputFiles(data.waybillFiles),
    ]);

    await api
      .put(`/delivery-order/${props.id}/v2/assembly-info`, {
        count: Number(data.count),
        weight: data.weight,
        volume: data.volume,
        packedFiles: packedFiles.map((file) => file.file),
        waybillFiles: waybillFiles.map((file) => file.file),
      })
      .then(({ data }: { data: { deliveryOrder: DeliveryOrder } }) => {
        AlertService.success();
        props.onSave(data.deliveryOrder);
      });
  });

  function handleCancel() {
    props.onCancel();
  }

  return (
    <form className={props.className} onSubmit={onSubmit}>
      <div className="d-flex flex-row justify-content-between">
        <div className="fs-18 fw-600 color-gray-450">
          <FormattedMessage
            defaultMessage="Комплектация"
            id="delivery.label.onPicking"
            description="Подзаголовок на странице"
          />
        </div>
        <div>
          <button
            className="btn btn-link text-decoration-none"
            disabled={formState.isSubmitting}
          >
            {formState.isSubmitting ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Сохранить"
                id="receiptConfirmation.label.save"
                description="Надпись на кнопке"
              />
            )}
          </button>
          <button
            className="btn btn-link text-decoration-none"
            disabled={formState.isSubmitting}
            type="button"
            onClick={handleCancel}
          >
            <FormattedMessage
              defaultMessage="Отменить"
              id="receiptConfirmation.label.cancel"
              description="Надпись на кнопке"
            />
          </button>
        </div>
      </div>
      <Controller
        control={control}
        rules={{ ...required }}
        name="packedFiles"
        render={({ field, fieldState }) => (
          <InputWrapper
            theme="light"
            className="mt-3"
            title={intl.formatMessage({
              defaultMessage: 'Фото упакованного товара',
              id: 'deliveryAssemblyModal.input-label.photoOfThePackagedGoods',
              description: 'Заголовок поля ввода',
            })}
            required
            error={fieldState.error?.message}
          >
            <MediaInput {...field} className="mt-2" multiple />
          </InputWrapper>
        )}
      />
      <Controller
        control={control}
        rules={{ ...required }}
        name="waybillFiles"
        render={({ field, fieldState }) => (
          <InputWrapper
            theme="light"
            className="mt-3"
            title={intl.formatMessage({
              defaultMessage: 'Транспортная накладная',
              id: 'deliveryAssemblyModal.input-label.consignment',
              description: 'Заголовок поля ввода',
            })}
            required
            error={fieldState.error?.message}
          >
            <MediaInput {...field} className="mt-2" multiple />
          </InputWrapper>
        )}
      />
      <div className="row mt-3">
        <div className="col-4">
          <InputWrapper
            theme="light"
            className="mt-3"
            title={intl.formatMessage({
              defaultMessage: 'Кол-во мест',
              id: 'deliveryAssemblyModal.input-label.amountOfPlaces',
              description: 'Заголовок поля ввода',
            })}
            error={formState.errors.count?.message}
          >
            <input
              className="form-control"
              type="text"
              {...register('count', { ...required, ...intValidation })}
            />
          </InputWrapper>
        </div>
        <div className="col-4">
          <InputWrapper
            theme="light"
            className="mt-3"
            title={intl.formatMessage({
              defaultMessage: 'Вес',
              id: 'input.label.weight',
              description: 'Заголовок поля ввода',
            })}
            error={formState.errors.weight?.message}
          >
            <div className="input-group">
              <input
                className="form-control"
                type="number"
                min={0}
                step={0.01}
                {...register('weight', { ...required, ...decimalValidation })}
              />
              <span className="input-group-text">кг</span>
            </div>
          </InputWrapper>
        </div>
        <div className="col-4">
          <InputWrapper
            theme="light"
            className="mt-3"
            title="Объём"
            error={formState.errors.volume?.message}
          >
            <div className="input-group">
              <input
                className="form-control"
                type="number"
                min={0}
                step={0.01}
                {...register('volume', { ...required, ...decimalValidation })}
              />
              <span className="input-group-text">м³</span>
            </div>
          </InputWrapper>
        </div>
      </div>
    </form>
  );
};
