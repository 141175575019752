import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { intValidation, required } from '../../../utils/validations';
import { AlertService } from '../../../services/alert.service';
import { InputWrapper } from '../../common/input-wrapper';
import { api } from '../../../services/api';
import { FormattedMessage, useIntl } from 'react-intl';
import { useModalClose } from '../../../utils/useModalClose';

type CompositeOrderReceivedModalProps = {
  close: () => void;
  reject: () => void;
  compositeDeliveryId: string;
};

export const CompositeOrderReceivedModal: React.FC<
  CompositeOrderReceivedModalProps
> = (props) => {
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<{
    deliveryCount: string;
  }>();
  const intl = useIntl();
  const onSubmit = handleSubmit((form) => {
    api
      .put(`/composite-delivery/${props.compositeDeliveryId}`, {
        status: 'receivedInRussia',
        deliveryCount: form.deliveryCount,
      })
      .then(() => {
        AlertService.success();
        props.close();
      });
  });

  const modalRef = useModalClose(props.reject);

  return (
    <div className="modal-dialog" ref={modalRef}>
      <form className="modal-content" onSubmit={onSubmit}>
        <div className="modal-header border-bottom-0 p-4">
          <h3>
            <FormattedMessage
              defaultMessage="Подтвердить количество посылок"
              id="deliveryAssemblyModal.title.confirmNumberParcels"
              description="Заголовок"
            />
          </h3>
          <button
            type="button"
            className="btn-close"
            onClick={() => props.reject()}
          />
        </div>

        <div className="modal-body pt-2 p-4">
          <Controller
            control={control}
            name="deliveryCount"
            rules={{ ...required, ...intValidation }}
            render={({ field, fieldState }) => (
              <InputWrapper
                title={intl.formatMessage({
                  defaultMessage: 'Количество посылок',
                  id: 'deliveryAssemblyModal.input.NumberParcels',
                  description: 'Заголовок поля ввода',
                })}
                className="mb-3"
                error={fieldState.error?.message}
                required
              >
                <div className="input-group">
                  <input
                    className="form-control"
                    type="number"
                    min={1}
                    step={1}
                    {...field}
                  />
                  <span className="input-group-text">шт</span>
                </div>
              </InputWrapper>
            )}
          />
        </div>
        <div className="modal-footer pt-3 p-4 justify-content-end">
          <button
            type="submit"
            className="btn btn-success"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Подтвердить отправку"
                id="deliveryAssemblyModal.btn-label.confirmShipment"
                description="Надпись на кнопке"
              />
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
