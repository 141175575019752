import React, { useEffect } from 'react';
import { Header } from '../../common/header.component';
import { useParams } from 'react-router-dom';
import { DeliveryOrderStatus } from '../../common/delivery-order-status.component';
import {
  setBodyOverflowHidden,
  unsetBodyOverflowHidden,
} from '../../../utils/common.utils';
import { AlertService } from '../../../services/alert.service';
import useSWR from 'swr';
import { api, fetcher } from '../../../services/api';
import { Rbac } from '../../common/Rbac';
import {
  CleanCompositeDelivery,
  RbacObject,
} from '../../../export-types/cleaned-types';
import { useProfile } from '../../../api/profile';
import { FormattedMessage } from 'react-intl';
import { AdminCompositeDeliveryStatusTable } from './admin-composite-delivery-status-table.component';
import { CompositeDeliveryOrdersInfo } from './composite-delivery-orders-info';
import { CompositeDeliveryInfo } from './composite-delivery-info';
import { CompositeDeliveryFinancesInfo } from './composite-delivery-finances-info';
import { ModalsService } from '../../../services/modals.service';
import { CompositeOrderReceivedModal } from './composite-order-recieved-modal';

export const CompositeDeliveryScreen: React.FC = () => {
  const { id } = useParams();
  const { data: user } = useProfile();
  const { data: order, mutate: mutateOrder } = useSWR<CleanCompositeDelivery>(
    `/composite-delivery/${id}`,
    fetcher,
  );

  useEffect(() => {
    setBodyOverflowHidden();
    return () => {
      unsetBodyOverflowHidden();
    };
  }, []);

  if (!order || !user) {
    return null;
  }

  function getBtnText() {
    if (!order) return null;

    if (user?.role === 'employee' && order.status === 'sentToRussia')
      return null;

    switch (order.status) {
      case 'assembly':
        return (
          <FormattedMessage
            defaultMessage="Подтвердить отправку в РФ"
            id="delivery.btn-label.confirmShipmentToTheRussianFederation"
            description="Надпись на кнопке/заголовок"
          />
        );
      case 'sentToRussia':
        return 'Подтвердить получение в РФ';
      case 'receivedInRussia':
        if (
          order.deliveryOrders?.every(
            (order) => order.status === 'sentToRussia',
          )
        ) {
          return 'Подтвердить количество посылок';
        } else {
          return null;
        }
    }
  }

  function nextStep() {
    if (!order) return null;

    switch (order.status) {
      case 'assembly':
        const result = window.confirm(
          'Вы действительно хотите подтвердить отправку?',
        );
        if (!result) {
          return;
        }
        return api
          .put(`/composite-delivery/${order.id}`, { status: 'sentToRussia' })
          .then(() => {
            AlertService.success();
          })
          .then(() => mutateOrder());
      case 'sentToRussia': {
        const result = window.confirm(
          'Вы действительно хотите отметить заказ полученным в РФ?',
        );
        if (!result) {
          return;
        }

        return api
          .put(`/composite-delivery/${order.id}`, {
            status: 'receivedInRussia',
          })
          .then(() => {
            AlertService.success();
          })
          .then(() => mutateOrder());
      }
      case 'receivedInRussia': {
        ModalsService.createModal(CompositeOrderReceivedModal, {
          compositeDeliveryId: order.id,
        }).then(() => mutateOrder());
      }
    }
  }

  if (!order || !user) {
    return null;
  }

  return (
    <div className="container-fluid h-100">
      <Header>
        <div className="d-flex flex-row align-items-center">
          <h1 className="me-3 mb-0">
            <FormattedMessage
              defaultMessage="Транзитная доставка {value}"
              id="compositDelivery.label.transitDelivery"
              description="Заголовок на странице"
              values={{ value: 'T' + order.internalId }}
            />
          </h1>
          <DeliveryOrderStatus status={order.status} />
        </div>
      </Header>
      <div className="row h-100">
        <div className="col-4 col-sm-8 h-100 position-relative">
          <div
            className="position-absolute overflow-auto corner-position no-scroll-bar"
            style={{ paddingBottom: 100 }}
          >
            <div className="bg-gray p-3 pb-0 rounded-8 mb-3">
              <div className="fs-18 fw-600 color-gray-450 mb-3">
                Статус заказа
              </div>
              <AdminCompositeDeliveryStatusTable order={order} />
            </div>
            <div className="bg-gray p-3 rounded-8 my-3">
              <CompositeDeliveryOrdersInfo id={order.id} />
            </div>
            <div className="bg-gray p-3 rounded-8">
              <CompositeDeliveryInfo id={order.id} />
            </div>
            <Rbac
              object={RbacObject.DeliveryOrder}
              action={['read:finances-client', 'read:finances-company']}
            >
              <div className="bg-gray p-3 rounded-8 mt-3">
                <CompositeDeliveryFinancesInfo id={order.id} />
              </div>
            </Rbac>
          </div>
        </div>
        <div className="col-8 col-sm-4">
          <Rbac
            object={RbacObject.DeliveryOrder}
            action={'write:markSentToRussia'}
          >
            {!!getBtnText() && (
              <div className="d-flex flex-row justify-content-center">
                <div className="btn btn-success" onClick={nextStep}>
                  {getBtnText()} | <i className="bi bi-check-lg fs-18" />
                </div>
              </div>
            )}
          </Rbac>
        </div>
      </div>
    </div>
  );
};
