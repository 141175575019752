import Table, { CellProps } from 'rsuite/Table';
import React, { useRef } from 'react';
import { toMoney } from '../../../utils/common.utils';
import Popover from 'rsuite/Popover';
import Dropdown from 'rsuite/Dropdown';
import Whisper from 'rsuite/Whisper';
import IconButton from 'rsuite/IconButton';
import { CleanDeliveryOrderDeliveryMarkup } from '../../../export-types/cleaned-types';
import { WhisperSpeaker } from '../../../utils/whisper-speaker';

type Props = {
  markups: Array<CleanDeliveryOrderDeliveryMarkup>;
  onEdit: (markup: CleanDeliveryOrderDeliveryMarkup) => void;
  currency?: string;
  onDelete: (id: string) => void;
  isLoading: boolean;
};

export const MarkupTable = ({
  markups,
  onEdit,
  currency = '¥',
  onDelete,
  isLoading,
}: Props) => {
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const PriceCell = ({
    rowData,
    dataKey,
    ...props
  }: CellProps<CleanDeliveryOrderDeliveryMarkup>) => {
    return (
      rowData &&
      dataKey && (
        <Table.Cell {...props}>
          {toMoney(
            rowData[
              dataKey as keyof CleanDeliveryOrderDeliveryMarkup
            ] as string,
          )}{' '}
          {currency}
        </Table.Cell>
      )
    );
  };

  const MarkupCell = ({
    rowData,
    ...props
  }: CellProps<CleanDeliveryOrderDeliveryMarkup>) =>
    rowData && <Table.Cell {...props}>{rowData.markup} %</Table.Cell>;

  const renderMenu =
    (markup: CleanDeliveryOrderDeliveryMarkup): WhisperSpeaker =>
    ({ onClose, left, top, className }, ref) => {
      const handleSelect = (eventKey: number | string | undefined) => {
        if (timeout.current) {
          clearTimeout(timeout.current);
        }
        timeout.current = setTimeout(() => {
          onClose();
          switch (eventKey) {
            case 1: {
              return onEdit(markup);
            }
            case 2: {
              return onDelete(markup.id);
            }
            default:
              return;
          }
        }, 100);
      };
      return (
        <Popover ref={ref} className={className} style={{ left, top }} full>
          <Dropdown.Menu onSelect={handleSelect}>
            <Dropdown.Item eventKey={1}>
              <i className="bi bi-pencil" />
              <span className="ps-2">Изменить</span>
            </Dropdown.Item>
            <Dropdown.Item eventKey={2}>
              <i className="bi bi-trash" />
              <span className="ps-2">Удалить</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Popover>
      );
    };

  const ActionCell = ({
    rowData,
    dataKey,
    ...props
  }: CellProps<CleanDeliveryOrderDeliveryMarkup>) => {
    return (
      rowData && (
        <Table.Cell
          {...props}
          className="link-group no-padding-cell pt-2"
          align="right"
        >
          <Whisper
            placement="leftStart"
            trigger="click"
            speaker={renderMenu(rowData)}
          >
            <IconButton
              appearance="subtle"
              icon={<i className="bi bi-three-dots" />}
            />
          </Whisper>
        </Table.Cell>
      )
    );
  };

  return (
    <Table data={markups} headerHeight={57} autoHeight loading={isLoading}>
      <Table.Column flexGrow={1} minWidth={120}>
        <Table.HeaderCell>Цена до</Table.HeaderCell>
        <PriceCell dataKey="priceTo" />
      </Table.Column>
      <Table.Column flexGrow={1} minWidth={120}>
        <Table.HeaderCell>Наценка</Table.HeaderCell>
        <MarkupCell />
      </Table.Column>
      <Table.Column>
        <Table.HeaderCell> </Table.HeaderCell>
        <ActionCell />
      </Table.Column>
    </Table>
  );
};
