import PdfIcon from '../../assets/pdf-file.svg';
import { getFileV2ApiUrl } from '../../utils/common.utils';
import classNames from 'classnames';
import React from 'react';
import { MediaInputValue } from './media-input';
import useObjectUrl from '../../utils/useObjectUrl';

type Props = {
  data: MediaInputValue;
  index: number;
  unloadedIndexes?: number[] | undefined;
  loading?: boolean | undefined;
  removeFile: (index: number) => void;
};

export const RenderFile = ({
  data,
  index,
  unloadedIndexes,
  loading,
  removeFile,
}: Props) => {
  let anchorAttributes;
  let original;
  let thumbnailSrc;
  let thumbnail;

  let type = 'unknown';

  const blobUrl = useObjectUrl(data.type === 'new' ? data.file : undefined);
  if (data.type === 'new') {
    original = blobUrl!;

    if (data.file.type.startsWith('image/')) {
      type = 'image';
      thumbnailSrc = original;
    } else if (data.file.type.startsWith('video/')) {
      type = 'video';
      thumbnailSrc = original;
    } else if (data.file.type === 'application/pdf') {
      type = 'pdf';
      thumbnailSrc = PdfIcon;
    } else {
      type = 'unknown';
    }
  } else if (data.type === 'exist') {
    original = getFileV2ApiUrl(data.file.id);
    type = data.file.type;

    switch (data.file.type) {
      case 'image':
        thumbnailSrc = getFileV2ApiUrl(data.file.id, '64x64');
        break;
      case 'video':
        thumbnailSrc = original;
        break;
      case 'pdf':
        thumbnailSrc = PdfIcon;
        break;
    }
  }

  switch (type) {
    case 'image': {
      thumbnail = (
        <img
          className="rounded fit-cover"
          src={thumbnailSrc}
          width={64}
          height={64}
          alt=""
        />
      );

      anchorAttributes = {
        'data-src': original,
      };
      break;
    }
    case 'pdf': {
      thumbnail = (
        <img
          className="rounded fit-cover"
          src={PdfIcon}
          width={64}
          height={64}
          alt=""
        />
      );

      anchorAttributes = {
        'data-iframe': true,
        'data-src': original,
      };
      break;
    }
    case 'video': {
      thumbnail = (
        <video className="rounded fit-cover" width={64} height={64}>
          <source src={original} />
        </video>
      );
      anchorAttributes = {
        'data-video': JSON.stringify({
          source: [{ src: original }],
        }),
      };
      break;
    }
  }

  if (!original || !thumbnail) {
    return null;
  }

  let fileClassName = classNames(
    'd-inline-block cursor-pointer position-relative me-2',
    {
      'border border-2 border-warning':
        unloadedIndexes?.includes(index) && !loading,
    },
  );

  return (
    <div key={index} className={fileClassName} {...anchorAttributes}>
      {thumbnail}
      <i
        className="bi bi-x-circle-fill remove-file-btn"
        onClickCapture={(e) => {
          e.preventDefault();
          e.stopPropagation();

          removeFile(index);
        }}
      />
    </div>
  );
};
