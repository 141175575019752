import React, { useState } from 'react';
import { Input } from '../../common/input.component';
import { useForm } from 'react-hook-form';
import { required } from '../../../utils/validations';
import { api } from '../../../services/api';
import { AlertService } from '../../../services/alert.service';
import { UploadQRCode } from '../../common/upload-qr-code.component';
import { useModalClose } from '../../../utils/useModalClose';

type Form = {
  cardNumber: string;
};

type AddBankCardModelProps = {
  close: () => void;
  reject: () => void;
  account?: {
    cardNumber: string;
    qrFile: {
      id: string;
      key: string;
    } | null;
  };
};

export const WeChatMethodModal: React.FC<AddBankCardModelProps> = ({
  close,
  reject,
  account,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Form>({
    defaultValues: {
      cardNumber: account?.cardNumber,
    },
  });

  const [file, setFile] = useState<File | null>(null);

  function onChangeFile(file: File | null) {
    setFile(file);
  }

  function onSave(data: Form) {
    if (!file && account && !account.qrFile) {
      return AlertService.error('Загрузите QR код');
    }

    api
      .post(`/top-up/methods/wechat/accounts`, {
        ...data,
        qrFile: account?.qrFile,
      })
      .then(() => {
        if (file) {
          const data = new FormData();
          data.append('file', file);

          return api.post(`/top-up/methods/wechat/accounts`, data);
        }

        return;
      })
      .then(() => {
        close();
      });
  }

  const modalRef = useModalClose(reject);

  return (
    <div className="modal-dialog" ref={modalRef}>
      <form className="modal-content" onSubmit={handleSubmit(onSave)}>
        <div className="modal-body">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h3>Изменить Перевод на WeChat</h3>
            <div className="btn p-0" onClick={reject}>
              <i className="bi bi-x fs-36 color-gray-600" />
            </div>
          </div>
          <div className="row mt-3">
            <Input
              {...register('cardNumber', {
                ...required,
              })}
              // @ts-ignore TODO
              className="col"
              type="text"
              title="Номер счета / ID"
              // @ts-ignore TODO
              error={errors.cardNumber?.message}
            />
          </div>
          <div className="row mt-3">
            <div className="mb-2">Изображение QR кода</div>
            <UploadQRCode
              file={file}
              onChange={onChangeFile}
              currentFile={account?.qrFile || null}
            />
          </div>
        </div>
        <div className="modal-footer justify-content-end mt-4">
          <button type="submit" className="btn btn-success">
            Сохранить
          </button>
        </div>
      </form>
    </div>
  );
};
