import React from 'react';
import { CleanNote } from '../../export-types/cleaned-types';
import { Controller, useForm } from 'react-hook-form';
import { api } from '../../services/api';

type NoteFormProps = {
  note?: CleanNote;
  type: 'delivery' | 'purchase';
  id: string;
  handleSave: () => void;
  handleCancel: () => void;
};

type Form = {
  noteText: string;
};

export default function NoteForm({
  note,
  handleCancel,
  type,
  id,
  handleSave,
}: NoteFormProps) {
  const { control, handleSubmit, formState } = useForm<Form>({
    defaultValues: note
      ? {
          noteText: note.noteText,
        }
      : {
          noteText: '',
        },
  });

  const onSubmit = handleSubmit(async (data) => {
    if (note) {
      await api.put(
        type === 'delivery'
          ? `/delivery-order/${id}/notes/${note.id}`
          : `/purchase-order/${id}/notes/${note.id}`,
        data,
      );
    } else {
      await api.post(
        type === 'delivery'
          ? `/delivery-order/${id}/notes`
          : `/purchase-order/${id}/notes`,
        data,
      );
    }
    handleSave();
  });

  return (
    <form className="d-flex flex-column w-full" onSubmit={onSubmit}>
      <div className="col">
        <Controller
          control={control}
          name="noteText"
          render={({ field }) => (
            <textarea
              {...field}
              className="form-control bg-gray h-100"
              placeholder="Написать заметку..."
            />
          )}
        />
      </div>
      <div className="d-flex flex-row mt-1">
        <div>
          <button
            className="btn btn-link text-decoration-none"
            disabled={formState.isSubmitting}
          >
            {formState.isSubmitting ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'Сохранить'
            )}
          </button>
          <button
            className="btn btn-link text-decoration-none"
            type="button"
            disabled={formState.isSubmitting}
            onClick={handleCancel}
          >
            Отменить
          </button>
        </div>
      </div>
    </form>
  );
}
