import React from 'react';
import {
  RbacObject,
  RbacObjectActionsMap,
} from '../../export-types/cleaned-types';
import { Authorizer } from 'casbin.js';
import { useProfile } from '../../api/profile';

export type Enumerable<T> = T | Array<T>;

export function useRbac() {
  const { data: user } = useProfile();

  const authorizer = React.useMemo(() => {
    if (!user) {
      return null;
    }

    const authorizer = new Authorizer('manual');
    authorizer.setPermission(user.permissions);

    return authorizer;
  }, [user]);

  const hasPermission = <
    Object extends RbacObject,
    Action extends string & (typeof RbacObjectActionsMap)[Object][number],
  >(
    object: Object,
    action: Action,
  ) => {
    return Boolean(authorizer?.permission?.check(action, object));
  };

  const isLoading = authorizer === null;

  return { hasPermission, isLoading };
}

export const Rbac = <
  Object extends RbacObject,
  Action extends string & (typeof RbacObjectActionsMap)[Object][number],
>({
  object,
  action,
  otherwise,
  children,
}: React.PropsWithChildren<{
  object: Object;
  action: Enumerable<Action>;
  otherwise?: () => React.ReactNode;
}>) => {
  const { isLoading, hasPermission } = useRbac();
  if (isLoading) {
    return null;
  }

  const actions = Array.isArray(action) ? action : [action];
  for (const action of actions) {
    if (hasPermission(object, action)) {
      return <>{children}</>;
    }
  }

  if (otherwise) {
    return <>{otherwise()}</>;
  }

  return null;
};

export const RbacSome = <
  Object extends RbacObject,
  Action extends string & (typeof RbacObjectActionsMap)[Object][number],
>({
  checks,
  otherwise,
  children,
}: React.PropsWithChildren<{
  checks: Array<{
    object: Object;
    action: Enumerable<Action>;
  }>;
  otherwise?: () => React.ReactNode;
}>) => {
  const { isLoading, hasPermission } = useRbac();
  if (isLoading) {
    return null;
  }

  for (const { object, action } of checks) {
    const actions = Array.isArray(action) ? action : [action];
    for (const action of actions) {
      if (hasPermission(object, action)) {
        return <>{children}</>;
      }
    }
  }

  if (otherwise) {
    return <>{otherwise()}</>;
  }

  return null;
};
