import React from 'react';
import { useForm } from 'react-hook-form';
import { AlertService } from '../../../services/alert.service';
import { api } from '../../../services/api';
import { InputWrapper } from '../../common/input-wrapper';
import { FormattedMessage, useIntl } from 'react-intl';
import { CleanPurchaseOrder } from '../../../export-types/cleaned-types';

type PurchaseOrderPurchaseConfirmationModalProps = {
  close: () => void;
  reject: () => void;
  order: CleanPurchaseOrder;
};

type Form = {
  orderLink: string;
  orderNumber: string;
  orderAccount: string;
  landingBill: string;
  transportCompany: string;
};

export const PurchaseOrderEditOrderModal: React.FC<
  PurchaseOrderPurchaseConfirmationModalProps
> = ({ order, ...props }) => {
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<Form>({
    defaultValues: {
      orderLink: order.orderLink ?? undefined,
      orderNumber: order.orderNumber ?? undefined,
      orderAccount: order.orderAccount ?? undefined,
      landingBill: order.landingBill ?? undefined,
      transportCompany: order.transportCompany ?? undefined,
    },
  });

  const intl = useIntl();

  const onSubmit = handleSubmit(async (data) => {
    await api.put(`/purchase-order/v2/${order.id}/order-info`, {
      orderLink: data.orderLink || undefined,
      orderNumber: data.orderNumber || undefined,
      orderAccount: data.orderAccount || undefined,
      landingBill: data.landingBill || undefined,
      transportCompany: data.transportCompany || undefined,
    });

    AlertService.success();
    props.close();
  });

  return (
    <div className="modal-dialog">
      <form className="modal-content" onSubmit={onSubmit}>
        <div className="modal-header border-bottom-0 p-4">
          <h3>
            <FormattedMessage
              defaultMessage="Изменение ордера"
              id="purchaseOrderModal.label.orderEdit"
              description="Заголовок модального окна"
            />
          </h3>
          <button
            type="button"
            className="btn-close"
            onClick={() => props.reject()}
          />
        </div>

        <div className="modal-body pt-2 p-4">
          <InputWrapper
            title={intl.formatMessage({
              defaultMessage: 'Ссылка на ордер',
              id: 'purchaseOrderModal.input-label.orderLink',
              description: 'Заголовок поля ввода',
            })}
            className="mb-3"
            error={errors.orderLink?.message}
          >
            <div className="input-group">
              <input className="form-control" {...register('orderLink')} />
            </div>
          </InputWrapper>
          <InputWrapper
            title={intl.formatMessage({
              defaultMessage: 'Номер ордера',
              id: 'purchaseOrderModal.input-label.orderNumber',
              description: 'Заголовок поля ввода',
            })}
            className="mb-3"
            error={errors.orderNumber?.message}
          >
            <div className="input-group">
              <input className="form-control" {...register('orderNumber')} />
            </div>
          </InputWrapper>
          <InputWrapper
            title={intl.formatMessage({
              defaultMessage: 'Аккаунт',
              id: 'purchaseOrderModal.input-label.orderAccount',
              description: 'Заголовок поля ввода',
            })}
            className="mb-3"
            error={errors.orderAccount?.message}
          >
            <div className="input-group">
              <input className="form-control" {...register('orderAccount')} />
            </div>
          </InputWrapper>
          <InputWrapper
            title={intl.formatMessage({
              defaultMessage: '№ ТТН',
              id: 'purchaseOrderModal.input-label.landingBill',
              description: 'Заголовок поля ввода',
            })}
            className="mb-3"
            error={errors.landingBill?.message}
          >
            <div className="input-group">
              <input
                autoFocus
                className="form-control"
                {...register('landingBill')}
              />
            </div>
          </InputWrapper>
          <InputWrapper
            title={intl.formatMessage({
              defaultMessage: 'Название транспортной компании',
              id: 'purchaseOrderModal.input-label.transportCompany',
              description: 'Заголовок поля ввода',
            })}
            className="mb-3"
            error={errors.transportCompany?.message}
          >
            <div className="input-group">
              <input
                className="form-control"
                {...register('transportCompany')}
              />
            </div>
          </InputWrapper>
        </div>
        <div className="modal-footer justify-content-end">
          <button
            type="submit"
            className="btn btn-success"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Подтвердить выкуп"
                id="purchaseOrderPage.status-label.confirmRedemption"
                description="Надпись на кнопке"
              />
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
