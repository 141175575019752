import React from 'react';
import { ModalProps } from '../../../services/modals.service';
import { CleanUser } from '../../../export-types/cleaned-types';
import { useForm } from 'react-hook-form';
import { ProfileService } from '../../../services/profile.service';
import { AlertService } from '../../../services/alert.service';
import { Input } from '../../common/input.component';
import { emailValidation, required } from '../../../utils/validations';
import { useGlobalMutate } from '../../../api/useGlobalMutate';
import { useModalClose } from '../../../utils/useModalClose';

type Props = ModalProps & {
  defaultValues: CleanUser;
};

type Fields = {
  name: string;
  email: string;
};

export const ProfileModal: React.FC<Props> = ({
  defaultValues: user,
  close,
  reject,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Fields>({
    defaultValues: {
      name: user.name ?? '',
      email: user.email ?? '',
    },
  });

  const { mutate } = useGlobalMutate();

  const onSave = (fields: Fields) => {
    ProfileService.updateProfile(fields).then(() => {
      mutate();
      close();
      AlertService.success();
    });
  };

  const modalRef = useModalClose(reject);

  return (
    <div className="modal-dialog" ref={modalRef}>
      <form className="modal-content" onSubmit={handleSubmit(onSave)}>
        <div className="modal-body">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h3>Профиль</h3>
            <div className="btn p-0" onClick={reject}>
              <i className="bi bi-x fs-36 color-gray-600" />
            </div>
          </div>
          <Input
            className="mb-4"
            title="Ваше имя"
            {...register('name', {
              ...required,
            })}
            error={errors.name?.message}
          />
          <Input
            className="mb-4"
            title="Номер телефона"
            value={user.phone}
            disabled
          />
          <Input
            title="Электронная почта"
            {...register('email', {
              ...emailValidation,
            })}
            error={errors.email?.message}
            type="email"
          />
          <div className="fs-14 color-gray-400">
            Будем присылать уведомления об изменениях в заказах и новости
            сервиса.
          </div>
        </div>
        <div className="modal-footer">
          <div className="d-flex flex-row justify-content-end">
            <button type="submit" className="btn btn-success">
              Сохранить
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
