import React from 'react';
import { Input } from '../../common/input.component';
import { useForm } from 'react-hook-form';
import { required } from '../../../utils/validations';
import { api } from '../../../services/api';
import { useModalClose } from '../../../utils/useModalClose';

type Form = {
  cardNumber: string;
  cardHolderName: string;
};

type AddBankCardModelProps = {
  close: () => void;
  reject: () => void;
  account?: {
    cardNumber: string;
    cardHolderName: string;
  };
};

export const AlipayMethodModal: React.FC<AddBankCardModelProps> = ({
  close,
  reject,
  account,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Form>({
    defaultValues: {
      cardNumber: account?.cardNumber,
      cardHolderName: account?.cardHolderName,
    },
  });

  function onSave(data: Form) {
    api.post(`/top-up/methods/alipay/accounts`, data).then(() => {
      close();
    });
  }

  const modalRef = useModalClose(reject);

  return (
    <div className="modal-dialog" ref={modalRef}>
      <form className="modal-content" onSubmit={handleSubmit(onSave)}>
        <div className="modal-body">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h3>Изменить Перевод на Alipay</h3>
            <div className="btn p-0" onClick={reject}>
              <i className="bi bi-x fs-36 color-gray-600" />
            </div>
          </div>
          <div className="row mt-3">
            <Input
              {...register('cardNumber', {
                ...required,
              })}
              // @ts-ignore TODO
              className="col"
              type="text"
              title="Номер счета / ID"
              // @ts-ignore TODO
              error={errors.cardNumber?.message}
            />
          </div>
          <div className="row mt-3">
            <Input
              {...register('cardHolderName', {
                ...required,
              })}
              // @ts-ignore TODO
              className="col"
              type="text"
              title="Имя получателя"
              placeholder="Например, Иван Иванович К."
              // @ts-ignore TODO
              error={errors.cardHolderName?.message}
            />
          </div>
        </div>
        <div className="modal-footer justify-content-end mt-4">
          <button type="submit" className="btn btn-success">
            Сохранить
          </button>
        </div>
      </form>
    </div>
  );
};
