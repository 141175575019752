import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { LoginScreen } from './screens/auth/login-screen.component';
import { ForgotPasswordScreen } from './screens/auth/forgot-password-screen.component';
import { NavigateWithRetpath } from './navigate-with-retpath';
import * as Sentry from '@sentry/react';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

type Props = {
  userIsClient: boolean;
};

export const AuthRouter = ({ userIsClient }: Props) => {
  return (
    <SentryRoutes>
      <Route
        path="/login"
        element={<LoginScreen userIsClient={userIsClient} />}
      />
      <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
      <Route path="*" element={<NavigateWithRetpath to="/login" />} />
    </SentryRoutes>
  );
};
