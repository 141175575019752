import React, { useEffect, useState, useCallback, SetStateAction } from 'react';
import { UploadedFileV2 } from '../../services/file-service';
import AddImg from '../../assets/add-img.svg';
import { Gallery } from './gallery/gallery';
import { FormattedMessage } from 'react-intl';
import { uploadFile } from '../../services/file-service';

import { RenderFile } from './render-file';

export type MediaInputValue =
  | {
      type: 'new';
      file: File | Blob;
      uploading?: ReturnType<typeof uploadFile>;
    }
  | {
      type: 'exist';
      file: UploadedFileV2;
    };

type MediaInputProps = {
  className?: string;
  name?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  value?: MediaInputValue[];
  onChange: (event: { target: { value: MediaInputValue[] } }) => void;
  multiple?: boolean;
  enableClipboardPasteHandler?: boolean;
  enableUploadOnAttach?: boolean;
  loading?: boolean;
  setLoading?: React.Dispatch<SetStateAction<boolean>>;
  updateFilesResponse?: MediaInputValue[];
};

export const MediaInput: React.FC<MediaInputProps> = React.forwardRef<
  HTMLInputElement,
  MediaInputProps
>(
  (
    {
      multiple,
      onChange,
      enableClipboardPasteHandler = true,
      enableUploadOnAttach,
      loading,
      setLoading,
      updateFilesResponse,
      ...props
    },
    ref,
  ) => {
    const [unloadedIndexes, setUnloadedIndexes] = useState<number[]>([]);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
      if (!loading) {
        setProgress(0);
      } else {
        const timer = setInterval(() => {
          setProgress((prevProgress) => {
            const step = (100 - prevProgress) / 12;
            const newProgress = prevProgress + step;

            if (newProgress >= 90) {
              clearInterval(timer);
              return 90;
            }

            return newProgress;
          });
        }, 100);

        return () => {
          clearInterval(timer);
        };
      }
    }, [loading]);

    const handleChange = useCallback(
      async function (value: MediaInputValue[]) {
        if (enableUploadOnAttach) {
          for (const item of value) {
            if (item.type === 'new' && typeof item.uploading === 'undefined') {
              item.uploading = uploadFile(item.file);
            }
          }
        }
        onChange({ target: { value: value } });
      },

      [onChange, enableUploadOnAttach],
    );

    function removeFile(index: number) {
      const newValue = Array.from(props.value || []);
      newValue.splice(index, 1);

      handleChange(newValue);
    }

    useEffect(() => {
      if (!enableClipboardPasteHandler) {
        return;
      }
      const handlePasteFile = async (event: ClipboardEvent) => {
        if (
          event.clipboardData?.files &&
          event.clipboardData?.files.length > 0
        ) {
          const value: MediaInputValue[] = Array.from(
            event.clipboardData.files,
          ).map((file) => ({
            type: 'new' as 'new',
            file,
          }));

          handleChange(multiple ? [...(props.value || []), ...value] : value);
        }
      };

      document.addEventListener('paste', handlePasteFile);
      return () => {
        document.removeEventListener('paste', handlePasteFile);
      };
    }, [enableClipboardPasteHandler, multiple, props.value, handleChange]);

    const handlePasteImage = async () => {
      try {
        const permission = await navigator.permissions.query({
          name: 'clipboard-read' as PermissionName,
        });
        if (permission.state === 'denied') {
          return;
        }
      } catch (e) {
        console.log('Clipboard Permission Request failed', e);
      }

      const clipboardContents = await navigator?.clipboard?.read();
      for (const item of clipboardContents) {
        if (!item.types.includes('image/png')) {
          continue;
        }
        const blob = await item.getType('image/png');
        const value = [
          {
            type: 'new' as const,
            file: blob,
          },
        ];
        handleChange(multiple ? [...(props.value || []), ...value] : value);
      }
    };

    useEffect(() => {
      const unloadedFiles = updateFilesResponse
        ?.map((obj, index) => (obj.type === 'new' ? index : null))
        .filter((index): index is NonNullable<typeof index> => index !== null);

      setUnloadedIndexes(unloadedFiles ?? []);
    }, [updateFilesResponse]);

    return (
      <div className={props.className}>
        <div className="d-flex flex-row">
          {(multiple ||
            !Array.isArray(props.value) ||
            props.value.length === 0) && (
            <div>
              <label className="cursor-pointer me-2">
                <img src={AddImg} width={64} height={64} alt="Добавить файл" />
                <input
                  multiple={multiple}
                  type="file"
                  className="d-none"
                  accept="image/png,image/jpeg,video/*,application/pdf"
                  name={props.name}
                  onBlur={props.onBlur}
                  ref={ref}
                  onChange={(e) => {
                    if (!e.target.files || e.target.files.length === 0) {
                      return;
                    }

                    const value: MediaInputValue[] = Array.from(
                      e.target.files,
                    ).map((file) => ({
                      type: 'new' as 'new',
                      file,
                    }));

                    handleChange(
                      multiple ? [...(props.value || []), ...value] : value,
                    );
                  }}
                />
              </label>
              <button
                type="button"
                className="btn me-5 mt-2 btn-screen-paste"
                onClick={handlePasteImage}
              >
                <i className="bi bi-clipboard-check me-1"></i>
                <FormattedMessage
                  defaultMessage="Вставить"
                  id="mediaInput.btn-label.insert"
                  description="Надпись на кнопке"
                />
              </button>
            </div>
          )}
          <Gallery>
            {unloadedIndexes.length === 0 || loading === undefined
              ? props.value?.map((data, index) => (
                  <RenderFile
                    key={index}
                    data={data}
                    index={index}
                    unloadedIndexes={unloadedIndexes}
                    loading={loading}
                    removeFile={removeFile}
                  />
                ))
              : updateFilesResponse?.map(
                  (data, index) =>
                    data.type === 'exist' && (
                      <RenderFile
                        key={index}
                        data={data}
                        index={index}
                        unloadedIndexes={unloadedIndexes}
                        loading={loading}
                        removeFile={removeFile}
                      />
                    ),
                )}
          </Gallery>
        </div>
        <div>
          {unloadedIndexes.length !== 0 &&
            !loading &&
            loading !== undefined && (
              <>
                <div className="mt-3 d-flex align-items-center justify-content-left">
                  <button
                    type="button"
                    className="btn btn-upload me-5 mt-2 "
                    onClick={() => {
                      setLoading && setLoading(true);
                      updateFilesResponse && handleChange(updateFilesResponse);
                      setUnloadedIndexes([]);
                    }}
                  >
                    <i className="bi bi-repeat me-1"></i>
                    <FormattedMessage
                      defaultMessage="Загрузить еще раз"
                      id="mediaInput.btn-label.upload"
                      description="Надпись на кнопке"
                    />
                  </button>
                  <div className="mt-3">
                    <Gallery>
                      {updateFilesResponse?.map(
                        (data, index) =>
                          data.type === 'new' && (
                            <RenderFile
                              key={index}
                              data={data}
                              index={index}
                              unloadedIndexes={unloadedIndexes}
                              loading={loading}
                              removeFile={removeFile}
                            />
                          ),
                      )}
                    </Gallery>
                  </div>
                </div>
                <div className="mt-2 text-warning">
                  <FormattedMessage
                    defaultMessage="Выделенные файлы не загружены. Попробуйте ещё раз или удалите файлы."
                    id="mediaInput.label.selectedFilesAreNotDownloaded..."
                    description="Надпись на странице"
                  />
                </div>
              </>
            )}
        </div>
        {loading && (
          <div
            className="progress mt-3"
            role="progressbar"
            aria-label="Animated striped example"
            aria-valuenow={progress}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            <div
              className="progress-bar progress-bar-striped progress-bar-animated bg-secondary"
              style={{ width: `${progress}%` }}
            >
              <FormattedMessage
                defaultMessage="Загрузка"
                id="mediaInput.label.loading"
                description="Надпись на странице"
              />
            </div>
          </div>
        )}
      </div>
    );
  },
);
