import React, { createElement, Fragment, useEffect } from 'react';
// @ts-ignore
import { useBranch } from 'baobab-react/hooks';

export const Modals: React.FC = () => {
  const {
    modals,
  }: { modals: Array<{ id: string; type: React.FC; props: {} }> } = useBranch({
    modals: ['modals'],
  });

  useEffect(() => {
    if (modals.length) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  });

  return (
    <>
      {modals.map((modal, index) => (
        <Fragment key={modal.id}>
          <div
            className="modal-backdrop fade show"
            style={{ zIndex: (index + 1) * 1040 }}
          />
          <div
            className="modal fade show d-block"
            style={{ zIndex: (index + 1) * 1050 }}
          >
            {createElement(modal.type, modal.props)}
          </div>
        </Fragment>
      ))}
    </>
  );
};
