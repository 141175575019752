import React from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import {
  articlePoizonValidation,
  required,
  urlValidation,
} from '../../../utils/validations';
import { InputWrapper } from '../../common/input-wrapper';
import { fetcher } from '../../../services/api';
import { AlertService } from '../../../services/alert.service';
import { RouterOutput, trpcSwr } from '../../../api/trpc';
import { MediaInput, MediaInputValue } from '../../common/media-input';
import {
  uploadFile,
  uploadMediaInputFiles,
} from '../../../services/file-service';
import { Select } from '../../common/select.component';
import { PRODUCT_CATEGORIES } from './ProductData';
import { getCurrencySymbol } from '../../../utils/common.utils';
import { useNavigate } from 'react-router-dom';
import { Decimal } from 'decimal.js';
import { AddButton } from '../../common/add-button';

import { useGlobalMutate } from '../../../api/useGlobalMutate';
import { useBrands } from './useBrandsList';

type SizesAndPricesType = {
  originalSize?: string | number;
  priceProduct: string;
  size: string;
  footLength?: string;
};

type Form = {
  disabled: boolean;
  originalId: string;
  article: string;
  link: string;
  name: string;
  brandId: number;
  categoryId: number;
  cover: MediaInputValue[];
  files: MediaInputValue[];
  sizesAndPrices: SizesAndPricesType[];
  description: string;
  characteristics: string;
  color: string;
  currency: 'rub' | 'usd' | 'cny';
};

type CardPoizonItemType = RouterOutput['productCardPoizonAdmin']['itemInfo'];

type CardPoizonFileProps = {
  itemInfo?: CardPoizonItemType | null;
};

export const CardPoizonFile = ({ itemInfo }: CardPoizonFileProps) => {
  const navigate = useNavigate();

  const { mutate } = useGlobalMutate();

  const { data: brands } = useBrands();

  const currency = 'cny' as const;

  const defaultItemValues = itemInfo
    ? {
        originalId: itemInfo.originalId,
        article: `${itemInfo.productId}-${itemInfo.modificationId}`,
        link: itemInfo.link,
        name: itemInfo.name,
        brandId: itemInfo.brand?.id,
        categoryId: itemInfo.category?.id,
        cover: [
          {
            type: 'exist' as const,
            file: itemInfo.cover,
          },
        ],
        files: itemInfo.files.map((file) => ({
          type: 'exist' as const,
          file,
        })),
        sizesAndPrices: itemInfo.sizesAndPrices.map((item) => ({
          originalSize: item.brandSize?.id,
          size: item.brandSize?.size || '',
          footLength: item.brandSize?.footLength || '',
          priceProduct: item.priceProduct,
        })),
        description: itemInfo.description,
        characteristics: itemInfo.characteristics,
        color: itemInfo.color,
        currency: itemInfo.currency,
      }
    : {
        sizesAndPrices: [
          {
            originalSize: '',
            priceProduct: '',
            size: '',
          },
        ],
      };

  const {
    formState: { isSubmitting },
    handleSubmit,
    control,
    watch,
    setValue,
  } = useForm<Form>({
    defaultValues: defaultItemValues,
  });

  const [brandId, categoryId] = watch(['brandId', 'categoryId']);

  const brandNames =
    brands?.items.map((item) => ({
      id: item.id,
      name: item.name,
    })) || [];

  const categories =
    brands?.items
      .filter((brand) => brand.id === Number(brandId))
      .map((brand) =>
        brand.category.map((item) => ({
          id: item.id,
          name: item.name,
        })),
      )[0] || [];

  const sizes =
    brands?.items
      .filter((brand) => brand.id === Number(brandId))
      .map((brand) =>
        brand.category
          .filter((category) => category.id === Number(categoryId))
          .flatMap((category) =>
            category.brandSize.map((item) => ({
              id: item.id,
              originalSize: item.originalSize,
              size: item.size,
              footLength: item.footLength,
            })),
          ),
      )[0] || [];

  const { trigger: triggerUpdate } =
    trpcSwr.productCardPoizonAdmin.update.useSWRMutation({ fetcher });
  const { trigger: triggerCreate, error: createError } =
    trpcSwr.productCardPoizonAdmin.create.useSWRMutation({ fetcher });

  if (createError) {
    AlertService.error(createError.message);
  }

  const onSubmit = handleSubmit(async (form) => {
    const [productId, modificationId] = form.article.split('-');
    const cover = await uploadMediaInputFiles(form.cover);

    const images = await Promise.all(
      form.files.map(async (item) => {
        const data = item;
        return data.type === 'new'
          ? uploadFile(data.file).then((data) => data.file)
          : Promise.resolve(data.file);
      }),
    );

    const sizesAndPrices = form.sizesAndPrices.map((item) => {
      const brandSizeId = Number(item.originalSize);
      const priceProduct = new Decimal(item.priceProduct).toFixed(2);

      return {
        brandSizeId,
        priceProduct,
      };
    });

    const data = {
      originalId: form.originalId,
      productId: productId,
      modificationId: modificationId,
      link: form.link,
      name: form.name,
      brandId: Number(form.brandId),
      categoryId: Number(form.categoryId),
      coverId: cover[0].file.id,
      files: images.map((item) => item.id),
      sizesAndPrices: sizesAndPrices,
      description: form.description,
      characteristics: form.characteristics,
      color: form.color,
      currency: currency,
    };

    if (itemInfo) {
      await triggerUpdate({
        id: itemInfo.id,
        data: data,
      });
      mutate();
    } else {
      await triggerCreate(data);
    }
    AlertService.success();
    navigate('/marketing/poizon');
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sizesAndPrices',
  });

  return (
    <>
      <div className="container pb-5" style={{ marginTop: -70 }}>
        <h1 className="mb-4">
          {itemInfo ? 'Редактировать' : 'Добавить'} карточку товара Poizon
        </h1>
        <form onSubmit={onSubmit}>
          {brandNames ? (
            <Controller
              control={control}
              name="brandId"
              rules={{ ...required }}
              render={({ field, fieldState }) => {
                const options = brandNames.map((brand) => ({
                  label: brand.name,
                  value: brand.id,
                }));

                return (
                  <Select
                    {...field}
                    options={options}
                    title="Бренд"
                    className="mt-3"
                    required
                    placeholder="Выберите бренд"
                    error={fieldState.error?.message}
                  />
                );
              }}
            />
          ) : null}

          {categories ? (
            <Controller
              control={control}
              name="categoryId"
              rules={{ ...required }}
              render={({ field, fieldState }) => {
                const options = categories.map((category) => ({
                  label:
                    PRODUCT_CATEGORIES.find(
                      (item) => item.value === category.name,
                    )?.label || '',
                  value: category.id,
                }));

                return (
                  <Select
                    {...field}
                    options={options}
                    title="Категория"
                    className="mt-3"
                    required
                    placeholder="Выберите категорию"
                    error={fieldState.error?.message}
                  />
                );
              }}
            />
          ) : null}

          <Controller
            control={control}
            name="originalId"
            rules={{
              ...required,
            }}
            render={({ field, fieldState }) => (
              <InputWrapper
                title="Оригинальный артикул товара"
                className="mt-3"
                error={fieldState.error?.message}
                required
              >
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  {...field}
                />
                <div className="fs-14 color-gray-400 mt-1">
                  Оригинальный артикул товара на сайте магазина, где будет
                  осуществляться закупка
                </div>
              </InputWrapper>
            )}
          />
          <Controller
            control={control}
            name={`link`}
            rules={{
              ...required,
              ...urlValidation,
            }}
            render={({ field, fieldState }) => (
              <InputWrapper
                title="Ссылка на товар"
                className="mt-3"
                error={fieldState.error?.message}
                required
              >
                <input className="form-control" type="text" {...field} />
              </InputWrapper>
            )}
          />
          <Controller
            control={control}
            name="article"
            rules={{
              ...required,
              ...articlePoizonValidation,
            }}
            render={({ field, fieldState }) => (
              <InputWrapper
                title="Внутренний артикул товара"
                error={fieldState.error?.message}
                className="mt-3"
                required
              >
                <input
                  className="form-control "
                  type="text"
                  placeholder="poizonNB574-1"
                  {...field}
                />
                <div className="fs-14 color-gray-400 mt-1">
                  Внутренний артикул, предназначенный для отображения в карточке
                  товара ExT. Где poizon - обязательная часть артикула, NB574 -
                  идентификатор модели, а 1 - идентификатор конкретной
                  модификации товара. Товары с совпадающим идентификатором
                  модели будут взаимосвязаны.
                </div>
              </InputWrapper>
            )}
          />
          <Controller
            control={control}
            name="name"
            rules={{ ...required }}
            render={({ field, fieldState }) => (
              <InputWrapper
                className="mt-3"
                required
                title="Название товара"
                error={fieldState.error?.message}
              >
                <input className="form-control" type="text" {...field} />
                <div className="fs-14 color-gray-400 mt-1">
                  Отображается в карточке товара
                </div>
              </InputWrapper>
            )}
          />

          <Controller
            control={control}
            name="cover"
            rules={{ ...required }}
            render={({ field, fieldState }) => (
              <InputWrapper
                theme="light"
                className="mt-3"
                required
                title="Обложка карточки товара"
                error={fieldState.error?.message}
              >
                <MediaInput {...field} className="form-control" />
              </InputWrapper>
            )}
          />
          <Controller
            control={control}
            name={`files`}
            rules={{ ...required }}
            render={({ field, fieldState }) => (
              <InputWrapper
                theme="light"
                title="Изображения товара"
                className="mt-3"
                required
                error={fieldState.error?.message}
              >
                <MediaInput
                  {...field}
                  multiple
                  enableClipboardPasteHandler={false}
                />
                <div className="fs-14 color-gray-400 mt-1">
                  Размер 1080х1920 px, формат .jpg или .png
                </div>
              </InputWrapper>
            )}
          />

          <Controller
            control={control}
            name="description"
            rules={{ ...required }}
            render={({ field, fieldState }) => (
              <InputWrapper
                theme="light"
                className="mt-3"
                required
                title="Описание товара"
                error={fieldState.error?.message}
              >
                <textarea
                  className="form-control"
                  style={{ height: '120px' }}
                  {...field}
                />
              </InputWrapper>
            )}
          />

          <Controller
            control={control}
            name="characteristics"
            rules={{ ...required }}
            render={({ field, fieldState }) => (
              <InputWrapper
                theme="light"
                className="mt-3"
                required
                title="Характеристики"
                error={fieldState.error?.message}
              >
                <textarea
                  className="form-control"
                  style={{ height: '120px' }}
                  {...field}
                />
              </InputWrapper>
            )}
          />

          <Controller
            control={control}
            rules={{ ...required }}
            name={`color`}
            render={({ field, fieldState }) => {
              return (
                <InputWrapper
                  title="Укажите цвет товара"
                  error={fieldState.error?.message}
                  required
                  className="mt-3"
                >
                  <input type="text" className="form-control" {...field} />
                </InputWrapper>
              );
            }}
          />

          <h3 className="mt-3">Цена и размерная сетка</h3>
          {fields.map((field, index) => (
            <div
              className="col-12 mb-3 mt-3 d-flex justify-content-between"
              key={field.id}
            >
              <div className="col-10">
                <div className="rs-text-green">№{index + 1}</div>

                <Controller
                  name={`sizesAndPrices.${index}.originalSize`}
                  control={control}
                  rules={{ ...required }}
                  render={({ field: { onChange, value }, fieldState }) => {
                    const handleOriginalSizeChange = (
                      selectedValue: string,
                    ) => {
                      const selectedSize = sizes.find(
                        (item) => item.id === Number(selectedValue),
                      );
                      onChange(selectedValue);

                      setValue(
                        `sizesAndPrices.${index}.footLength`,
                        String(selectedSize?.footLength) || '',
                      );
                      setValue(
                        `sizesAndPrices.${index}.size`,
                        String(selectedSize?.size) || '',
                      );
                    };

                    const options = sizes.map((item) => ({
                      label: item.originalSize,
                      value: item.id,
                    }));

                    return (
                      <>
                        <Select
                          value={value}
                          onChange={(e) =>
                            handleOriginalSizeChange(e.target.value)
                          }
                          options={options}
                          className="mt-3"
                          required
                          error={fieldState.error?.message}
                          title="Оригинальный размер"
                          placeholder="Укажите размер производителя"
                        />

                        <InputWrapper
                          title="Российский размер"
                          className="mt-3"
                          error={fieldState.error?.message}
                        >
                          <input
                            className="form-control bg-gray"
                            type="text"
                            readOnly={true}
                            value={watch(`sizesAndPrices.${index}.size`) || ''} // Убедитесь, что это строка
                            onChange={() => {}} // Пустая функция, так как поле readOnly
                          />
                        </InputWrapper>

                        <InputWrapper
                          title="Длина стопы"
                          className="mt-3"
                          error={fieldState.error?.message}
                        >
                          <div className="input-group">
                            <input
                              className="form-control bg-gray"
                              type="text"
                              readOnly={true}
                              value={
                                watch(`sizesAndPrices.${index}.footLength`) ||
                                ''
                              } // Убедитесь, что это строка
                              onChange={() => {}} // Пустая функция, так как поле readOnly
                            />
                            <span className="input-group-text">см</span>
                          </div>
                        </InputWrapper>
                      </>
                    );
                  }}
                />

                <Controller
                  control={control}
                  name={`sizesAndPrices.${index}.priceProduct`}
                  rules={{ ...required }}
                  render={({ field, fieldState }) => (
                    <InputWrapper
                      title="Цена"
                      className="mt-3"
                      required
                      error={fieldState.error?.message}
                    >
                      <div className="input-group">
                        <input
                          className="form-control"
                          type="number"
                          {...field}
                        />
                        <span className="input-group-text">
                          {getCurrencySymbol(currency)}
                        </span>
                      </div>
                    </InputWrapper>
                  )}
                />
              </div>
              <div className="d-flex flex-row align-items-center">
                <i
                  className="bi bi-trash p-2 cursor-pointer hover-red"
                  onClick={() => remove(index)}
                />
              </div>
            </div>
          ))}
          <div className="mb-3">
            <AddButton
              onClick={() =>
                append({
                  size: '',
                  originalSize: '',
                  priceProduct: '',
                  footLength: '',
                })
              }
            >
              Добавить размер
            </AddButton>
          </div>

          <div className="col-12 pt-3 mt-5">
            <button
              type="submit"
              className="btn btn-success w-100 mb-5"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : itemInfo ? (
                'Сохранить'
              ) : (
                'Добавить'
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
