import { useState, useRef, ChangeEventHandler } from 'react';
import { Input } from '../../common/input.component';
import { AuthService } from '../../../services/auth.service';
import { AlertService } from '../../../services/alert.service';
import { ProfileService } from '../../../services/profile.service';
import React from 'react';
import moment from 'moment';

export const ForgotPasswordScreen = () => {
  const userId = useRef<string | null>(null);
  const interval = useRef<ReturnType<typeof setInterval> | null>(null);
  const timerRef = useRef<number>(60);

  const [timer, setTimer] = useState(timerRef.current);
  const [phone, setPhone] = useState('');
  const [step, setStep] = useState<'phone' | 'sms' | 'password'>('phone');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [checkPassword, setCheckPassword] = useState('');

  function initResetPassword() {
    AuthService.forgotPassword(phone).then(({ id, nextAttemptAt }) => {
      userId.current = id;
      setStep('sms');
      setCode('');
      startTimer(
        nextAttemptAt ? moment(nextAttemptAt).diff(Date.now(), 'seconds') : 60,
      );
    });
  }

  function startTimer(seconds: number) {
    timerRef.current = seconds;
    interval.current = setInterval(() => {
      timerRef.current = timerRef.current - 1;
      setTimer(timerRef.current);
      if (timerRef.current === 0) {
        clearInterval(interval.current!);
      }
    }, 1000);
  }

  const onChangeCode: ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    if (value.length > 4) {
      return;
    }
    setCode(value);
    if (value.length === 4) {
      AuthService.checkCode(userId.current!, value)
        .then(() => {
          setStep('password');
        })
        .catch(() => {
          setCode('');
        });
    }
  };

  function resetPassword() {
    if (password !== checkPassword) {
      return AlertService.error('Пароли не совпадают');
    }

    AuthService.setPassword(userId.current!, code, password).then(() => {
      ProfileService.getCurrentUser();
      AlertService.success('Пароль изменен');
    });
  }

  const smsBlock = (
    <>
      <Input
        disabled={step !== 'sms'}
        title="Введите код"
        value={code}
        onChange={onChangeCode}
        type="text"
      />
      {!!timer && (
        <div className="fs-14 color-gray-600 mb-4">
          Запросить код повторно можно через {timer} сек
        </div>
      )}
      {!timer && (
        <div
          className="cursor-pointer btn-link d-block link-success mb-4"
          onClick={initResetPassword}
        >
          Выслать повторно
        </div>
      )}
    </>
  );

  const passwordBlock = (
    <>
      <div className="mb-4" />
      <Input
        className="mb-4"
        title="Придумайте пароль"
        value={password}
        onChange={({ target: { value } }) => setPassword(value)}
        type="password"
      />
      <Input
        className="mb-4"
        title="Повторите пароль"
        value={checkPassword}
        onChange={({ target: { value } }) => setCheckPassword(value)}
        type="password"
      />
      <div className="btn btn-success w-100" onClick={resetPassword}>
        Продолжить
      </div>
    </>
  );

  return (
    <div className="full-height d-flex flex-column justify-content-between">
      <div />
      <div className="flex-grow-1 d-flex justify-content-center align-items-center">
        <div className="auth-wrapper">
          <h3 className="mb-1">Восстановление пароля</h3>
          <div className="color-gray-600 mb-4">
            Введите номер телефона, мы пришлем смс с кодом для восстановления
            пароля.
          </div>
          <Input
            title="Номер телефона"
            className="mb-4"
            placeholder="+7"
            disabled={step !== 'phone'}
            onChange={({ target: { value } }) => setPhone(value)}
            mask="+7 (999) 999-99-99"
            value={phone}
          />
          {step === 'phone' && (
            <div className="btn btn-success w-100" onClick={initResetPassword}>
              Восстановить пароль
            </div>
          )}
          {step === 'sms' && smsBlock}
          {step === 'password' && (
            <>
              {smsBlock}
              {passwordBlock}
            </>
          )}
        </div>
      </div>
      <div />
    </div>
  );
};
