import React from 'react';
import Table from 'rsuite/Table';
import Toggle from 'rsuite/Toggle';

type CellProps =
  typeof Table.Cell extends React.ComponentType<infer T> ? T : never;

type ToggleCellProps = Omit<CellProps, 'children' | 'onChange'> & {
  onChange:
    | ((rowData: any, checked: boolean) => void)
    | ((rowData: any, checked: boolean) => Promise<any>);
  dataKey: string;
  inverted?: boolean;
  disabled?: boolean | ((rowData: CellProps['rowData']) => boolean);
};

export const ToggleCell: React.FC<ToggleCellProps> = ({
  rowData,
  dataKey,
  onChange,
  disabled,
  inverted,
  ...props
}) => {
  const [loading, setLoading] = React.useState(false);

  const handleChange = React.useCallback(
    (value: boolean) => {
      const result = onChange(rowData, inverted ? !value : value);

      if (result && result.then) {
        setLoading(true);

        result.then(
          () => {
            setLoading(false);
          },
          () => {
            setLoading(false);
          },
        );
      }
    },
    [onChange, rowData, inverted],
  );

  if (!rowData) return null;

  return (
    <Table.Cell {...props}>
      <Toggle
        checked={
          typeof rowData[dataKey] === 'undefined'
            ? false
            : inverted
              ? !rowData[dataKey]
              : rowData[dataKey]
        }
        onChange={handleChange}
        loading={loading}
        disabled={typeof disabled === 'function' ? disabled(rowData) : disabled}
      />
    </Table.Cell>
  );
};
