import React from 'react';
import { useNavigate } from 'react-router-dom';
import Popover from 'rsuite/Popover';
import Dropdown from 'rsuite/Dropdown';
import Whisper from 'rsuite/Whisper';
import { CleanPurchaseOrder } from '../../export-types/cleaned-types';
import { RequestEditForm } from '../screens/purchase-order/request-edit-form';

type supplierInfoRequestActionsProps = {
  order: CleanPurchaseOrder;
  onChange: () => void;
  onEdit: () => void;
};

export const SupplierInfoRequestActions: React.FC<
  supplierInfoRequestActionsProps
> = (props) => {
  const speaker = (
    { onClose, left, top, className }: any,
    ref: React.Ref<any>,
  ) => (
    <Popover ref={ref} className={className} style={{ left, top }} full>
      <Dropdown.Menu>
        <Dropdown.Item
          onSelect={function () {
            onClose();
            props.onEdit();
          }}
        >
          <i className="bi bi-link" />
          <span className="ps-2">Изменить поставщика</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Popover>
  );

  return (
    <Whisper placement="bottomEnd" trigger="click" speaker={speaker}>
      <button className="btn btn-link text-decoration-none">
        <i className="bi bi-three-dots" />
      </button>
    </Whisper>
  );
};
