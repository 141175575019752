import React from 'react';
import { FormattedMessage } from 'react-intl';

type TrackNumberCellProps = {
  trackNumber: string;
};

export const TrackNumberCell: React.FC<TrackNumberCellProps> = ({
  trackNumber,
}) => {
  return (
    <div>
      <div className="color-gray-800 fs-16 fw-500">{trackNumber}</div>
      <div className="color-gray-600 fs-14 fw-400">
        <FormattedMessage
          defaultMessage="Трек-номер"
          id="parcelInfo.label.trackNumber"
          description="Информация о трек-номере"
        />
      </div>
    </div>
  );
};
