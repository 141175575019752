import React from 'react';
import Popover from 'rsuite/Popover';
import Dropdown from 'rsuite/Dropdown';
import Whisper from 'rsuite/Whisper';
import { CleanDeliveryOrder } from '../../../export-types/cleaned-types';

type RequestInfoActionsProps = {
  onEditAddress: () => void;
  onEditDeliveryMethod: () => void;
  order?: CleanDeliveryOrder;
};

export const DeliveryOrderRequestInfoActions: React.FC<
  RequestInfoActionsProps
> = (props) => {
  const speaker = (
    { onClose, left, top, className }: any,
    ref: React.Ref<any>,
  ) => (
    <Popover ref={ref} className={className} style={{ left, top }} full>
      <Dropdown.Menu>
        {props.order?.status === 'assembly' && (
          <Dropdown.Item
            onSelect={function () {
              onClose();
              props.onEditDeliveryMethod();
            }}
          >
            <i className="bi bi-pencil" />
            <span className="ps-2">Изменить способ доставки</span>
          </Dropdown.Item>
        )}
        <Dropdown.Item
          onSelect={function () {
            onClose();
            props.onEditAddress();
          }}
        >
          <i className="bi bi-pencil" />
          <span className="ps-2">Изменить адрес</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Popover>
  );

  return (
    <Whisper placement="bottomEnd" trigger="click" speaker={speaker}>
      <button className="btn btn-link text-decoration-none">
        <i className="bi bi-three-dots" />
      </button>
    </Whisper>
  );
};
