import { api } from './api';
import { FormType } from '../components/screens/delivery/delivery-insurance-screen.components';

export class DeliveryInsuranceService {
  static deleteDeliveryInsurance(id: string) {
    return api.delete(`/delivery-insurance/${id}`);
  }

  static createDeliveryInsurance(deliveryInsurance: FormType) {
    return api
      .post('/delivery-insurance', deliveryInsurance)
      .then(({ data }) => {
        return data;
      });
  }
  static updateDeliveryInsurance(
    deliveryInsurance: Partial<FormType>,
    id: string,
  ) {
    return api
      .put(`/delivery-insurance/${id}`, deliveryInsurance)
      .then(({ data }) => {
        return data;
      });
  }
}
