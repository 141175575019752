import { useSuppliers } from '../../../api/suppliers';
import React from 'react';
import Table from 'rsuite/Table';
import { CleanSupplier } from '../../../export-types/cleaned-types';
import { ModalsService } from '../../../services/modals.service';
import { SupplierModal } from './supplier-modal.component';
import { ToggleCell } from '../../common/table/toggle-cell';
import { api } from '../../../services/api';
import { AlertService } from '../../../services/alert.service';

export const SuppliersTable = () => {
  const { data: items, isLoading, mutate } = useSuppliers();

  async function handleEdit(supplier: CleanSupplier) {
    await ModalsService.createModal(SupplierModal, { supplier });
  }

  async function toggleDisable(rowData: CleanSupplier, value: boolean) {
    await mutate(
      api.put(`/supplier/${rowData.id}`, {
        disabled: value,
      }),
      {
        optimisticData: [
          ...(items ? items.filter((item) => item.id !== rowData.id) : []),
          { ...rowData, disabled: value },
        ],
      },
    );
    AlertService.success();
  }

  return (
    <Table data={items} headerHeight={57} autoHeight loading={isLoading}>
      <Table.Column width={60}>
        <Table.HeaderCell>Id</Table.HeaderCell>
        <Table.Cell dataKey={'id'} />
      </Table.Column>
      <Table.Column fullText width={200}>
        <Table.HeaderCell>Название</Table.HeaderCell>
        <Table.Cell dataKey={'name'} />
      </Table.Column>
      <Table.Column fullText width={200}>
        <Table.HeaderCell>Локация</Table.HeaderCell>
        <Table.Cell>
          {(rowData) =>
            (rowData as CleanSupplier).location ? rowData.location.name : ''
          }
        </Table.Cell>
      </Table.Column>
      <Table.Column fullText width={200}>
        <Table.HeaderCell>Адрес</Table.HeaderCell>
        <Table.Cell dataKey={'addressForParcels'} />
      </Table.Column>
      <Table.Column width={150}>
        <Table.HeaderCell>Валюта выкупов</Table.HeaderCell>
        <Table.Cell dataKey={'purchaseCurrency'} />
      </Table.Column>
      <Table.Column width={150}>
        <Table.HeaderCell>Приоритет</Table.HeaderCell>
        <Table.Cell dataKey={'priority'} />
      </Table.Column>
      {/*<Table.Column fullText>*/}
      {/*  <Table.HeaderCell>Валюта доставок</Table.HeaderCell>*/}
      {/*  <Table.Cell dataKey={'deliveryCurrency'} />*/}
      {/*</Table.Column>*/}
      <Table.Column flexGrow={1} minWidth={100}>
        <Table.HeaderCell>Включен</Table.HeaderCell>
        <ToggleCell
          dataKey="disabled"
          inverted={true}
          onChange={toggleDisable}
        />
      </Table.Column>
      <Table.Column width={150}>
        <Table.HeaderCell children="" />
        <Table.Cell>
          {(rowData) => {
            return (
              <button
                className="btn btn-sm"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  return handleEdit(rowData as CleanSupplier);
                }}
              >
                <i className="bi bi-pencil" />
                <span className="ps-2">Изменить</span>
              </button>
            );
          }}
        </Table.Cell>
      </Table.Column>
    </Table>
  );
};
