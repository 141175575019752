import { useCallback } from 'react';
import { MarkupTable } from '../finance/markup-table.component';
import { CommonService } from '../../../services/common.service';
import { ModalsService } from '../../../services/modals.service';
import { AlertService } from '../../../services/alert.service';
import { MarkupModal } from '../finance/markup-modal.component';
import React from 'react';
import { useDeliveryMarkup } from '../../../api/delivery';
import { CleanDeliveryOrderDeliveryMarkup } from '../../../export-types/cleaned-types';
import { MarkupLimitInfo } from '../finance/markup-limit-info.component';

export const DeliveryMarkupsTab = () => {
  const { data: markups, isLoading, mutate } = useDeliveryMarkup();

  const onEdit = useCallback(
    (markup: CleanDeliveryOrderDeliveryMarkup) => {
      ModalsService.createModal(MarkupModal, {
        markup,
        title: 'Комиссия за доставку',
      })
        .then((markup) => {
          return CommonService.updateDeliveryMarkup(markup.id, markup);
        })
        .then(() => {
          AlertService.success();
          mutate();
        });
    },
    [mutate],
  );

  const onDelete = useCallback(
    (id: string) => {
      CommonService.deleteDeliveryMarkup(id).then(() => {
        AlertService.success();
        mutate();
      });
    },
    [mutate],
  );

  return (
    <>
      <MarkupTable
        isLoading={isLoading}
        markups={markups ?? []}
        onEdit={onEdit}
        currency="$"
        onDelete={onDelete}
      />
      <MarkupLimitInfo />
    </>
  );
};
