import {
  ProfitReportUrlParams,
  ReconciliationActUrlParams,
} from '../../../utils/common.utils';
import {
  getCurrentDateValue,
  parseRawDateValue,
} from '../../../utils/date-value';
import { useMemo } from 'react';

type PartParams = {
  kind: string;
  start?: string;
  end?: string;
};

type Params = PartParams & (ReconciliationActUrlParams | ProfitReportUrlParams);

export type Period =
  | 'all_time'
  | 'week'
  | 'month'
  | 'year'
  | 'last_week'
  | 'last_month'
  | 'last_year'
  | 'range';
export const periods: Array<{ title: string; id: Period }> = [
  { title: 'За все время', id: 'all_time' },
  { title: 'Текущая неделя', id: 'week' },
  { title: 'Текущий месяц', id: 'month' },
  { title: 'Этот год', id: 'year' },
  { title: 'Прошлая неделя', id: 'last_week' },
  { title: 'Прошлый месяц', id: 'last_month' },
  { title: 'Прошлый год', id: 'last_year' },
  { title: 'Выбрать диапазон', id: 'range' },
];

export const useReportLink = (
  isValid: boolean,
  period: string,
  params: Params,
  reportUrlGenerator: (params: Params) => string,
  customStart?: string | undefined,
  customEnd?: string | undefined,
) => {
  const reportLink = useMemo(() => {
    if (!isValid) return null;

    switch (period) {
      case 'all_time':
        return reportUrlGenerator(params);
      case 'range':
        if (!isValid || (!customStart && !customEnd)) return null;

        return reportUrlGenerator({
          ...params,
          start: customStart
            ? parseRawDateValue(customStart).startOf('day').valueOf().toString()
            : undefined,
          end: customEnd
            ? parseRawDateValue(customEnd).endOf('day').valueOf().toString()
            : undefined,
        });
      case 'week':
        return reportUrlGenerator({
          ...params,
          start: getCurrentDateValue().startOf('isoWeek').valueOf().toString(),
        });
      case 'month':
        return reportUrlGenerator({
          ...params,
          start: getCurrentDateValue().startOf('month').valueOf().toString(),
        });
      case 'year':
        return reportUrlGenerator({
          ...params,
          start: getCurrentDateValue().startOf('year').valueOf().toString(),
        });
      case 'last_week':
        return reportUrlGenerator({
          ...params,
          start: getCurrentDateValue()
            .subtract(1, 'week')
            .startOf('isoWeek')
            .valueOf()
            .toString(),
          end: getCurrentDateValue()
            .subtract(1, 'week')
            .endOf('isoWeek')
            .valueOf()
            .toString(),
        });
      case 'last_month':
        return reportUrlGenerator({
          ...params,
          start: getCurrentDateValue()
            .subtract(1, 'month')
            .startOf('month')
            .valueOf()
            .toString(),
          end: getCurrentDateValue()
            .subtract(1, 'month')
            .endOf('month')
            .valueOf()
            .toString(),
        });
      case 'last_year':
        return reportUrlGenerator({
          ...params,
          start: getCurrentDateValue()
            .subtract(1, 'year')
            .startOf('year')
            .valueOf()
            .toString(),
          end: getCurrentDateValue()
            .subtract(1, 'year')
            .endOf('year')
            .valueOf()
            .toString(),
        });
      default:
        return null;
    }
  }, [isValid, period, params, customStart, customEnd]);

  return reportLink;
};
