import React from 'react';
import {
  getDeliveryMethodIcon,
  getDeliveryPeriod,
} from '../../../utils/common.utils';

type TariffCellProps = {
  deliveryMethod: {
    method: string;
    deliveryPeriodFrom: number;
  };
};

export const TariffCell: React.FC<TariffCellProps> = ({ deliveryMethod }) => (
  <>
    <img
      src={getDeliveryMethodIcon(deliveryMethod.method)}
      className="me-3"
      alt=""
    />
    <span>{getDeliveryPeriod(deliveryMethod)}</span>
  </>
);
