import React from 'react';
import { InputWrapper } from '../../common/input-wrapper';
import { Gallery } from '../../common/gallery/gallery';
import { getFileV2ApiUrl } from '../../../utils/common.utils';
import { fetcher } from '../../../services/api';
import useSWR from 'swr';
import PdfIcon from '../../../assets/pdf-file.svg';
import { RequestInfoActions } from './request-info-actions';
import { RequestEditForm } from './request-edit-form';
import {
  CleanPurchaseOrder,
  RbacObject,
} from '../../../export-types/cleaned-types';
import { Rbac } from '../../common/Rbac';
import { useProfile } from '../../../api/profile';
import { FormattedMessage, useIntl } from 'react-intl';
import { OtcVendorInfoBlock } from './otc-vendor-info';

type RequestInfoProps = {
  id: string;
  actions?: React.ReactNode;
};

export const PurchaseOrderRequest: React.FC<RequestInfoProps> = (props) => {
  const intl = useIntl();
  const { data: user } = useProfile();
  const { data: order, mutate: orderMutate } = useSWR<CleanPurchaseOrder>(
    `/purchase-order/${props.id}`,
    fetcher,
  );

  const [editMode, setEditMode] = React.useState(false);

  if (!order || !user) {
    return null;
  }

  function handleChange() {
    orderMutate();
  }

  function handleSave(data: CleanPurchaseOrder) {
    orderMutate(data);
    setEditMode(false);
  }

  function handleEdit() {
    setEditMode(true);
  }

  function handleCancel() {
    setEditMode(false);
  }

  if (editMode) {
    return (
      <RequestEditForm
        id={order.id}
        defaultValues={{
          name: order.name || '',
          link: order.link || '',
          requestFiles: order.requestFilesV2.map((file) => ({
            type: 'exist',
            file,
          })),
          requestCount: order.requestCount || null,
          comment: order.comment || '',
          autoSend: order.autoSend,
        }}
        onSave={handleSave}
        onCancel={handleCancel}
      />
    );
  }

  const requestInfoActions = (
    <RequestInfoActions
      order={order}
      onChange={handleChange}
      onEdit={handleEdit}
    />
  );

  const link = order.productCard ? order.productCard.link : order.link;
  return (
    <div>
      <div className="d-flex flex-row justify-content-between">
        <div className="fs-18 fw-600 color-gray-450">
          <FormattedMessage
            defaultMessage="О заказе"
            id="orderInfo.label.aboutOrder"
            description="Информация о заказе"
          />
        </div>

        {order.status !== 'canceled' && (
          <Rbac
            object={RbacObject.Purchase}
            action={'write:admin'}
            children={requestInfoActions}
            otherwise={() => {
              if (order.status === 'waitingForPayment') {
                return (
                  <Rbac
                    object={RbacObject.Purchase}
                    action={'write:while-waiting-on-payment'}
                  >
                    {requestInfoActions}
                  </Rbac>
                );
              }
              if (order.status === 'inWork') {
                return (
                  <Rbac
                    object={RbacObject.Purchase}
                    action={'write:while-in-work'}
                  >
                    {requestInfoActions}
                  </Rbac>
                );
              }
              return null;
            }}
          />
        )}
      </div>
      <InputWrapper
        theme="light"
        title={intl.formatMessage({
          defaultMessage: 'Автоотправка',
          id: 'purchaseOrderRequest.label-input.autoSend',
          description: 'Поле ввода',
        })}
      >
        {order.autoSend ? (
          <FormattedMessage
            defaultMessage="Включена"
            id="purchaseOrderRequest.label.Enabled"
            description="Информация об автоотправке товара"
          />
        ) : (
          <FormattedMessage
            defaultMessage="Отключена"
            id="purchaseOrderRequest.label.Disabled"
            description="Информация об автоотправке товара"
          />
        )}
      </InputWrapper>
      {order.name && (
        <div className="mt-3">
          <div className="mb-1 color-gray-600">
            <FormattedMessage
              defaultMessage="Название"
              id="purchaseOrderRequest.label.name"
              description="Информация о товаре"
            />
          </div>
          <div className="text-black text-wrap text-break">{order.name}</div>
        </div>
      )}
      {link ? (
        <InputWrapper
          theme="light"
          className="mt-3 text-truncate"
          title={intl.formatMessage({
            defaultMessage: 'Ссылка на товар',
            id: 'purchaseOrderRequest.label-input.goodsLink',
            description: 'Поле для загрузки фотографий',
          })}
        >
          <a
            className="text-black text-wrap text-break"
            href={link}
            target="_blank"
            rel="noreferrer"
          >
            {link}
          </a>
        </InputWrapper>
      ) : null}

      {order.requestCount && (
        <InputWrapper
          theme="light"
          className="mt-3"
          title={intl.formatMessage({
            defaultMessage: 'Количество товаров',
            id: 'purchaseOrderRequest.input-label.numberOfGoods',
            description: 'Заголовок поля ввода',
          })}
        >
          {order.requestCount} шт
        </InputWrapper>
      )}
      {order.requestFilesV2.length > 0 && (
        <InputWrapper
          theme="light"
          className="mt-3"
          title={intl.formatMessage({
            defaultMessage: 'Фотографии с модификациями товара',
            id: 'purchaseOrderRequest.label-input.photosWithGoodsModifications',
            description: 'Поле для загрузки фотографий',
          })}
        >
          <Gallery className="mt-2">
            {order.requestFilesV2.map((file, index) => (
              <a
                href={getFileV2ApiUrl(file.id)}
                key={index}
                data-iframe={file.type === 'pdf'}
              >
                <img
                  className="rounded fit-cover me-2"
                  src={
                    file.type === 'pdf'
                      ? PdfIcon
                      : getFileV2ApiUrl(file.id, '64x64')
                  }
                  width={64}
                  height={64}
                  alt=""
                />
              </a>
            ))}
          </Gallery>
        </InputWrapper>
      )}
      {order.comment && (
        <InputWrapper
          theme="light"
          className="mt-3"
          title={intl.formatMessage({
            defaultMessage: 'Комментарий',
            id: 'purchaseOrderRequest.label-input.comment',
            description: 'Поле для комментария',
          })}
        >
          <div className="text-break" style={{ whiteSpace: 'pre-line' }}>
            {order.comment.startsWith('1688Flag') ? (
              <>
                {order.comment
                  .replace('1688Flag', '')
                  .replace(
                    user.accesses.includes('fulfillment')
                      ? /\n?\s*(总价|Итого\sстоимость):\s?\d+(\.\d{1,2})?\s?¥/g
                      : '',
                    '',
                  )
                  .replace(/\d+\)\s*https?:\/\/[^\s]+/g, '')
                  .split(/(https?:\/\/[^\s]+)/)
                  .map((part, index) =>
                    part.startsWith('http') ? (
                      <React.Fragment key={index}>
                        <a
                          key={index}
                          href={part}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img width={100} height={100} src={part} alt="" />
                        </a>
                      </React.Fragment>
                    ) : (
                      part
                    ),
                  )}
              </>
            ) : (
              <>{order.comment}</>
            )}
          </div>
        </InputWrapper>
      )}

      <OtcVendorInfoBlock order={order} />

      {order.productCardId ? (
        <div className="mt-3">
          <FormattedMessage
            defaultMessage="Заказ из каталога ExpressToday"
            id="purchaseOrderRequest.label-input.OrderFromTheExpressTodayCatalog"
            description="Информация о заказе"
          />
        </div>
      ) : null}
    </div>
  );
};
