import { api } from './api';
import { state } from '../state';

const userCursor = state.select('user');

type User = {
  name: string;
  email: string;
};

export class ProfileService {
  static getCurrentUser() {
    return api.get('/profile').then(({ data }) => {
      userCursor.set(data);
      return data;
    });
  }

  static updateProfile(user: User) {
    return api.put('/profile', { user }).then(({ data }) => {
      userCursor.set(data);
      return data;
    });
  }

  static setPassword(data: unknown) {
    return api.post('/profile/password', data);
  }
}
